/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import titleIcon from '../../../../Assets/titleIcon.png';
import nameIcon from '../../../../Assets/nameIcon.png';
import emailIcon from '../../../../Assets/emailIcon.png';
import phoneIcon from '../../../../Assets/phoneIcon.png';
import './SourcingForm.css';
import { useBriefingContext } from '../../../../Contexts/BriefingContext';

const ModalProviderDetail = props => {
  const [sourceSupplierContact, setSourceSupplierContact] = useState([]);
  const [sourceSupplierProduct, setSourceSupplierProduct] = useState([]);
  const modalFornecedor = document.getElementsByClassName('new');
  const { getProvider } = useBriefingContext();

  async function getData() {
    const resp = await getProvider(props.id);
    setSourceSupplierContact(resp.data.contacts);
    setSourceSupplierProduct(resp.data.products);
  }

  useEffect(() => {
    getData();
  }, []);

  function closeForce() {
    for (var i = 0; i < modalFornecedor.length; i += 1) {
      modalFornecedor[i].style.display = 'none';
    }
  }

  return (
    <div id={props.id} className="modal new modal-sourcing">
      <div
        className="modal-content modal-content1"
        id="bgFornecedor">
        <button
          id="closeFornecedor"
          className="btn btnOrange btnLarge modal-close"
          onClick={closeForce}>
          Voltar
        </button>
        <br clear="all" />
        <span>
          <img alt="title" src={titleIcon} /> {props.name}
        </span>
        <br />
        <br />
        <span>Tipos de Produto</span>
        <br />
        <br />
        <div>
          {sourceSupplierProduct &&
            sourceSupplierProduct.map(products => (
              <span key={products.id} className="text-badge-gray">
                {products.name}
              </span>
            ))}
        </div>
        <br />
        <br />
        <br />
        <span>Contatos</span>
        <br />
        <br />
        {sourceSupplierContact &&
          sourceSupplierContact.map(contact => (
            <div key={contact.id}>
              <span className="text-badge-gray">
                <img alt="name" src={nameIcon} /> {contact.name}
              </span>
              {contact.contacts_info &&
                contact.contacts_info.map((contactsInfo, i) => (
                  <span key={`contact-${i}`} className="text-badge-gray">
                    <img
                      alt="email"
                      src={
                        contactsInfo.contact_type === 'Email'
                          ? emailIcon
                          : phoneIcon
                      }
                    />{' '}
                    {contactsInfo.value}
                  </span>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ModalProviderDetail;
