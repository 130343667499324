import React from 'react';
// import TagManager from 'react-gtm-module';
// import ReactGA from 'react-ga';
import Login from './Pages/Login/Login';
import Home from './Pages/Home/Home';
import NotFound from './Pages/NotFound/NotFound';
import ProtectedElement from './Components/Helpers/ProtectedElement';
import Pdm from './Pages/Pdm/Pdm';
import Indicators from './Pages/Indicators/Indicators';
import CommercialManagement from './Pages/CommercialManagement/CommercialManagement';
import InovAmicci from './Pages/InovAmicci/InovAmicci';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Animations.css';
import './bootstrap.css';
import './rsuite.css';
import './App.css';
import Amplify from 'aws-amplify';
import amplify_config from './amplifyConfig';
import ForgoutPasswordCognito from './Pages/Login/ForgoutPasswordCognito';
import SessionExpired from './Pages/SessionExpired/SessionExpired';
import TagManager from 'react-gtm-module';

Amplify.configure(amplify_config);

function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-52VMHWZ',
  };
  TagManager.initialize(tagManagerArgs);
  // ReactGA.initialize(process.env.REACT_APP_FRONT_GA);
  // ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="app">
      <BrowserRouter>
        {/* <Header /> */}

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/sessao-expirada" element={<SessionExpired />} />
          <Route path="/esqueceu-senha" element={<ForgoutPasswordCognito />} />

          <Route
            path="/home"
            element={
              <ProtectedElement>
                <Home />
              </ProtectedElement>
            }
          />
          <Route
            path="/pdm/*"
            element={
              <ProtectedElement>
                <Pdm />
              </ProtectedElement>
            }
          />

          <Route
            path="/indicadores/*"
            element={
              <ProtectedElement>
                <Indicators />
              </ProtectedElement>
            }
          />
          <Route
            path="/gestao-comercial/*"
            element={
              <ProtectedElement>
                <CommercialManagement />
              </ProtectedElement>
            }
          />
          <Route
            path="/inov"
            element={
              <ProtectedElement>
                <InovAmicci />
              </ProtectedElement>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
