import React, { useState } from 'react';
// import ReactGA from 'react-ga';
import styles from './styles.module.css';
import { Col, Button, Modal, Icon, Row, Container, Alert } from 'rsuite';
import apiConnectSourcing from '../../../../../../../../services/apiConnectSourcing';
import FileSaver from 'file-saver';

function ModalExportMassive({ show, closeModal, prospection }) {
  // ReactGA.pageview('/pdm');
  const [loading, setLoading] = useState(false);
  const handleExportMassiveCost = () => {
    setLoading(true);
    apiConnectSourcing
      .get(`/prospections/${prospection?.id}/export-cost-massive/`, {
        responseType: 'arraybuffer',
      })
      .then(response => {
        if (response.data) {
          var blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          FileSaver.saveAs(blob, `documentos-de-custo.xlsx`);
        } else {
          Alert.error(
            'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
            3000
          );
        }
        setLoading(false);
      })
      .catch(error => {
        if (error.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado. Tente novamente, mais tarde!'
          );
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Modal size="xs" show={show} onHide={closeModal}>
        <Modal.Header>
          <Container>
            <Row className={styles.iconTittle}>
              <Col className={styles.modalIcon} md={5}>
                <Icon icon="bell-o" size="lg" />
              </Col>
              <Col className={styles.modalTittle} md={19}>
                Exportar Arquivos
              </Col>
            </Row>
          </Container>
        </Modal.Header>

        <Modal.Body className={styles.body}>
          <Container>
            <Row>
              <Col md={8}>
                <Button
                  block
                  loading={loading}
                  onClick={() => handleExportMassiveCost()}
                  className={styles.buttonCost}>
                  Custo
                </Button>
              </Col>

              {/* <Col md={8} mdOffset={8}>
                <a
                  href={
                    apiConnectSourcing.defaults.baseURL +
                    `prospections/${prospection?.id}/export-checklist-massive/`
                  }>
                  <Button block className={styles.buttonChecklist}>
                    Check-List
                  </Button>
                </a>
              </Col> */}
            </Row>
          </Container>
        </Modal.Body>
        {/* <Modal.Footer>
            <Row>

              <Col md={8} mdOffset={16}>
                <Row>
                  <Col md={12}>
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      appearance="primary"
                      loading={loading}
                    >
                      Confirmar
                    </Button>
                  </Col>
                  <Col md={12}>
                    <Button onClick={handleCancel} appearance="subtle">
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default ModalExportMassive;
