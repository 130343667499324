import React, { forwardRef, useImperativeHandle } from 'react';
// import './ModalSaving.scss';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import useToggle from '../../../../../../utils/hooks/useToggle';
import './ModalBriefingDelete.scss';

const ModalBriefingDelete = (
  { onConfirm = () => {}, loading = false },
  ref
) => {
  const [isOpen, toggle] = useToggle();

  useImperativeHandle(ref, () => ({ toggle }));

  return (
    <Modal
      size="sm"
      centered
      fade={false}
      toggle={toggle}
      isOpen={isOpen}
      className="modalSaving">
      <ModalHeader>
        <span className="font18 bold p-b-1 text-left">
          Exclusão de Briefing
        </span>
      </ModalHeader>
      <ModalBody className="modal-briefing-delete">
        <span>Tem certeza que deseja excluir o briefing?</span>
        <div className="button-container">
          <button
            className="action-button-red"
            onClick={toggle}
            disabled={loading}>
            Cancelar
          </button>
          <button
            className="action-button-blue"
            onClick={onConfirm}
            disabled={loading}>
            Confirmar
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default forwardRef(ModalBriefingDelete);
