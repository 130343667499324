import React, { useRef, useState } from 'react';
import './StagesView.scss';
import { Table } from 'reactstrap';
import ButtonDefault from '../../../../../../Components/ButtonDefault/ButtonDefault';
import { Message } from 'rsuite';
import { classNames, generateUniqueKey } from '../../../../../../utils/utils';
import moment from 'moment';
import ModalSteps from '../../ModalSteps/ModalSteps';
import ModalStageCompletion from '../../ModalStageCompletion/ModalStageCompletion';
import ModalNoApplicable from '../../ModalNoApplicable/ModalNoApplicable';
import ModalApplyStep from '../../ModalApplyStep/ModalApplyStep';
// import ModalModuleReopening from '../../ModalModuleReopening/ModalModuleReopening';
import ModalStepReopening from '../../ModalStepReopening/ModalStepReopening';
import ModalDayEdition from '../../ModalDayEdition/ModalDayEdition';
import ModalObservation from '../../ModalObservation/ModalObservation';
import ModalStartEanDun from '../../ModalStartEanDun/ModalStartEanDun';
import ModalRevisonSku from '../../ModalRevisonSku/ModalRevisonSku';
import ModalDateStep from '../../ModalDateStep/ModalDateStep';
//import { Alert } from 'rsuite';
//import { useFormik } from 'formik';
//import { useCognitoAuth } from '../../../../../../Contexts/AuthCognitoContext';
//import * as Yup from 'yup';

import {
  MODULE_TYPE,
  MODULE_STATUS,
  STAGE_STATUS,
  STAGE_TYPE,
} from '../../../constants/constants';
import { useManagementContext } from '../../../../../../Contexts/ManagementContext';
import { FaPen } from 'react-icons/fa';

//const now = new Date();

// const validationSchema = Yup.object({
//     date: Yup.string().required('Campo Obrigatório'),
//   });


const StagesView = ({
  moduleType,
  // moduleStages,
  setModuleStages,
  setProject,
  setModuleStatus,
  moduleStatus,
  disabled,
  readOnly,
  projectId,
  reloadProject = () => {},
}) => {
  const { stages, getPredecessorSteps } = useManagementContext();
  const modalStepsRef = useRef(null);
  const modalStageCompletionRef = useRef(null);
  const modalNoApplicableRef = useRef(null);
  const modalApplyStepRef = useRef(null);
  const modalStepReopeningRef = useRef(null);
  const modalObservationRef = useRef(null);
  const modalDayEditionRef = useRef(null);
  const modalStartEanDunRef = useRef(null);
  const modalRevisionSkusRef = useRef(null);
   const modalDateStepsRef = useRef(null);
  const [moduleStageModal, setModuleStageModal] = useState(null);
  const [initialDataObs, setInitialDataObs] = useState({
    obs: '',
    obsType: null,
  });

  const [hasPred, setHasPred] = useState();
  //const { user } = useCognitoAuth();

  //const [loadingConfirm, setLoadingConfirm] = useState(false);

  // const [showModal, setShowModal] = useState(false);
  // function closeModal() {
  //   setShowModal(!showModal);
  // }

  // console.log('id do projeto', projectId);
 // console.log('teste de reload');

  const buttonDaysToFinish = moduleStage => (
    <ButtonDefault
      onClick={() => {
        setModuleStageModal(moduleStage);
        if (modalDayEditionRef) {
          modalDayEditionRef.current.toggleModal();
        }
      }}
      disabled={
        moduleStage.status_stage.id === STAGE_STATUS.CONCLUDED ||
        moduleStage.status_stage.id === STAGE_STATUS.NOT_APPLICABLE ||
        moduleStage.delayed ||
        moduleStage.stage.stage_type.id === STAGE_STATUS.SNAPSHOT ||
        /* (moduleStage?.stage.auto_finish_when_started &&
          moduleStage?.stage.auto_start_when_finished) || */
        readOnly ||
        moduleStatus?.id === MODULE_STATUS.CONCLUDED
      }
      color={classNames({
        grey:
          !(
            (
              moduleStage.status_stage.id === STAGE_STATUS.CONCLUDED ||
              moduleStage.status_stage.id === STAGE_STATUS.NOT_APPLICABLE ||
              moduleStage.delayed ||
              moduleStage.stage.stage_type.id === STAGE_TYPE.SNAPSHOT
            ) /*||
             (moduleStage?.stage.auto_finish_when_started &&
              moduleStage?.stage.auto_start_when_finished) */
          ) ||
          readOnly ||
          moduleStatus?.id === MODULE_STATUS.CONCLUDED,
      })}
      size="xsmall"
      radius>
      <div className="innerBtnDaysToFinish">
        <FaPen />
        <span>{moduleStage.days_to_finish}</span>
      </div>
    </ButtonDefault>
  );

  const checkForPredecessorStep = async (id) => {
    const resp = await getPredecessorSteps(id);
    if (resp?.success) {
      const hp = resp.data.length > 0;
      setHasPred(hp);
      return hp;
    }
  }

  // console.log('hasPred', hasPred);
  // console.log('stage', stages);

  // const { handleSubmit, resetForm, values, error } = useFormik({
  //   initialValues: {
  //     date: moment(now).format('YYYY-MM-DD').toString(),
  //   },
  //   enableReinitialize: true,
  //   validateOnChange: false,
  //   validationSchema: validationSchema,
  //   onSubmit: values => {
  //     console.log('values', values);
  //   }
  // });

  // console.log('values fora', values);
  // //  onSubmit: values => {
  // //     onHandleConfirm(values);
  // //     console.log('values', values);
  // //   },

  // const onHandleConfirm = async values => {
  //   setLoadingConfirm(true);
  //   const body = {
  //     user: user,
  //     date: values.date,
  //   };
  //   console.log('body', body)
  //   const response = await changeStatus(
  //     moduleType,
  //     moduleStageModal?.project_module,
  //     moduleStageModal?.id,
  //     2,
  //     body
  //   );
  //   console.log('response', response)
  //   setLoadingConfirm(false);
  //   if (response.success) {
  //     resetForm();
  //     closeModal();
  //     reloadProjectAndModule();
  //   } else {
  //     Alert.error(response.message, 50000);
  //   }
  // };

  const checkPredecessorBeforeSubmit = (hasPred) => {
    if(hasPred) {
      modalDateStepsRef.current?.toggleModal();
    }
  }

  const buttonChangeStatus = moduleStage => (
    <ButtonDefault
      style={{ width: '100%' }}
      // disabled={
      //   moduleStage.status_stage.id === STAGE_STATUS.NOT_APPLICABLE ||
      //   moduleStage.delayed ||
      //   (moduleStage?.stage.auto_finish_when_started &&
      //     moduleStage?.stage.auto_start_when_finished &&
      //     moduleStage.status_stage.id !== STAGE_STATUS.CONCLUDED) ||
      //   (moduleStage?.stage.auto_finish_when_started &&
      //     moduleStage.status_stage.id === STAGE_STATUS.INITIATED) ||
      //   (moduleStage?.stage.auto_start_when_finished &&
      //     moduleStage.status_stage.id === STAGE_STATUS.NOT_STARTED) ||
      //   readOnly ||
      //   moduleStatus?.id === MODULE_STATUS.CONCLUDED ||
      //   (moduleStage.status_stage.id === STAGE_STATUS.CONCLUDED &&
      //     moduleStage.stage.stage_type.id === STAGE_TYPE.SNAPSHOT)
      // }
      disabled
      color={
        (moduleStage.status_stage.id === STAGE_STATUS.INITIATED ||
          moduleStage?.stage.stage_type.id === STAGE_TYPE.SNAPSHOT) &&
        moduleStage?.status_stage.id !== STAGE_STATUS.CONCLUDED
          ? 'blue'
          : moduleStage.status_stage.id === STAGE_STATUS.CONCLUDED
          ? 'orange'
          : 'green'
      }
      size="xsmall"
      onClick={async () => {
       // console.log('moduleStage', moduleStage);
        setModuleStageModal(moduleStage);
        const resp = await checkForPredecessorStep(moduleStage?.id);
       // console.log('resp da função', resp);
        if (moduleStage.status_stage.id === STAGE_STATUS.INITIATED) {
          if (modalStageCompletionRef && moduleStage.stage.id === 43) {
            modalRevisionSkusRef.current.toggleModal();
          } else if (modalStageCompletionRef) {
            modalStageCompletionRef.current.toggleModal();
          }
        } else if (moduleStage.status_stage.id === STAGE_STATUS.CONCLUDED) {
          if (modalStepReopeningRef) {
            modalStepReopeningRef.current.toggleModal();
          }
        } else if (hasPred) {
          if (hasPred && modalStepsRef) {
            modalStepsRef.current.toggleModal();
          }  
        } else {
          if (modalStartEanDunRef
              && (moduleStage.stage.id === 25 && resp === false)) {
            modalStartEanDunRef.current.toggleModal();
          } else if (modalDateStepsRef) {
            modalDateStepsRef.current.toggleModal();
          }
        }
      }}>
      {moduleStage.status_stage.id === STAGE_STATUS.INITIATED
        ? 'Finalizar'
        : moduleStage.status_stage.id === STAGE_STATUS.CONCLUDED
        ? 'Reabrir'
        : 'Iniciar'}
    </ButtonDefault>
  );

  const buttonObs = moduleStage => {
    const colorRed =
      (moduleStage.status_stage.id !== STAGE_STATUS.CONCLUDED ||
        moduleStage.status_stage.id !== STAGE_STATUS.NOT_APPLICABLE) &&
      moduleStage.delayed &&
      !readOnly;

    return (
      <ButtonDefault
        color={classNames({
          grey: !colorRed,
          red: colorRed,
        })}
        size="xsmall"
        disabled
        onClick={() => {
          setModuleStageModal(moduleStage);
          if (moduleStage?.last_activity) {
            setInitialDataObs({
              obs: moduleStage?.last_activity.obs,
              obsType: moduleStage?.last_activity.obs_type.id,
            });
          }

          if (modalObservationRef) {
            modalObservationRef.current.toggleModal();
          }
        }}
        radius
        opacity>
        <i className="fa fa-exclamation fa-2x"></i>
      </ButtonDefault>
    );
  };

  const buttonApplyStage = moduleStage => (
    <ButtonDefault
      disabled
      onClick={() => {
        setModuleStageModal(moduleStage);
        if (moduleStage.status_stage.id === STAGE_STATUS.NOT_APPLICABLE) {
          if (modalApplyStepRef) {
            modalApplyStepRef.current.toggleModal();
          }
        } else {
          if (modalNoApplicableRef) {
            modalNoApplicableRef.current.toggleModal();
          }
        }
      }}
      disabled
      color={
        moduleStage.status_stage.id === STAGE_STATUS.NOT_APPLICABLE
          ? 'blue'
          : 'orange'
      }
      size="xsmall">
      {moduleStage.status_stage.id === STAGE_STATUS.NOT_APPLICABLE
        ? 'Aplicar etapa'
        : 'Não se aplica'}
    </ButtonDefault>
  );

  return (
    <>
      <Table
        id="stages"
        borderless
        className={classNames({ 'disabled-component': disabled })}>
        <thead>
          <tr>
            <th>Etapa</th>
            <th>Data de inicio</th> 
            { /* 
              <th>Dias úteis conclusão</th>
              <th>Conclusão Prevista</th>
              <th>Conclusão Projetada</th>
              <th>Data final real</th>
              <th>Dias Utilizados</th>
            */ }
            <th></th>
            <th>Observação</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {stages.length > 0 ? (
            stages?.map(moduleStage => {
              return (
                <tr key={generateUniqueKey()}>
                  <td>
                    <div
                      className={classNames({
                        'stage-title': true,
                        'color-red': moduleStage.delayed,
                        'color-blue':
                          !moduleStage.delayed &&
                          moduleStage.status_stage.id ===
                            STAGE_STATUS.INITIATED,
                        'color-green':
                          !moduleStage.delayed &&
                          moduleStage.status_stage.id ===
                            STAGE_STATUS.CONCLUDED,
                      })}>
                      {moduleStage.stage.name}
                    </div>
                  </td>
                  <td>
                    <div className="tdTable">
                      {moduleStage.start_date
                        ? moment(moduleStage.start_date).format('DD/MM/YYYY')
                        : '00/00/0000'}
                    </div>
                  </td>
                  {/*
                    <td className="backWhite">
                      {buttonDaysToFinish(moduleStage)}
                    </td>
                    <td>
                      {moduleStage.finish_original_date
                        ? moment(moduleStage.finish_original_date).format(
                            'DD/MM/YYYY'
                          )
                        : '00/00/0000'}
                    </td>
                    <td>
                      <span>
                        {moduleStage.finish_forecast_date
                          ? moment(moduleStage.finish_forecast_date).format(
                              'DD/MM/YYYY'
                            )
                          : '00/00/0000'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleStage.finished_at
                          ? moment(moduleStage.finished_at).format('DD/MM/YYYY')
                          : '00/00/0000'}
                      </span>
                    </td>
                    <td>
                      <span color="grey" size="xsmall">
                        {moduleStage.used_days || '--'}
                      </span>
                    </td>
                  */}
                  <td className="backWhite">
                    {buttonChangeStatus(moduleStage)}
                  </td>
                  <td className="backWhite">{buttonObs(moduleStage)}</td>
                  <td className="backWhite">{buttonApplyStage(moduleStage)}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td style={{ backgroundColor: '#FFFFFF' }} colSpan="8">
                <Message
                  style={{ textAlign: 'center' }}
                  type="warning"
                  description="Não existe etapas para esse Módulo"
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {moduleType === MODULE_TYPE.AUDIT && (
        <div className="alert alert-primary mt-4 mb-0">
          * Essa etapa não faz parte da contagem do relatório
        </div>
      )}
      <ModalObservation
        initialDataObs={initialDataObs}
        setInitialDataObs={setInitialDataObs}
        moduleType={moduleType}
        moduleStage={moduleStageModal}
        setModuleStages={setModuleStages}
        ref={modalObservationRef}
        onSubmit={reloadProject}
      />
      <ModalNoApplicable
        moduleType={moduleType}
        moduleStage={moduleStageModal}
        setModuleStages={setModuleStages}
        setModuleStatus={setModuleStatus}
        setProject={setProject}
        ref={modalNoApplicableRef}
        onSubmit={reloadProject}
      />
      <ModalApplyStep
        moduleType={moduleType}
        moduleStage={moduleStageModal}
        setModuleStages={setModuleStages}
        ref={modalApplyStepRef}
        onSubmit={reloadProject}
      />
      <ModalSteps
        moduleType={moduleType}
        moduleStage={moduleStageModal}
        setModuleStages={setModuleStages}
        setModuleStatus={setModuleStatus}
        setProject={setProject}
        ref={modalStepsRef}
        onSubmit={() => checkPredecessorBeforeSubmit(hasPred)}
      />
      <ModalStartEanDun
        ref={modalStartEanDunRef}
        projectId={projectId}
      />
      <ModalStageCompletion
        moduleType={moduleType}
        moduleStage={moduleStageModal}
        setModuleStages={setModuleStages}
        ref={modalStageCompletionRef}
        // onSubmit={reloadProject}
      />
      <ModalStepReopening
        moduleType={moduleType}
        moduleStage={moduleStageModal}
        setModuleStages={setModuleStages}
        ref={modalStepReopeningRef}
        // onSubmit={reloadProject}
      />
      <ModalDayEdition
        moduleType={moduleType}
        moduleStage={moduleStageModal}
        setModuleStages={setModuleStages}
        ref={modalDayEditionRef}
        onSubmit={reloadProject}
      />
      <ModalRevisonSku
        ref={modalRevisionSkusRef}
        modalStageCompletionRef={modalStageCompletionRef}
        projectId={projectId}
        moduleType={moduleType}
        moduleStage={moduleStageModal}
        setModuleStages={setModuleStages}
        setModuleStatus={setModuleStatus}
        setProject={setProject}
      />
      <ModalDateStep ref={modalDateStepsRef}  moduleType={moduleType}
        moduleStage={moduleStageModal}/>
    </>
  );
};

export default StagesView;
