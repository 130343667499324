/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import styles from './Sourcing.module.css';
import { useCognitoAuth } from '../../../Contexts/AuthCognitoContext';
// import apiConnect from '../../../services/apiConnect';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'rsuite';
import MenuPdm from '../../../Components/Sidebar/MenuPdm';
import SourcingList from './SourcingList/SourcingList';
import SourcingForm from './SourcingForm/SourcingForm';
import ProviderSearch from './SourcingForm/ProviderSearch';
import { useBriefingContext } from '../../../Contexts/BriefingContext';

function Sourcing() {
  const {
    user: {
      attributes: { email },
    },
  } = useCognitoAuth();
  const navigate = useNavigate();
  const { user, getUser } = useBriefingContext();

  useEffect(() => {
    if (!user && email) {
      getUser(email);
    }
  }, []);

  useEffect(() => {
    if (user) {
      const { department_name } = user;
      if (!['SOURCING', 'DIGITAL', 'DIRETORIA'].includes(department_name)) {
        navigate('/pdm/');
        Alert.error('Você não tem acesso a essa área.');
      }
    }
  }, [user]);

  return (
    <>
      <Sidebar hideLogo>
        <MenuPdm opened="sourcing" />
      </Sidebar>
      <main className={styles.homeBriefing}>
        <Routes>
          <Route path="/" exact element={<SourcingList />} />
          <Route path="/:id" element={<SourcingForm />} />
          <Route path="/:id/suppliers" element={<ProviderSearch />} />
        </Routes>
      </main>
    </>
  );
}

export default Sourcing;
