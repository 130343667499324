/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { Alert } from 'rsuite';
import ButtonDefault from '../../../../../Components/ButtonDefault/ButtonDefault';
import './ModalDateStep.scss';
import { useCognitoAuth } from '../../../../../Contexts/AuthCognitoContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InlineCalendar from '../../../../../Components/InlineCalendar/InlineCalendar';
import { classNames } from '../../../../../utils/utils';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';
import useToggle from '../../../../../utils/hooks/useToggle';

const ModalDateStep = (
  {
    moduleType,
    moduleStage,
  },
  ref
) => {
  const { user } = useCognitoAuth();
  const {
    changeStatus,
    reloadProjectAndModule,
    getPredecessorSteps,
  } = useManagementContext();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const [, setPredecessorSteps] = useState([]);
  const [hasPredecessor, setHasPredecessor] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const [, toggle] = useToggle();
  
  useImperativeHandle(ref, () => ({ toggle }));

  const now = new Date();
  const [dateMin, setDateMin] = useState(
    moment(now).subtract(730, 'days').format('YYYY-MM-DD').toString()
  );
  const [dateMax, setDateMax] = useState(
    moment(now).format('YYYY-MM-DD').toString()
  );
  const validationSchema = Yup.object({
    date: Yup.string().required('Campo Obrigatório'),
  });

  useEffect(() => {
    if (showModal) {
      loadPredecessorSteps();
    } else {
      setPredecessorSteps([]);
      setHasPredecessor(false);
    }
  }, [showModal]);

  const loadPredecessorSteps = async () => {
    const resp = await getPredecessorSteps(moduleStage?.id);
    if (resp?.success) {
      setPredecessorSteps(resp.data);
      const hp = resp.data.length > 0;
      setHasPredecessor(hp);
    }
  };

  const { handleSubmit, handleChange, values, errors, resetForm } = useFormik({
    initialValues: {
      date: moment(now).format('YYYY-MM-DD').toString(),
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      onHandleConfirm(values);
      //console.log('values', values)
    },
  });

  const onHandleConfirm = async values => {
    setLoadingConfirm(true);
    const body = {
      user: user,
      date: values.date,
    };
    const response = await changeStatus(
      moduleType,
      moduleStage?.project_module,
      moduleStage?.id,
      2,
      body
    );
    //console.log('response', response)
    setLoadingConfirm(false);
    if (response.success) {
      resetForm();
      closeModal();
      reloadProjectAndModule();
    } else {
      Alert.error(response.message, 50000);
    }
  };

  //console.log('values foraa', values);

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  function closeModal() {
    setShowModal(!showModal);
  }

  useEffect(() => {
    if (moduleStage?.stage.stage_type.id === 4) {
      setDateMin('');
    } else {
      setDateMin(
        moment(now).subtract(730, 'days').format('YYYY-MM-DD').toString()
      );
      setDateMax(moment(now).format('YYYY-MM-DD').toString());
    }
  }, [moduleStage]);

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={false}
      centered={true}
      className="custom-modal">
      <ModalHeader>
        <Row>
          <Col
            md={1}
            className={classNames({
              'icon-head-steps': true,
              'color-red': hasPredecessor,
            })}>
            <svg
              id="Icon"
              width="48.9"
              height="50.589"
              viewBox="0 0 43.9 45.589">
              <ellipse
                id="Ellipse_13"
                data-name="Ellipse 13"
                cx="21.95"
                cy="22.794"
                rx="21.95"
                ry="22.794"
              />
              <path
                id="Icon_awesome-check-circle"
                data-name="Icon awesome-check-circle"
                d="M27.8,14.182A13.62,13.62,0,1,1,14.182.563,13.62,13.62,0,0,1,27.8,14.182Zm-15.2,7.211,10.1-10.1a.879.879,0,0,0,0-1.243L21.469,8.8a.879.879,0,0,0-1.243,0l-8.241,8.241L8.138,13.2a.879.879,0,0,0-1.243,0L5.653,14.439a.879.879,0,0,0,0,1.243l5.711,5.711a.879.879,0,0,0,1.243,0Z"
                transform="translate(7.768 8.613)"
                fill="#fff"
              />
            </svg>
          </Col>
          <Col md={7} className="texts-modal">
            <h2
              className={classNames({
                'text-steps': true,
                'color-red': hasPredecessor,
              })}>
              {'Início da Etapa'}
            </h2>
            </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <>
          <p>
            Você está{' '}
            <span className="text-color-blue">iniciando</span> uma
            etapa. Ao clicar em confirmar, a data de início será a
            data do dia.
          </p>
          <section>
          {/* 
            <Row className="padding-top-20 padding-bottom-20 justify-content-center">
              <Col md={6} lg={4}>
                <div
                  style={{
                  border: '1px solid #1f4d74',
                  borderRadius: '5px',
                  }}>
                <InlineCalendar
                  date={values.date}
                  position={{ left: 0 }}
                  setDate={handleChange('date')}
                  minDate={dateMin}
                  maxDate={dateMax}
                  placeholder="00/00/0000"
                  dateStr={true}
                  classes={['date-in']}
                  disableWeekend
                  disableHolidays
                  />
                </div>
              </Col>
              <span
                style={{
                  paddingTop: 10,
                  color: '#1f4d74',
                  fontWeight: 'bold',
                }}>
                  Data de início
              </span>
            </Row>
            <Row>
              <span className="error">
                {errors.date ? errors.date : null}
              </span>
            </Row> 
          */}
          </section>
        </>
      </ModalBody>
      <ModalFooter>
        <ButtonDefault
          color="red"
          radius
          onClick={() => {
            closeModal();
            resetForm();
          }}>
           Voltar
        </ButtonDefault>
        <ButtonDefault
          disabled={loadingConfirm}
          loading={loadingConfirm}
          onClick={handleSubmit}
          radius
          color="green">
            Iniciar etapa
        </ButtonDefault>
      </ModalFooter>
    </Modal>
  );
};

export default forwardRef(ModalDateStep);