import axios from 'axios';
import getCognitoCredentials from '../Components/Helpers/getCognitoCredentials';
let ambiente = '';
if (process.env.REACT_APP_FRONT_ENV === 'LOCAL') {
  ambiente =
    'https://twlmvcmlnd.execute-api.us-east-1.amazonaws.com/viabilidade/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'DEV') {
  ambiente =
    'https://twlmvcmlnd.execute-api.us-east-1.amazonaws.com/viabilidade/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'HMG') {
  ambiente =
    'https://apis-hmg.amicci-digital.com.br/viabilidade/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'PROD') {
  ambiente =
    'https://apis.amicci.com.br/viabilidade/api';
  // 'https://twlmvcmlnd.execute-api.us-east-1.amazonaws.com/sourcing/api';
}

const apiConnectViability = axios.create({
  baseURL: ambiente,
});

apiConnectViability.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    const { idToken, accessToken } = getCognitoCredentials();
    if (error.response?.status === 403 && accessToken && idToken) {
      error.response.data.message = 'Você não tem acesso';
    }
    return Promise.reject(error);
  }
);

export default apiConnectViability;
