import React from 'react';
import Header from '../../Components/Header/Header';
import HeaderModule from '../../Components/HeaderModule/HeaderModule';

function InovAmicci() {
  const h = window.innerHeight;
  const height = h - 200;
  return (
    <>
      <Header classHeader="header-inov" />
      <HeaderModule classHeader="InovHeaderModule">InovAmicci</HeaderModule>
      <div className="mainDiv">
        <center>
          <iframe
            title="inov"
            width="100%"
            height={height}
            src="https://forms.office.com/Pages/ResponsePage.aspx?id=xFOG36z0lEeb0SNXwRKlSicYEsRRE2xDp7gHoBMXhdRUN1dDN0dCNEFTWFVLU1cyM1FNWlhXQTFOSSQlQCN0PWcu&embed=true"
            frameBorder="0"
            marginWidth="0"
            marginHeight="0"
            style={{ border: 'none', maxWidth: '100%', maxHeight: '100vh' }}
            allowFullScreen></iframe>
        </center>
      </div>
    </>
  );
}

export default InovAmicci;
