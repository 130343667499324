import React from 'react';
// import apiConnectIndicators from '../../services/apiConnectIndicators';

// const USERNAME = process.env.REACT_APP_INDICATORS_REFRESH_TOKEN_USER;
// const PASSWORD = process.env.REACT_APP_INDICATORS_REFRESH_TOKEN_PASS;

const IndicatorsTokenRefresh = () => {
  // const [indicators_token, setToken] = React.useState('');
  // React.useEffect(() => {
  //   apiConnectIndicators
  //     .request({
  //       url: '/token/',
  //       method: 'post',
  //       data: {
  //         username: USERNAME,
  //         password: PASSWORD,
  //       },
  //     })
  //     .then(function (res) {
  //       // setToken(res.data.access)
  //       window.localStorage.setItem('indicators-token', res.data.access);
  //     })
  //     .catch(function (res) {
  //       console.log(res.response);
  //     });
  // }, []);

  // setTimeout(() => {
  //   apiConnectIndicators
  //     .request({
  //       url: '/token/',
  //       method: 'post',
  //       data: {
  //         username: USERNAME,
  //         password: PASSWORD,
  //       },
  //     })
  //     .then(function (res) {
  //       // setToken(res.data.access)
  //       window.localStorage.setItem('indicators-token', res.data.access);
  //     })
  //     .catch(function (res) {
  //       console.log(res.response);
  //     });
  // }, 400000);
  return <></>;
};

export default IndicatorsTokenRefresh;
