import React, { useState } from 'react';
// import ReactGA from 'react-ga';
import Loading from '../../Components/Helpers/Loading';
import Sidebar from '../../Components/Sidebar/Sidebar';
import MenuIndicators from '../../Components/Sidebar/MenuIndicators';
import HeaderModule from '../../Components/HeaderModule/HeaderModule';
import homeImgIndicators from '../../Assets/homeImgIndicators.png';

function HomeDash() {
  // ReactGA.pageview('/indicadores');
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 1000);

  if (loading) return <Loading />;
  return (
    <>
      <HeaderModule classHeader="indicatorsHeaderModule">
        INDICADORES
      </HeaderModule>
      <Sidebar link="/indicadores" title="INDICADORES">
        <MenuIndicators />
      </Sidebar>
      <main>
        <div className="mainDiv">
          <div className="flex-center-gap homeImg">
            <img width="921" src={homeImgIndicators} alt="Home" />
          </div>
        </div>
      </main>
    </>
  );
}

export default HomeDash;
