import React from 'react';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import duplicateLine from '../../../../Assets/duplicarlinha.svg';
import { BsTrash } from 'react-icons/bs';
import { BsThreeDotsVertical } from 'react-icons/bs';

const PopoverAddAndExit = ({
  briefing,
  inputList,
  index,
  handleDuplicateLine,
  handleRemoveClick,
}) => {
  return (
    <div>
      <button
        id={`pop${index}`}
        type="button"
        style={{
          backgroundColor: 'transparent',
          transform: 'translate(-3px,6px)',
        }}>
        <span>
          <BsThreeDotsVertical size={20} />
        </span>
      </button>
      <UncontrolledPopover
        target={`pop${index}`}
        placement="auto-end"
        trigger="focus"
        style={{}}>
        <PopoverBody>
          <div>
            <div style={{ display: 'flex' }}>
              <button
                type="button"
                style={{
                  backgroundColor: 'transparent',
                  display: 'flex',
                }}
                onClick={() => handleDuplicateLine(index)}
                disabled={briefing?.status_code === 'F'}>
                <div style={{ marginRight: '10px' }}>
                  <img
                    src={duplicateLine}
                    alt="duplicar"
                    style={{ height: '30px', color: '#6C757D' }}
                  />
                </div>
                <span
                  style={{
                    fontFamily: 'Ubuntu',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '11px',
                    textAlign: 'center',
                    color: '#1A213F',
                    marginTop: '10px',
                  }}>
                  Duplicar Linhas
                </span>
              </button>
            </div>

            <div style={{ display: 'flex', marginTop: '10px' }}>
              <button
                type="button"
                style={{
                  backgroundColor: 'transparent',
                  display: 'flex',
                  border: 'none',
                }}
                onClick={() => {
                  console.log('handleRemoveClick', index);
                  handleRemoveClick(index);
                }}
                disabled={
                  inputList.length === 1 || briefing?.status_code === 'F'
                }>
                <div style={{ marginRight: '10px', color: '#6C757D' }}>
                  <BsTrash size={30} />
                </div>
                <span
                  style={{
                    fontFamily: 'Ubuntu',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '11px',
                    textAlign: 'center',
                    color: '#1A213F',
                    marginTop: '12px',
                  }}>
                  Excluir
                </span>
              </button>
            </div>
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

export default PopoverAddAndExit;
