import React, { useState, useEffect, useCallback } from 'react';
// import ReactGA from 'react-ga';
import { classNames } from '../../../../../../../../utils/utils';
import styles from './styles.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import FileSaver from 'file-saver';
import {
  Col,
  Button,
  Modal,
  Icon,
  Row,
  Container,
  Animation,
  Alert,
  Form,
  DatePicker,
  RadioGroup,
  Radio,
} from 'rsuite';
import apiConnectSourcing from '../../../../../../../../services/apiConnectSourcing';

const PanelDetails = React.forwardRef(({ responsibleName, ...props }, ref) => (
  <Row
    {...props}
    ref={ref}
    style={{
      overflow: 'hidden',
      paddingleft: '200px',
    }}>
    <Col md={24}>
      <p>{responsibleName}</p>
      <p>Data: {moment(new Date()).format('DD/MM/YYYY')}</p>
    </Col>
  </Row>
));

function ModalProspectionTask({
  show,
  task,
  closeModal,
  prospection,
  supplierName,
  setProspection,
  isCheckOrFinance,
  supplierIndex,
  taskIndex,
  costDocumentReceived,
  hasMainContact,
}) {
  // ReactGA.pageview('/pdm');

  const [showCollapse, setShowCollapse] = useState(false);
  const [loadingCompetitionDocument, setLoadingCompetitionDocument] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const { Collapse } = Animation;
  const validationSchema = Yup.object({
    scheduleTo: Yup.date().when('concluded', {
      is: false,
      then: Yup.date()
        .min(new Date(), 'A data escolhida precisa ser maior que a atual')
        .required('Escolha uma data'),
    }),
    concluded: Yup.boolean().required('Campo Obrigatório').nullable(),
    notes: Yup.string().required('Campo Obrigatório'),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      scheduleTo: moment(new Date()).toDate(),
      concluded: false,
      notes: '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: values => {
      setLoading(true);
      apiConnectSourcing
        .put(`/tasks/${task.id}/`, {
          scheduled_to: moment(values.scheduleTo).format('YYYY-MM-DD'),
          concluded: values.concluded,
          notes: values.notes,
          is_seller_linked: prospection?.supplierInfo[supplierIndex]?.is_seller_linked,
          supplierInfo: prospection?.supplierInfo?.[supplierIndex],
          supplier: prospection?.suppliers?.[supplierIndex]
        })
        .then(response => {
          if (response.data) {
            if (response.status === 200) {
              handleUpdateTask(response.data, false);
            }
            if (response.status === 201) {
              handleUpdateTask(response.data, true);
            }
            Alert.success('Demanda atualizada com Sucesso!', 3000);
          } else {
            Alert.error(
              'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
              3000
            );
          }
          setLoading(false);
        })
        .catch(error => {
          if (error.response?.status === 403) {
            Alert.error(error.response.data.message, 50000);
          } else if (error.response?.status === 400) {
            Alert.error(error.response.data.message, 50000);
          } else {
            Alert.error(
              'Aconteceu um erro inesperado. Tente novamente, mais tarde!'
            );
          }
          setLoading(false);
        })
        .finally(() => {
          handleCancel();
          setLoading(false);
        });
    },
  });

  const handleUpdateTask = async (task, concluded) => {
    const newProspection = { ...prospection };

    newProspection.suppliers[supplierIndex].is_delete_ok = task.is_delete_ok;

    if (concluded) {
      newProspection.suppliers[supplierIndex].tasks[taskIndex].notes =
        values.notes;
      newProspection.suppliers[supplierIndex].tasks[taskIndex].modified_at =
        moment().format();
      newProspection.suppliers[supplierIndex].tasks[taskIndex].performed = true;
      newProspection.suppliers[supplierIndex].tasks[taskIndex].concluded = true;

      if (taskIndex === 2 && task.forEach) {
        task.forEach((ob, i) => {
          newProspection.suppliers[supplierIndex].tasks[taskIndex + 1 + i] = ob;
        });
      } else {
        newProspection.suppliers[supplierIndex].tasks[taskIndex + 1] = task;
      }
    } else {
      newProspection.suppliers[supplierIndex].tasks[taskIndex] = task;
    }
    setTimeout(() => {
      setProspection(newProspection);
    }, 1000);
  };

  const handleToogleCollpase = () => {
    setShowCollapse(!showCollapse);
  };

  const handleCancel = () => {
    closeModal();
    resetForm({
      values: {
        scheduleTo: moment(task?.scheduled_to).toDate(),
        concluded: task?.concluded,
        notes: task?.notes,
      },
    });
  };

  const handleExportCost = () => {
    setLoading(true);
    apiConnectSourcing
      .get(`/download-cost-sheet/${
        prospection.id
      }/suppliers/${
        prospection?.suppliers[supplierIndex].supplier_id
      }/`, { responseType: 'arraybuffer' })
      .then(response => {
        const { data, headers } = response;
        const type = `${headers['content-type']}`;
        const file_extension = `${
          headers?.["content-disposition"]?.split('filename=')?.[1] || ''
        }`.split('.').pop();

        const filename = `documento-custo-${
          supplierName.replace(/\s/g, '-').substring(0,32)
        }-${prospection.id}.${file_extension}`;

        if (data) {
          var blob = new Blob([data], { type });
          FileSaver.saveAs(
            blob,
            filename
          );
        } else {
          Alert.error(
            'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
            3000
          );
        }
        setLoading(false);
      })
      .catch(error => {
        if (error.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado. Tente novamente, mais tarde!'
          );
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResendCompetitionDocument = () => {
    setLoadingCompetitionDocument(true);
    apiConnectSourcing
      .post(
        `prospections/${prospection?.id}/suppliers/${prospection?.suppliers[supplierIndex].supplier_id}/resend-competition/`,
        {
          supplierInfo: prospection?.supplierInfo?.[supplierIndex],
          supplier: prospection?.suppliers?.[supplierIndex]
        }
      )
      .then(response => {
        if (response) {
          Alert.success('Documento de concorrência enviado com sucesso', 3000);
        }
        setLoadingCompetitionDocument(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado. Tente novamente, mais tarde!'
          );
        }
        setLoadingCompetitionDocument(false);
      })
      .finally(() => {
        setLoadingCompetitionDocument(false);
      });
  };

  const updateValues = useCallback(() => {
    setFieldValue(
      'scheduleTo',
      moment(task?.scheduled_to ? task?.scheduled_to : Date()).toDate()
    );
    setFieldValue('concluded', task?.concluded);
    setFieldValue('notes', task?.notes);
  }, [setFieldValue, task]);
  useEffect(() => {
    updateValues();
  }, [updateValues]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Modal show={show} onHide={closeModal}>
          <Modal.Header>
            <Container>
              <Row>
                <Col sm={2}>
                  <Row className={styles.iconTittle}>
                    <Col className={styles.modalIcon} md={5}>
                      <Icon icon="bell-o" size="lg" />
                    </Col>
                  </Row>
                </Col>
                <Col sm={22}>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <Col sm={14}>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Fornecedor:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {supplierName}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Projeto:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {prospection?.briefing_name}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={10}>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Cliente:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {prospection?.clientInfo.name}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Etapa:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {task?.stage?.name}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Header>

          <Modal.Body className={styles.body}>
            <Container>
              <Row>
                <Col md={24}>
                  <textarea
                    disabled={task?.concluded}
                    value={values.notes}
                    placeholder="Digite aqui"
                    className={styles.bodyCard}
                    onChange={handleChange('notes')}></textarea>
                  <span className={styles.error}>
                    {errors.notes ? errors.notes : null}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '10px' }}>
                <Col md={11}>
                  <Row>
                    <Col md={24}>
                      <label>Etapa Concluída:</label>
                      <RadioGroup
                        name="radioList"
                        value={values.concluded}
                        onChange={value => {
                          setFieldValue('concluded', value);
                        }}
                        inline>
                        <Radio disabled={task?.concluded} value={true}>
                          Sim
                        </Radio>
                        <Radio disabled={task?.concluded} value={false}>
                          Não
                        </Radio>
                      </RadioGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <span className={styles.error}>
                        {errors.concluded ? errors.concluded : null}
                      </span>
                    </Col>
                  </Row>
                </Col>
                {(!values.concluded || task?.concluded) && (
                  <Col md={13}>
                    <Row>
                      <Col md={24}>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                          <Col md={10}>
                            <label>Programar para:</label>
                          </Col>
                          <Col md={14}>
                            <DatePicker
                              disabled={task?.concluded}
                              oneTap
                              size="sm"
                              placeholder="DD/MM/YYYY"
                              value={
                                task?.concluded
                                  ? task?.modified_at
                                  : values.scheduleTo
                              }
                              format="DD/MM/YYYY"
                              onChange={value => {
                                setFieldValue('scheduleTo', value);
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={24}>
                        <span className={styles.error}>
                          {errors.scheduleTo ? errors.scheduleTo : null}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col sm={6} md={6}>
                  <span
                    className={styles.buttonDetails}
                    onClick={handleToogleCollpase}>
                    {' '}
                    Detalhes{' '}
                    <Icon
                      style={{ color: '#615b56', fontWeight: 700 }}
                      icon={classNames({
                        'angle-right': !showCollapse,
                        'angle-down': showCollapse,
                      })}
                    />
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col md={24}>
                  <Collapse in={showCollapse}>
                    <PanelDetails responsibleName={task?.userInfo?.name} />
                  </Collapse>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              {isCheckOrFinance && (
                <Col md={4}>
                  <Button
                    type="submit"
                    color="green"
                    onClick={() => {
                      handleExportCost();
                    }}
                    loading={loading}
                    disabled={!prospection.cost_sheets_data.length}>
                    Exportar
                  </Button>
                </Col>
              )}
              {taskIndex === 2 && task?.concluded && (
                <Col md={4}>
                  <Button
                    type="submit"
                    color="green"
                    onClick={() => {
                      handleResendCompetitionDocument();
                    }}
                    loading={loadingCompetitionDocument}
                    disabled={
                      (!hasMainContact ||
                        !task?.concluded) &&
                      taskIndex === 2
                    }>
                    Notificar novamente
                  </Button>
                </Col>
              )}
              <Col
                md={8}
                mdOffset={
                  isCheckOrFinance || (taskIndex === 2 && task?.concluded)
                    ? 12
                    : 16
                }>
                <Row>
                  <Col md={task?.concluded ? 24 : 12}>
                    <Button
                      onClick={handleCancel}
                      appearance={task?.concluded ? 'primary' : 'default'}>
                      Cancelar
                    </Button>
                  </Col>
                  {!task?.concluded && (
                    <Col md={12}>
                      {hasMainContact}
                      <Button
                        disabled={
                          task?.concluded ||
                          (!hasMainContact &&
                            taskIndex === 2)
                        }
                        type="submit"
                        onClick={handleSubmit}
                        loading={loading}
                        color="blue"
                        appearance="primary">
                        Confirmar
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
}

export default ModalProspectionTask;
