/* eslint-disable react-hooks/exhaustive-deps */
import { React, forwardRef, useImperativeHandle, useState, useEffect, useRef,} from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './ModalRevisonSku.scss';
import linkPlataform from '../../../../../utils/linkPlataform';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';
import Character from '../../../../../Assets/character-revision-skus.svg'
import ModalSteps from '../ModalSteps/ModalSteps';

const ModalRevisonSku = (
  { projectId,
    moduleType,
    moduleStage,
    setModuleStages,
    setModuleStatus,
    setProject,
    modalStageCompletionRef }
  , ref) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const modalStepsRef = useRef(null);

  const { getProjectSkus, projectSkus } = useManagementContext();

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  useEffect(() => {
    if (projectId) {
      getProjectSkus(projectId);
    }
  }, [projectId]);

  const redirectToPlattaform = () => {
    window.open(`${linkPlataform}/details-project/${projectId}/?sku=true`, '_blank');
  };

  const checkStageSku = (sku) => {
    if (!sku?.available) {
      return (
        <p className="sku-cancelled">Cancelado</p>
      );
    }
    return "";
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      centered
      size="lg"
      className="modal-revision-skus">
      <div className="header-rev-skus">
        <div>
          <p className="title-modal">
            Todos seus SKUS foram lançados?
          </p>
          <p className="subtitle-modal">
            Caso algum SKU tenha sido cancelado durante o fluxo de desenvolvimento,
            vá aos SKUs do projeto e o cancele. Se nenhum tiver sido cancelado,
            fique tranquilo e finalize a etapa.
          </p>
        </div>
        <img src={Character} alt="Personagem" />
      </div>
      <ModalBody>
        <div className="table-modal-details-skus">
          <table>
            {projectSkus &&
              projectSkus?.map(sku => (
                <tr>
                  <td className="name-list-modal-details-skus">{sku?.product}</td>
                  <td className="variant-list-modal-details-skus">
                    {sku?.variant}
                  </td>
                  <td className="weight-list-modal-details-skus">
                    {sku?.weight}
                  </td>
                  <td className="package-list-modal-details-skus">
                    {sku?.package}
                  </td>
                  <td className="ean-list-modal-details-skus">
                    {sku?.ean}
                  </td>
                  <td className="status-list-modal-details-skus">
                    {checkStageSku(sku)}
                  </td>
                </tr>
              ))}
            {projectSkus?.length === 0 && (
              <tr>
                <td>Nenhum SKU encontrado</td>
              </tr>
            )}
          </table>
        </div>
      </ModalBody>
      <div className="div-buttons">
        <button
          type="button"
          className="btn-edit-skus"
          onClick={redirectToPlattaform}
        >
          Editar os SKUs
        </button>
        <button
          type="button"
          className="btn-finish-step"
          onClick={() => {
            modalStageCompletionRef.current.toggleModal();
            toggleModal();
          }}
          >
            Quero finalizar a etapa
        </button>
      </div>
      <ModalSteps
        moduleType={moduleType}
        moduleStage={moduleStage}
        setModuleStages={setModuleStages}
        setModuleStatus={setModuleStatus}
        setProject={setProject}
        ref={modalStepsRef}
      />
    </Modal>
  );
};

export default forwardRef(ModalRevisonSku);
