/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import HeaderModule from '../../../../Components/HeaderModule/HeaderModule';
import TabsFilterAndDefinition from './TabsFilterAndDefinition';
import styles from './SupplierDefinitionFilter.module.css';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import MenuPdm from '../../../../Components/Sidebar/MenuPdm';
import { Col, Row } from 'reactstrap';
import apiConnectViability from '../../../../services/apiConnectViability';
import { useParams } from 'react-router-dom';
import ButtonBack from '../../../../Components/ButtonBack/ButtonBack';

function SupplierDefinitionFilter({ showTab }) {
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [dataProject, setDataProject] = useState({});
  const [supplierList, setSupplierList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [definitionList, setDefinitionList] = useState([]);
  const [supplierFilterSelected, setSupplierFilterSelected] = useState(false);
  const [supplierSelected, setSupplierSelected] = useState(false);

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    checkData();
  }, [project]);

  function getClients() {
    setLoading(true);
    apiConnectViability
      .get(`/projects/${id}/filtro_comercial`, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
          id_token: window.localStorage.getItem('id-token-cognito'),
          access_token: window.localStorage.getItem('access-token-cognito'),
        },
      })
      .then(ret => {
        console.log('API', ret.data);
        setProject(ret.data);
        setSupplierList(ret.data.filter);
        const filterSelected = ret.data.filter.find(fs => fs.status);
        if (filterSelected) setSupplierFilterSelected(true);
        setOpened(ret.data.definition.opened);
        setDefinitionList(ret.data.definition.suppliers);
        const selected = ret.data.definition.suppliers.find(
          sp => sp.chosen_supplier
        );
        if (selected) setSupplierSelected(true);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
      });
  }

  const checkData = () => {
    setDataProject({
      clientName: project.client_name,
      projectName: project.project_name,
    });
    console.log(dataProject);
  };

  return (
    <>
      <Sidebar hideLogo>
        <MenuPdm />
      </Sidebar>
      <HeaderModule classHeader="pdmHeaderModule">
        Seleção de fornecedores
      </HeaderModule>
      <Row className={styles.mainDiv1}>
        <Col md={10}>
          <h2 className={styles.client}>
            Cliente:{' '}
            <span className={styles.clientName}>{dataProject.clientName}</span>
          </h2>
        </Col>
        <Col className="text-right" md={2}>
          <ButtonBack />
        </Col>
      </Row>
      <Row className={styles.mainDiv2}>
        <Col>
          <h2 className={styles.project}>
            Projeto:{' '}
            <span className={styles.projectName}>
              {dataProject.projectName}
            </span>
          </h2>
        </Col>
      </Row>
      <Row className={styles.mainDiv3}>
        <Col>
          <TabsFilterAndDefinition
            supplierList={supplierList}
            setSupplierList={setSupplierList}
            supplierFilterSelected={supplierFilterSelected}
            loading={loading}
            opened={opened}
            definitionList={definitionList}
            setDefinitionList={setDefinitionList}
            getClients={getClients}
            supplierSelected={supplierSelected}
          />
        </Col>
      </Row>
    </>
  );
}

export default SupplierDefinitionFilter;
