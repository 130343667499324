/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import apiConnectSuppliersDB from '../../services/apiConnectSuppliersDB';
import moment from 'moment';

const USERNAME = process.env.REACT_APP_SUPPLIERS_DB_REFRESH_TOKEN_USER;
const PASSWORD = process.env.REACT_APP_SUPPLIERS_DB_REFRESH_TOKEN_PASS;

const SuppliersDBTokenRefresh = () => {
  var timestampNow = moment(new Date()).unix();
  const token = localStorage.getItem('suppliers-db-token');
  const expired = localStorage.getItem('suppliers-db-token-expired');

  useEffect(() => {
    if (!token || Number(expired) <= timestampNow) {
      apiConnectSuppliersDB
        .request({
          url: '/token/',
          method: 'post',
          data: {
            username: USERNAME,
            password: PASSWORD,
          },
        })
        .then(function (res) {
          window.localStorage.setItem('suppliers-db-token', res.data.access);
          window.localStorage.setItem(
            'suppliers-db-token-expired',
            timestampNow + 1740
          );
        })
        .catch(function (res) {
          console.log(res.response);
        });
    }
  }, []);

  return <></>;
};

export default SuppliersDBTokenRefresh;
