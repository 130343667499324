import axios from 'axios';

let ambiente = '';
if (process.env.REACT_APP_FRONT_ENV === 'LOCAL') {
  ambiente = 'https://briefing-api-dev.amicci-digital.com.br/api/report/';
}

if (process.env.REACT_APP_FRONT_ENV === 'DEV') {
  ambiente = 'https://briefing-api-dev.amicci-digital.com.br/api/report/';
}

if (process.env.REACT_APP_FRONT_ENV === 'HMG') {
  ambiente = 'https://briefing-api-hmg.amicci-digital.com.br/api/report/';
}

if (process.env.REACT_APP_FRONT_ENV === 'PROD') {
  ambiente = 'https://briefing-api.amicci-digital.com.br/api/report/';
}

const api_permission = axios.create({
  // baseURL: "https://hig1yfkqe4.execute-api.us-east-1.amazonaws.com/dev/getembedinfo/"
  // baseURL: "https://briefing-api-dev.amicci-digital.com.br/api/report/"
  // baseURL: "https://briefing-api-dev.amicci-digital.com.br/api/report/"

  baseURL: ambiente,
  // bernardo.bicalho@amicci.com.br
});

export default api_permission;
