import React from 'react';
import './ButtonOutline.scss';

const ButtonOutline = ({ label, onClick = () => {}, disabled = false }) => {
  return (
    <button
      type="button"
      className="buttonOutline"
      onClick={onClick}
      disabled={disabled}>
      {label}
    </button>
  );
};

export default ButtonOutline;
