/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
// import InputText from '../../../../../Components/InputText/InputText';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { Alert, Loader } from 'rsuite';
import ButtonDefault from '../../../../../Components/ButtonDefault/ButtonDefault';
import './ModalSteps.scss';
// import apiConnectManagement from '../../../../../services/apiConnectManagement';
import { useCognitoAuth } from '../../../../../Contexts/AuthCognitoContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import InlineCalendar from '../../../../../Components/InlineCalendar/InlineCalendar';
import { classNames } from '../../../../../utils/utils';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';

const ModalSteps = (
  {
    moduleType,
    moduleStage,
    // setModuleStages,
    // setProject,
    // setModuleStatus,
    onSubmit = () => {},
  },
  ref
) => {
  const { user } = useCognitoAuth();
  const {
    loading,
    error,
    changeStatus,
    reloadProjectAndModule,
    getPredecessorSteps,
  } = useManagementContext();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const [predecessorSteps, setPredecessorSteps] = useState([]);
  const [hasPredecessor, setHasPredecessor] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const now = new Date();
  // const [dateMin, setDateMin] = useState(
  //   moment(now).subtract(730, 'days').format('YYYY-MM-DD').toString()
  // );
  // const [dateMax, setDateMax] = useState(
  //   moment(now).format('YYYY-MM-DD').toString()
  // );
  const validationSchema = Yup.object({
    date: Yup.string().required('Campo Obrigatório'),
  });

  useEffect(() => {
    if (showModal) {
      loadPredecessorSteps();
    }
  }, [showModal]);

  // else {
  //     setPredecessorSteps([]);
  //     setHasPredecessor(false);
  //   }

  const loadPredecessorSteps = async () => {
    const resp = await getPredecessorSteps(moduleStage?.id);
    if (resp?.success) {
      setPredecessorSteps(resp.data);
      const hp = resp.data.length > 0;
      setHasPredecessor(hp);
    }
  };

  // const isDependent =
  //   moduleStage?.stage.stage_type.id !== 1 &&
  //   moduleStage?.stage.stage_type.id !== 4;

  const { resetForm } = useFormik({
    initialValues: {
      date: moment(now).format('YYYY-MM-DD').toString(),
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      onHandleConfirm(values);
      //console.log('values', values)
    },
  });

  const onHandleConfirm = async values => {
    setLoadingConfirm(true);
    const body = {
      user: user,
      date: values.date,
    };
    const response = await changeStatus(
      moduleType,
      moduleStage?.project_module,
      moduleStage?.id,
      2,
      body
    );
    setLoadingConfirm(false);
    if (response.success) {
      // onHandleUpdateStages(response?.data);
      // onHandleUpdateProjectModule(response?.data.project_module);
      // onSubmit();
      resetForm();
      closeModal();
      reloadProjectAndModule();
    } else {
      Alert.error(response.message, 50000);
    }
  };

  // const onHandleUpdateStages = data => {
  //   let tempStages = [...stages];
  //   let stage = [];
  //   stage.push(data.stage_module);
  //   if (data.chain_stage_modules) {
  //     stage.push(data.chain_stage_modules[0][0]);
  //   }
  //   for (var i = 0; i < stage.length; i++) {
  //     // eslint-disable-next-line no-loop-func
  //     const stageIndex = stages.findIndex(moduleStage => {
  //       return moduleStage.id === stage[i].id;
  //     });
  //     tempStages[stageIndex] = stage[i];
  //   }
  //   setModuleStages(tempStages);
  // };

  // const onHandleUpdateProjectModule = projectModule => {
  //   let tempProjectModules = [...project.modules];
  //   const projectModuleIndex = tempProjectModules.findIndex(ob => {
  //     return ob.id === projectModule.id;
  //   });
  //   tempProjectModules[projectModuleIndex] = projectModule;
  //   setProject({ ...project, modules: tempProjectModules });
  //   setModuleStatus(projectModule.status_module);
  // };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  function closeModal() {
    setShowModal(!showModal);
  }

  // useEffect(() => {
  //   if (moduleStage?.stage.stage_type.id === 4) {
  //     setDateMin('');
  //   } else {
  //     setDateMin(
  //       moment(now).subtract(730, 'days').format('YYYY-MM-DD').toString()
  //     );
  //     setDateMax(moment(now).format('YYYY-MM-DD').toString());
  //   }
  // }, [moduleStage]);

  const listPrevTasks = predSteps => {
    // separa apenas os nomes dos modulos (sem repetir)
    const moduleStages = {};
    for (let ps of predSteps) {
      if (!moduleStages[ps.stage.module_type.name]) {
        moduleStages[ps.stage.module_type.name] = [ps.stage.name];
      } else {
        moduleStages[ps.stage.module_type.name].push(ps.stage.name);
      }
    }
    return (
      <ul className="list-next-tasks">
        {Object.keys(moduleStages).map(key => (
          <li key={`pred-steps-${key}`}>
            <span>{key}</span>
            <ul className="inside-list">
              {moduleStages[key].map(ps => (
                <li key={ps}>- {ps}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={false}
      centered={true}
      className="custom-modal">
      <ModalHeader>
        <Row>
          <Col
            md={1}
            className={classNames({
              'icon-head-steps': true,
              'color-red': hasPredecessor,
            })}>
            <svg
              id="Icon"
              width="48.9"
              height="50.589"
              viewBox="0 0 43.9 45.589">
              <defs>
                {/* <linearGradient
                  id="linear-gradient"
                  x1="0.808"
                  y1="0.102"
                  x2="0.258"
                  y2="0.89"
                  gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#78f0a8" />
                  <stop offset="1" stop-color="#0e7d3a" />
                </linearGradient> */}
              </defs>
              <ellipse
                id="Ellipse_13"
                data-name="Ellipse 13"
                cx="21.95"
                cy="22.794"
                rx="21.95"
                ry="22.794"
                // fill="url(#linear-gradient)"
              />
              <path
                id="Icon_awesome-check-circle"
                data-name="Icon awesome-check-circle"
                d="M27.8,14.182A13.62,13.62,0,1,1,14.182.563,13.62,13.62,0,0,1,27.8,14.182Zm-15.2,7.211,10.1-10.1a.879.879,0,0,0,0-1.243L21.469,8.8a.879.879,0,0,0-1.243,0l-8.241,8.241L8.138,13.2a.879.879,0,0,0-1.243,0L5.653,14.439a.879.879,0,0,0,0,1.243l5.711,5.711a.879.879,0,0,0,1.243,0Z"
                transform="translate(7.768 8.613)"
                fill="#fff"
              />
            </svg>
          </Col>
          <Col md={7} className="texts-modal">
            <h2
              className={classNames({
                'text-steps': true,
                'color-red': hasPredecessor,
              })}>
              {moduleStage?.stage.stage_type.id === 2
                ? 'Início e fim da Etapa'
                : 'Início da Etapa'}
            </h2>
            <h3
              className={classNames({
                'sub-text-steps': true,
                'bar-red': hasPredecessor,
              })}>
              {moduleStage?.stage.name}
            </h3>
          </Col>
          <Col md={4} className="date-modal">
            Data: {moment(now).locale('pt-br').format('DD/MM/YYYY')} <br />
            <span style={{ textTransform: 'capitalize' }}>
              {moment(now).format('dddd').split('-')[0]}
            </span>{' '}
            {moment(now).format('HH:mm')}
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {loading.predecessorSteps ? (
          <div
            style={{
              height: '180px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Loader speed="slow" size="lg" />
          </div>
        ) : (
          <>
            {error.predecessorSteps ? (
              <p>
                <span className="text-color-red">{error.predecessorSteps}</span>
              </p>
            ) : (
              <>
                {hasPredecessor ? (
                  <>
                    <p>
                      <span className="text-color-blue">Observação</span>
                    </p>
                    <p>
                      As etapas abaixo ainda não foram concluídas. Deseja
                      iniciar esta etapa mesmo assim?
                    </p>
                    {listPrevTasks(predecessorSteps)}
                  </>
                ) : null}
              </>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <>
          {/* {hasPredecessor ? (
              <ButtonDefault
                color="blue"
                radius
                onClick={() => {
                  closeModal();
                }}>
                Fechar
              </ButtonDefault>
            ) : (
            <> */}
          <ButtonDefault
            color="red"
            radius
            onClick={() => {
              closeModal();
              resetForm();
            }}>
            Voltar
          </ButtonDefault>
          <ButtonDefault
            disabled={loadingConfirm}
            // loading={loadingConfirm}
            onClick={() => {
              closeModal();
              onSubmit();
            }}
            radius
            color="green">
            Iniciar etapa
          </ButtonDefault>
          {/*</> */}
        </>
      </ModalFooter>
    </Modal>
  );
};

export default forwardRef(ModalSteps);
