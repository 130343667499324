import React from 'react';
// import ReactGA from 'react-ga';
import styles from './styles.module.css';
import moment from 'moment';
import file from './images/file.png';

function ItemDemand({ task, setType, setStep }) {
  // ReactGA.pageview('/pdm');

  const changeStep = value => {
    setType(value);
    setStep(3);
  };

  return (
    <>
      <tr>
        <td className={styles.bold}>{task?.name_stage}</td>
        <td>
          Notas Atribuídas: <span>{task?.total_stage_note} Notas</span>
        </td>
        <td>
          Data da última nota:{' '}
          <span>
            {task?.last_date
              ? moment(task?.last_date).format('DD/MM/yyyy')
              : ''}
          </span>
        </td>
        <td>
          {task?.total_stage_note !== 0 && (
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => changeStep(task?.type)}
              src={file}
              alt="file"
            />
          )}
        </td>
      </tr>
    </>
  );
}

export default ItemDemand;
