import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import classSide from './Sidebar.module.css';
// import qualityIcon from '../../Assets/qualityIcon.png';
// import sourcingIcon from '../../Assets/sourcingIcon.png';
// import importationIcon from '../../Assets/importationIcon.png';
// import lawIcon from '../../Assets/lawIcon.png';
// import peopleIcon from '../../Assets/peopleIcon.png';
import managerIcon from '../../Assets/managerIcon.png';
import { IndicatorsContext } from '../../Pages/Indicators/Indicators';

function MenuIndicators() {
  const [indicators] = useContext(IndicatorsContext);

  const menuList = indicators.map(ind => (
    <li key={ind.name}>
      <Link
        to={`/indicadores/grafico/${ind.name}/`}
        className={classSide.linkMenu}>
        <section className="hold-menu-indicators">
          <img src={managerIcon} alt={ind.label} />
        </section>
        {ind.label}
      </Link>
    </li>
  ));

  return (
    <>
      <ul className={classSide.ulMenuSidebar}>
        {menuList}
        {/* <li>
            <Link to="/indicadores/diretoria" className={classSide.linkMenu}>
              <img src={managerIcon} alt="Diretoria e Gerência" />
              Diretoria e Gerência
            </Link>
          </li> */}
        {/* <li>
            <Link to="/indicadores/indicadoresemetas/" className={classSide.linkMenu}>
              <section className="hold-menu-indicators">
                <img src={managerIcon} alt="Indicadores e Metas 3.0 Gerencial" />
              </section>
              Indicadores e Metas 3.0 Gerencial
            </Link>
          </li>
          <li>
            <Link to="/indicadores/indicadorestime/" className={classSide.linkMenu}>
              <section className="hold-menu-indicators">
                <img src={managerIcon} alt="Indicadores e Metas 3.0 Operacional" />
              </section>
              Indicadores e Metas 3.0 Operacional
            </Link>
          </li>
          <li>
            <Link to="/indicadores/gestao" className={classSide.linkMenu}>
              <section className="hold-menu-indicators">
                <img src={peopleIcon} alt="Gente e Gestão" />
              </section>
              Gente e Gestão
            </Link>
          </li>
          <li>
            <Link to="/indicadores/importacao" className={classSide.linkMenu}>
              <section className="hold-menu-indicators">
                <img src={importationIcon} alt="Importação" />
              </section>
              Importação
            </Link>
          </li>
          <li>
            <Link to="/indicadores/juridico" className={classSide.linkMenu}>
              <section className="hold-menu-indicators">
                <img src={lawIcon} alt="Jurídico" />
              </section>
              Jurídico
            </Link>
          </li>
          <li>
            <Link to="/indicadores/qualidade" className={classSide.linkMenu}>
              <section className="hold-menu-indicators">
                <img src={qualityIcon} alt="Qualidade" />
              </section>
              Qualidade
            </Link>
          </li>
          <li>
            <Link to="/indicadores/sourcing" className={classSide.linkMenu}>
              <section className="hold-menu-indicators">
                <img src={sourcingIcon} alt="Sourcing" />
              </section>
              Sourcing
            </Link>
          </li> */}
      </ul>
    </>
  );
}

export default MenuIndicators;
