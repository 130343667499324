import React, { useState, forwardRef, useImperativeHandle } from 'react';
import InputText from '../../../../../Components/InputText/InputText';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { Alert } from 'rsuite';
import ButtonDefault from '../../../../../Components/ButtonDefault/ButtonDefault';
import './ModalStepReopening.scss';
// import apiConnectManagement from '../../../../../services/apiConnectManagement';
import { useCognitoAuth } from '../../../../../Contexts/AuthCognitoContext';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { classNames } from '../../../../../utils/utils';
import moment from 'moment';

const ModalStepReopening = (
  {
    moduleType = null,
    moduleStage = null,
    // setModuleStages = () => {},
    displayOnly = false,
    displayStageText = null,
    displayObs = null,
    displayDate = null,
    onSubmit = () => {},
  },
  ref
) => {
  const { user } = useCognitoAuth();
  const { restartStageModule, reloadProjectAndModule } = useManagementContext();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const now = new Date();
  const validationSchema = Yup.object({
    obs: Yup.string()
      .max(500, ' Limite de 500 caracteres')
      .required('Campo Obrigatório'),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    //isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      obs: '',
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      onHandleConfirm(values);
    },
  });

  const onHandleConfirm = async values => {
    setLoadingConfirm(true);
    const body = {
      user: user,
      obs: values.obs,
    };
    const response = await restartStageModule(
      moduleType,
      moduleStage?.project_module,
      moduleStage?.id,
      body
    );
    if (response.success) {
      // onHandleUpdateStages(response?.data.stage_module);
      onSubmit();
      resetForm();
      closeModal();
      reloadProjectAndModule();
    } else {
      Alert.error(response.message, 50000);
    }
    // apiConnectManagement
    //   .put(
    //     `/module_type/${moduleType}/project_module/${moduleStage?.project_module}/stage/${moduleStage?.id}/restart_stage_module`,
    //     body
    //   )
    //   .then(response => {
    //     if (response?.data) {
    //       onHandleUpdateStages(response?.data.stage_module);
    //       onSubmit();
    //       closeModal();
    //       resetForm();
    //     }
    //   })
    //   .catch(error => {
    //     if (error?.response?.status === 403) {
    //       Alert.error(error.response.data.message, 50000);
    //     } else if (error?.response?.status === 400) {
    //       Alert.error(error.response.data.error, 50000);
    //     } else {
    //       Alert.error(
    //         'Aconteceu um erro inesperado, tente novamente mais tarde!'
    //       );
    //     }
    //   })
    //   .finally(() => {
    //     setLoadingConfirm(false);
    //   });
  };

  // const onHandleUpdateStages = stage => {
  //   const stageIndex = stages.findIndex(moduleStage => {
  //     return moduleStage.id === stage.id;
  //   });
  //   const tempStages = [...stages];
  //   tempStages[stageIndex] = stage;

  //   setModuleStages(tempStages);
  // };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  function closeModal() {
    setShowModal(!showModal);
  }

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={false}
      centered={true}
      className="custom-modal">
      <ModalHeader>
        <Row>
          <Col md={1} className="icon-head-reopening">
            <svg width="43" height="43.003" viewBox="0 0 40 40.003">
              <defs>
                {/* <linearGradient
                  id="linear-gradient"
                  x1="0.5"
                  x2="0.5"
                  y2="1"
                  gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#699bc5" />
                  <stop offset="1" stop-color="#f39332" />
                </linearGradient> */}
              </defs>
              <path
                id="issuereopened"
                d="M37.529,22.5a2.469,2.469,0,0,1-2.5-2.5,14.688,14.688,0,0,0-2.012-7.52,14.893,14.893,0,0,0-5.469-5.469A14.688,14.688,0,0,0,20.028,5,14.559,14.559,0,0,0,10.3,8.593l2.227,2.227a1.621,1.621,0,0,1-.488,1.191,1.576,1.576,0,0,1-1.152.488H4.168a1.576,1.576,0,0,1-1.152-.488,1.623,1.623,0,0,1-.489-1.19V4.18a1.621,1.621,0,0,1,.488-1.191A1.576,1.576,0,0,1,4.167,2.5L6.745,5.079A19.9,19.9,0,0,1,27.8,1.582,19.569,19.569,0,0,1,38.445,12.226,19.623,19.623,0,0,1,40.027,20a2.469,2.469,0,0,1-2.5,2.5ZM21.278,25h-2.5a1.269,1.269,0,0,1-1.25-1.25v-15a1.269,1.269,0,0,1,1.25-1.25h2.5a1.269,1.269,0,0,1,1.25,1.25v15A1.269,1.269,0,0,1,21.278,25Zm1.25,6.251a1.269,1.269,0,0,1-1.25,1.25h-2.5a1.269,1.269,0,0,1-1.25-1.25v-2.5a1.269,1.269,0,0,1,1.25-1.25h2.5a1.269,1.269,0,0,1,1.25,1.25ZM5.027,20a14.688,14.688,0,0,0,2.012,7.52,14.893,14.893,0,0,0,5.469,5.469A14.688,14.688,0,0,0,20.028,35a14.559,14.559,0,0,0,9.727-3.594l-2.227-2.227a1.621,1.621,0,0,1,.488-1.191,1.576,1.576,0,0,1,1.152-.488h6.719a1.576,1.576,0,0,1,1.152.488,1.621,1.621,0,0,1,.488,1.191v6.641a1.621,1.621,0,0,1-.488,1.191,1.576,1.576,0,0,1-1.152.488l-2.578-2.578a19.9,19.9,0,0,1-21.056,3.5A19.569,19.569,0,0,1,1.609,27.777,19.631,19.631,0,0,1,.027,20a2.4,2.4,0,0,1,.742-1.757A2.4,2.4,0,0,1,2.526,17.5a2.4,2.4,0,0,1,1.757.742A2.4,2.4,0,0,1,5.025,20Z"
                transform="translate(-0.027)"
                // fill="url(#linear-gradient)"
              />
            </svg>
          </Col>
          <Col md={7} className="texts-modal">
            <h2 className="text-reopening-step">Reabertura da etapa</h2>
            <h3 className="sub-reopening">
              {displayOnly ? displayStageText : moduleStage?.stage.name}
            </h3>
          </Col>
          <Col md={4} className="date-modal">
            {displayOnly ? (
              <>
                {moment(displayDate).format('DD/MM/YYYY')}{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {moment(displayDate).format('dddd').split('-')[0]}{' '}
                </span>{' '}
                {moment(displayDate).format('HH:mm').toUpperCase()}
              </>
            ) : (
              <>
                {moment(now).format('DD/MM/YYYY')}{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {moment(now).format('dddd').split('-')[0]}{' '}
                </span>{' '}
                {moment(now).format('HH:mm')}
              </>
            )}
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {!displayOnly && (
          <p>
            Você está reabrindo uma etapa. Esperamos que corra tudo bem dessa
            vez. Lembre-se que a contagem de dias utilizados será somada aos da
            reabertura. Por favor, adicione um observação do motivo da
            reabertura para prosseguir. Obrigado!
          </p>
        )}
        <section className="custom-input-component">
          <label className="text-observation">Observações</label>
          <InputText
            maxLength={500}
            type="textarea"
            readOnly={displayOnly}
            value={displayOnly ? displayObs : values.obs}
            onChange={handleChange('obs')}
            className={classNames({
              'error-input': Boolean(errors.obs),
            })}
            placeholder="Ex: O etapa foi reaberta por conta de um erro na documentação enviada anteriormente. Nova é a correta."
          />
          <small className="text-limit">Limite de 500 caracteres</small>
          <br />
          <span className="error">{errors.obs ? errors.obs : null}</span>
        </section>
      </ModalBody>
      {displayOnly ? (
        <ModalFooter>
          <ButtonDefault color="gradBlue2" onClick={closeModal}>
            Fechar
          </ButtonDefault>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <ButtonDefault color="red" radius onClick={closeModal}>
            Cancelar
          </ButtonDefault>
          <ButtonDefault
            color="green"
            radius
            disabled={loadingConfirm}
            loading={loadingConfirm}
            onClick={handleSubmit}>
            Confirmar
          </ButtonDefault>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default forwardRef(ModalStepReopening);
