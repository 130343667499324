import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import styles from '../../Sourcing/Sourcing.module.css';
import Main from './Components/Main';
import globalStyle from '../styles.module.css';
import MenuPlm from '../../../../Components/Sidebar/MenuPdm';

function ActivityRecord() {
  return (
    <>
      <Sidebar>
        <MenuPlm opened="sourcing" />
      </Sidebar>
      <main className={styles.homeBriefing}>
        <Routes>
          <Route path="/" element={<Main globalStyle={globalStyle} />} />
        </Routes>
      </main>
    </>
  );
}

export default ActivityRecord;
