import React from 'react';
import { Link } from 'react-router-dom';
import { BsBoxArrowInLeft } from 'react-icons/bs';
import styles from './ButtonBack.module.css';

const ButtonBack = () => {
  return (
    <section className={styles.scetionBtnBack}>
      <Link to="/pdm/select-supplier" className={styles.btnBack}>
        Voltar
        <BsBoxArrowInLeft />
      </Link>
    </section>
  );
}

export default ButtonBack;