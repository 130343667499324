import React from 'react';
import moment from 'moment';
import { Navigate } from 'react-router-dom';
import { useCognitoAuth } from '../../Contexts/AuthCognitoContext';
import getCognitoCredentials from './getCognitoCredentials';

const ProtectedElement = ({ children }) => {
  const { authenticated, isFinished } = useCognitoAuth();
  const { expiredToken } = getCognitoCredentials();
  const timestampNow = moment(new Date()).unix();

  const expired = expiredToken && Number(expiredToken) <= timestampNow;

  if ((expired || !authenticated) && isFinished) {
    window.sessionStorage.setItem('last-path', window.location.href);
  }

  return (
    <>
      {isFinished && (
        <>
          {expired ? (
            <Navigate to="/sessao-expirada" />
          ) : authenticated ? (
            children
          ) : (
            <Navigate to="/" />
          )}
        </>
      )}
    </>
  );
};

export default ProtectedElement;
