import React from 'react';
import './FakeButton.scss';
import { classNames } from '../../utils/utils';

const FakeButton = ({
  color = 'blue',
  size = 'default',
  noBorderRadius,
  children,
  ...otherProps
}) => {
  const classes = classNames({
    fakeStatusButtons: true,
    [`btn-${color}`]: true,
    [`btn-${size}`]: true,
    'no-border-radius': noBorderRadius,
  });
  return (
    <>
      <span className={classes} {...otherProps}>
        {children}
      </span>
    </>
  );
};

export default FakeButton;
