/* eslint-disable no-empty-pattern */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import './ModalResponseAddSupplier.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import useToggle from '../../../../../../utils/hooks/useToggle';

const ModalResponseAddSupplier = ({}, ref) => {
  const [show, toggle] = useToggle();
  const [success, setSuccess] = useState(true);

  const toggleModal = (ok = true) => {
    setSuccess(ok);
    toggle();
  };

  useImperativeHandle(ref, () => ({ toggleModal }));

  return (
    <>
      <Modal
        backdrop={true}
        centered={true}
        size="md"
        toggle={toggle}
        isOpen={show}>
        <ModalHeader>
          {success ? (
            <div style={{ marginLeft: '60px', color: '#4BAF5A' }}>
              Sua solicitação foi enviada com sucesso
            </div>
          ) : (
            <div style={{ color: '#FF6363' }}>
              Não é possível continuar sem fornecedores sugeridos
            </div>
          )}
        </ModalHeader>
        <ModalBody>
          {success ? (
            <p>
              Nosso time de Sourcing vai analisar os dados e inserir o
              fornecedor em nossa base. Enquanto isso seu Briefing estará
              pausado até a inserção. <br />
              Após o fornecedor ser inserido, você será notificado e poderá dar
              finalização ao seu Briefing e enviá-lo para prospecção.
            </p>
          ) : (
            <p>
              Você está tentando continuar uma prospecção Via Varejo sem sugerir
              fornecedores.
              <br />
              Por favor, insira a sugestão de fornecedores para que possa
              continuar. <br />
              Caso o fornecedor escolhido não esteja em nossa base, solicite o
              cadastro do mesmo em nosso sistema.
            </p>
          )}
        </ModalBody>
        <ModalFooter>
          <div style={{ marginRight: '200px' }}>
            <button onClick={toggle} className="buttonBlue">
              Fechar
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default forwardRef(ModalResponseAddSupplier);
