import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import Briefing from './Briefing/Briefing';
import Sourcing from './Sourcing/Sourcing';
import HomeDash from './HomeDash';
import IndicatorsTokenRefresh from '../../Components/TokenRefresh/IndicatorsTokenRefresh';
import Prospection from './Sourcing2/Prospection';
import Demands from './Sourcing2/Demands';
import ActivityRecord from './Sourcing2/ActivityRecord';
import globalStyle from './Sourcing2/styles.module.css';
import SuppliersDBTokenRefresh from '../../Components/TokenRefresh/SuppliersDBTokenRefresh';
import Management from './Management/Management';
import CommercialFilter from './CommercialFilter/CommercialFilter';

import { BriefingContextProvider } from '../../Contexts/BriefingContext';
import { ManagementContextProvider } from '../../Contexts/ManagementContext';

function Plm() {
  return (
    <>
      <Header classHeader="header-pdm" />
      <BriefingContextProvider>
        <ManagementContextProvider>
          <IndicatorsTokenRefresh />
          <SuppliersDBTokenRefresh />
          <Routes>
            <Route path="/" element={<HomeDash />} />
            <Route path="/briefing/*" element={<Briefing />} />
            <Route path="/management/*" element={<Management />} />
            <Route path="/select-supplier/*" element={<CommercialFilter />} />
            <Route
              path="/prospection/*"
              element={<Prospection globalStyle={globalStyle} />}
            />
            <Route
              path="/demands/*"
              element={<Demands globalStyle={globalStyle} />}
            />
            <Route
              path="/activity-record/*"
              element={<ActivityRecord globalStyle={globalStyle} />}
            />
            <Route path="/sourcing/*" element={<Sourcing />} />
          </Routes>
        </ManagementContextProvider>
      </BriefingContextProvider>
    </>
  );
}

export default Plm;
