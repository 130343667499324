import React, { useState } from 'react';
// import ReactGA from 'react-ga';
import { classNames } from '../../../../../../utils/utils';
import Loading from '../../../../../../Components/Loading';
import styles from './styles.module.css';
import { Container } from 'rsuite';

import Header from '../Header';
import Home from '../Home';
import ListProspections from '../ListProspections';
import ListDemands from '../ListDemands';
import ListNotes from '../ListNotes';

function Main({ globalStyle }) {
  // ReactGA.pageview('/pdm');

  const [client, setClient] = useState();
  const [clientId, setClientId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [prospections, setProspections] = useState();
  const [prospectionId, setProspectionId] = useState();
  const [summary, setSummary] = useState();
  const [step, setStep] = useState(0);
  const [type, setType] = useState();

  return (
    <>
      <Header
        isLoading={isLoading}
        clientId={clientId}
        step={step}
        setStep={setStep}
        setProspections={setProspections}
        setIsLoading={setIsLoading}
        setClientId={setClientId}
        setClient={setClient}>
        <Container
          className={classNames({
            [styles.body]: true,
            [styles.bodyLoading]: isLoading,
          })}>
          {isLoading && <Loading />} {step === 0 && <Home />}
          {step === 1 && (
            <ListProspections
              prospections={prospections}
              clientId={clientId}
              client={client}
              isLoading={isLoading}
              setStep={setStep}
              setIsLoading={setIsLoading}
              setSummary={setSummary}
              setProspections={setProspections}
              setProspectionId={setProspectionId}
            />
          )}
          {step === 2 && (
            <ListDemands
              summary={summary}
              setStep={setStep}
              setType={setType}
            />
          )}
          {step === 3 && (
            <ListNotes
              setStep={setStep}
              setIsLoading={setIsLoading}
              clientId={clientId}
              type={type}
              prospectionId={prospectionId}
            />
          )}
        </Container>
      </Header>
    </>
  );
}

export default Main;
