import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import MenuPdm from '../../../Components/Sidebar/MenuPdm';
import BriefingList from './BriefingList/BriefingList';
import BriefingForm from './BriefingForm/BriefingForm';
import styles from './Briefing.module.css';

function Briefing() {
  return (
    <>
      <Sidebar hideLogo>
        <MenuPdm opened="briefing" />
      </Sidebar>
      <main className={styles.homeBriefing}>
        <Routes>
          <Route path="/" element={<BriefingList />} />
          <Route path="/:id" element={<BriefingForm />} />
          <Route path="/novo-briefing" element={<BriefingForm />} />
        </Routes>
      </main>
    </>
  );
}

export default Briefing;
