/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { classNames } from '../../../../../../utils/utils';
// import ReactGA from 'react-ga';
import styles from './styles.module.css';
import Loading from '../../../../../../Components/Loading';
import {
  Icon,
  Container,
  Row,
  Col,
  SelectPicker,
  Alert,
  Message,
} from 'rsuite';
import moment from 'moment';
import apiConnectSourcing from '../../../../../../services/apiConnectSourcing';
import NoteCard from './Components/NoteCard';

function ListNotes({ prospectionId, clientId, type, setStep, setIsLoading }) {
  // ReactGA.pageview('/pdm');

  const [optionsSuppliers, setOptionsSuppliers] = useState([]);
  const [supplier, setSupplier] = useState();
  const [notes, setNotes] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [projectStartedAt, setProjectStartedAt] = useState('');
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const filterNotes = value => {
    setSupplier(value);
    setIsLoadingFilter(true);
    apiConnectSourcing(
      `clients/${clientId}/prospections/${prospectionId}/details/?type=${type}&supplier=${value}`
    )
      .then(response => {
        if (response.data) {
          setNotes(response.data.card_notes);
        }
        setIsLoadingFilter(false);
      })
      .catch(error => {
        if (error.response?.status === 403) {
          Alert.error(error.response.data.message);
        } else {
          Alert.error('Não foi possível filtrar!');
        }

        setIsLoadingFilter(false);
      });
  };

  const getNotes = () => {
    setIsLoading(true);
    apiConnectSourcing(
      `clients/${clientId}/prospections/${prospectionId}/details/?type=${type}`
    )
      .then(response => {
        if (response.data) {
          setNotes(response.data.card_notes);
          setProjectName(response.data.project_name);
          setProjectStartedAt(
            moment(response.data.project_started_at).format('DD/MM/YYYY')
          );

          if (notes.length === 0) {
            let newOptionsSuppliers = [];
            response.data.card_notes.forEach((ob, i) => {
              const result = {
                label: ob.supplier_name,
                value: ob.supplier_id,
              };
              newOptionsSuppliers = checkArray(
                result.value,
                newOptionsSuppliers,
                result
              );
            });

            const result = {
              label: 'Todos',
              value: '',
            };
            newOptionsSuppliers.unshift(result);
            setOptionsSuppliers(newOptionsSuppliers);
          }
        }
        setIsLoading(false);
      })
      .catch(error => {
        if (error.response?.status === 403) {
          Alert.error(error.response.data.message);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado, tente novamente mais tarde!'
          );
        }

        setIsLoading(false);
      });
  };

  const checkArray = (id, newOptionsSuppliers, result) => {
    let index = newOptionsSuppliers.findIndex(val => val.value === id);
    if (index < 0) {
      newOptionsSuppliers.push(result);
    }
    return newOptionsSuppliers;
  };

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <Container className={styles.mainDiv}>
      <Row className={styles.notes}>
        <Col md={14} lg={7}>
          <Row className={styles.titleDate}>
            <Col md={12} lg={12}>
              Projeto: <span>{projectName}</span>
            </Col>
            <Col md={12} lg={12}>
              Data Inicio: <span>{projectStartedAt}</span>
            </Col>
          </Row>
        </Col>
        <Col md={10} lg={6} lgPush={11}>
          <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Col md={12} hidden={type === -2}>
              <SelectPicker
                hidden={type === -2}
                data={optionsSuppliers}
                onChange={value => {
                  filterNotes(value);
                }}
                value={supplier}
                appearance="default"
                placeholder="Fornecedor"
                renderMenuItem={(label, item) => {
                  return <div>{label}</div>;
                }}
                disabled={isLoadingFilter}
              />
            </Col>
            <Col md={10} lg={10}>
              <span
                onClick={() => setStep(2)}
                className={classNames(styles.closeButton)}>
                Fechar
                <Icon
                  className={classNames(styles.icon)}
                  icon="sign-in"
                  size="lg"
                />
              </span>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        className={classNames({
          [styles.notesListCard]: !isLoadingFilter, //always applies
          [styles.loadingNotesCard]: isLoadingFilter, //only when open === true
        })}>
        {isLoadingFilter ? (
          <Loading />
        ) : (
          notes?.map((ob, i) => {
            return (
              <>
                {ob.supplier_name !== 'Não Encontrado' && (
                  <NoteCard
                    key={`${i}-note-card`}
                    note={ob}
                    projectName={projectName}
                  />
                )}
              </>
            );
          })
        )}
        {notes.length === 0 && !isLoadingFilter && (
          <div>
            <Message
              style={{ textAlign: 'center' }}
              type="warning"
              description="Sem resultados"
            />
          </div>
        )}
      </Row>
    </Container>
  );
}

export default ListNotes;
