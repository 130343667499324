import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import ButtonDefault from '../../../../../Components/ButtonDefault/ButtonDefault';
import moment from 'moment';
import 'moment/locale/pt-br';
import './ModalNoApplicable.scss';
// import apiConnectManagement from '../../../../../services/apiConnectManagement';
import { useCognitoAuth } from '../../../../../Contexts/AuthCognitoContext';
import { Alert } from 'rsuite';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';

const ModalNoApplicable = (
  {
    moduleType,
    moduleStage,
    // moduleStages,
    // setModuleStages,
    // project,
    // setProject,
    // setModuleStatus,
    onSubmit = () => {},
  },
  ref
) => {
  const { user } = useCognitoAuth();
  const { changeStatus, reloadProjectAndModule } = useManagementContext();
  const [showModal, setShowModal] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const now = new Date();
  const toggleModal = () => setShowModal(!showModal);
  const [openedStages, setOpenedStages] = useState(null);

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  function closeModal() {
    setOpenedStages(null);
    setShowModal(!showModal);
  }

  const onHandleConfirm = async () => {
    setLoadingConfirm(true);
    const body = {
      user: user,
      date: moment(new Date()).format('YYYY-MM-DD').toString(),
    };
    const response = await changeStatus(
      moduleType,
      moduleStage?.project_module,
      moduleStage?.id,
      4,
      body
    );
    setLoadingConfirm(false);
    if (response.success) {
      // onHandleUpdateStages(response?.data);
      // onHandleUpdateProjectModule(response?.data.project_module);
      // onSubmit();
      closeModal();
      reloadProjectAndModule();
    } else {
      Alert.error(response.message, 50000);
    }
  };

  // const onHandleOpenedStages = stages => {
  //   if (stages) {
  //     const keys = Object.keys(stages);
  //     if (keys.length > 0 && stages[keys[0]].length > 0) {
  //       setOpenedStages(stages);
  //     } else {
  //       setOpenedStages({});
  //     }
  //   }
  // };

  // const onHandleUpdateStages = stage => {
  //   console.log(stage.id);
  //   const stageIndex = stages.findIndex(moduleStage => {
  //     return moduleStage.id === stage.id;
  //   });
  //   console.log(stageIndex);
  //   const tempStages = [...stages];
  //   tempStages[stageIndex] = stage;

  //   setModuleStages(tempStages);
  // };

  // const onHandleUpdateProjectModule = projectModule => {
  //   let tempProjectModules = [...project.modules];
  //   const projectModuleIndex = tempProjectModules.findIndex(ob => {
  //     return ob.id === projectModule.id;
  //   });
  //   tempProjectModules[projectModuleIndex] = projectModule;
  //   setProject({ ...project, modules: tempProjectModules });
  //   setModuleStatus(projectModule.status_module);
  // };

  const loadNextModules = async () => {};

  useEffect(() => {
    if (showModal) {
      loadNextModules();
    }
  }, [showModal]);

  const listNextTasks = arrOpenedStages => {
    if (arrOpenedStages) {
      const keys = Object.keys(arrOpenedStages);
      if (keys.length > 0) {
        return (
          <ul className="list-next-tasks">
            {keys.map(op => (
              <li key={op}>
                <span>{op}</span>
                <ul className="inside-list">
                  {arrOpenedStages[op].map(s => (
                    <li key={s}>- {s}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        );
      }
      return (
        <ul className="list-next-tasks">
          <li>
            <span>Sem etapas liberadas.</span>
          </li>
        </ul>
      );
    }
    return null;
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={false}
      centered={true}
      className="custom-modal">
      <ModalHeader>
        <Row>
          <Col md={1} className="icon-head-no-applicable">
            <svg width="43.191" height="43.191" viewBox="0 0 36.191 36.191">
              <defs>
                {/* <linearGradient
                  id="linear-gradient"
                  x1="0.5"
                  x2="0.5"
                  y2="1"
                  gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#9a9b9b" />
                  <stop offset="1" stop-color="#545454" />
                </linearGradient> */}
              </defs>
              <path
                id="Icon_material-do-not-disturb-alt"
                data-name="Icon material-do-not-disturb-alt"
                d="M21.1,3a18.1,18.1,0,1,0,18.1,18.1A18.149,18.149,0,0,0,21.1,3ZM6.619,21.1A14.519,14.519,0,0,1,21.1,6.619,14.261,14.261,0,0,1,29.963,9.7L9.7,29.963A14.261,14.261,0,0,1,6.619,21.1ZM21.1,35.572A14.261,14.261,0,0,1,12.229,32.5L32.5,12.229A14.261,14.261,0,0,1,35.572,21.1,14.519,14.519,0,0,1,21.1,35.572Z"
                transform="translate(-3 -3)"
                // fill="url(#linear-gradient)"
              />
            </svg>
          </Col>
          <Col md={6} className="texts-modal">
            <h2 className="text-no-applicable">Não se aplica</h2>
            <h3 className="sub-text-no-applicable">
              {moduleStage?.stage.name}
            </h3>
          </Col>
          <Col md={5} className="date-modal">
            Data: {moment(now).locale('pt-br').format('DD/MM/YYYY')} <br />
            <span style={{ textTransform: 'capitalize' }}>
              {moment(now).format('dddd').split('-')[0]}{' '}
            </span>{' '}
            {moment(now).format('HH:mm')}
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {openedStages && (
          <>
            <p className="text-color-blue mt-2">
              <strong>Observação</strong>
            </p>
            <p>
              Você optou por
              <span className="text-color-orange"> não aplicar esta etapa</span>
              . Esteja ciente que é uma etapa qu não irá contabilizar no seu
              relatório. Caso seu projeto não contemple esse processo, prossiga.
              As seguintes etapas podem ser iniciadas:
            </p>
            <section>{listNextTasks(openedStages)}</section>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {openedStages ? (
          <ButtonDefault color="blue" onClick={closeModal} radius>
            Fechar
          </ButtonDefault>
        ) : (
          <>
            <ButtonDefault color="red" radius onClick={closeModal}>
              Cancelar
            </ButtonDefault>
            <ButtonDefault
              color="green"
              radius
              loading={loadingConfirm}
              onClick={onHandleConfirm}
              disabled={loadingConfirm}>
              Confirmar
            </ButtonDefault>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default forwardRef(ModalNoApplicable);
