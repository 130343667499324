/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import styles from './ListViewCommercialFilter.module.css';
import HeaderModule from '../../../../Components/HeaderModule/HeaderModule';
import TableList from '../../../../Components/TableList/TableList';
import Pagination from '../../../../Components/Pagination/Pagination';
import 'react-circular-progressbar/dist/styles.css';
import { Row, Col, SelectPicker, Button, Alert, Input } from 'rsuite';
import apiConnectManagement from '../../../../services/apiConnectManagement';
import apiConnect from '../../../../services/apiConnect';
import { Link } from 'react-router-dom';
import { useCognitoAuth } from '../../../../Contexts/AuthCognitoContext';
import useLocalStorage from '../../../../utils/hooks/useLocalStorage';

function ListViewProjects() {
  const { user } = useCognitoAuth();
  const email = user.attributes.email;
  const [loading, setLoading] = useState(true);
  const [departmentName, setDepartmentName] = useState('');
  const [userId, setUserId] = useState(null);
  const [valuesFilter, setValuesFilter] = useLocalStorage(
    'commercial-filter-filters',
    {
      client: '',
      project: '',
      project_type: '',
      status: '',
    }
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [optionsStatus, setOptionsStatus] = useState([]);
  const [listManagers, setListManagers] = useState([]);
  const [optionsProjectTypes, setOptionsProjectTypes] = useState([]);
  const [projects, setProjects] = useState([]);
  const [optionsClients, setOptionsClients] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [pages, setPages] = useState(1);
  const headers = {
    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
  };

  const returnClientOptions = async (department, user) => {
    if (department === 'VAREJISTA' || department === 'NOVOS CLIENTES') {
      const response = await apiConnect.get(`/users/${user}/retailers`, {
        headers,
      });
      setOptionsClients(() =>
        response.data.map(r => ({
          label: r.retailer_name,
          value: r.retailer,
        }))
      );
    } else {
      const response = await apiConnectManagement.get('/clients');
      if (response?.data) {
        const client = response.data.clients;
        // console.log('client', client);
        const arrOpt = [];
        client.forEach(ob => {
          arrOpt.push({
            label: ob.client_name,
            value: ob.client_id,
          });
        });
        setOptionsClients(optionsClients => [...optionsClients, ...arrOpt]);
      }
    }
  };

  const loadUserAndClients = async () => {
    const users = await apiConnect.get(`/users/?email=${email}`, { headers });
    const department = users.data[0]?.department_name;
    const uId = users.data[0]?.id;

    setDepartmentName(department);
    setUserId(uId);
    returnClientOptions(department, uId);
  };

  // stage_id = 49
  // stage_status_id = 3

  const returnSearch = (page = 1) => {
    setLoading(true);
    let url = '';
    if (valuesFilter.project) {
      url = `/projects_by_name?page=${page}&name=${valuesFilter.project}`;
    } else {
      url = `/projects?stage_id=49&stage_status_id=3&page=${page}`;
    }
    if (valuesFilter.client) {
      url += `&client_id=${valuesFilter.client}`;
    } else if (
      !valuesFilter.client &&
      (departmentName === 'VAREJISTA' || departmentName === 'NOVOS CLIENTES')
    ) {
      url += `&client_id=${optionsClients.map(o => o.value).join(',')}`;
    }
    if (valuesFilter.project) {
      url += `&name=${valuesFilter.project}`;
    }
    if (valuesFilter.project_type) {
      url += `&project_type_id=${valuesFilter.project_type}`;
    }
    if (valuesFilter.status) {
      url += `&status_project_id=${valuesFilter.status}`;
    }
    if (valuesFilter.manager_user_id) {
      url += `&manager_user_id=${valuesFilter.manager_user_id}`;
    }
    apiConnectManagement
      .get(url)
      .then(response => {
        if (response?.data) {
          const projects = response.data.results;
          // console.log('projects 1', projects);
          setCurrentPage(response.data.current_page);
          setPages(response.data.pages);
          setProjects(projects);
        }
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else if (error?.response?.status === 400) {
          Alert.error(error.response.data.error, 50000);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado, tente novamente mais tarde!'
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClear = () => {
    setLoading(true);
    setDisabled(true);
    setValuesFilter({
      client: '',
      project: '',
      project_type: '',
      status: '',
      manager_user_id: '',
    });
    // setLoading(false);
    let url;
    if (departmentName === 'VAREJISTA' || departmentName === 'NOVOS CLIENTES') {
      url = `/projects?stage_id=49&stage_status_id=3&page=${currentPage}&client_id=${optionsClients
        .map(o => o.value)
        .join(',')}`;
    } else {
      url = `/projects?stage_id=49&stage_status_id=3&page=${currentPage}`;
    }
    apiConnectManagement.get(url).then(response => {
      if (response?.data) {
        const projects = response.data.results;
        // console.log('projects 2', projects);
        setCurrentPage(response.data.current_page);
        setPages(response.data.pages);
        setProjects(projects);
      }
      setLoading(false);
    });
  };

  const handleFiltered = () => {
    setCurrentPage(1);
    returnSearch();
  };

  const handlePagination = page => {
    setCurrentPage(page);
    returnSearch(page);
  };

  const returnProjectTypes = () => {
    apiConnectManagement.get('/project_types').then(response => {
      if (response?.data) {
        const types = response.data.project_types;
        localStorage.setItem('projectTypes', JSON.stringify(types));
        // console.log('status', status);
        types.forEach(ob => {
          const result = {
            label: ob.name,
            value: ob.id,
          };
          setOptionsProjectTypes(optionsProjectTypes => [
            ...optionsProjectTypes,
            result,
          ]);
        });
      }
    });
  };

  const returnStatusOptions = () => {
    apiConnectManagement.get('/statuses_project').then(response => {
      if (response?.data) {
        const status = response.data.statuses_projects;
        localStorage.setItem('statusesProject', JSON.stringify(status));
        // console.log('status', status);
        const opts = [];
        status.forEach(ob => {
          opts.push({
            label: ob.name,
            value: ob.id,
          });
        });
        setOptionsStatus(opts);
      }
    });
  };

  const returnManagersOptions = () => {
    apiConnectManagement.get('/managers_clients').then(response => {
      if (response?.data?.managers) {
        setListManagers(response.data.managers);
      }
    });
  };

  const optionsManagers = useMemo(() => {
    if (
      (departmentName === 'VAREJISTA' || departmentName === 'NOVOS CLIENTES') &&
      !valuesFilter.client
    ) {
      // pega a lista com ids dos clientes
      const listIdsClients = optionsClients.map(c => c.value);
      // filtra comparando a lista de ids de clientes
      // com os ids de clientes de cada responsavel
      return listManagers
        .filter(m => listIdsClients.some(el => m.clients_id.includes(el)))
        .map(op => ({
          label: op.manager_name,
          value: op.manager_user_id,
        }));
    } else if (valuesFilter.client) {
      return listManagers
        .filter(m => m.clients_id.includes(Number(valuesFilter.client)))
        .map(op => ({
          label: op.manager_name,
          value: op.manager_user_id,
        }));
    }
    return listManagers.map(op => ({
      label: op.manager_name,
      value: op.manager_user_id,
    }));
  }, [departmentName, optionsClients, valuesFilter.client, listManagers]);

  // inicialização
  useEffect(() => {
    returnStatusOptions();
    returnProjectTypes();
    returnManagersOptions();
    if (
      valuesFilter?.client ||
      valuesFilter?.project ||
      valuesFilter?.project_type ||
      valuesFilter?.status ||
      valuesFilter?.manager_user_id
    ) {
      setDisabled(false);
    }
  }, []);

  useEffect(() => {
    if (departmentName && userId && optionsClients.length > 0) {
      returnSearch();
    } else if (!departmentName && !userId) {
      loadUserAndClients();
    }
  }, [departmentName, userId, optionsClients]);

  const linkProject = ({ id, name }) => (
    <Link to={`/pdm/select-supplier/${id}`}>
      <span style={{ color: '#338CE1' }}>{name}</span>
    </Link>
  );

  const launchForecastDate = ({ launch_forecast_date }) => (
    <span style={{ color: '#9A9B9B' }}>
      {launch_forecast_date
        ? moment(launch_forecast_date).format('MM/YYYY')
        : '--'}
    </span>
  );

  const startDate = ({ start_date }) =>
    start_date ? moment(start_date).format('DD/MM/YYYY') : '--';

  const columHeaderTable = [
    { clientName: 'CLIENTE' },
    { name: 'PROJETO' },
    { statusProject: 'STATUS' },
    { projectType: 'TIPO DE PROJETO' },
    { managerName: 'RESPONSÁVEL DE PROJETO' },
    { launchForecastDate: 'DATA PROJETADA DE LANÇAMENTO' },
    { startDate: 'DATA REAL DE INÍCIO' },
  ];

  const listTable = useMemo(() => {
    if (projects.length > 0) {
      return projects.map(ob => ({
        clientName: ob.client_name,
        name: linkProject(ob),
        statusProject: ob.status_project.name,
        projectType: ob.project_type?.name || '--',
        managerName: ob.manager_name || '--',
        launchForecastDate: launchForecastDate(ob),
        startDate: startDate(ob),
      }));
    }
    return [];
  }, [projects]);

  return (
    <>
      <HeaderModule classHeader="pdmHeaderModule" afterText="FILTRO">
        Filtro Comercial
      </HeaderModule>
      <Row className={styles.mainDiv2}>
        <Col md={2} mdOffset={9}>
          <SelectPicker
            className="border-0"
            data={optionsClients}
            onChange={(value, event) => {
              setValuesFilter({ ...valuesFilter, client: value });
              setDisabled(false);
            }}
            value={valuesFilter.client}
            appearance="default"
            placeholder="Cliente"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
          />
        </Col>
        <Col md={2}>
          <Input
            placeholder="Projeto"
            value={valuesFilter.project}
            onChange={(value, event) => {
              setValuesFilter({ ...valuesFilter, project: value });
              setDisabled(false);
            }}
            className={styles.textInput}
          />
        </Col>
        <Col md={2}>
          <SelectPicker
            className="border-0"
            data={optionsProjectTypes}
            onChange={(value, event) => {
              setValuesFilter({ ...valuesFilter, project_type: value });
              setDisabled(false);
            }}
            value={valuesFilter.project_type}
            appearance="default"
            placeholder="Tipo"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
          />
        </Col>
        <Col md={2}>
          <SelectPicker
            className="border-0"
            data={optionsStatus}
            onChange={(value, event) => {
              setValuesFilter({ ...valuesFilter, status: value });
              setDisabled(false);
            }}
            value={valuesFilter.status}
            appearance="default"
            placeholder="Status"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
          />
        </Col>
        <Col md={3}>
          <SelectPicker
            className="border-0"
            data={optionsManagers}
            onChange={(value, event) => {
              setValuesFilter({ ...valuesFilter, manager_user_id: value });
              setDisabled(false);
            }}
            value={valuesFilter.manager_user_id}
            appearance="default"
            placeholder="Responsável"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
          />
        </Col>
        <Col md={2}>
          <Button
            disabled={disabled || loading}
            style={{ minHeight: 38 }}
            onClick={handleFiltered}
            color="blue"
            block>
            Filtrar
          </Button>
        </Col>
        <Col md={2}>
          <Button
            disabled={disabled || loading}
            style={{ minHeight: 38 }}
            onClick={handleClear}
            color="grey"
            block>
            Limpar
          </Button>
        </Col>
      </Row>

      <TableList
        columns={columHeaderTable}
        lines={listTable}
        loading={loading}
      />

      {!loading && (
        <div style={{ margin: '0 15px' }}>
          <Pagination
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={handlePagination}
            loading={loading}
          />
        </div>
      )}
    </>
  );
}

export default ListViewProjects;
