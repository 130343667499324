/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaSistrix } from 'react-icons/fa';
import { Input, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import {
  Alert,
  Col,
  DatePicker,
  Grid,
  Row,
  SelectPicker as SelectP,
} from 'rsuite';
import Header from '../../../../Components/Header2/Header';
import Loading from '../../../../Components/Helpers/Loading';
import SelectPicker from '../../../../Components/SelectPicker/SelectPicker';
import ModalCategory from '../../Briefing/BriefingForm/Modals/ModalCategory/ModalCategory';
import './NewProject.scss';
// Project Nature
import { ReactComponent as GlobeFill } from '../../../../Assets/globe-fill.svg';
import { ReactComponent as Industry } from '../../../../Assets/industry.svg';
import { ReactComponent as Marketplace } from '../../../../Assets/marketplace.svg';
import { ReactComponent as StoreFill } from '../../../../Assets/store-fill.svg';
// Project type
import { ReactComponent as Altboard } from '../../../../Assets/artboard-line.svg';
// import { ReactComponent as Box } from '../../../../Assets/box-simple.svg';
import { ReactComponent as Group } from '../../../../Assets/group-line.svg';
import { ReactComponent as Tube } from '../../../../Assets/tube-line.svg';

import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useCognitoAuth } from '../../../../Contexts/AuthCognitoContext';
import { useBriefingContext } from '../../../../Contexts/BriefingContext';
import { useManagementContext } from '../../../../Contexts/ManagementContext';
import useDebounce from '../../../../utils/hooks/useDebounce';
import { classNames } from '../../../../utils/utils';

const NewProject = () => {
  const { user: authUser } = useCognitoAuth();
  const navigate = useNavigate();

  const email = authUser.attributes.email;

  const [selectedOpType, setSelectedOpType] = useState('');
  const [arrayBrands, setArrayBrands] = useState([]);
  const [productsList, setProductsList] = useState([]); //

  /* estados do modal de tipo de projeto */
  const [newType, setNewType] = useState(-1);
  const [showNewProductModal, setShowNewProductModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debounceSearchTerm = useDebounce(searchTerm, 600);
  const [projects, setProjects] = useState([]);

  const [upperLevels, setUpperLevels] = useState();

  const modalCategoryRef = useRef(null);

  const {
    loading,
    user,
    retailers,
    trailTypes,
    operationTypes,
    listBrands,
    listUsers,
    dtiUsers,
    managers,
    serviceManagers,
    managerProject,
    clientResponsibles,
    createProject,
    searchBriefings,
    ...briefingContext
  } = useBriefingContext();

  const { getListParentProjects } = useManagementContext();

  const displayProjectAlert = () =>
    Alert.error(
      'CAMPOS OBRIGATÓRIOS - SELECIONE O PROJETO A SER DERIVADO',
      5000
    );

  const onSubmit = async () => {
    if (errors.hiddenTypeField) {
      displayProjectAlert();
      return;
    }

    if (newType <= 0) {
      Alert.error('CAMPOS OBRIGATÓRIOS - SELECIONE O TIPO DE PROJETO', 5000);
      return;
    }

    if (selectedOpType === '') {
      Alert.error(
        'CAMPOS OBRIGATÓRIOS - SELECIONE A NATUREZA DA OPERAÇÃO',
        5000
      );
      return;
    }

    const payload = {
      name: values.nameProject,
      operation_type: parseInt(selectedOpType),
      client_id: values.retailer,
      client_name: filterNameByIdProjects(values.retailer, arrayRetailers),
      client_brand: values.brands,
      development_responsible_id: values.serviceManagerSelect,
      development_responsible: filterNameByIdProjects(
        values.serviceManagerSelect,
        arrayServiceManagers
      ),
      service_responsible: filterNameByIdProjects(
        values.managerSelect,
        arrayManagers
      ),
      service_responsible_id: values.managerSelect,
      dti_responsible_id: values.dtiSelect,
      dti_responsible: filterNameByIdProjects(
        values.dtiSelect,
        arrayDTISelecteds
      ),
      client_responsible_id: values.clientResponsibleSelect,
      client_responsible: filterNameByIdProjects(
        values.clientResponsibleSelect,
        arrayManagerCustomer
      ),
      trail_type: null,
      division: values.inputDivision,
      project_classification_id: upperLevels,
      project_type: newType,
      manager_user_id: values.managerProjectSelect,
      manager_name: filterNameByIdProjects(
        values.managerProjectSelect,
        arrayManagerProject
      ),
      user: {
        username: authUser.username,
        attributes: {
          sub: authUser.attributes.sub,
          email: authUser.attributes.email,
          phone_number: null,
        },
      },
      ...(newType === 1
        ? {
            derived_from_project: null,
            derived_from_name: null,
            derived_from_launch_date: null,
          }
        : {}),
      ...(values.modalProject
        ? {
            derived_from_project: values.modalProject,
            derived_from_name: null,
            derived_from_launch_date: null,
          }
        : {}),
      ...(values.modalProjectName
        ? {
            derived_from_project: null,
            derived_from_name: values.modalProjectName,
            derived_from_launch_date: values.modalProjectDate,
          }
        : {}),
    };

    console.log('payload', payload);

    const { success, data } = await createProject(payload);
    if (success) {
      Alert.success('Projeto criado com sucesso', 5000);
      resetForm();
      navigate(`/pdm/management/${data.id}`);
    } else {
      Alert.erro('Não foi possível criar o projeto', 5000);
    }
  };

  const validationSchema = Yup.object({
    retailer: Yup.string().required('Campo Obrigatório'),
    selectedTrailType: Yup.number().nullable(),
    nameProject: Yup.string().required('Campo Obrigatório'),
    brands: Yup.string().when([], {
      is: () => arrayBrands.length > 0,
      then: Yup.string().required('Campo Obrigatório'),
    }),
    managerProjectSelect: Yup.number().required('Campo Obrigatório').nullable(),
    dtiSelect: Yup.number().required('Campo Obrigatório').nullable(),
    serviceManagerSelect: Yup.number().nullable(),
    managerSelect: Yup.string().required('Campo Obrigatório').nullable(),
    clientResponsibleSelect: Yup.string().when([], {
      is: () => arrayManagerCustomer.length > 0,
      then: Yup.string().required('Campo Obrigatório'),
    }),
    inputDivision: Yup.string().required('Campo Obrigatório'),

    modalRetailer: Yup.string(),
    modalProject: Yup.string().when('modalRetailer', {
      is: modalRetailer => modalRetailer && newType > 1,
      then: Yup.string().required('Campo Obrigatório'),
    }),
    modalProjectName: Yup.string(),
    modalProjectDate: Yup.string().when('modalProjectName', {
      is: modalProjectName => modalProjectName && newType > 1,
      then: Yup.string().required('Campo Obrigatório'),
    }),
    hiddenTypeField: Yup.string().when(['modalRetailer', 'modalProjectName'], {
      is: (a, b) => newType > 1 && !a && !b,
      then: Yup.string().required('Campo Obrigatório'),
    }),
  });

  const {
    handleChange,
    values,
    setFieldValue,
    resetForm,
    handleSubmit,
    errors,
    validateForm,
  } = useFormik({
    initialValues: {
      retailer: '',
      selectedTrailType: null,
      nameProject: '',
      brands: '',
      managerProjectSelect: null,
      dtiSelect: null,
      serviceManagerSelect: null,
      managerSelect: null,
      clientResponsibleSelect: '',
      inputDivision: '',
      modalRetailer: '',
      modalProject: '',
      modalProjectName: '',
      modalProjectDate: '',
      hiddenTypeField: '',
    },
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!user) {
      briefingContext.getUser(email);
    }
    briefingContext.getOperationTypes();
    briefingContext.getDtiUsers();
  }, []);

  useEffect(() => {
    if (user) {
      briefingContext.getRetailers();
      briefingContext.getDtiUsers();
    }
  }, [user]);

  useEffect(() => {
    if (values.retailer) {
      Promise.all([
        briefingContext.getManagers(values.retailer),
        briefingContext.getServiceManagers(values.retailer),
        briefingContext.getManagerProject(values.retailer, true),
        briefingContext.getListBrands(values.retailer),
        briefingContext.getClientResponsibles(values.retailer),
      ]).then(() => validateForm());
    }
  }, [values.retailer]);

  useEffect(() => {
    values.selectedTrailType = '';
    if (selectedOpType) {
      const inter = selectedOpType === '2' ? true : false;
      briefingContext.getTrailTypes(inter);
    }
  }, [selectedOpType]);

  useEffect(() => {
    if (listBrands) {
      setArrayBrands(
        listBrands.brands.map(b => ({
          value: b,
          label: b,
        }))
      );
    }
  }, [listBrands]);

  useEffect(() => {
    console.log('listBrands', listBrands);
  }, [listBrands]);

  // filtra pelo departamento
  const filteredDtiUsers = useMemo(
    () => dtiUsers.filter(dti => dti.department === 4),
    [dtiUsers]
  );

  const arrayRetailers = useMemo(() => {
    return retailers.map(ret => ({
      ...ret,
      value: ret.id,
      label: ret.name,
    }));
  }, [retailers]);

  //  const arrayTrailTypes = useMemo(() => {
  //    return trailTypes.map(tt => ({
  //      value: tt.id,
  //      label: tt.name,
  //    }));
  //  }, [trailTypes]);

  const arrayDTISelecteds = useMemo(() => {
    return filteredDtiUsers.map(dti => ({
      value: dti.id,
      label: dti.name,
    }));
  }, [filteredDtiUsers]);

  const arrayManagers = useMemo(() => {
    return managers.map(m => ({
      value: m.user,
      label: m.user_name,
    }));
  }, [managers]);

  const arrayServiceManagers = useMemo(() => {
    return serviceManagers.map(ms => ({
      value: ms.user,
      label: ms.user_name,
    }));
  }, [serviceManagers]);

  const arrayManagerProject = useMemo(() => {
    return managerProject.map(mp => ({
      value: mp.user,
      label: mp.user_name,
    }));
  }, [managerProject]);

  const arrayManagerCustomer = useMemo(() => {
    return clientResponsibles.map(c => ({
      value: c.id,
      label: `${c.given_name} ${c.family_name}`,
    }));
  }, [clientResponsibles]);

  /*  const filterNameById = (id, list) => {
  return list.find(item => item.value === id)?.name;
}; */

  const filterNameByIdProjects = (id, list) => {
    return list.find(item => item.value === id)?.label;
  };

  const displayProjectName = () => {
    if (values.modalProject) {
      return filterNameByIdProjects(values.modalProject, projects);
    } else if (values.modalProjectName) {
      return values.modalProjectName;
    }
  };

  const datetimeToISODate = value => {
    const newFormat = value.toISOString().split('T')[0];
    return newFormat;
  };

  /* mesmos id da API */
  const typeProject = [
    // { id: 1, name: 'Novo Produto', image: Box },
    { id: 2, name: 'Nova Embalagem', image: Altboard },
    { id: 4, name: 'Extensão de Linha', image: Tube },
    { id: 6, name: 'Troca de Fornecedor', image: Group },
    { id: 3, name: 'Reformulação', image: Tube },
  ];

  const getOperationTypeImage = id => {
    const types = {
      1: <StoreFill />,
      2: <GlobeFill />,
      3: <Industry />,
      4: <Marketplace />,
    };
    if (types[id]) {
      return types[id];
    }
    return '';
  };

  const validateButtonOnCloseModal = e => {
    const { value, msg } = e;
    if (!value) {
      Alert.error(`${msg}`, 5000);
    }
  };

  /* funções do modal de tipo de projeto */

  const searchProjects = term => {
    if (term && term.length > 1) {
      setSearchTerm(term);
    } else {
      setProjects([]);
    }
  };

  useEffect(() => {
    if (showNewProductModal && retailers.length === 0) {
      briefingContext.getRetailers();
    }
    if (!showNewProductModal) {
      /* setSelectedProject('');
    setSearchTerm('');
    setSelectedRetailer(null);
    setProjects([]);
    setName('');
    setDateValue(null); */
    }
  }, [retailers, showNewProductModal]);

  useEffect(() => {
    if (debounceSearchTerm) {
      getListParentProjects(debounceSearchTerm).then(res => {
        const list = res.data.map(r => ({
          value: r.id,
          label: `${r.id} - ${r.name}`,
        }));
        setProjects(list);
      });
    }
  }, [debounceSearchTerm]);

  function toggleShowNewProductModal() {
    setShowNewProductModal(!showNewProductModal);
    if (!values.modalProject && !values.modalProjectName) {
      displayProjectAlert();
    }
  }

  const newProduct = () => {
    setSearchTerm('');
    setProjects([]);
  };

  const canConfirm = useMemo(() => {
    if (values.modalProject) return true;
    if (values.modalProjectName.length > 2 && values.modalProjectDate)
      return true;
    return false;
  }, [values.modalProject, values.modalProjectName, values.modalProjectDate]);

  /* const goNewProduct = (nType = null) => {
  const params = {};
  params.newType = typeof nType === 'number' ? nType : newType;
  if (selectedRetailer) params.retailer = selectedRetailer;
  if (selectedProject) params.project = selectedProject;
  if (name.length > 2) params.name = name;
  if (dateValue) params.date = dateValue.toISOString();
  const urlParams = new URLSearchParams(params).toString();
  setShowNewProductModal(false);
  window.location.href = `/pdm/briefing/novo-briefing/?${urlParams}`;
  // document.getElementById('closeMenuButton')?.click();
  // navigate(`/pdm/briefing/novo-briefing/?${urlParams}`);
}; */

  // useEffect(() => {
  //   console.log('setUpperLevels', upperLevels);
  // }, [upperLevels]);

  return (
    <div className="NewProject">
      {loading.createProject && <Loading />}
      <Header>NOVO PROJETO</Header>
      <Grid className="formProject">
        <Row>
          <h1 className="titleProject">
            Vai começar um novo projeto?
            <br /> Esperamos que essa jornada seja ótima!
          </h1>
        </Row>
        <Row className="rowProject">
          <Col md={10}>
            <p className="subtitleProject">
              <strong>1.</strong> Por favor, escolha a natureza da operação
            </p>
            <div className="divOperation">
              {operationTypes
                .filter(op => op.id !== 4) // remove produtos ofertados
                .sort((a, b) => a.id - b.id)
                .map(op => (
                  <label
                    className="divOp"
                    key={`op-${op.id}`}
                    htmlFor={`op${op.id}`}>
                    <input
                      type="radio"
                      name="operation_type"
                      id={`op${op.id}`}
                      value={op.id}
                      onChange={ev => {
                        setSelectedOpType(String(op.id));
                        // Marketplace só pode ser do tipo "Novo Produto"
                        // if (String(op.id) === '4') {
                        //   newProduct();
                        //   setNewType(1);
                        // }
                      }}
                      checked={selectedOpType === String(op.id)}
                    />
                    <div
                      className={classNames({
                        boxRetail: true,
                        selected: selectedOpType === String(op.id),
                      })}>
                      {getOperationTypeImage(op.id)}
                    </div>
                    <span className="nameOpType">{op.name}</span>
                  </label>
                ))}
              {/* <div className="divOp">
               <div className="boxComex">
                 <img src={globeFill} alt="Comex"></img>
               </div>
               <span>COMEX</span>
             </div> */}
            </div>
          </Col>
          <Col md={14}>
            <p className="subtitleProject">
              <strong>2.</strong> Agora, o tipo de projeto
            </p>
            <div className="divTypeProject">
              {typeProject.map(p => (
                <button
                  type="button"
                  key={p.name}
                  disabled={p.id !== 1 && selectedOpType === '4'}
                  className={classNames({
                    boxProject: true,
                    selected:
                      p.id === 4 ? [4, 5].includes(newType) : newType === p.id,
                  })}
                  onClick={() => {
                    if (p.id !== 1) {
                      setNewType(p.id);
                      setShowNewProductModal(true);
                    } else {
                      newProduct();
                      setNewType(1);
                    }
                  }}>
                  <p.image />
                  {p.name}
                </button>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="rowForm">
          <p className="subtitleProject">Preencha os dados do novo projeto</p>
          <Col md={6}>
            <div className="divForm">
              <label>Cliente</label>
              <SelectPicker
                value={values.retailer}
                onChange={v => setFieldValue('retailer', v)}
                data={arrayRetailers}
                className="selectPicker selectPickerCustom"
                placeholder="Selecione"
                error={errors.retailer}
              />
            </div>
            <div className="divForm">
              <label>Categoria</label>
              <button
                type="button"
                className="btnInputCategory"
                id="inputDivision"
                onClick={() => modalCategoryRef.current?.toggle()}
                title={
                  values.inputDivision?.length > 39 ? values.inputDivision : ''
                }>
                {values.inputDivision || 'Selecione'}
              </button>
              {!!errors.inputDivision && (
                <span className="formError">{errors.inputDivision}</span>
              )}
            </div>
            <div className="divForm">
              <label>Nome do Projeto</label>
              <input
                type="text"
                placeholder="Ex: Chocolate diet"
                className="inputNameProj"
                value={values.nameProject}
                onChange={handleChange('nameProject')}
              />
              {!!errors.nameProject && (
                <span className="formError">{errors.nameProject}</span>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="divForm">
              <label>Marca do Cliente</label>
              <SelectPicker
                value={values.brands}
                onChange={v => setFieldValue('brands', v)}
                data={arrayBrands}
                className="selectPicker selectPickerCustom"
                placeholder="Selecione"
                disabled={
                  loading.listBrand ||
                  !values.retailer ||
                  arrayBrands.length === 0
                }
                error={errors.brands}
              />
            </div>
            <div className="divForm">
              <label>Gestor</label>
              <SelectPicker
                value={values.managerProjectSelect}
                onChange={v => setFieldValue('managerProjectSelect', v)}
                data={arrayManagerProject}
                className="selectPicker selectPickerCustom"
                placeholder="Preenchido"
                disabled={loading.managerProject || !values.retailer}
                error={errors.managerProjectSelect}
              />
            </div>
            <div className="divForm">
              <label>Responsável DTI</label>
              <SelectPicker
                value={values.dtiSelect}
                onChange={v => setFieldValue('dtiSelect', v)}
                data={arrayDTISelecteds}
                className="selectPicker selectPickerCustom"
                placeholder="Selecione"
                error={errors.dtiSelect}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="divForm">
              <label>Responsável Cliente</label>
              <SelectPicker
                value={values.clientResponsibleSelect}
                onChange={v => setFieldValue('clientResponsibleSelect', v)}
                data={arrayManagerCustomer}
                className="selectPicker selectPickerCustom"
                placeholder="Selecione"
                disabled={
                  loading.managerCustomer ||
                  !values.retailer ||
                  arrayManagerCustomer.length === 0
                }
                error={errors.clientResponsibleSelect}
              />
            </div>
            <div className="divForm">
              <label>Responsável Desenvolvimento</label>
              <SelectPicker
                value={values.serviceManagerSelect}
                onChange={v => setFieldValue('serviceManagerSelect', v)}
                data={arrayServiceManagers}
                className="selectPicker selectPickerCustom"
                placeholder="Selecione"
                disabled={
                  loading.serviceManagers ||
                  !values.retailer ||
                  arrayServiceManagers.length === 0
                }
                error={errors.serviceManagerSelect}
              />
            </div>
            <div className="divForm">
              <label>Responsável Atendimento</label>
              <SelectPicker
                value={values.managerSelect}
                onChange={v => setFieldValue('managerSelect', v)}
                data={arrayManagers}
                className="selectPicker selectPickerCustom"
                placeholder="Selecione"
                disabled={loading.managers || !values.retailer}
                error={errors.managerSelect}
              />
            </div>
            {values.modalProject || values.modalProjectName ? (
              <div className="divDerivProj">
                <span>Projeto Derivado: {displayProjectName()}</span>
              </div>
            ) : (
              <div></div>
            )}
          </Col>
        </Row>
        <Row>
          <div className="div-buttons">
            <button
              type="button"
              className="btn-danger"
              onClick={() => navigate('/pdm/management')}>
              Cancelar
            </button>
            <button
              type="button"
              className="btn-success"
              disabled={loading.createProject}
              onClick={handleSubmit}>
              Salvar
            </button>
          </div>
        </Row>
      </Grid>
      <ModalCategory
        ref={modalCategoryRef}
        inputCategory={values.inputDivision}
        setInputCategory={v => setFieldValue('inputDivision', v)}
        setProductsList={setProductsList}
        productsList={productsList}
        setUpperLevels={setUpperLevels}
        onClosed={() =>
          validateButtonOnCloseModal({
            value: values.inputDivision,
            msg: 'CAMPO CATEGORIA OBRIGATÓRIO - SELECIONE A CATEGORIA DO PROJETO',
          })
        }
      />
      <Modal isOpen={showNewProductModal} toggle={toggleShowNewProductModal}>
        <ModalBody className="modalNewProducst">
          <p className="textNewProduct">
            Por favor, indique de qual projeto e cliente pretende fazer a{' '}
            <span className="orange">
              {newType === 2 && 'Nova Embalagem'}
              {newType === 4 && 'Extensão de Linha'}
              {newType === 6 && 'Troca de Fornecedor'}
              {newType === 3 && 'Reformulação'}
            </span>
          </p>
          <fieldset className="blueSearch fieldsetModal">
            <button
              type="button"
              className="blueBtnPickerSearch"
              onClick={() => {
                document.getElementById('selectRetailer')?.click();
              }}>
              {loading.retailers ? (
                <Spinner size="sm" />
              ) : (
                <FaSistrix size={20} />
              )}
            </button>
            <SelectP
              id="selectRetailer"
              className="bluePickerSearch"
              value={values.modalRetailer}
              onChange={v => setFieldValue('modalRetailer', v)}
              data={arrayRetailers}
              placeholder="Especifique o Cliente"
            />
          </fieldset>
          <fieldset className="blueSearch fieldsetModal">
            <button
              type="button"
              className="blueBtnPickerSearch"
              onClick={() => {
                document.getElementById('selectProject')?.click();
              }}>
              <FaSistrix size={20} />
            </button>
            <SelectP
              id="selectProject"
              className="bluePickerSearch"
              value={values.modalProject}
              onChange={v => setFieldValue('modalProject', v)}
              data={projects}
              onSearch={searchProjects}
              renderMenu={menu => {
                if (loading.searchBriefings) {
                  return (
                    <p
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        marginBottom: '10px',
                      }}>
                      <Spinner size="sm" /> Buscando...
                    </p>
                  );
                }
                return menu;
              }}
              placeholder="Indique o Projeto"
            />
          </fieldset>
          <p className="textNewProduct">
            Caso o Projeto não conste na base, por favor insira manualmente o{' '}
            <span>Nome</span> e <span>Data de Lançamento</span>
          </p>
          <div className="lineInputs">
            <Input
              value={values.modalProjectName}
              onChange={e => setFieldValue('modalProjectName', e.target.value)}
              className="lightblueInput"
              placeholder="Ex: Chocolate 500"
            />
            <DatePicker
              format="DD/MM/YYYY"
              placement="rightEnd"
              onChange={v =>
                setFieldValue('modalProjectDate', datetimeToISODate(v))
              }
              value={values.modalProjectDate}
              placeholder="Dia/Mês/Ano"
              name="datePickerInput"
              className="lightbluePicker"
              locale={{
                sunday: 'D',
                monday: 'S',
                tuesday: 'T',
                wednesday: 'Q',
                thursday: 'Q',
                friday: 'S',
                saturday: 'S',
                ok: 'OK',
                today: 'Hoje',
                yesterday: 'Ontem',
                hours: 'Horas',
                minutes: 'Minutos',
                seconds: 'Segundos',
              }}
            />
          </div>
          <ModalFooter className="footerModal">
            {![4, 5].includes(newType) ? (
              <>
                <button
                  type="button"
                  className="btnRed"
                  onClick={toggleShowNewProductModal}>
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btnGreen"
                  onClick={/* goNewProduct */ toggleShowNewProductModal}
                  disabled={!canConfirm}>
                  Confirmar
                </button>
              </>
            ) : (
              <div className="areaBtnsGray">
                {/* <button
                  type="button"
                  className="btnGray"
                  disabled={!canConfirm}
                  onClick={() => {
                    setNewType(4);
                    toggleShowNewProductModal();
                  }}>
                  Mesmo Fornecedor
                </button> */}
                <button
                  type="button"
                  className="btnGray"
                  disabled={!canConfirm}
                  onClick={() => {
                    setNewType(5);
                    toggleShowNewProductModal();
                  }}>
                  Salvar
                </button>
              </div>
            )}
          </ModalFooter>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default NewProject;
