import React, { forwardRef, useImperativeHandle } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import useToggle from '../../../../../utils/hooks/useToggle';
import './ModalProjectObservation.scss';

const ModalProjectObservation = ({ project, onClickBriefing }, ref) => {
  const [show, toggle] = useToggle();
  
  useImperativeHandle(ref, () => ({ toggle }));

  return (
    <>
      <Modal
        backdrop={true}
        centered={true}
        className="modal-observation"
        size="md"
        toggle={toggle}
        isOpen={show}>
        <div className='lineHeader'>
          <span>
            OBSERVAÇÕES GERAIS
          </span>
          <button
          onClick={onClickBriefing}
          className='buttonBriefing'
          disabled={project?.briefing_id === null}
          >
            Briefing
          </button>
        </div>
        <ModalBody>
          <div
            className='observationProject'
            >
            {project?.observação_projeto}
          </div>
        </ModalBody>
        <ModalFooter>
          <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
            <button
              onClick={toggle}
              className='buttonFooter'
              >
              Fechar
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default forwardRef(ModalProjectObservation);
