import React, { forwardRef, useImperativeHandle } from 'react';
import './ModalStepNotEnabled.scss';
import { Modal, ModalBody } from 'reactstrap';
import useToggle from '../../../../utils/hooks/useToggle';

const ModalFinalSelect = ({ duplicateSkus }, ref) => {
  const [show, toggle] = useToggle();

  useImperativeHandle(ref, () => ({ toggle }));

  return (
    <>
    <Modal
      backdrop={true}
      centered={true}
      className="modal-cd-destiny-sku"
      size="sm"
      toggle={toggle}
      isOpen={show}>
      <ModalBody >
        <p className="text-copy-sku">
         Alteração feita com sucesso!
        </p>
      </ModalBody>
    </Modal>
    </>
  );
};

export default forwardRef(ModalFinalSelect);