import React from 'react';
import styles from './styles.module.css';
import { classNames } from '../../utils/utils';
import { Link } from 'react-router-dom';
import { Col, FlexboxGrid } from 'rsuite';

const Tabs = ({ children, tabActive }) => {
  return (
    <FlexboxGrid className={styles.tabs} justify="space-between">
      <FlexboxGrid.Item componentClass={Col} sm={24} md={24} lg={8}>
        <FlexboxGrid>
          <FlexboxGrid.Item
            className={classNames({
              [styles.select]: tabActive === 1 ? true : false,
              [styles.notSelect]: tabActive !== 1 ? true : false,
            })}
            componentClass={Col}
            sm={24}
            md={8}
            lg={6}>
            <Link to="/pdm/prospection/"> Projetos</Link>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            className={classNames({
              [styles.select]: tabActive === 2 ? true : false,
              [styles.notSelect]: tabActive !== 2 ? true : false,
            })}
            componentClass={Col}
            sm={24}
            md={6}
            lg={6}>
            <Link to="/pdm/demands/"> Demandas</Link>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            className={classNames({
              [styles.select]: tabActive === 3 ? true : false,
              [styles.notSelect]: tabActive !== 3 ? true : false,
            })}
            componentClass={Col}
            sm={24}
            md={10}
            lg={12}>
            <Link to="/pdm/activity-record/"> Registro de Atividades</Link>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item
        componentClass={Col}
        className={styles.tabsFilter}
        sm={24}
        md={24}
        lg={16}>
        {children}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default Tabs;
