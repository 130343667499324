/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import ReactGA from 'react-ga';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Alert, SelectPicker, Message, Button } from 'rsuite';
import ProspectionCard from './ProspectionCard';
import Loading from '../../../../../../Components/Loading';
import { storageIsValid } from '../../../utils/utils';
import apiConnectSourcing from '../../../../../../services/apiConnectSourcing';
import titleIcon from '../../../../../../Assets/titleIcon.png';

function ListProspections({
  prospections,
  setProspectionId,
  clientId,
  client,
  isLoading,
  setSummary,
  setStep,
  setIsLoading,
  setProspections,
}) {
  // ReactGA.pageview('/pdm');

  const [optionsStatus, setOptionsStatus] = useState([]);
  const [optionsProjects, setOptionsProjects] = useState([]);
  const [status, setStatus] = useState('');
  const [project, setProject] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    prospections?.forEach(ob => {
      const result = {
        label: ob.briefing_name,
        value: ob.id,
      };
      setOptionsProjects(optionsProjects => [...optionsProjects, result]);
    });
  }, []);

  const getDemandsFromProspections = id => {
    setProspectionId(id);
    apiConnectSourcing
      .get(`/clients/${clientId}/prospections/${id}/summary/`)
      .then(response => {
        if (response.data) {
          setSummary(response.data);
        }
        setStep(2);
        setIsLoading(false);
      })
      .catch(error => {
        if (error.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
          navigate('/pdm');
        } else {
          Alert.error(error.response.data.message, 5000);
        }

        setIsLoading(false);
      });
  };

  const handleFiltered = () => {
    setIsLoading(true);
    apiConnectSourcing
      .get(
        `/clients/${clientId}/prospections/?status=${status}&project=${project}`
      )
      .then(response => {
        if (response.data) {
          setProspections(response.data);
        }

        setIsLoading(false);
      })
      .catch(error => {
        if (error.response?.status === 403) {
          Alert.error(error.response.data.message, 5000);
        } else {
          if (error.response?.status === 404) {
            Alert.warning(error.response.data.message, 5000);
          } else {
            Alert.error(
              'Aconteceu um erro inesperado. Tente novamente, mais tarde!'
            );
          }
        }
        setProspections([]);
        setIsLoading(false);
      });
  };

  const getStatus = () => {
    const [isvalid, timeOrStorage] = storageIsValid('status');
    if (!isvalid) {
      apiConnectSourcing
        .get('/status/')
        .then(response => {
          if (response.data) {
            window.localStorage.setItem(
              'status',
              JSON.stringify({
                data: response.data.results,
                expired: timeOrStorage,
              })
            );
            const status = response.data.results;

            status.forEach(ob => {
              const result = {
                label: ob.name,
                value: ob.id,
              };
              setOptionsStatus(optionsStatus => [...optionsStatus, result]);
            });
          }
        })
        .catch(error => {
          if (error.response?.status === 403) {
            Alert.error(error.response.data.message, 5000);
            navigate('/pdm');
          }
        });
    } else {
      const status = timeOrStorage.data;
      if (optionsStatus.length === 0) {
        status.forEach(ob => {
          const result = {
            label: ob.name,
            value: ob.id,
          };
          setOptionsStatus(optionsStatus => [...optionsStatus, result]);
        });
      }
    }
  };
  const handleClear = () => {
    setIsLoading(true);
    setProject('');
    setStatus('');
    apiConnectSourcing
      .get(`/clients/${clientId}/prospections/`)
      .then(response => {
        if (response.data) {
          setProspections(response.data);
        }

        setIsLoading(false);
      })
      .catch(error => {
        if (error.response?.status === 403) {
          Alert.error(error.response.data.message, 5000);
          navigate('/pdm');
        } else {
          if (error.response?.status === 404) {
            Alert.warning(error.response.data.message, 5000);
          } else {
            Alert.error(
              'Aconteceu um erro inesperado. Tente novamente, mais tarde!'
            );
          }
        }
        setProspections([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <>
      <Row className={styles.rowTitle}>
        <Col md={4} className={styles.colTitle}>
          <img className={styles.img} src={titleIcon} alt="Icone titulo" />
          <strong className={styles.TitleName}>{client}</strong>
        </Col>
        <Col md={4} mdPush={8}>
          {' '}
          <SelectPicker
            data={optionsProjects}
            onChange={(value, event) => {
              setProject(value);
            }}
            value={project}
            appearance="default"
            placeholder="Projetos"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
            disabled={isLoading}
          />
        </Col>
        <Col md={4} mdPush={8}>
          <SelectPicker
            data={optionsStatus}
            onChange={(value, event) => {
              setStatus(value);
            }}
            value={status}
            appearance="default"
            placeholder="Status"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
            disabled={isLoading}
          />
        </Col>
        <Col md={2} mdPush={8}>
          <Button
            disabled={isLoading}
            onClick={handleClear}
            appearance="default"
            block>
            Limpar
          </Button>
        </Col>
        <Col md={2} mdPush={8}>
          <Button
            disabled={isLoading}
            onClick={handleFiltered}
            color="blue"
            block>
            Filtrar
          </Button>
        </Col>
      </Row>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          prospections.length > 0 &&
          prospections?.map((ob, i) => {
            return (
              <ProspectionCard
                key={`${i}-card`}
                onClick={() => getDemandsFromProspections(ob.id)}
                prospectionDetails={ob}
              />
            );
          })
        )}
        {prospections.length === 0 && !isLoading && (
          <div>
            <Message
              style={{ textAlign: 'center' }}
              type="warning"
              description="Sem resultados"
            />
          </div>
        )}
      </Row>
    </>
  );
}

export default ListProspections;
