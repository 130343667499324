/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
// import InputText from '../../../../../Components/InputText/InputText';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import ButtonDefault from '../../../../../Components/ButtonDefault/ButtonDefault';
import { Alert } from 'rsuite';
import './ModalStageCompletion.scss';
// import apiConnectManagement from '../../../../../services/apiConnectManagement';
import { useCognitoAuth } from '../../../../../Contexts/AuthCognitoContext';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InlineCalendar from '../../../../../Components/InlineCalendar/InlineCalendar';

const ModalStageCompletion = (
  { moduleType, moduleStage, onSubmit = () => {} },
  ref
) => {
  const { changeStatus, reloadProjectAndModule } = useManagementContext();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const now = new Date();
  const [dateMin, setDateMin] = useState(
    moment(now).subtract(730, 'days').format('YYYY-MM-DD').toString()
  );
  const [dateMax, setDateMax] = useState(
    moment(now).format('YYYY-MM-DD').toString()
  );
  const [openedStages, setOpenedStages] = useState(null);
  const { user } = useCognitoAuth();
  const validationSchema = Yup.object({
    date: Yup.string().required('Campo Obrigatório'),
  });

  const { handleSubmit, handleChange, values, errors, resetForm } = useFormik({
    initialValues: {
      date: moment(now).format('YYYY-MM-DD').toString(),
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      onHandleConfirm(values);
    },
  });

  const onHandleConfirm = async values => {
    setLoadingConfirm(true);
    const body = {
      user: user,
      date: values.date,
    };
    const response = await changeStatus(
      moduleType,
      moduleStage?.project_module,
      moduleStage?.id,
      3,
      body
    );
    setLoadingConfirm(false);
    if (response.success) {
      onHandleUpdateStages(response?.data);
      resetForm();
      // closeModal();
    } else {
      Alert.error(response.message, 50000);
    }
  };

  const onHandleUpdateStages = data => {
    if (data.opened_stages) {
      const keys = Object.keys(data.opened_stages);
      if (keys.length > 0 && data.opened_stages[keys[0]].length > 0) {
        setOpenedStages(data.opened_stages);
      } else {
        setOpenedStages({});
      }
    }
    // let tempStages = [...stages];
    // // console.log(data);
    // let stage = [];
    // stage.push(data.stage_module);
    // if (data.chain_stage_modules) {
    //   stage.push(data.chain_stage_modules[0][0]);
    // }
    // for (var i = 0; i < stage.length; i++) {
    //   // eslint-disable-next-line no-loop-func
    //   const stageIndex = stages.findIndex(moduleStage => {
    //     return moduleStage.id === stage[i].id;
    //   });
    //   tempStages[stageIndex] = stage[i];
    // }
    // setModuleStages(tempStages);
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  function closeModal() {
    setOpenedStages(null);
    setShowModal(!showModal);
  }

  const closeAndReload = () => {
    onSubmit();
    closeModal();
    reloadProjectAndModule();
  };

  useEffect(() => {
    if (moduleStage?.stage.stage_type.id === 4) {
      setDateMin('');
      setDateMax('');
    } else {
      const mmtDefMinDays = moment(now).subtract(730, 'days');
      const mmtStageStartDate = moduleStage?.start_date
        ? moment(moduleStage.start_date)
        : mmtDefMinDays;
      const mmtDateMin =
        mmtStageStartDate.diff(mmtDefMinDays) > 0
          ? mmtStageStartDate
          : mmtDefMinDays;
      setDateMin(mmtDateMin.format('YYYY-MM-DD').toString());
      setDateMax(moment(now).format('YYYY-MM-DD').toString());
    }
  }, [moduleStage]);

  const loadNextModules = async () => {};

  useEffect(() => {
    if (showModal) {
      loadNextModules();
    }
  }, [showModal]);

  const listNextTasks = arrOpenedStages => {
    if (arrOpenedStages) {
      const keys = Object.keys(arrOpenedStages);
      if (keys.length > 0) {
        return (
          <ul className="list-next-tasks">
            {keys.map(op => (
              <li key={op}>
                <span>{op}</span>
                <ul className="inside-list">
                  {arrOpenedStages[op].map(s => (
                    <li key={s}>- {s}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        );
      }
      return (
        <ul className="list-next-tasks">
          <li>
            <span>Sem etapas liberadas.</span>
          </li>
        </ul>
      );
    }
    return null;
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={false}
      centered={true}
      className="custom-modal">
      <ModalHeader>
        <Row>
          <Col md={1} className="icon-head-stage-completion">
            <svg
              id="Icon"
              width="48.9"
              height="50.589"
              viewBox="0 0 43.9 45.589">
              <defs>
                {/* <linearGradient
                  id="linear-gradient"
                  x1="0.808"
                  y1="0.102"
                  x2="0.258"
                  y2="0.89"
                  gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#6698c1" />
                  <stop offset="1" stop-color="#354d63" />
                </linearGradient> */}
              </defs>
              <ellipse
                id="Ellipse_13"
                data-name="Ellipse 13"
                cx="21.95"
                cy="22.794"
                rx="21.95"
                ry="22.794"
                // fill="url(#linear-gradient)"
              />
              <path
                id="Icon_awesome-check-circle"
                data-name="Icon awesome-check-circle"
                d="M27.8,14.182A13.62,13.62,0,1,1,14.182.563,13.62,13.62,0,0,1,27.8,14.182Zm-15.2,7.211,10.1-10.1a.879.879,0,0,0,0-1.243L21.469,8.8a.879.879,0,0,0-1.243,0l-8.241,8.241L8.138,13.2a.879.879,0,0,0-1.243,0L5.653,14.439a.879.879,0,0,0,0,1.243l5.711,5.711a.879.879,0,0,0,1.243,0Z"
                transform="translate(7.768 8.613)"
                fill="#fff"
              />
            </svg>
          </Col>
          <Col md={7} className="texts-modal">
            <h2 className="text-stage-completion">Finalização da Etapa</h2>
            <h3 className="sub-stage-completion">{moduleStage?.stage.name}</h3>
          </Col>

          <Col md={4} className="date-modal">
            Data: {moment(now).locale('pt-br').format('DD/MM/YYYY')} <br />
            <span style={{ textTransform: 'capitalize' }}>
              {moment(now).format('dddd').split('-')[0]}
            </span>{' '}
            {moment(now).format('HH:mm')}
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {openedStages ? (
          <>
            <p>
              Você <span className="text-color-green">FINALIZOU</span> a etapa
              de {moduleStage?.stage.name}. As seguintes etapas já podem ser
              iniciadas:
            </p>
            <section>{listNextTasks(openedStages)}</section>
          </>
        ) : (
          <section>
            { /*
              <Row className="padding-top-20 padding-bottom-20 justify-content-center">
                <Col md={6} lg={4}>
                  <div
                    style={{ border: '1px solid #1f4d74', borderRadius: '5px' }}>
                    <InlineCalendar
                      date={values.date}
                      position={{ left: 0 }}
                      setDate={handleChange('date')}
                      minDate={dateMin}
                      maxDate={dateMax}
                      placeholder="00/00/0000"
                      dateStr={true}
                      classes={['date-in']}
                    />
                  </div>
                </Col>
                <span
                  style={{
                    paddingTop: 10,
                    color: '#1f4d74',
                    fontWeight: 'bold',
                  }}>
                  Data de finalização
                </span>
              </Row>
            */ }
            <Row>
              <span className="error">{errors.date ? errors.date : null}</span>
            </Row>
          </section>
        )}
      </ModalBody>
      <ModalFooter>
        {openedStages ? (
          <ButtonDefault color="blue" onClick={closeAndReload} radius>
            Fechar
          </ButtonDefault>
        ) : (
          <>
            <ButtonDefault color="red" onClick={closeModal} radius>
              Cancelar
            </ButtonDefault>
            <ButtonDefault
              disabled={loadingConfirm}
              loading={loadingConfirm}
              onClick={handleSubmit}
              color="green"
              radius>
              Confirmar
            </ButtonDefault>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default forwardRef(ModalStageCompletion);
