import React from 'react';
import HeaderModule from '../../../Components/HeaderModule/HeaderModule';

function OpeningCallsReport() {
  return (
    <>
      <HeaderModule classHeader="indicatorsHeaderModule">
        Abertura de chamados
      </HeaderModule>
      <div className="mainDiv">
        <center>
          <iframe
            title="iframeOpeningCalls"
            className="clickup-embed clickup-dynamic-height"
            src="https://forms.clickup.com/f/2y3rj-150/0KJTRCZGFQ6FD57F53"
            onwheel=""
            width="100%"
            height="100%"></iframe>
        </center>
      </div>
    </>
  );
}

export default OpeningCallsReport;
