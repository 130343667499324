/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import ReactGA from 'react-ga';
import { storageIsValid } from '../../../utils/utils';
// import moment from 'moment';
import styles from './styles.module.css';
import HeaderModule from '../../../../../../Components/HeaderModule/HeaderModule';
import apiConnectSourcing from '../../../../../../services/apiConnectSourcing';
import apiConnect from '../../../../../../services/apiConnect';
import { Alert, SelectPicker, Col, Row } from 'rsuite';
import Tabs from '../../../../../../Components/Tabs';
function Header({
  isLoading,
  clientId,
  step,
  setStep,
  setIsLoading,
  setClient,
  setClientId,
  setProspections,
  children,
}) {
  // ReactGA.pageview('/pdm');

  const headers = {
    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
  };

  const getProspectionsFromClient = (value, event) => {
    setIsLoading(true);
    setClient(event.target.innerHTML);
    setClientId(value);
    apiConnectSourcing
      .get(`/clients/${value}/prospections/`)
      .then(response => {
        if (response.data) {
          setProspections(response.data);
        }
        setStep(1);
        setIsLoading(false);
      })
      .catch(error => {
        if (error.response?.status !== 403) {
          Alert.error(error.response.data.message);
        }

        setIsLoading(false);
        setProspections([]);

        setStep(1);
      });
  };

  const [optionsClients, setOptionsClients] = useState([]);

  const getClients = () => {
    const [isvalid, timeOrStorage] = storageIsValid('clients');
    if (!isvalid) {
      apiConnect
        .get('/retailers/', { headers })
        .then(response => {
          if (response.data) {
            window.localStorage.setItem(
              'clients',
              JSON.stringify({
                data: response.data,
                expired: timeOrStorage,
              })
            );
            const clients = response.data;

            clients.forEach(ob => {
              const result = {
                label: ob.name,
                value: ob.id,
              };
              setOptionsClients(optionsClients => [...optionsClients, result]);
            });
          }
        })
        .catch(error => {});
    } else {
      const clients = timeOrStorage.data;
      if (optionsClients.length === 0) {
        clients.forEach(ob => {
          const result = {
            label: ob.name,
            value: ob.id,
          };
          setOptionsClients(optionsClients => [...optionsClients, result]);
        });
      }
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <>
      <HeaderModule classHeader="pdmHeaderModule">
        Controle de Projetos
      </HeaderModule>
      <Tabs tabActive={3} />
      <div className={styles.mainDiv}>
        <Row className={styles.header}>
          <Col md={4}>
            <SelectPicker
              data={optionsClients}
              onChange={(value, event) => {
                getProspectionsFromClient(value, event);
              }}
              value={clientId}
              appearance="default"
              placeholder="Clientes"
              renderMenuItem={(label, item) => {
                return <div>{label}</div>;
              }}
              disabled={isLoading || step > 1}
            />
          </Col>
        </Row>
        {children}
      </div>
    </>
  );
}

export default Header;
