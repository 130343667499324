export const MODULE_STATUS = {
  NOT_STARTED: 1, //NÃO INICIADO
  IN_PROGRESS: 2, // EM ANDAMENTO
  STANDBY: 3, // STANDBY
  UNFEASIBLE: 4, //INVIABILIZADO
  CANCELLED: 5, //CANCELADO
  CONCLUDED: 6, //CONCLUÍDO
  DEVELOPING: 7, // EM DESENVOLVIMENTO
  NOT_APPLY: 8, // NÃO SE APLICA
};

export const MODULE_TYPE = {
  BRIEFING: 1, //BRIEFING
  SOURCING: 2, // SOURCING
  VIABILITY: 3, // VIABILIDADE
  AUDIT: 4, // AUDITORIA
  SUPPLIER_DEFINITION: 5, //DEFINIÇÃO DO FORNECEDOR
  CONTRACTS_AND_REGISTRATION: 6, //CONTRATOS E CADASTRO
  PACKAGING_DEVELOPMENT: 7, // DESENVOLVIMENTO DE EMBALAGEM
  PRINT: 8, // IMPRESSÃO
  FIRST_PRODUCTION: 9, // PRIMEIRA PRODUÇÃO
  RELEASE: 10, // LANÇAMENTO
};

export const PROJECT_STATUS = {
  IN_PROGRESS: 1, // EM ANDAMENTO
  STANDBY: 2, // STANDBY
  UNFEASIBLE: 3, //INVIABILIZADO
  CANCELLED: 4, //CANCELADO
  FINISHED: 5, // FINALIZADO
  DELETED: 6, // EXCLUÍDO
  RELEASED: 7, // LANÇADO
  NOT_STARTED: 8, // NAO INICIADO
};

export const ACTIVITY = {
  INITIATED_STEP: 1, // ETAPA INICIADA
  END_OF_STAGE: 2, // FINALIZAÇÃO DE ETAPA
  STAGE_REOPENING: 3, // REABERTURA DE ETAPA
  NOT_APPLICABLE: 4, // NÃO SE APLICA
  ASSIGNED_NOTE: 5, // OBSERVAÇÃO ATRIBUÍDA
  STATUS_CHANGE: 6, // ALTERAÇÃO DE STATUS
  CHANGE_OF_WORKING_DAYS: 7, // ALTERAÇÃO DE DIAS ÚTEIS
  INITIATED_MODULE: 8, // MÓDULO INICIADO
  MODULE_FINALIZATION: 9, // FINALIZAÇÃO DE MODULO
  MODULE_REOPENING: 10, // REABERTURA DE MODULO
  STEP_REAPPLICATION: 11, // REAPLICAÇÃO DE ETAPA
  STANDBY_MODULE: 12, // MODULO EM STANDBY
  UNFEASIBLE_MODULE: 13, // MODULO INVIABILIZADO
  CANCELLED_MODULE: 14, // MODULO CANCELADO
  MODULE_OPENING: 15, // ABERTURA DE MODULO
};

export const STAGE_STATUS = {
  NOT_STARTED: 1, //NÃO INICIADO
  INITIATED: 2, // INICIADO
  CONCLUDED: 3, // CONCLUÍDO
  NOT_APPLICABLE: 4, //NÃO SE APLICA
};

export const STAGE_TYPE = {
  STANDARD: 1, //PADRÃO - FUNCIONALIDADE BÁSICA
  SNAPSHOT: 2, // INSTANTÂNEA - ETAPA COM DURAÇÃO 0, INICIADA E FINALIZADA NO MESMO INSTANTE
  CHAINED: 3, // ENCADEADA - USA OS CAMPOS DE ENCADEAMENTO
  FREE_DATE: 4, //DATA LIVRE - DATA DE INICIO E TERMINO PODEM SER DEFINIDAS LIVREMENTE
};

export const PROJECT_TYPE = {
  NEW_PRODUCT: 1, // "Novo Produto"
  NEW_GRAPHIC_CHARTER: 2, // "Nova Carta Gráfica"
  REFORMULATION: 3, // "Reformulação"
  LINE_EXTENSION: 4, // "Extensão de Linha"
  OTHER_LINE_EXTENSION: 5, // "Extensão de Linha Outro Fornecedor"
  SUPPLIER_CHANGE: 6, // "Troca de Fornecedor"
};
