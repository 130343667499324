import moment from 'moment';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Alert, Loader, SelectPicker } from 'rsuite';
import { ReactComponent as FileIco } from '../../../../../../Assets/file.svg';
import { ReactComponent as RegBlueStar } from '../../../../../../Assets/reg_tarefa_estrela_azul.svg';
import { ReactComponent as RegGrayStar } from '../../../../../../Assets/reg_tarefa_estrela_cinza.svg';
import { ReactComponent as RegOrangeStar } from '../../../../../../Assets/reg_tarefa_estrela_laranja.svg';
import { ReactComponent as RegGreenStar } from '../../../../../../Assets/reg_tarefa_estrela_verde.svg';
import { ReactComponent as RegRedStar } from '../../../../../../Assets/reg_tarefa_estrela_vermelha.svg';
import ButtonDefault from '../../../../../../Components/ButtonDefault/ButtonDefault';
import InlineDateRange from '../../../../../../Components/InlineDateRange/InlineDateRange';
import { useManagementContext } from '../../../../../../Contexts/ManagementContext';
import apiConnectManagement from '../../../../../../services/apiConnectManagement';
import { generateUniqueKey } from '../../../../../../utils/utils';
import {
  ACTIVITY,
  MODULE_STATUS,
  MODULE_TYPE,
} from '../../../constants/constants';
import ModalComment from '../../ModalComment/ModalComment';
import ModalDayEdition from '../../ModalDayEdition/ModalDayEdition';
import ModalModuleReopening from '../../ModalModuleReopening/ModalModuleReopening';
import ModalNotApplyModule from '../../ModalNotApplyModule/ModalNotApplyModule';
import ModalObservation from '../../ModalObservation/ModalObservation';
import ModalOpenModule from '../../ModalOpenModule/ModalOpenModule';
import ModalStatusProject from '../../ModalStatusProject/ModalStatusProject';
import ModalStepReopening from '../../ModalStepReopening/ModalStepReopening';
import './ActivityRecordView.scss';

const ActivityRecordView = (
  {
    optionsStages,
    moduleTypeId,
    moduleTypeName,
    moduleId,
    moduleStatus,
    readOnly,
    // setModuleStages,
    setProject = () => {},
    setModuleStatus = () => {},
  },
  ref
) => {
  const { project } = useManagementContext();
  const modalStatusProjectRef = useRef(null);
  const modalObservationRef = useRef(null);
  const modalCommentRef = useRef(null);
  const modalDaysRef = useRef(null);
  const modalNotApplyModuleRef = useRef(null);
  const modalModuleReopeningRef = useRef(null);
  const modalOpenModuleRef = useRef(null);
  const modalStepReopeningRef = useRef(null);
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [regActivities, setRegActivities] = useState([]);
  const [valuesFilter, setValuesFilter] = useState({
    stage: null,
    dateRange: null,
  });
  const [modalData, setModalData] = useState({
    displayOnly: true,
    stage: null,
    status: null,
    obs: null,
    days: null,
    date: new Date(),
  });

  const toggleDisplayModals = row => {
    if (row.stage_module) {
      if (row.activity.id === ACTIVITY.CHANGE_OF_WORKING_DAYS) {
        setModalData({
          ...modalData,
          displayOnly: true,
          days: row.days_extended,
          stage: row.stage_name,
          obs: row.obs,
        });
        modalDaysRef?.current.toggleModal();
      } else if (row.activity.id === ACTIVITY.STAGE_REOPENING) {
        setModalData({
          ...modalData,
          displayOnly: true,
          date: new Date(row.created_at),
          stage: row.stage_name,
          obs: row.obs,
        });
        modalStepReopeningRef?.current.toggleModal();
      } else {
        setModalData({
          ...modalData,
          displayOnly: true,
          status: row.obs_type.name,
          stage: row.stage_name,
          obs: row.obs,
        });
        modalObservationRef?.current.toggleModal();
      }
    } else if (row.project_module) {
      if (row.activity.id === ACTIVITY.MODULE_FINALIZATION) {
        setModalData({
          ...modalData,
          displayOnly: true,
          date: new Date(row.created_at),
          obs: row.obs,
        });
        modalNotApplyModuleRef?.current.toggleModal();
      } else if (row.activity.id === ACTIVITY.MODULE_REOPENING) {
        setModalData({
          ...modalData,
          displayOnly: true,
          date: new Date(row.created_at),
          obs: row.obs,
        });
      } else if (row.activity.id === ACTIVITY.MODULE_OPENING) {
        setModalData({
          ...modalData,
          displayOnly: true,
          date: new Date(row.created_at),
          obs: row.obs,
        });
        //modalOpenModuleRef?.current.toggleModal();
      }
    } else if (row.project) {
      setModalData({
        ...modalData,
        displayOnly: true,
        status: row.obs_type.name,
        obs: row.obs,
      });
      modalStatusProjectRef?.current.toggleModal();
    }
  };

  const openModalComment = () => {
    setModalData({
      ...modalData,
      displayOnly: false,
      initialDataObs: {
        // obs: 'teste de texto',
        // obsType: 2,
      },
    });
    modalCommentRef?.current?.toggleModal();
  };

  const notApplyModuleClicked = () => {
    setModalData({
      ...modalData,
      displayOnly: false,
    });
    modalNotApplyModuleRef?.current.toggleModal();
  };

  const reopenModuleClicked = () => {
    setModalData({
      ...modalData,
      displayOnly: false,
    });
  };

  const fetchRegActivities = () => {
    const dateRange = valuesFilter.dateRange;
    const filter = {
      stage: valuesFilter.stage,
      start_date: dateRange?.startDate
        ? moment(dateRange.startDate).format('YYYY-MM-DD')
        : null,
      end_date: dateRange?.endDate
        ? moment(dateRange.endDate).format('YYYY-MM-DD')
        : null,
    };
    setLoadingActivities(true);

    apiConnectManagement
      .get(
        `/module_type/${moduleTypeId}/project_module/${moduleId}/show_reg_activity`,
        {
          params: filter,
        }
      )
      .then(response => {
        if (response?.data) {
          const regs = response.data.reduce((acc, reg) => {
            const key = reg.created_at.substr(0, 10);
            const fullKey = reg.created_at;
            const el = acc.find(o => o.key === key);

            if (el) {
              el.regs.push(reg);
            } else {
              acc.push({
                key: key,
                weekday: moment(fullKey).format('dddd').split('-')[0],
                time: moment(fullKey).format('HH:mm'),
                day: moment(fullKey).format('DD/MM/YYYY'),
                regs: [reg],
              });
            }

            return acc;
          }, []);
          setRegActivities(regs);
        }
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else if (error?.response?.status === 400) {
          Alert.error(error.response.data.error, 50000);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado, tente novamente mais tarde!'
          );
        }
      })
      .finally(() => {
        setLoadingActivities(false);
      });
  };

  useEffect(() => {
    if (moduleTypeId && moduleId) {
      fetchRegActivities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleTypeId, moduleId, valuesFilter]);

  useImperativeHandle(ref, () => ({
    fetchRegActivities,
  }));

  return (
    <div id="activity-record">
      <div className="header">
        <div className="column">
          <button
            disabled
            type="button"
            className="btnComment"
            onClick={openModalComment}>
            <span>Comentário</span>
            <span className="imgBalloon" />
          </button>
        </div>
        <div className="column">
          <SelectPicker
            data={optionsStages}
            disabled
            onChange={(value, event) => {
              setValuesFilter({ ...valuesFilter, stage: value });
            }}
            value={valuesFilter.stage}
            appearance="default"
            placeholder="Selecionar etapa"
            cleanable={true}
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
          />
        </div>
        <div className="column">
          <InlineDateRange
            disabled
            dateRange={valuesFilter.dateRange}
            position={{ right: 0 }}
            setDateRange={value => {
              setValuesFilter({ ...valuesFilter, dateRange: value });
            }}
          />
        </div>
      </div>
      <div className="body">
        {regActivities.length && !loadingActivities ? (
          regActivities.map(regAc => {
            return (
              <div key={generateUniqueKey()}>
                <p className="regActivityWeekdayHeader">
                  {regAc.day} - {regAc.weekday}
                </p>
                {regAc.regs.map(a => {
                  return (
                    <ActivityRecordViewRow
                      toggleDisplayModals={toggleDisplayModals}
                      row={a}
                      key={`row-regActivity-${a.id}`}
                    />
                  );
                })}
              </div>
            );
          })
        ) : loadingActivities ? (
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: 60,
            }}>
            <Loader speed="slow" size="lg" />
          </div>
        ) : (
          <div className="noResults">
            <p>
              <strong>Nenhuma</strong> Informação Atribuída
            </p>
          </div>
        )}
      </div>
      {moduleTypeId !== MODULE_TYPE.BRIEFING && !readOnly && (
        <div className="footer">
          {moduleStatus?.id === MODULE_STATUS.CONCLUDED ? (
            <>
              <div>
                <p>
                  Para reabrir módulo de <b>{moduleTypeName}</b> clique aqui
                </p>
              </div>
              <div>
                <ButtonDefault
                  color="orange"
                  onClick={() => reopenModuleClicked()}
                  radius
                  disabled>
                  Reabrir
                </ButtonDefault>
              </div>
            </>
          ) : [
              MODULE_STATUS.NOT_STARTED,
              MODULE_STATUS.IN_PROGRESS,
              MODULE_STATUS.STANDBY,
              MODULE_STATUS.DEVELOPING,
            ].includes(moduleStatus?.id) ? (
            ![MODULE_TYPE.SOURCING, MODULE_TYPE.VIABILITY].includes(
              moduleTypeId
            ) && (
              <>
                <div>
                  <p>
                    Caso queira <b>NÃO APLICAR</b> o módulo inteiro, clique aqui
                  </p>
                </div>
                <div>
                  <ButtonDefault
                    color="red"
                    onClick={() => notApplyModuleClicked()}
                    radius
                    disabled>
                    Não aplicar
                  </ButtonDefault>
                </div>
              </>
            )
          ) : null}
        </div>
      )}

      <ModalStatusProject
        displayOnly={modalData.displayOnly}
        displayStatusText={modalData.status}
        displayObs={modalData.obs}
        ref={modalStatusProjectRef}
      />
      <ModalObservation
        displayOnly={modalData.displayOnly}
        displayStatusText={modalData.status}
        displayStageText={modalData.stage}
        displayObs={modalData.obs}
        ref={modalObservationRef}
      />
      <ModalComment
        displayOnly={modalData.displayOnly}
        displayObs={modalData.obs}
        initialDataObs={modalData.initialDataObs}
        moduleType={moduleTypeId}
        moduleId={moduleId}
        fetchRegActivities={fetchRegActivities}
        ref={modalCommentRef}
      />
      <ModalDayEdition
        displayOnly={modalData.displayOnly}
        displayDays={modalData.days}
        displayStageText={modalData.stage}
        displayObs={modalData.obs}
        ref={modalDaysRef}
      />
      <ModalNotApplyModule
        displayOnly={modalData.displayOnly}
        displayObs={modalData.obs}
        displayDate={modalData.date}
        ref={modalNotApplyModuleRef}
        moduleName={moduleTypeName}
        moduleId={moduleId}
        moduleTypeId={moduleTypeId}
        setModuleStatus={setModuleStatus}
        // onSubmit={fetchRegActivities}
      />
      <ModalModuleReopening
        displayOnly={modalData.displayOnly}
        displayObs={modalData.obs}
        displayDate={modalData.date}
        ref={modalModuleReopeningRef}
        moduleName={moduleTypeName}
        moduleId={moduleId}
        moduleTypeId={moduleTypeId}
        setModuleStatus={setModuleStatus}
        onSubmit={fetchRegActivities}
        project={project}
        setProject={setProject}
      />
      <ModalOpenModule
        displayOnly={modalData.displayOnly}
        displayObs={modalData.obs}
        displayDate={modalData.date}
        ref={modalOpenModuleRef}
        moduleName={moduleTypeName}
        moduleId={moduleId}
        moduleTypeId={moduleTypeId}
        setModuleStatus={setModuleStatus}
        onSubmit={fetchRegActivities}
        project={project}
        setProject={setProject}
      />
      <ModalStepReopening
        displayOnly={modalData.displayOnly}
        displayStageText={modalData.stage}
        displayObs={modalData.obs}
        displayDate={modalData.date}
        ref={modalStepReopeningRef}
      />
    </div>
  );
};

const ActivityRecordViewRow = ({ toggleDisplayModals = () => {}, row }) => {
  return (
    <div className="regActivityRow">
      <div className="icoCol">
        {row.activity.need_obs ? (
          <div className="hover-wrapper">
            <button 
              disabled
              className="obsModalBtn"
              onClick={() => toggleDisplayModals(row)}>
              <FileIco />
            </button>
            <div className="in-tooltip">Visualizar</div>
          </div>
        ) : row.activity.id === ACTIVITY.INITIATED_STEP ? (
          <RegGreenStar />
        ) : row.activity.id === ACTIVITY.END_OF_STAGE ? (
          <RegBlueStar />
        ) : row.activity.id === ACTIVITY.NOT_APPLICABLE ? (
          <RegRedStar />
        ) : row.activity.id === ACTIVITY.STEP_REAPPLICATION ? (
          <RegOrangeStar />
        ) : (
          <RegGrayStar />
        )}
      </div>
      <div className="textCol">
        {row.stage_module && (
          <>
            <strong> Etapa: </strong>
            {row.stage_name}
            <br />
          </>
        )}
        <strong> Ação: </strong>
        {row.activity.name}
        <strong> Hora: </strong>
        {new moment(row.created_at).format('HH:mm')}
        <br />
        <strong> Responsável: </strong>
        {row.user_data.username}
      </div>
    </div>
  );
};

export default forwardRef(ActivityRecordView);
