/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
// import styles from './ListViewProjects.module.css';
// import HeaderModule from '../../../../Components/HeaderModule/HeaderModule';
import { useNavigate } from 'react-router-dom';
import Header from '../../../../Components/Header2/Header';
import TableList from '../../../../Components/TableList/TableList';
import Pagination from '../../../../Components/Pagination/Pagination';
import 'react-circular-progressbar/dist/styles.css';
import { Row, Col, Alert } from 'rsuite';
import { Link } from 'react-router-dom';
import { useCognitoAuth } from '../../../../Contexts/AuthCognitoContext';
import { useManagementContext } from '../../../../Contexts/ManagementContext';
import useLocalStorage from '../../../../utils/hooks/useLocalStorage';
import './ListViewProjects.scss';
import SelectPicker from '../../../../Components/SelectPicker/SelectPicker';
import InputSearch from '../../../../Components/InputSearch/InputSearch';
import SelectPeriodLaunch from '../../../../Components/SelectPeriodLaunch/SelectPeriodLaunch';
import ButtonOutline from '../../../../Components/ButtonOutline/ButtonOutline';
import ButtonDefault from '../../../../Components/ButtonDefault/ButtonDefault';

function ListViewProjects() {
  const { user: authUser } = useCognitoAuth();
  const email = authUser.attributes.email;
  const navigate = useNavigate();
  const {
    loading,
    user,
    getUser,
    optionsClients,
    getOptionsClients,
    optionsProjectTypes,
    getOptionsProjectTypes,
    optionsStatus,
    getOptionsStatus,
    listManagers,
    getListManagers,
    pages,
    currentPage,
    projects,
    totalProjects,
    getProjects,
  } = useManagementContext();

  const clearedFilters = {
    delivery_status: '',
    client: '',
    project: '',
    project_type: '',
    status: '',
    manager_user_id: '',
    start_forecast_date: '',
    launch_forecast_date: '',
  };

  const [valuesFilter, setValuesFilter] = useLocalStorage(
    'management-filters',
    { ...clearedFilters }
  );
  const [departmentName, setDepartmentName] = useState('');
  const [disabled, setDisabled] = useState(true);

  const returnSearch = async (page = 1) => {
    const resp = await getProjects(page, valuesFilter);
    if (!resp?.success && resp?.message) {
      Alert.error(resp.message, 50000);
    }
  };

  const handleClear = () => {
    setDisabled(true);
    setValuesFilter({ ...clearedFilters });
    getProjects(1, clearedFilters);
  };

  const handleFiltered = () => {
    returnSearch(1);
  };

  const handlePagination = page => {
    returnSearch(page);
  };

  useEffect(() => {
    handleFiltered();
  }, [
    valuesFilter.delivery_status,
    valuesFilter.client,
    valuesFilter.project_type,
    valuesFilter.status,
    valuesFilter.manager_user_id,
    valuesFilter.start_forecast_date,
    valuesFilter.launch_forecast_date,
  ]);

  const optionsManagers = useMemo(() => {
    if (
      (departmentName === 'VAREJISTA' || departmentName === 'NOVOS CLIENTES') &&
      !valuesFilter.client
    ) {
      // pega a lista com ids dos clientes
      const listIdsClients = optionsClients.map(c => c.value);
      // filtra comparando a lista de ids de clientes
      // com os ids de clientes de cada responsavel
      return listManagers
        .filter(m => listIdsClients.some(el => m.clients_id.includes(el)))
        .map(op => ({
          label: op.manager_name,
          value: op.manager_user_id,
        }));
    } else if (valuesFilter.client) {
      return listManagers
        .filter(m => m.clients_id.includes(Number(valuesFilter.client)))
        .map(op => ({
          label: op.manager_name,
          value: op.manager_user_id,
        }));
    }
    return listManagers.map(op => ({
      label: op.manager_name,
      value: op.manager_user_id,
    }));
  }, [departmentName, optionsClients, valuesFilter.client, listManagers]);

  // inicialização
  useEffect(() => {
    getOptionsStatus();
    getOptionsProjectTypes();
    getListManagers();
    if (
      valuesFilter?.delivery_status ||
      valuesFilter?.client ||
      valuesFilter?.project ||
      valuesFilter?.project_type ||
      valuesFilter?.status ||
      valuesFilter?.manager_user_id
    ) {
      setDisabled(false);
    }

    /* TODO remover alteracao por js */
    const header = document.getElementsByClassName('headerContainer');
    if (header[0]) {
      header[0].style.backgroundColor = '#f5f5f5';
    }
    const headerModuleBefore = document.getElementsByClassName(
      'headerModule-before'
    );
    if (headerModuleBefore[0]) {
      headerModuleBefore[0].style.background = '#f5f5f5';
    }
    const versionGit = document.getElementsByClassName('versionGit');
    if (versionGit[0]) {
      versionGit[0].style.color = '#333';
    }

    return () => {
      if (header[0]) {
        header[0].style.backgroundColor = '#1a213f';
      }
      if (headerModuleBefore[0]) {
        headerModuleBefore[0].style.background =
          'linear-gradient(180deg, #1b213f 10%, #65697d 100%);';
      }
    };
  }, []);

  useEffect(() => {
    if (!user && email) {
      getUser(email);
    } else if (user && optionsClients.length === 0) {
      setDepartmentName(user.department_name);
      getOptionsClients();
    } else if (user && optionsClients.length > 0) {
      returnSearch();
    }
  }, [email, user, optionsClients]);

  const linkProject = ({ id, name }) => (
    <Link to={`/pdm/management/${id}`}>
      <span style={{ color: '#338CE1' }}>{name}</span>
    </Link>
  );

  const launchForecastDate = ({ launch_forecast_date }) => {
    if (launch_forecast_date) {
      return moment(launch_forecast_date).format('MM/YY');
    }
    return '--';
  };

  const startForecastDate = ({ start_forecast_date }) => {
    if (start_forecast_date) {
      return moment(start_forecast_date).format('MM/YY');
    }
    return '--';
  };

  const getColorSituation = situation => {
    switch (situation) {
      case 'on time':
        return '#00a2ff';
      case 'delayed':
        return '#fd4444';
      default:
        return '#4baf5a';
    }
  };

  const getTranslateSituation = situation => {
    switch (situation) {
      case 'on time':
        return 'No prazo';
      case 'delayed':
        return 'Atrasado';
      default:
        return 'Adiantado';
    }
  };

  const columHeaderTable = [
    { name: 'PROJETO' },
    { open_stages: 'ETAPAS ABERTAS' },
    { situation: 'SITUAÇÃO' },
    { manager: 'RESPONSÁVEL' },
    { statusProject: 'STATUS' },
    { startForecastDate: 'LANÇAMENTO PLANEJADO' },
    { launchForecastDate: 'LANÇAMENTO REAL' },
  ];

  const listTable = useMemo(() => {
    if (projects.length > 0) {
      return projects.map(ob => ({
        name: linkProject(ob),
        open_stages: ob.open_stages.map(os => (
          <span style={{ color: getColorSituation(os.situation) }}>
            {os.stage_name}
          </span>
        )),
        situation: [
          <span style={{ color: getColorSituation(ob.project_situation) }}>
            {getTranslateSituation(ob.project_situation)}
          </span>,
          ...ob.open_stages.map(os => (
            <span style={{ color: getColorSituation(os.situation) }}>
              {getTranslateSituation(os.situation)}
            </span>
          )),
        ],
        manager: [
          ob.manager_name,
          ...ob.open_stages.map(os => os.responsavel.join('/')),
        ],
        statusProject: ob.status_project.name,
        startForecastDate: startForecastDate(ob),
        launchForecastDate: launchForecastDate(ob),
      }));
    }
    return [];
  }, [projects]);

  return (
    <div className="listManagementPage">
      <Header fixed>Gestão de Projetos</Header>
      <Row className="mainDivListViewProjects">
        <Col md={2} /* mdOffset={5} */>
          <div className="inputAtrasados">
            <label>
              <input
                type="checkbox"
                checked={valuesFilter.delivery_status === '3'}
                onChange={e => {
                  setValuesFilter({
                    ...valuesFilter,
                    delivery_status: e.target.checked ? '3' : '',
                  });
                  setDisabled(false);
                }}
              />
              <span>Atrasados</span>
            </label>
          </div>
        </Col>
        <Col md={2}>
          <SelectPicker
            data={optionsClients}
            cleanable
            value={valuesFilter.client}
            onChange={value => {
              setValuesFilter({ ...valuesFilter, client: value });
              setDisabled(false);
            }}
            placeholder="Cliente"
            searchPlaceholder="Filtrar"
          />
        </Col>
        <Col md={2}>
          <InputSearch
            placeholder="Projeto"
            cleanable
            value={valuesFilter.project}
            onChange={value => {
              setValuesFilter({ ...valuesFilter, project: value });
              setDisabled(false);
            }}
            onSearch={() => handleFiltered()}
            onClear={() => handleFiltered()}
          />
        </Col>
        <Col md={2}>
          <SelectPicker
            cleanable
            data={optionsProjectTypes}
            onChange={value => {
              setValuesFilter({ ...valuesFilter, project_type: value });
              setDisabled(false);
            }}
            value={valuesFilter.project_type}
            placeholder="Tipo"
            searchPlaceholder="Filtrar"
          />
        </Col>
        <Col md={3}>
          <SelectPicker
            cleanable
            data={optionsManagers}
            onChange={value => {
              setValuesFilter({ ...valuesFilter, manager_user_id: value });
              setDisabled(false);
            }}
            value={valuesFilter.manager_user_id}
            placeholder="Responsável"
            searchPlaceholder="Filtrar"
          />
        </Col>
        <Col md={2}>
          <SelectPicker
            cleanable
            data={optionsStatus}
            onChange={value => {
              setValuesFilter({ ...valuesFilter, status: value });
              setDisabled(false);
            }}
            value={valuesFilter.status}
            placeholder="Status"
            searchPlaceholder="Filtrar"
          />
        </Col>
        <Col md={3}>
          <SelectPeriodLaunch
            placeholder="Lançamento"
            cleanable
            values={{
              start_forecast_date: valuesFilter.start_forecast_date,
              launch_forecast_date: valuesFilter.launch_forecast_date,
            }}
            onChange={vals => {
              setValuesFilter({
                ...valuesFilter,
                start_forecast_date: vals.start_forecast_date,
                launch_forecast_date: vals.launch_forecast_date,
              });
              setDisabled(false);
            }}
          />
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonOutline
              label="Limpar todos"
              onClick={handleClear}
              disabled={disabled || loading.projects}
            />
          </div>
        </Col>
      </Row>
      <div className="areaTableList">
        <TableList
          newTable
          columns={columHeaderTable}
          lines={listTable}
          openableColumn="open_stages"
          openColumnLabel="Mostrar todas"
          closeColumnLabel="Fechar todas"
          loading={
            loading.optionsClients ||
            loading.optionsStatus ||
            loading.listManagers ||
            loading.optionsProjectTypes ||
            loading.projects ||
            loading.user
          }
        />
      </div>
      {!loading.projects && (
        <div className="areaFooter">
          <div className="colTotProjects">
            <p className="totProjects">Total de Projetos: {totalProjects}</p>
          </div>
          <Pagination
            newType
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={handlePagination}
            loading={loading.projects}
          />
        </div>
      )}
    </div>
  );
}

export default ListViewProjects;
