/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useImperativeHandle } from 'react';
import { Modal } from 'reactstrap';
import '../ModalBriefingReview/ModalBriefingReview.scss';
import useToggle from '../../../../../../utils/hooks/useToggle';
import { classNames } from '../../../../../../utils/utils';

const ModalBriefingReview = (
  { status, briefingStatus, reviewBriefing = [] },
  ref
) => {
  const [show, toggle] = useToggle();

  useImperativeHandle(ref, () => ({ toggle }));

  const tranformDate = date => new Date(date).toLocaleString().split(' ')[0];

  return (
    <>
      <Modal
        backdrop={true}
        centered={true}
        className="modal-briefing-review"
        size="lg"
        toggle={toggle}
        isOpen={show}>
        <div className="container-modal">
          <div className="lineHeader">
            <h1 className="title">Validação do Cliente</h1>
            <p
              className={classNames({
                status: true,
                statusApproved: briefingStatus.approved,
                statusInRevision: briefingStatus.inRevision,
                statusAppByManager: briefingStatus.notApplicable,
                statusPendApp: briefingStatus.pendingApproval,
              })}>
              Status: {status()}
            </p>
          </div>
          <p className="subtitle">O cliente fez as seguintes alterações:</p>
          <div className="main-modal">
            {reviewBriefing.map(ob => (
              <div className="div-observation">
                <p className="title-main">
                  Observação do Cliente - em {tranformDate(ob.created_at)}
                </p>
                <p className="text-observation">{ob.review}</p>
              </div>
            ))}
          </div>
          <div className="div-btn-open">
            <button className="btn-open" onClick={toggle}>
              Fechar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default forwardRef(ModalBriefingReview);
