/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import './DetailsViewProject.scss';
// import moment from 'moment';
import { Row, Col } from 'rsuite';
// import HeaderModule from '../../../../Components/HeaderModule/HeaderModule';
import Header from '../../../../Components/Header2/Header';
import { useNavigate, useParams } from 'react-router-dom';
import Grupo15 from '../../../../Assets/Grupo15.svg';
import ModuleButton from './Components/ModuleButton/ModuleButton';
import ButtonDefault from '../../../../Components/ButtonDefault/ButtonDefault';
import { BsBoxArrowInLeft, BsPlusSquareFill } from 'react-icons/bs';
import { BiFileBlank } from 'react-icons/bi';
import FakeButton from '../../../../Components/FakeButton/FakeButton';
// import DetailsHomeViewProject from './Components/DetailsHomeViewProject/DetailsHomeViewProject';
import StagesView from './Components/StagesView/StagesView';
import Loading from '../../../../Components/Helpers/Loading';
import { Alert, Loader } from 'rsuite';
import ActivityRecordView from './Components/ActivityRecordView/ActivityRecordView';
import ModalStatusProject from './ModalStatusProject/ModalStatusProject';
import ModalOpenModule from './ModalOpenModule/ModalOpenModule';
import ModalEditProject from './ModalEditProject/ModalEditProject';
import { MODULE_STATUS, MODULE_TYPE, PROJECT_STATUS } from '../constants/constants';
import { useManagementContext } from '../../../../Contexts/ManagementContext';
import ModalDetailSkus from './ModalDetailSkus/ModalDetailSkus';
import ModalProjectObservation from './ModalProjectObservation/ModalProjectObservation';
import ModalDownloadDoc from '../../Briefing/BriefingList/ModalDownloadDoc/ModalDownloadDoc';
import Charts from '../../../../Components/Charts/Charts';
import { useBriefingContext } from '../../../../Contexts/BriefingContext';
import { classNames } from '../../../../utils/utils';
import ModalDateStep from './ModalDateStep/ModalDateStep';
import linkPlataform from '../../../../utils/linkPlataform';
import ModalRedirectsInViability from './ModalRedirectsInViability/ModalRedirectsInViability';

const DetailsViewProject = () => {
  const navigate = useNavigate();
  const { id, urlModuleId } = useParams();
  const {
    loading,
    getProject,
    getStages,
    setProjectId,
    selectedModuleId,
    setSelectedModuleId,
    setSelectedModule,
    getProjectSkus,
    projectSkus,
  } = useManagementContext();

  const {
    getOperationTypes,
    getTrailTypes,
    updateBriefing,
    operationTypes,
    trailTypes,
  } = useBriefingContext();

  const [project, setProject] = useState(null);
  const [moduleClick, setModuleClick] = useState(null);

  const [stages, setStages] = useState(null);
  const [optionsStages, setOptionsStages] = useState([]);
  const [moduleType, setModuleType] = useState(null);
  const [moduleTypeName, setModuleTypeName] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  const [moduleStatus, setModuleStatus] = useState(null);
  const [readOnly, setReadOnly] = useState(false);

  const modalStatusProjectRef = useRef(null);
  const modalOpenModuleRef = useRef(null);
  const activityRecordRef = useRef(null);
  const modalDetailSkusRef = useRef(null);
  const modalProjectObservationRef = useRef(null);
  const modalEditProjectRef = useRef(null);
  const modalDownloadDocRef = useRef(null);
  const modalRedirectsInViabilityRef = useRef(null);

  const loadProject = async () => {
    setProjectId(id);
    const resp = await getProject(id);
    if (resp?.success) {
      setProject(resp.data);
      await getOperationTypes();
      await getTrailTypes(project?.operation_type === 2);
      console.log('project', resp.data);
      if (urlModuleId) {
        setSelectedModuleId(urlModuleId);
        const md = resp.data.modules.find(m => m.id === Number(urlModuleId));
        if (md) {
          setSelectedModule(md);
          if (md.status_module.id === MODULE_STATUS.NOT_APPLY) {
            clearSelectedModuleId();
          } else {
            moduleButtonClicked(md);
          }
        }
      }
    } else {
      Alert.error(resp?.message, 50000);
    }
  };

  useEffect(() => {
    if (id) {
      loadProject();
      getProjectSkus(id);
    }
  }, [id]);

  const onHandleClickModule = async (moduleTypeId, moduleId) => {
    if (moduleClick !== null && moduleClick === moduleId) {
      setModuleClick(null);
      setStages(null);
    } else {
      setModuleClick(moduleTypeId);
      setSelectedModuleId(moduleId);
      const resp = await getStages(moduleTypeId, moduleId);
      if (resp?.success) {
        setStages(resp.data);
        setOptionsStages(
          resp.data.map(ob => ({
            label: ob.stage.name,
            value: ob.stage.id,
          }))
        );
      } else {
        Alert.error(resp.message, 50000);
      }
    }
  };

  const reloadProject = () => {
    // activityRecordRef?.current.fetchRegActivities();
    loadProject();
  };

  const moduleButtonClicked = md => {
    window.history.replaceState(null, '', `/pdm/management/${id}/${md.id}`);
    setStages(null);
    setModuleClick(md.module_type.id);
    setModuleId(md.id);
    setModuleType(md.module_type.id);
    setModuleTypeName(md.module_type.name);
    setModuleStatus(md.status_module);
    // Desabilita preenchimento de módulos de Sourcing e Viabilidade
    if ([MODULE_TYPE.SOURCING, MODULE_TYPE.VIABILITY].includes(md.module_type.id)) {
      setReadOnly(true);
    } else {
      setReadOnly(md.read_only);
    }
    if (md.status_module.id === MODULE_STATUS.NOT_APPLY) {
      //modalOpenModuleRef?.current?.toggleModal();
    } else {
      onHandleClickModule(md.module_type.id, md.id);
    }
  };

  const clearSelectedModuleId = () => {
    window.history.replaceState(null, '', `/pdm/management/${id}`);
    setSelectedModuleId(null);
    setModuleId(null);
    setModuleType(null);
    setModuleTypeName(null);
    setModuleStatus(null);
    setReadOnly(false);
    setModuleClick(null);
    setStages(null);
  };

  const prevDataLaunch = date => {
    const d = new Date(`${date}T00:00:00`);
    return d.toLocaleDateString('pt-BR');
  };

  const filterNameByIdProjects = (id, list) => {
    return list.find(item => item.value === id)?.label;
  };

  const arrayTrailTypes = useMemo(() => {
    return trailTypes.map(tt => ({
      value: tt.id,
      label: tt.name,
    }));
  }, [trailTypes]);

  const handleClickDetailSkus = () => {
    modalDetailSkusRef.current?.toggleModal();
  };

  const handleClickProjectObservation = () => {
    modalProjectObservationRef.current?.toggle();
  };

  const handleClickEditProject = () => {
    modalEditProjectRef.current?.toggle();
  };

  const fakeBtnStatus = () => (
    <FakeButton
      style={{
        marginRight: '5px',
        fontWeight: '700',
        fontSize: '14px',
      }}
      color={classNames({
        blue2: project?.status_project.id === PROJECT_STATUS.IN_PROGRESS,
        orange: project?.status_project.id === PROJECT_STATUS.STANDBY,
        grey:
          project?.status_project.id === PROJECT_STATUS.UNFEASIBLE ||
          project?.status_project.id === PROJECT_STATUS.NOT_STARTED,
        pink: project?.status_project.id === PROJECT_STATUS.CANCELLED,
        green:
          project?.status_project.id === PROJECT_STATUS.FINISHED ||
          project?.status_project.id === PROJECT_STATUS.RELEASED,
        black: project?.status_project.id === PROJECT_STATUS.DELETED,
      })}
      size="small">
      {project?.status_project.name}
    </FakeButton>
  );

  const suppliersSummary = () => {
    if (project?.selecionados_filtro.length === 0) {
      return <span>Nenhum Fornecedor encontrado</span>;
    } else if (project?.selecionados_filtro.length === 1) {
      return (
        <span>{`${project?.selecionados_filtro.length} Fornecedor para Filtro Comercial`}</span>
      );
    }
    return (
      <span>{`${project?.selecionados_filtro.length} Fornecedores para Filtro Comercial`}</span>
    );
  };

  const editProject = async newProject => {
    const { success } = await updateBriefing(project.id, newProject);
    if (success) {
      handleClickEditProject();
      Alert.success('Projeto atualizado com sucesso');
      await loadProject();
    } else {
      Alert.error('Falha ao atualizar projeto', 50000);
    }
  };

  const checkStatusBriefing =
    project?.briefing_id === null;

  const navigateToBriefing = () => {
    let params = {
      projectId: project?.id,
      newType: project?.project_type?.id,
      retailer: project?.client_id,
    };

    if (project?.derived_from_project)
      params.project = project?.derived_from_project;
    if (project?.derived_from_name) params.name = project?.derived_from_name;
    if (project?.derived_from_launch_date)
      params.date = project?.derived_from_launch_date;
    const urlParams = new URLSearchParams(params).toString();

    if (checkStatusBriefing) {
      navigate(`/pdm/briefing/novo-briefing?${urlParams}`);
      return;
    }
    navigate(`/pdm/briefing/${project?.briefing_id}/?${urlParams}`);
  };

  const projectNature =
    operationTypes.find(op => project?.operation_type === op.id)?.name || '';

  const redirectToPlatform = () => {
    window.open(`${linkPlataform}/details-supplier/${project?.id}`, '_blank');
  };

  return (
    <div className="detailProject">
      {/* <HeaderModule classHeader="pdmHeaderModule">
        Gestão de Projetos
      </HeaderModule> */}
      <Header>
        <div className="headerProject">
          <div>
            PROJETO
            <span className="projectNature">{projectNature}</span>
          </div>
          <div className="containerStatusButtons">
            <span className="status">Status</span>
            <div className="containerButton">
              <div className="subContainerButton">
                {fakeBtnStatus()}
                <ButtonDefault
                  onClick={() => {
                    modalStatusProjectRef?.current?.toggleModal();
                  }}
                  disabled
                  radius
                  size="small"
                  color="primary3"
                  style={{
                    backgroundColor: '#425BA6',
                    padding: '10px',
                    fontWeight: '700',
                    fontSize: '14px',
                  }}>
                  Alterar
                </ButtonDefault>
              </div>
              {selectedModuleId && (
                <ButtonDefault
                  onClick={clearSelectedModuleId}
                  radius
                  size="small"
                  style={{
                    marginLeft: '30px',
                    backgroundColor: '#425BA6',
                    fontWeight: '700',
                    fontSize: '14px',
                    padding: '10px',
                  }}>
                  Visão do projeto
                </ButtonDefault>
              )}
              <ButtonDefault
                icon="right"
                color="transparent"
                size="small"
                onClick={() => navigate('/pdm/management')}
                radius
                style={{
                  fontSize: '16px',
                  color: '#28418C',
                  lineHeight: '24px',
                  fontWeight: '500',
                  marginRight: '10px',
                }}>
                Voltar
                <BsBoxArrowInLeft color="#28418C" size={22} />
              </ButtonDefault>
            </div>
          </div>
        </div>
      </Header>
      <div className="mainContainer">
        <Col md={24}>
          {loading.project || !project ? (
            <Loading />
          ) : (
            <>
              <Row className="margin">
                <Col md={9}>
                  <header className="header">
                    {/* <img src={Grupo15} alt="Grupo" /> */}
                    <div className="details-container">
                      <div className="client-project-container">
                        <span className="iconProject">
                          <img src={Grupo15} alt="Grupo" />
                        </span>
                        <div className="infosProject">
                          <span className="projectName">{project?.name}</span>
                          <span>ID projeto: {project?.id}</span>
                          <span className="clientName">
                            {project?.client_name}
                          </span>
                          <div>
                            <span className="releaseDate">
                              {project.status_project.id ===
                                PROJECT_STATUS.FINISHED ||
                              project.status_project.id ===
                                PROJECT_STATUS.RELEASED
                                ? 'Lançado em:'
                                : 'Previsão de lançamento:'}{' '}
                              {/* <img src={MetroCalendar} alt="calendar" /> */}
                              <span>
                                {project?.launch_forecast_date
                                  ? prevDataLaunch(project.launch_forecast_date)
                                  : '--'}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </header>
                </Col>
                <Col md={6}>
                  <div className="detailsProjectContainer">
                    <span>
                      <strong>Tipo:</strong> {project?.project_type?.name || ''}
                    </span>
                    <span>
                      <strong>Categoria:</strong> {project?.division || ''}
                    </span>
                    <span>
                      <strong>Marca:</strong> {project?.client_brand || ''}
                    </span>
                    <span>
                      <strong>Gestor:</strong> {project?.manager_name || ''}
                    </span>
                  </div>
                </Col>
                <Col md={7}>
                  <div className="detailsProjectContainer">
                    <span>
                      <strong>Responsável Desenvolvimento:</strong>{' '}
                      {project?.development_responsible || ''}
                    </span>
                    <span>
                      <strong>Responsável DTI:</strong>{' '}
                      {project?.dti_responsible || ''}
                    </span>
                    <span>
                      <strong>Responsável Atendimento:</strong>{' '}
                      {project?.service_responsible || ''}
                    </span>
                    <span>
                      <strong>Responsável Cliente:</strong>{' '}
                      {project?.client_responsible || ''}
                    </span>
                  </div>
                </Col>
                <Col md={1} style={{ marginTop: '-95px' }}>
                  {/*
                    <ButtonDefault
                      icon="right"
                      color="transparent"
                      size="small"
                      onClick={handleClickEditProject}
                      radius
                      style={{
                        fontSize: '16px',
                        color: '#4364D9',
                        lineHeight: '24px',
                      }}>
                      Editar
                      {/* <BsBoxArrowInRight color="#4364D9" size={22} /> }
                    </ButtonDefault>
                   */}
                </Col>
              </Row>
              <Row>
                <main>
                  <Row className={'divider'}>
                    <Col md={24}>
                      <span>Fluxo de Desenvolvimento do Produto</span>
                    </Col>
                  </Row>
                  <Row className={'timeLine'}>
                    {project.modules.map(ob => (
                      <ModuleButton
                        key={ob.module_type.id}
                        status={ob.status_module.id}
                        type={ob.module_type.id}
                        active={moduleClick === ob.module_type.id}
                        onClick={() => {
                          moduleButtonClicked(ob)
                          // módulo de viabilidade
                          if (ob.module_type.id === 3) {
                            modalRedirectsInViabilityRef.current.toggleModal();
                          }
                        }}
                        title={ob.module_type.name}
                      />
                    ))}
                  </Row>
                  {!selectedModuleId ? (
                    <>
                      <Row className="divider">
                        <Col md={24}>
                          <span>Visão Geral do Projeto</span>
                        </Col>
                      </Row>
                      <div className="dataProject">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: '400px',
                          }}>
                          <div className="divModal">
                            <ButtonDefault
                              size="small"
                              radius
                              style={{ fontWeight: '700' }}
                              onClick={navigateToBriefing}>
                              {!checkStatusBriefing ? (
                                'Briefing'
                              ) : (
                                <>
                                  {' '}
                                  <BsPlusSquareFill /> Novo Briefing
                                </>
                              )}
                            </ButtonDefault>
                            <div className="detailsModal">
                              <span>
                                <strong>Etapa:</strong>{' '}
                                {project?.stage_briefing}
                              </span>
                              {!checkStatusBriefing && (
                                <span>
                                  <strong>Tipo de prospecção: </strong>
                                  {filterNameByIdProjects(
                                    project?.trail_type,
                                    arrayTrailTypes
                                  )}
                                </span>
                              )}
                            </div>
                            {checkStatusBriefing &&
                              project?.operation_type !== 3 && (
                                <ButtonDefault
                                  color="orange"
                                  size="small"
                                  radius
                                  style={{
                                    fontWeight: '700',
                                    marginLeft: '10rem',
                                  }}
                                  onClick={() =>
                                    modalDownloadDocRef.current?.toggle()
                                  }>
                                  <BiFileBlank />
                                  Documentos de Briefing
                                </ButtonDefault>
                              )}
                          </div>
                          <div className="divModal">
                            <ButtonDefault
                              size="small"
                              radius
                              style={{ fontWeight: '700' }}
                              onClick={() => handleClickDetailSkus()}>
                              SKUs
                            </ButtonDefault>
                            <div className="detailsModal">
                              <span>{projectSkus?.length} SKUs</span>
                              <span>
                                {projectSkus?.reduce(
                                  (acc, current) =>
                                    !current.available ? acc + 1 : acc,
                                  0
                                )}{' '}
                                Cancelados
                              </span>
                            </div>
                          </div>
                          <div className="divModal">
                            <ButtonDefault
                              size="small"
                              radius
                              style={{ fontWeight: '700' }}
                              onClick={redirectToPlatform}>
                              Fornecedores
                            </ButtonDefault>
                          </div>
                          <div className="divModal">
                            <ButtonDefault
                              size="small"
                              radius
                              style={{ fontWeight: '700' }}
                              onClick={() => handleClickProjectObservation()}>
                              Observação geral
                            </ButtonDefault>
                          </div>
                        </div>
                        <div className="lado-grafico">
                          <Charts
                            data={project.grafico}
                            modulo={project.modulo_atual}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Row className="divider">
                        <Col md={24}>
                          <span>Acompanhamento de Etapas</span>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          margin: '25px 5px 0px 0px',
                          paddingBottom: '15px',
                        }}>
                        {
                          /*!stages ? (
                          <DetailsHomeViewProject />
                        ) : (*/
                          <>
                            <Col md={16}>
                              {stages && !loading.stages ? (
                                <StagesView
                                  readOnly={readOnly}
                                  setProject={setProject}
                                  moduleType={moduleType}
                                  setModuleStages={setStages}
                                  setModuleStatus={setModuleStatus}
                                  moduleStatus={moduleStatus}
                                  disabled
                                  reloadProject={reloadProject}
                                  projectId={project?.id}
                                />
                              ) : loading.stages ? (
                                <Loader
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '45vh',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  speed="slow"
                                  size="lg"
                                />
                              ) : (
                                <p>Não tem etapas</p>
                              )}
                            </Col>
                            <Col md={8}>
                              {stages && !loading.stages ? (
                                <ActivityRecordView
                                  setProject={setProject}
                                  optionsStages={optionsStages}
                                  moduleTypeId={moduleType}
                                  moduleTypeName={moduleTypeName}
                                  moduleId={moduleId}
                                  moduleStatus={moduleStatus}
                                  readOnly={readOnly}
                                  setModuleStatus={setModuleStatus}
                                  setModuleStages={setStages}
                                  ref={activityRecordRef}
                                />
                              ) : (
                                <></>
                              )}
                            </Col>
                          </>
                        }
                      </Row>
                    </>
                  )}
                </main>
              </Row>
            </>
          )}
        </Col>
      </div>
      <ModalStatusProject
        setProject={setProject}
        moduleId={moduleId}
        ref={modalStatusProjectRef}
        onSubmit={reloadProject}
      />
      <ModalOpenModule
        moduleId={moduleId}
        moduleTypeId={moduleType}
        moduleName={moduleTypeName}
        project={project}
        setProject={setProject}
        setModuleStatus={setModuleStatus}
        ref={modalOpenModuleRef}
      />
      <ModalDetailSkus
        ref={modalDetailSkusRef}
        projectId={project?.id}
        projectSkus={projectSkus}
      />
      <ModalProjectObservation
        ref={modalProjectObservationRef}
        project={project}
        onClickBriefing={navigateToBriefing}
      />
      <ModalEditProject
        ref={modalEditProjectRef}
        project={project}
        onSave={editProject}
      />
      <ModalDownloadDoc ref={modalDownloadDocRef} />
      <ModalRedirectsInViability
        ref={modalRedirectsInViabilityRef}
        projectId={project?.id}
      />
    </div>
  );
};

export default DetailsViewProject;
