import React from 'react';
// import ReactGA from 'react-ga';
import moment from 'moment';
import styles from './styles.module.css';
import { Col, Row } from 'rsuite';
import titleIcon from '../../../../../../../Assets/titleIcon.png';
import calendarIcon from '../../../../../../../Assets/calendar-icon.png';

function ProspectionCard({ prospectionDetails, onClick }) {
  // ReactGA.pageview('/pdm');

  return (
    <>
      <Col onClick={onClick} sm={12} md={6} lg={4} className={styles.card}>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Col md={4}>
            <img src={titleIcon} alt="Icone titulo" />
          </Col>
          <Col className={styles.prospectionTitle} md={20}>
            {prospectionDetails?.client_name}
          </Col>
        </Row>
        <Row>
          <Col className={styles.prospectionName} md={24}>
            Projeto: {prospectionDetails?.briefing_name}
          </Col>
        </Row>
        <Row>
          <Col className={styles.prospectionDateBegin} md={24}>
            Data de inicio
          </Col>
        </Row>
        <Row>
          <Col className={styles.prospectionDateBegin} md={24}>
            <img src={calendarIcon} alt="calendário" />
            {moment(prospectionDetails?.started_at).format('DD/MM/YYYY')}
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default ProspectionCard;
