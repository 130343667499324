/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import classes from './styles.module.css';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaRegTrashAlt } from 'react-icons/fa';
import { Alert } from 'rsuite';
import 'react-circular-progressbar/dist/styles.css';
import useToggle from '../../../../../../../../utils/hooks/useToggle';
import apiConnectSourcing from '../../../../../../../../services/apiConnectSourcing';
import { useCognitoAuth } from '../../../../../../../../Contexts/AuthCognitoContext';
import { useBriefingContext } from '../../../../../../../../Contexts/BriefingContext';

const ModalRemoveSupplier = (
  { supplierId, prospection },
  ref
) => {
  const [loading, setLoading] = useState(false);
  const [open, toggleOpen] = useToggle(false, true);
  const { user } = useCognitoAuth();
  const { user: briefingData } = useBriefingContext();
  const validationSchema = Yup.object({
    note: Yup.string().required('Campo Obrigatório'),
  });
  const { handleSubmit, handleChange, values, errors, isValid, dirty, resetForm } =
    useFormik({
      initialValues: {
        note: '',
      },
      validationSchema,
      validateOnChange: true,
      onSubmit: values => {
        setLoading(true);
        apiConnectSourcing
          .delete(`/delete-prospection-supplier/${
            prospection.id
          }/suppliers/${
            supplierId
          }/`, { data: { note: values.note } })
          .then(response => {
            window.location.reload();
          })
          .catch(error => {
            Alert.error(
              'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
              3000
            );
          }).finally(() => {
            setLoading(false);
          });
          ;
      },
    });

  useImperativeHandle(ref, () => ({
    toggleOpen,
  }));

  const handleClose = () => {
    resetForm(true);
    toggleOpen();
  }

  return (
    <Modal
      backdrop="static"
      centered
      size='lg'
      toggle={toggleOpen}
      isOpen={open}>
      <ModalBody className={clsx(classes.content,classes.modalBody)}>
        <div className={clsx("col-12", classes.guideCol)}>
          <div className={clsx(classes.compGuideSquare, classes.blue)}>
            <FaRegTrashAlt />
          </div>
          <p><strong>Exclusão de Fornecedor</strong></p>
        </div>
        <div className={classes.list}>
          <p className={classes.midHead}>
            Usuário: {user?.username}
            <br/>
            <span className={classes.subText}>
              Departamento: {briefingData?.department_name || '-'}
            </span>
          </p>
          <form onSubmit={(e) => {
            e.preventDefault();
            if (isValid) {
              console.log("go");
              handleSubmit();
            } else {
              console.log(errors);
            }
          }}>
            <label className={classes.label}>Descrição</label>
            <textarea
              value={values.note}
              placeholder="Digite aqui"
              className={classes.bodyCard}
              onChange={handleChange('note')}></textarea>
            <div className='d-flex justify-content-center mt-4'>
              {loading ? (
                <Spinner size="md" />
              ) : (
                <>
                  <button
                    onClick={handleClose}
                    type='button'
                    className={clsx(classes.btn, classes.cancel)}>
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    disabled={!isValid || !dirty}
                    className={clsx(classes.btn, classes.action)}>
                    Confirmar Exclusão
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default forwardRef(ModalRemoveSupplier);
