import moment from 'moment';

export const storageIsValid = nameStorage => {
  const storage = JSON.parse(localStorage.getItem(nameStorage));
  const timestampNow = moment(new Date()).unix();
  if (!storage || storage.expired <= timestampNow) {
    return [false, timestampNow + 14400];
  }
  return [true, storage];
};
