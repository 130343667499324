import React, { forwardRef, useImperativeHandle } from 'react';
import classes from './styles.module.css';
import { Modal, ModalBody } from 'reactstrap';
import clsx from 'clsx';
import { CgCloseO } from 'react-icons/cg';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { RiFolderDownloadLine, RiFolderUploadLine } from 'react-icons/ri';
import 'react-circular-progressbar/dist/styles.css';
import useToggle from '../../../../../../../../utils/hooks/useToggle';

// eslint-disable-next-line no-empty-pattern
const ModalCostSheetHelp = ({}, ref) => {
  const [open, toggleOpen] = useToggle(false, true);

  useImperativeHandle(ref, () => ({
    toggleOpen,
  }));

  return (
    <Modal centered size="lg" toggle={toggleOpen} isOpen={open}>
      <ModalBody className={clsx(classes.content, classes.modalBody)}>
        <button
          type="button"
          className={classes.btnCloseCustom}
          onClick={toggleOpen}>
          <CgCloseO />
        </button>
        <div className={clsx('col-12', classes.guideCol)}>
          <div className={clsx(classes.compGuideSquare, classes.blue)}>
            <HiQuestionMarkCircle />
          </div>
          <p>
            <strong>
              Informativos de{' '}
              <span className={classes.orange}>Download e Upload</span>
            </strong>
            <br />
            Dentro da coluna{' '}
            <span className={classes.orange}>
              Retorno da P. Financeira
            </span>{' '}
            você irá visualizar duas informações, uma sobre o{' '}
            <span className={classes.orange}>Download</span> e{' '}
            <span className={classes.orange}>Upload</span> da planilha de custo
            feito pela plataforma Seller.
          </p>
        </div>
        <div className={classes.list}>
          <p className={classes.midHead}>
            Orientação para leitura da informação
          </p>
          <div className="row">
            <div className="col">
              <div className={classes.item}>
                <div className={classes.icoBox}>
                  <RiFolderDownloadLine className={clsx(classes.icon)} />
                  <RiFolderUploadLine className={clsx(classes.icon)} />
                </div>
                <p>
                  O primeiro comportamento informado mostra que não foi efetuado
                  download ou upload da planilha de custos!
                  <br />
                  <span className={classes.orange}>
                    Obs: Para efetuar o upload será necessário fazer o download
                    antes!
                  </span>
                </p>
              </div>

              <div className={classes.item}>
                <div className={classes.icoBox}>
                  <RiFolderDownloadLine
                    className={clsx(classes.icon, classes.green)}
                  />
                </div>
                <p>
                  O segundo comportamento mostra que o Download foi efetuado em
                  Seller.
                </p>
              </div>

              <div className={classes.item}>
                <div className={classes.icoBox}>
                  <RiFolderUploadLine
                    className={clsx(classes.icon, classes.green)}
                  />
                </div>
                <p>
                  O Terceiro comportamento mostra que o{' '}
                  <span className={classes.orange}>Upload</span> foi efetuado em
                  Seller. Com o Upload feito pelo Seller o time de Sourcing
                  poderá efetuar o download da planilha clicando em{' '}
                  <span className={classes.green}>exportar</span>{' '}
                  disponibilizado no{' '}
                  <span className={classes.orange}>
                    Retorno da P. Financeira
                  </span>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default forwardRef(ModalCostSheetHelp);
