/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import styles from './SupplierDefinitionFilter.module.css';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from 'reactstrap';
import TableList from '../../../../Components/TableList/TableList';
import style from './TabsFilterAndDefinition.module.css';
import ModalSelectSupplier from './ModalSelectSupplier';
import ModalDefinitionSupplier from './ModalDefinitionSupplier';
import ModalStepNotEnabled from './ModalStepNotEnabled';

function TabsFilterAndDefinition({
  showTab,
  supplierList,
  setSupplierList,
  supplierFilterSelected,
  loading,
  opened,
  definitionList,
  setDefinitionList,
  getClients,
  supplierSelected,
}) {
  const [activeTab, setActiveTab] = useState('2');
  const modalSelectSupplierRef = useRef(null);
  const modalDefinitionSupplierRef = useRef(null);
  const modalStepNotRef = useRef(null);
  const [disableFilter, setDisableFilter] = useState(true);

  console.log('supplier', supplierList);

  const columHeaderTable = [
    { supplierName: 'NOME DO FORNECEDOR' },
    { supplierCnpj: '' },
    { statusSupplier: 'SELECIONE' },
  ];

  const onClickButtonSave = () => {
    modalSelectSupplierRef.current?.toggle();
  };

  const handleChecked = e => {
    const { value, checked } = e.target;
    const novaLista = supplierList.map(sp => {
      console.log('oi', sp);
      if (sp.supplier_name === value) {
        return {
          ...sp,
          status: checked,
        };
      } else {
        return sp;
      }
    });
    setDisableFilter(false);
    console.log('disable', disableFilter);
    setSupplierList(novaLista);
  };

  const getChoose = e => {
    const { value } = e.target;
    const supplierChoose = definitionList.map(sp => {
      if (sp.supplier_name === value) {
        return {
          ...sp,
          chosen_supplier: true,
        };
      } else {
        return {
          ...sp,
          chosen_supplier: false,
        };
      }
    });

    setDefinitionList(supplierChoose);
  };

  const onClickButtonSaveDefinition = () => {
    modalDefinitionSupplierRef.current?.toggle();
  };

  const listTable = useMemo(() => {
    return supplierList.map(l => ({
      supplierName: l.supplier_name,
      supplierCnpj: l.supplier_cnpj,
      statusSupplier: (
        <input
          key={l.id}
          type="checkbox"
          value={l.supplier_name}
          onChange={e => handleChecked(e)}
          checked={l.status}
        />
      ),
    }));
  }, [supplierList]);

  const listTableDefinition = useMemo(() => {
    return definitionList.map(l => ({
      supplierName: l.supplier_name,
      supplierCnpj: l.supplier_cnpj,
      statusSupplier: (
        <input
          key={l.id}
          type="radio"
          value={l.supplier_name}
          onChange={e => getChoose(e)}
          checked={l.chosen_supplier}
          className={style.inputRadio}
        />
      ),
    }));
  }, [definitionList]);

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    console.log('definição', definitionList);
  }, [definitionList]);

  const getModal = () => {
    if (opened === false) {
      modalStepNotRef.current?.toggle();
    } else {
      toggle('1');
    }
  };

  return (
    <>
      <section className={style.holdTabs}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={`${activeTab === '1' ? 'active' : ''} ${
                opened ? style.aba : style.navItemDisabled
              }`}
              onClick={() => {
                getModal();
              }}>
              Definição de fornecedor
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === '2' ? 'active' : ''} ${style.aba}`}
              onClick={() => {
                toggle('2');
              }}>
              Filtro Comercial
            </NavLink>
          </NavItem>
        </Nav>
      </section>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {/*definição de fornecedor*/}
          <TableList
            columns={columHeaderTable}
            lines={listTableDefinition}
            loading={loading}
            defaultMessageEmpty="Nenhum fornecedor selecionado"
          />
          <section className={styles.footerFilterComercial}>
            <div className={styles.mainFooterFilterComercial}>
              <Row>
                <Col md={10}>
                  <h4>Fornecedores selecionados no Filtro Comercial</h4>
                </Col>
                <Col md={2}>
                  <button
                    disabled={
                      !definitionList.find(sp => sp.chosen_supplier) ||
                      supplierSelected
                    }
                    className="btn btn-success font-weight-bold"
                    onClick={onClickButtonSaveDefinition}>
                    Salvar
                  </button>
                </Col>
              </Row>
              {definitionList.length > 0 ? (
                <>
                  <ul>
                    {definitionList
                      .filter(sp => sp.chosen_supplier)
                      .map(sp => (
                        <li key={`def-for-${sp.id}`}>
                          <span>{sp.supplier_name}</span>
                        </li>
                      ))}
                  </ul>
                </>
              ) : (
                <p>Selecione os fornecedores do painel acima</p>
              )}
            </div>
          </section>
          {/*end definição de fornecedor*/}
        </TabPane>
        <TabPane tabId="2">
          {/*filtro comercial*/}
          <TableList
            columns={columHeaderTable}
            lines={listTable}
            loading={loading}
            defaultMessageEmpty="Nenhum fornecedor selecionado"
          />
          {/* end filtro comercial*/}
          <section className={styles.footerFilterComercial}>
            <div className={styles.mainFooterFilterComercial}>
              <Row>
                <Col md={10}>
                  <h4>Fornecedores selecionados no Filtro Comercial</h4>
                </Col>
                <Col md={2}>
                  <button
                    className="btn btn-success font-weight-bold"
                    onClick={onClickButtonSave}
                    disabled={
                      !supplierList.find(sp => sp.status) ||
                      definitionList.find(sp => sp.chosen_supplier) ||
                      supplierFilterSelected
                    }>
                    Salvar
                  </button>
                </Col>
              </Row>
              {supplierList.length > 0 ? (
                <>
                  <ul>
                    {supplierList
                      .filter(sp => sp.status)
                      .map(sp => (
                        <li key={`fil-com-${sp.id}`}>
                          <span>{sp.supplier_name}</span>
                        </li>
                      ))}
                  </ul>
                </>
              ) : (
                <p>Selecione os fornecedores do painel acima</p>
              )}
            </div>
          </section>
        </TabPane>
      </TabContent>
      <ModalSelectSupplier
        ref={modalSelectSupplierRef}
        supplierList={supplierList}
        getClients={getClients}
      />
      <ModalDefinitionSupplier
        ref={modalDefinitionSupplierRef}
        definitionList={definitionList}
      />
      <ModalStepNotEnabled ref={modalStepNotRef} />
    </>
  );
}

export default TabsFilterAndDefinition;
