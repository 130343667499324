import React from 'react';
import './ModuleButton.scss';
import { classNames } from  '../../../../../../utils/utils';
import {
  MODULE_TYPE,
  MODULE_STATUS,
} from '../../../../../Pdm/Management/constants/constants';

import { ReactComponent as ViabilityModule } from '../../../../../../Assets/viabilidade3.svg';
import { ReactComponent as CheckModule } from '../../../../../../Assets/check.svg';
import { ReactComponent as BriefingModule } from '../../../../../../Assets/briefing.svg';
import { ReactComponent as SourcingModule } from '../../../../../../Assets/sourcing.svg';
import { ReactComponent as AuditoriaModule } from '../../../../../../Assets/auditoria.svg';
import { ReactComponent as DefinicaoModule } from '../../../../../../Assets/definicao.svg';
import { ReactComponent as ContratoModule } from '../../../../../../Assets/contrato.svg';
import { ReactComponent as EmbalagemModule } from '../../../../../../Assets/embalagem.svg';
import { ReactComponent as LancamentoModule } from '../../../../../../Assets/lancamento.svg';
import { ReactComponent as ImpressaoModule } from '../../../../../../Assets/impressao.svg';
import { ReactComponent as ProducaoModule } from '../../../../../../Assets/producao.svg';
import { ReactComponent as NaoAplicaModule } from '../../../../../../Assets/not-apply-module.svg';

const ModuleButton = ({ title, active, type, status, ...otherProps }) => {

  const disabled = status === MODULE_STATUS.DEVELOPING;
  const apply = status !== MODULE_STATUS.NOT_APPLY;
  const concluded = status === MODULE_STATUS.CONCLUDED
  const notStarted = status === MODULE_STATUS.NOT_STARTED;

  const classes = classNames({
    uiModuleButton: true,
    'active': active,
    'concluded': concluded,
    'apply': apply,
    'not-started': notStarted,
  });

  const getModuleIcon = () => {
    if (concluded) {
      return <CheckModule />;
    }
    switch (type) {
      case MODULE_TYPE.BRIEFING:
        return <BriefingModule />
      case MODULE_TYPE.SOURCING:
        return <SourcingModule />
      case MODULE_TYPE.VIABILITY:
        return <ViabilityModule />
      case MODULE_TYPE.AUDIT:
        return <AuditoriaModule />
      case MODULE_TYPE.SUPPLIER_DEFINITION:
        return <DefinicaoModule />
      case MODULE_TYPE.CONTRACTS_AND_REGISTRATION:
        return <ContratoModule />
      case MODULE_TYPE.PACKAGING_DEVELOPMENT:
        return <EmbalagemModule />
      case MODULE_TYPE.PRINT:
        return <ImpressaoModule />
      case MODULE_TYPE.FIRST_PRODUCTION:
        return <ProducaoModule />
      case MODULE_TYPE.RELEASE:
        return <LancamentoModule />
      default:
        <AuditoriaModule />
    }
  };

  return (
    <>
      <button {...otherProps} className={classes} disabled={disabled}>
        <div className="divIconDes">
          {getModuleIcon()}
          {!apply && <NaoAplicaModule />}
        </div>
        <span className={classNames({ tittle: true, dark: !apply })}>
          {title}
        </span>
      </button>
    </>
  );
};

export default ModuleButton;
