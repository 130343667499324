/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import './ModalSuppliers.scss';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { Alert, TagPicker } from 'rsuite';
import useToggle from '../../../../../../utils/hooks/useToggle';
import { validateCNPJ } from '../../../../../../utils/utils';
import { useBriefingContext } from '../../../../../../Contexts/BriefingContext';
import { BiSearch } from 'react-icons/bi';
import { ImLink } from 'react-icons/im';
import ModalSupplierRegistration from '../ModalSupplierRegistration/ModalSupplierRegistration';
import cnpjMask from '../../../../../../utils/masks/cnpjMask';

const ModalSuppliers = (
  { suppliersArray, setSuppliersArray, briefing, managers, emailUser },
  ref
) => {
  const { loading, searchProvidersV2 } = useBriefingContext();
  const [modal, toggle] = useToggle();
  const [inputNameSupplierSearch, setInputNameSupplierSearch] = useState('');
  const [inputCnpjSupplierSearch, setInputCnpjSupplierSearch] = useState('');
  const [cnpjValid, setCnpjValid] = useState(true);
  const [suppliersData, setSuppliersData] = useState([]);
  const [suppliersDataCache, setSuppliersDataCache] = useState([]);
  const [supplierSelectedIds, setSupplierSelectedIds] = useState([]);

  useImperativeHandle(ref, () => ({ toggle }));

  const modalSupplierRegistrationRef = useRef(null);

  useEffect(() => {
    if (inputCnpjSupplierSearch.length === 18) {
      setCnpjValid(validateCNPJ(inputCnpjSupplierSearch));
    } else {
      setCnpjValid(true);
    }
  }, [inputCnpjSupplierSearch]);

  const handleSetSelectedSuppliers = () => {
    if (modal) {
      // junta fornecedores selecionados na janela com os que já estavam selecionados
      // faz um filtro para evitar repetidos
      const unique = [...suppliersArray, ...suppliersDataCache].filter(
        (item, pos, self) => self.findIndex(i => i.id === item.id) === pos
      );
      setSuppliersArray(unique);
      // modalErrorSelectSupplierRef.current?.toggle();
      closeToModalObs();
    }
  };

  const handleNameSuppliers = async () => {
    // e.preventDefault();
    const input = document.getElementById('tagpick');
    if (inputNameSupplierSearch.length < 2) {
      Alert.error('No mínimo 2 letras');
    } else {
      searchProvidersByTerm(inputNameSupplierSearch);
      input.click();
    }
  };

  const handleCnpjSuppliers = async () => {
    // e.preventDefault();
    const input = document.getElementById('tagpick');
    if (inputCnpjSupplierSearch.length < 2) {
      Alert.error('No mínimo 2 letras');
    } else {
      const clearNum = inputCnpjSupplierSearch.replace(/[^\d]/g, '');
      searchProvidersByTerm(clearNum);
      input.click();
    }
  };

  const searchProvidersByTerm = async term => {
    const list = await searchProvidersV2(term);
    if (list?.success) setSuppliersData(list.data);
    if (list.data && list.data.length === 0) {
      setSuppliersData([]);
      Alert.error('Não encontramos nenhum fornecedor');
    }
  };

  const closeToModalObs = () => {
    setInputNameSupplierSearch('');
    setSuppliersDataCache([]);
    setSupplierSelectedIds([]);
    toggle();
  };

  const getModalRegistration = () => {
    modalSupplierRegistrationRef.current?.toggle();
  };

  const handleTagPicker = supplierIds => {
    setSupplierSelectedIds(supplierIds);
    const selectedSuppliers = [];
    for (let id of supplierIds) {
      const supplier = suppliersData.find(s => s.id === id);
      if (supplier) {
        selectedSuppliers.push(supplier);
      }
    }
    setSuppliersDataCache(selectedSuppliers);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={handleSetSelectedSuppliers} size="lg">
        <ModalHeader toggle={handleSetSelectedSuppliers}>
          <span className="font18 bold p-b-1 text-left">
            Recomendação de Fornecedor
          </span>
        </ModalHeader>
        <ModalBody>
          <form autoComplete="off" onSubmit={e => e.preventDefault()}>
            <InputGroup>
              <Input
                className="inputText noShadow"
                placeholder="Buscar por nome"
                name="suppliersInput"
                type="text"
                value={inputNameSupplierSearch}
                onChange={e => setInputNameSupplierSearch(e.target.value)}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    handleNameSuppliers();
                  }
                }}
              />
              <InputGroupAddon addonType="append">
                <Button
                  color="light"
                  className="searchInputButton"
                  onClick={handleNameSuppliers}>
                  <BiSearch size={20} />
                </Button>
              </InputGroupAddon>
            </InputGroup>
            <br />
            <InputGroup>
              <Input
                className="inputText noShadow"
                placeholder="Buscar por CNPJ"
                name="suppliersInput"
                type="text"
                value={inputCnpjSupplierSearch}
                onChange={e =>
                  setInputCnpjSupplierSearch(cnpjMask(e.target.value))
                }
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    handleCnpjSuppliers();
                  }
                }}
              />
              <InputGroupAddon addonType="append">
                <Button
                  color="light"
                  className="searchInputButton"
                  onClick={handleCnpjSuppliers}>
                  <BiSearch size={20} />
                </Button>
              </InputGroupAddon>
            </InputGroup>
            {!cnpjValid && (
              <p style={{ color: 'red', margin: '4px 0 0 10px' }}>
                CNPJ inválido!
              </p>
            )}
            <TagPicker
              onChange={e => handleTagPicker(e)}
              value={supplierSelectedIds}
              creatable={true}
              name="supplierNames"
              label="name"
              defaultValue="id"
              labelKey="name"
              valueKey="id"
              size="lg"
              block
              searchable={false}
              childrenKey="name"
              id="tagpick"
              data={suppliersData}
              placeholder={
                !loading.searchProviders
                  ? 'Selecione os fornecedores'
                  : 'Carregando...'
              }
              className="tagPicker"
              renderMenuItem={(_, item) => {
                return (
                  <div
                    className="tagLabels"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <span style={{ flex: '1' }}>{item.name}</span>
                    <span style={{ flex: '1', textAlign: 'center' }}>
                      {cnpjMask(item.cnpj)}
                    </span>
                    <span style={{ flex: '1', textAlign: 'right' }}>
                      {item.is_seller_linked ? (
                        <>
                          <ImLink size={14} /> Vinculado a Seller
                        </>
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                );
              }}
            />
          </form>
          <div className="flex-center m-t-7">
            <button onClick={handleSetSelectedSuppliers} className="buttonRed">
              Salvar
            </button>
            {briefing?.id && (
              <button
                onClick={getModalRegistration}
                className="buttonOrange"
                style={{ marginLeft: '20px' }}>
                Solicitar Cadastro de Fornecedor
              </button>
            )}
          </div>
        </ModalBody>
      </Modal>
      <ModalSupplierRegistration
        briefing={briefing}
        managers={managers}
        emailUser={emailUser}
        ref={modalSupplierRegistrationRef}
      />
    </>
  );
};

export default forwardRef(ModalSuppliers);
