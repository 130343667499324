import React, { useState, useEffect } from 'react';
// import ReactGA from 'react-ga';
import styles from './styles.module.css';
import { Col, Modal, Row, Container, Alert } from 'rsuite';
import Table from 'reactstrap/lib/Table';
import apiConnectSourcing from '../../../../../../../../services/apiConnectSourcing';

function ModalDemandsSummary({ closeModal, show }) {
  // ReactGA.pageview('/pdm');

  // const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState([]);

  useEffect(() => {
    apiConnectSourcing
      .get('/tasks-summary/')
      .then(response => {
        if (response.data) {
          setSummary(response.data);
        }
      })
      .catch(error => {
        if (error.response?.status !== 403) {
          Alert.error(
            'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
            5000
          );
        }
      });
  }, []);
  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header>
          <Container>
            <Row>
              <Col className={styles.modalTittle} sm={24} md={24} lg={24}>
                Resumo do dia
              </Col>
            </Row>
          </Container>
        </Modal.Header>

        <Modal.Body className={styles.body}>
          <Table borderless striped className={styles.tableBriefing}>
            <thead>
              <tr>
                <th className={styles.borderLeftRadius}>RESPONSÁVEL</th>
                <th>PENDENTE</th>
                <th>REALIZADO</th>
                <th className={styles.borderRightRadius}>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {summary?.map((ob, i) => {
                return (
                  <tr key={i}>
                    <td>{ob.manager}</td>
                    <td>{ob.pending}</td>
                    <td>{ob.completed}</td>
                    <td>{ob.total}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalDemandsSummary;
