/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
// import ReactGA from 'react-ga';
import { useParams } from 'react-router-dom';
import HeaderModule from '../../Components/HeaderModule/HeaderModule';
import Loading from '../../Components/Helpers/Loading';
import { Report } from 'powerbi-report-component';
import apiPbi from '../../services/apiPbi';
import { useCognitoAuth } from '../../Contexts/AuthCognitoContext';
import apiConnect from '../../services/apiConnect';

const msgError =
  'Não foi possível carregar o Relatório no momento. Em caso de dúvidas, entre em contato com o suporte de Inteligência Comercial.';

function GraphPage() {
  const { name } = useParams();
  // console.log('GraphPage', name);
  // ReactGA.pageview(`/gestao-comercial/${name}`);

  const { user } = useCognitoAuth();
  const email = user.attributes.email;
  const [graphParams, setGraphParams] = useState();
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState('');
  const [embedUrl, setEmbedUrl] = useState('');
  const [error, setError] = useState();

  useEffect(() => {
    setError('');
    setLoading(true);
    setGraphParams();
    (async () => {
      try {
        const url = `/retailers/report?useremail=${email}&reportname=${name}`;
        const resp = await apiConnect.get(url, {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
          },
        });
        // console.log('resp', resp.data);
        if (resp?.data?.length > 0) {
          setGraphParams(resp.data[0]);
        }
      } catch (error) {
        console.log('error get graphParams', error.response);
        setError(msgError);
        // setLoading(false);
      }
    })();
  }, [name]);

  const h = window.innerHeight;
  const height = h - 200;
  const reportStyle = {
    height: height,
  };
  const extraSettings = {
    filterPaneEnabled: false,
    navContentPaneEnabled: true,
    hideErrors: false,
  };

  const handleReportLoad = report => {
    return report;
  };

  const handleReportRender = report => {
    return report;
  };

  const handlePageChange = data => {};
  const handleDataSelected = data => {};

  const handleTileClicked = data => {
    console.log('Data from tile', data);
  };

  useEffect(() => {
    if (graphParams) {
      // console.log('graphParams', graphParams);
      const url =
        graphParams.report_id + '/' + graphParams.workspace_id + '/' + email;
      // console.log('url', url);
      apiPbi
        .get(url)
        .then(response => {
          console.log('response', response);
          // setDataCarrefour(response.data);
          // localStorage.setItem('carrefourPbiAcessToken', response.data.accessToken);
          // localStorage.setItem('carrefourPbiEmbedUrl', response.data.reportConfig[0].embedUrl);
          setEmbedUrl(response.data.reportConfig[0].embedUrl);
          setAccessToken(response.data.accessToken);
        })
        .catch(error => {
          console.log('error get embedUrl and accesstoken', error.response);
          setError(msgError);
          setEmbedUrl('');
          setAccessToken('');
        })
        .finally(() => {
          setLoading(false); //aqui marca que pegou tudo das APIS e decide se dá mensagem de erro ou não pro user para permissão
        });
    }
  }, [graphParams]);

  if (loading) return <Loading />;

  return (
    <>
      <HeaderModule classHeader="commercialHeaderModule">
        {graphParams?.name}
        <h5>{graphParams?.periodicidade}</h5>
      </HeaderModule>
      <div className="mainDiv">
        <center>
          {error ? (
            <div style={{ padding: '50px' }}>
              <Alert color="danger">{error}</Alert>
            </div>
          ) : (
            <Report
              key={name}
              tokenType="Embed"
              accessToken={accessToken} // accessToken goes here
              embedUrl={embedUrl}
              embedId={graphParams?.report_id} // embedId
              pageName=""
              reportMode="View"
              groupId={graphParams?.workspace_id} // groupId
              extraSettings={extraSettings}
              permissions="View"
              style={reportStyle}
              onLoad={handleReportLoad}
              onRender={handleReportRender}
              onSelectData={handleDataSelected}
              onPageChange={handlePageChange}
              onTileClicked={handleTileClicked}
            />
          )}
        </center>
      </div>
    </>
  );
}

export default GraphPage;

// [
//   {
//     id: 1,
//     created_at: '2022-02-04T16:50:29.369897-03:00',
//     modified_at: '2022-02-08T10:36:14.513965-03:00',
//     reportname: 'carrefour',
//     name: 'Carrefour 2',
//     report_id: '80714161-17c4-4c07-99fb-2bbfc5bd9f2c',
//     workspace_id: 'd885a49e-7c26-428f-848c-59f69fb57f3f',
//     periodicidade: 'Periodicidade: Toda Terça, talvez',
//   },
// ];
// [
//   {
//     id: 6,
//     created_at: '2022-02-04T16:50:29.369897-03:00',
//     modified_at: '2022-02-07T14:37:10.991616-03:00',
//     reportname: 'petz',
//     name: 'PETZ',
//     report_id: '3cdbe8d2-01fb-42f7-85d1-8e79899079f7',
//     workspace_id: '4df9ca4e-bd9e-4b92-aff1-b75aac79bbc9',
//     periodicidade: 'Periodicidade: Diário (d-2)',
//   },
// ];
