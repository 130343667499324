/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import './ModalCdDestiny.scss';
import { Modal, ModalBody } from 'reactstrap';
import useToggle from '../../../../../../utils/hooks/useToggle';
import { useBriefingContext } from '../../../../../../Contexts/BriefingContext';

const ModalCdDestiny = ({ cdStates, setCdStates, statusCode }, ref) => {
  const { states } = useBriefingContext();
  const [modal, toggle] = useToggle();
  const [selectedStates, setSelectedStates] = useState([]);

  useImperativeHandle(ref, () => ({ toggle }));

  useEffect(() => {
    if (modal && cdStates.length > 0) {
      setSelectedStates(cdStates);
    }
  }, [modal]);

  const saveCdDestiny = () => {
    setCdStates(selectedStates);
    setSelectedStates([]);
    toggle();
  };

  return (
    <Modal
      className="modal-cd-destiny"
      size="sm"
      toggle={toggle}
      isOpen={modal}>
      <ModalBody className="specialPaddingModal">
        <div className="notesProviders">
          <p>CD Destino</p>
          <ul className="listaCds">
            {states.map((state, i) => (
              <li
                key={state.code}
                className={
                  selectedStates.includes(state.id) ? 'listaCdAtivo' : ''
                }
                onClick={() => {
                  if (!selectedStates.includes(state.id))
                    setSelectedStates(st => [...st, state.id]);
                  else setSelectedStates(st => st.filter(s => s !== state.id));
                }}>
                {state.code}
              </li>
            ))}
          </ul>
        </div>
        <div className="modal-footer flex-center m-t-2 clear">
          <button
            onClick={() => setSelectedStates([])}
            className="buttonModalCdDestiny">
            Limpar seleção
          </button>
          <button
            onClick={() => setSelectedStates(() => states.map(s => s.id))}
            className="buttonModalCdDestiny">
            Selecionar todos
          </button>
          <button
            onClick={saveCdDestiny}
            className="buttonWhite"
            disabled={statusCode === 'F'}>
            Confirmar
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default forwardRef(ModalCdDestiny);
