let linkVendors = '';
if (process.env.REACT_APP_FRONT_ENV === 'LOCAL') {
  linkVendors = 'https://vendor-dev.amicci-digital.com.br/';
}

if (process.env.REACT_APP_FRONT_ENV === 'DEV') {
  linkVendors = 'https://vendor-dev.amicci-digital.com.br/';
}

if (process.env.REACT_APP_FRONT_ENV === 'HMG') {
  linkVendors = 'https://vendor-hmg.amicci-digital.com.br/';
}

if (process.env.REACT_APP_FRONT_ENV === 'PROD') {
  linkVendors = 'https://vendor.amicci-digital.com.br/';
}

export default linkVendors;
