/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import classes from './styles.module.css';
import moment from 'moment';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import FileSaver from 'file-saver';
import { Alert } from 'rsuite';
import clsx from 'clsx';
import { CgCloseO } from 'react-icons/cg';
import { TiWarningOutline } from 'react-icons/ti';
import 'react-circular-progressbar/dist/styles.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useToggle from '../../../../../../../../utils/hooks/useToggle';
import { classNames } from '../../../../../../../../utils/utils';
import { useCognitoAuth } from '../../../../../../../../Contexts/AuthCognitoContext';
import DragAndDropFileInput from '../../../../../../../../Components/DragAndDropFileInput/DragAndDropFileInput';
import InlineCalendar from '../../../../../../../../Components/InlineCalendar/InlineCalendar';
import apiConnectSourcing from '../../../../../../../../services/apiConnectSourcing';
import apiConnectSellerPublic from '../../../../../../../../services/apiConnectSellerPublic';

const ModalUploadCostSheet = (
  {
    task,
    prospection,
    supplierIndex,
    supplierName,
  },
  ref
) => {
  const { user } = useCognitoAuth();
  const email = user.attributes.email;
  const [open, toggleOpen] = useToggle(false, true);
  const [downloading, setDownloading] = useState(false);
  const [saving, setSaving] = useState(false);
  
  const dateMin = moment().format('YYYY-MM-DD');

  const acceptedTypes = [
    'application/zip',
    'application/x-zip-compressed',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  const logSellerEventCostReturn = () => {
    apiConnectSellerPublic.post(`/new-log-entry`, {
      user: email,
      action: 'Correção planilha Sourcing',
      entity: 'Prospecção',
      value: prospection.id,
    });
  };

  const validationSchema = Yup.object({
    date: Yup.string().required('Campo Obrigatório'),
    note: Yup.string().required('Campo Obrigatório'),
    productFile: Yup.mixed()
      .test(
        'file-test',
        'Formato inválido',
        value => value && acceptedTypes.includes(value.type)
      )
      .required('Arquivo é obrigatório'),
  });

  const {
    handleSubmit,
    handleChange,
    dirty,
    errors,
    isValid,
    values,
    touched,
    resetForm,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues: {
      note: '',
      productFile: undefined,
      date: dateMin
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: values => {
      console.log(values);
      const formData = new FormData();
      const jsonSupInfo = JSON.stringify(prospection?.supplierInfo?.[supplierIndex]);
      const jsonSup = JSON.stringify(prospection?.suppliers?.[supplierIndex]);
      formData.append('note', values.note);
      formData.append('file', values.productFile, values.productFile.name);
      formData.append('scheduled_to', values.date);
      formData.append('supplierInfo', jsonSupInfo);
      formData.append('supplier', jsonSup);

      setSaving(true);

      apiConnectSourcing
        .post(`/tasks/${task?.id}/upload-cost-return/`, formData)
        .then(response => {
          logSellerEventCostReturn();
          window.location.reload();
        })
        .catch(error => {
          console.log(error);
          Alert.error(
            'Aconteceu um erro inesperado. Tente novamente, mais tarde!'
          );
        })
        .finally(() => {
          setSaving(false);
        });
    },
  });

  const setFile = (file) => {
    setFieldValue('productFile', file);
    setFieldTouched('productFile', true, false);
  };

  const handleClose = () => {
    resetForm(true);
    toggleOpen();
  }

  const getCostReturn = () => {
    setDownloading(true);
    apiConnectSourcing
      .get(`/download-cost-sheet/${
        prospection.id
      }/suppliers/${
        prospection?.suppliers?.[supplierIndex]?.supplier_id
      }/`, { responseType: 'blob' })
      .then(response => {
        const { data, headers } = response;
        const type = `${headers['content-type']}`;
        const file_extension = `${
          headers?.["content-disposition"]?.split('filename=')?.[1] || ''
        }`.split('.').pop();

        const filename = `documento-custo-${
          supplierName.replace(/\s/g, '-').substring(0,32)
        }-${prospection.id}.${file_extension}`;

        if (data) {
          var blob = new Blob([data], { type });
          FileSaver.saveAs(
            blob,
            filename
          );
        } else {
          Alert.error(
            'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
            3000
          );
        }
      })
      .catch(error => {
        if (error.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado. Tente novamente, mais tarde!'
          );
        }
      })
      .finally(() => {
        setDownloading(false);
      });
  }

  useImperativeHandle(ref, () => ({
    toggleOpen,
  }));

  return (
    <Modal
      backdrop="static"
      centered
      size='lg'
      toggle={toggleOpen}
      isOpen={open}>
      <ModalBody className={clsx(classes.content,classes.modalBody)}>
        <button type="button" className={classes.btnCloseCustom} onClick={handleClose}>
          <CgCloseO />
        </button>
        <form onSubmit={(e) => {
            e.preventDefault();
            if (isValid) {
              console.log("go");
              handleSubmit();
            } else {
              console.log(errors);
            }
          }}>
          <div className={classes.head}>
            <h3 className={classes.title}>Download Planilha de Custos</h3>
            <div className="d-flex justify-content-between flex-wrap">
              <p className={classes.text}>
                Planilha de custo enviada por:{' '}
                <span className={classes.blue}>
                  {supplierName}
                </span>
              </p>
            </div>
            <div className="d-flex justify-content-between flex-wrap">
              <p className={classes.text}>
                Para exportar o documento{' '}
                {downloading
                  ? <Spinner size='sm' />
                  : (
                    <button
                      type='button'
                      onClick={getCostReturn}
                      className={classNames({
                        [classes.textBtn]: true,
                        [classes.blue]: true}
                    )}>
                    clique aqui!
                    </button>
                  )
                }
              </p>
            </div>
          </div>

          <div className={classes.warning}>
            <TiWarningOutline className={classes.warningIcon} />
            <p className={classes.warningText}>
              Caso a planilha não esteja prenchida corretamente, orientamos que sejam
              feitas as considerações dentro do padrão estabelicido pelo time e uma
              observação seja anexada junto ao documento, para que o Seller faça todos 
              os ajustes necessários. Em caso de envio de mais de um documento pedimos
              que seja feito em formato ZIP.
            </p>
          </div>

          <label className={classes.label}>Notificação para Seller</label>
          <p  className={classes.bodyCard}>
            Olá,<br />
            Agradecemos o envio da planilha de custos, após a validação feita por nosso
            time informamos que sua planilha foi reprovada, existem algumas informações
            a serem corrigidas. Para fazer a correção basta fazer o download da planilha
            e seguir as orientações feitas pelo nosso time, após o preenchimento reenvie
            para que possamos validar as informações. Obrigado.
          </p>

          <label className={classNames({
            [classes.label]: true,
            [classes.orange]: true
          })}>Registro de Sourcing</label>
          <textarea
            value={values.note}
            placeholder={
              "Ex: Encontramos erros em algumas informações de custo e volume " +
              "pedimos que o fornecedor efetue a correção das " +
              "informações e encaminhe novamente a planilha."
            }
            className={classes.bodyCard}
            onChange={handleChange('note')} />

          <div className="my-4 d-flex justify-content-end align-items-center">
            <p className="text">Programar para:</p>
            <div className={classes.calendarBox}>
              <InlineCalendar
                date={values.date}
                position={{ right: 0 }}
                setDate={handleChange('date')}
                minDate={dateMin}
                placeholder="00/00/0000"
                classes={[classes.borderCalendar]}
                dateStr={true}
                disableWeekend
                disableHolidays
              />
            </div>
          </div>

          <div className="my-4">
            <DragAndDropFileInput
              inline
              inlineText="Arraste sua planilha ou"
              acceptedTypes={acceptedTypes}
              setFile={setFile}
              file={values.productFile}
              errorMsg={touched.productFile && errors.productFile ? errors.productFile : ''}
            />
          </div>

          <div className='d-flex justify-content-center mt-4'>
            {saving ? (
              <Spinner size="md" />
            ) : (
              <>
                <button
                  onClick={handleClose}
                  type='button'
                  className={clsx(classes.btn, classes.cancel)}>
                  Cancelar
                </button>
                <button
                  type="submit"
                  disabled={!isValid || !dirty}
                  className={clsx(classes.btn, classes.action)}>
                  Enviar
                </button>
              </>
            )}
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default forwardRef(ModalUploadCostSheet);
