/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import './SelectPeriodLaunch.scss';
import { BsChevronDown, BsX } from 'react-icons/bs';
import { OutsideAlerter } from '../../utils/hooks/useOutsideAlerter';
import { Alert } from 'rsuite';

const SelectPeriodLaunch = ({
  values,
  onChange,
  placeholder = 'Selecione..',
  cleanable,
}) => {
  const defaultValues = {
    start_forecast_date: '',
    launch_forecast_date: '',
  };
  const months = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];
  const years = [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
  const [innerValue, setInnerValue] = useState({ ...defaultValues });
  const [fDate, setFDate] = useState('');
  const [startMonth, setStartMonth] = useState('');
  const [startYear, setStartYear] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [endYear, setEndYear] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (JSON.stringify(values) !== JSON.stringify(innerValue)) {
      setInnerValue(values);
      // verifica se passou algum intervalo
      // se passou start_forecast_date
      if (values.start_forecast_date) setFDate('start');
      // se passou launch_forecast_date
      else if (values.launch_forecast_date) setFDate('launch');
      else {
        // se nao passou nada
        setFDate('');
      }
    }
  }, [values]);

  const showWindow = () => {
    setShow(true);
  };

  const clearValue = () => {
    setInnerValue({ ...defaultValues });
    if (onChange) onChange({ ...defaultValues });
  };

  const hideWindow = () => {
    setTimeout(() => {
      setShow(false);
    }, 100);
  };

  const handleSave = () => {
    if (!fDate) {
      Alert.warning('Selecione entre Lanç. Planejado e Lanç. Real!');
    } else if (!startMonth) {
      Alert.warning('Selecione o mês inicial!');
    } else if (!startYear) {
      Alert.warning('Selecione o ano inicial');
    } else if (!endMonth) {
      Alert.warning('Selecione o mês final!');
    } else if (!endYear) {
      Alert.warning('Selecione o ano final!');
    } else {
      const startDate = `${startYear}-${startMonth}-01`;
      const lastMonthDay = new Date(endYear, Number(endMonth), 0).getDate();
      const endDate = `${endYear}-${endMonth}-${lastMonthDay}`;
      const sd = new Date(startDate);
      const ed = new Date(endDate);
      if (sd.getTime() > ed.getTime()) {
        Alert.warning('O período inicial deve ser anterior ao período final!');
      } else {
        let selectedPeriod;
        if (fDate === 'start') {
          selectedPeriod = {
            ...defaultValues,
            start_forecast_date: `${startDate},${endDate}`,
          };
        } else {
          selectedPeriod = {
            ...defaultValues,
            launch_forecast_date: `${startDate},${endDate}`,
          };
        }
        setInnerValue(selectedPeriod);
        if (onChange) onChange(selectedPeriod);
        hideWindow();
      }
    }
  };

  const handleCancel = () => {
    hideWindow();
  };

  const displayValue = useMemo(() => {
    if (innerValue.start_forecast_date || innerValue.launch_forecast_date) {
      const [startDate, endDate] = (
        innerValue.start_forecast_date || innerValue.launch_forecast_date
      ).split(',');
      const [sYear, sMonth] = startDate.split('-');
      const [eYear, eMonth] = endDate.split('-');
      return `${sMonth}/${sYear.substring(2)} - ${eMonth}/${eYear.substring(
        2
      )}`;
    }
    return '';
  }, [innerValue]);

  return (
    <OutsideAlerter onClickOutside={() => hideWindow()}>
      <div className="SelectPeriodLaunch">
        <input
          className="splInput"
          placeholder={placeholder}
          value={displayValue}
          onChange={() => {}}
          onFocus={showWindow}
        />
        <button type="button" className="splBtnIcon" onClick={showWindow}>
          <BsChevronDown />
        </button>
        {cleanable &&
          (innerValue.start_forecast_date ||
            innerValue.launch_forecast_date) && (
            <button
              title="Limpar"
              type="button"
              className="splBtnClear"
              onClick={clearValue}>
              <BsX />
            </button>
          )}
        <div
          className="splWindowOptions"
          style={{ display: show ? 'flex' : 'none' }}>
          <div className="splRadios">
            <label>
              <input
                type="radio"
                name="lancamento"
                value="start"
                checked={fDate === 'start'}
                onChange={e => {
                  if (e.target.checked) setFDate('start');
                }}
              />
              <span>Lanç. Planejada</span>
            </label>
            <label>
              <input
                type="radio"
                name="lancamento"
                value="launch"
                checked={fDate === 'launch'}
                onChange={e => {
                  if (e.target.checked) setFDate('launch');
                }}
              />
              <span>Lanç. Real</span>
            </label>
          </div>
          <div className="splSelects">
            <label>Período inicial</label>
            <div className="splLine">
              <select
                placeholder="mês"
                value={startMonth}
                onChange={e => setStartMonth(e.target.value)}>
                <option value="" disabled>
                  mês
                </option>
                {months.map(m => (
                  <option key={`init-${m.value}`} value={m.value}>
                    {m.label}
                  </option>
                ))}
              </select>
              <select
                placeholder="ano"
                value={startYear}
                onChange={e => setStartYear(e.target.value)}>
                <option value="" disabled>
                  ano
                </option>
                {years.map(y => (
                  <option key={`init-${y}`} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>
            <label>Período final</label>
            <div className="splLine">
              <select
                placeholder="mês"
                value={endMonth}
                onChange={e => setEndMonth(e.target.value)}>
                <option value="" disabled>
                  mês
                </option>
                {months.map(m => (
                  <option key={`fin-${m.value}`} value={m.value}>
                    {m.label}
                  </option>
                ))}
              </select>
              <select
                placeholder="ano"
                value={endYear}
                onChange={e => setEndYear(e.target.value)}>
                <option value="" disabled>
                  ano
                </option>
                {years.map(y => (
                  <option key={`fin-${y}`} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="splButtons">
            <button type="button" className="cancel" onClick={handleCancel}>
              Cancelar
            </button>
            <button type="button" className="save" onClick={handleSave}>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </OutsideAlerter>
  );
};

export default SelectPeriodLaunch;
