import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import InputText from '../../../../../Components/InputText/InputText';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { Alert } from 'rsuite';
import ButtonDefault from '../../../../../Components/ButtonDefault/ButtonDefault';
import './ModalDayEdition.scss';
import { classNames } from '../../../../../utils/utils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import apiConnectManagement from '../../../../../services/apiConnectManagement';
import { useCognitoAuth } from '../../../../../Contexts/AuthCognitoContext';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';

const ModalDayEdition = (
  {
    moduleType = null,
    moduleStage = null,
    setModuleStages = () => {},
    displayOnly = false,
    displayDays = null,
    displayStageText = null,
    displayObs = '',
    onSubmit = () => {},
  },
  ref
) => {
  const { user } = useCognitoAuth();
  const { stages } = useManagementContext();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const min = 0;
  const [initalValueData, setinitialValueData] = useState({
    obs: '',
    days_to_finish: 0,
  });

  const validationSchema = Yup.object({
    obs: Yup.string()
      .max(500, ' Limite de 500 caracteres')
      .required('Campo Obrigatório'),
    days_to_finish: Yup.number()
      .min(min, `Digite um número maior ou igual que ${min}.`)
      .required('Campo Obrigatório'),
  });

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  const { handleSubmit, handleChange, values, errors, resetForm } = useFormik({
    initialValues: initalValueData,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      console.log(values);
      onHandleConfirm(values);
    },
  });

  function closeModal() {
    setShowModal(!showModal);
    resetForm();
  }

  const onHandleConfirm = values => {
    setLoadingConfirm(true);
    const body = {
      user: user,
      obs: values.obs,
      days_to_finish: values.days_to_finish,
    };
    apiConnectManagement
      .put(
        `/module_type/${moduleType}/project_module/${moduleStage?.project_module}/stage/${moduleStage?.id}/extend_days`,
        body
      )
      .then(response => {
        if (response?.data) {
          onHandleUpdateStages(response?.data.stage_module);
          onSubmit();
          closeModal();
        }
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else if (error?.response?.status === 400) {
          Alert.error(error.response.data.error, 50000);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado, tente novamente mais tarde!'
          );
        }
      })
      .finally(() => {
        setLoadingConfirm(false);
      });
  };

  const onHandleUpdateStages = stage => {
    const stageIndex = stages.findIndex(moduleStage => {
      return moduleStage.id === stage.id;
    });
    const tempStages = [...stages];
    tempStages[stageIndex] = stage;

    setModuleStages(tempStages);
  };

  useEffect(() => {
    setinitialValueData({
      obs: '',
      days_to_finish: 0,
    });
  }, [moduleStage?.days_to_finish]);

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={false}
      centered={true}
      className="custom-modal modal-dialog-large">
      <ModalHeader>
        <Row>
          <Col md={1} className="icon-head-day-edition">
            <svg
              id="Icon"
              width="53.9"
              height="55.589"
              viewBox="0 0 43.9 45.589">
              <defs>
                {/* <linearGradient
                  id="linear-gradient"
                  x1="0.808"
                  y1="0.102"
                  x2="0.258"
                  y2="0.89"
                  gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#7467f0" />
                  <stop offset="1" stop-color="#2896e9" />
                </linearGradient> */}
              </defs>
              <ellipse
                id="Ellipse_13"
                data-name="Ellipse 13"
                cx="21.95"
                cy="22.794"
                rx="21.95"
                ry="22.794"
                // fill="url(#linear-gradient)"
              />
              <g id="schedule-button" transform="translate(11.313 12.157)">
                <g id="schedule">
                  <path
                    id="Path_1923"
                    data-name="Path 1923"
                    d="M10.975,0A10.975,10.975,0,1,0,21.95,10.975,11.007,11.007,0,0,0,10.975,0Zm0,19.755a8.78,8.78,0,1,1,8.78-8.78A8.806,8.806,0,0,1,10.975,19.755Z"
                    fill="#fff"
                  />
                  <path
                    id="Path_1924"
                    data-name="Path 1924"
                    d="M231.146,127.5H229.5v6.585l5.707,3.512.878-1.427-4.939-2.963Z"
                    transform="translate(-219.622 -122.012)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </Col>
          <Col md={5} className="texts-modal texts-modal-v3">
            <h2 className="text-day-edition">Alteração de dias úteis</h2>
            <h3 className="sub-day-edition">
              {displayOnly ? displayStageText : moduleStage?.stage.name}
            </h3>
          </Col>
          <Col md={6}>
            {!displayOnly && (
              <section className="box-modal-red">
                <div className="text-color-red5">
                  <svg
                    className="i-attention"
                    width="13.728"
                    height="12.334"
                    viewBox="0 0 13.728 12.334">
                    <path
                      id="attention"
                      d="M13.728,11.347a.86.86,0,0,1-.339.734,1.351,1.351,0,0,1-.833.253H1.172a1.331,1.331,0,0,1-.832-.259A.864.864,0,0,1,0,11.347a1.493,1.493,0,0,1,.222-.752L5.908.678A1.151,1.151,0,0,1,6.87,0a1.1,1.1,0,0,1,.937.678l5.7,9.929a1.522,1.522,0,0,1,.222.74Zm-6.007-6.3v-1.8H6.007v1.8a2.057,2.057,0,0,0,.025.327q.025.154.068.345t.068.327l.321,2h.728l.333-2q.025-.123.074-.321T7.7,5.371a2.057,2.057,0,0,0,.025-.327Zm0,4.774a.812.812,0,0,0-.253-.6.842.842,0,0,0-.611-.247.828.828,0,0,0-.6.247.812.812,0,0,0-.253.6.832.832,0,0,0,.253.611.818.818,0,0,0,.6.253.863.863,0,0,0,.863-.863Z"
                      fill="#b9254e"
                    />
                  </svg>

                  <strong>ATENÇÃO!</strong>
                </div>
                Você está modificando a estimativa de dias úteis dessa etapa.
                Por favor, informe o motivo dessa alteração. Essa ação irá
                modificar, também, a data prevista de entrega do projeto.
              </section>
            )}
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row className="hold-radio-button">
          <Col md={4}>
            <section>
              <div className="media-days">
                Média {displayOnly ? 'alterada' : 'atual'}:{' '}
                <span>
                  {displayOnly ? displayDays : moduleStage?.days_to_finish}
                </span>
                <span className="txt-day-size">Dias</span>
              </div>
              {!displayOnly && (
                <>
                  Insira o novo número de dias úteis para conclusão da etapa:
                  <section className="custom-numeric-input">
                    <span>Dias</span>
                    <InputText
                      type="number"
                      value={values.days_to_finish}
                      onChange={handleChange('days_to_finish')}
                    />
                    <span className="error">
                      {errors.days_to_finish ? errors.days_to_finish : null}
                    </span>
                  </section>
                </>
              )}
            </section>
          </Col>
          <Col md={8}>
            <label className="text-observation">
              Observações <span>adicionais</span>
            </label>
            <InputText
              maxLength={500}
              readonly={displayOnly}
              value={displayOnly ? displayObs : values.obs}
              onChange={handleChange('obs')}
              className={classNames({
                'custom-textarea': true,
                'error-input': Boolean(errors.obs),
              })}
              type="textarea"
              placeholder="Ex: De acordo com a reunião realizada junto ao cliente a data para conclusão da etapa precisará ser alterada........"></InputText>
            <small className="text-limit">Limite de 500 caracteres</small>
            <br />
            <span className="error">{errors.obs ? errors.obs : null}</span>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        {displayOnly ? (
          <ButtonDefault color="blue" radius onClick={closeModal}>
            Fechar
          </ButtonDefault>
        ) : (
          <>
            <ButtonDefault color="red" radius onClick={closeModal}>
              Cancelar
            </ButtonDefault>
            <ButtonDefault
              color="green"
              radius
              disabled={loadingConfirm}
              loading={loadingConfirm}
              onClick={handleSubmit}>
              Confirmar
            </ButtonDefault>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default forwardRef(ModalDayEdition);
