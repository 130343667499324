import React, { useState, createContext } from 'react';
import apiConnect from '../services/apiConnect';
import apiConnectManagement from '../services/apiConnectManagement';

const defaultMessage = (e = null, data = null) => ({
  success: e ? false : true,
  code: e ? e?.code || e?.response?.status : 200,
  message: e ? e?.message || e?.response?.statusText || e?.error : null,
  data: data || e?.response?.data || null,
});

const ManagementDefaultValue = {
  loading: {
    user: false,
    optionsClients: false,
    optionsProjectTypes: false,
    optionsStatus: false,
    listManagers: false,
    projects: false,
    project: false,
    stages: false,
    changeStatus: false,
    restartStageModule: false,
    predecessorSteps: false,
    changeModuleStatus: false,
    disableSku: false,
    listParentProjects: false,
    projectSkus: false,
    skuEditionReasons: false,
  },
  error: {
    user: null,
    optionsClients: null,
    optionsProjectTypes: null,
    optionsStatus: null,
    listManagers: null,
    projects: null,
    project: null,
    stages: null,
    changeStatus: null,
    restartStageModule: null,
    predecessorSteps: null,
    changeModuleStatus: null,
    listParentProjects: false,
    projectSkus: false,
    skuEditionReasons: false,
  },
  user: null,
  getUser: email => Promise.resolve(defaultMessage()),
  optionsClients: [],
  getOptionsClients: () => Promise.resolve(defaultMessage()),
  optionsProjectTypes: [],
  getOptionsProjectTypes: () => Promise.resolve(defaultMessage()),
  optionsStatus: [],
  getOptionsStatus: () => Promise.resolve(defaultMessage()),
  listManagers: [],
  getListManagers: () => Promise.resolve(defaultMessage()),
  projects: [],
  totalProjects: 0,
  pages: 1,
  currentPage: 1,
  getProjects: (page, filters) => () => Promise.resolve(defaultMessage()),
  project: null,
  getProject: (id, basicData) => () => Promise.resolve(defaultMessage()),
  stages: [],
  getStages: (moduleTypeId, moduleId) => Promise.resolve(defaultMessage()),
  projectId: null,
  setProjectId: id => null,
  selectedModuleId: null,
  setSelectedModuleId: id => null,
  selectedModule: null,
  setSelectedModule: module => null,
  reloadProjectAndModule: () => Promise.resolve(null),
  changeStatus: (projectModule, stageId, newModuleStatus, data) =>
    Promise.resolve(defaultMessage()),
  restartStageModule: (moduleType, projectModule, stageId, data) =>
    Promise.resolve(defaultMessage()),
  predecessorSteps: stageId => Promise.resolve(defaultMessage()),
  changeModuleStatus: (projectModule, status, data) =>
    Promise.resolve(defaultMessage()),
  setDisableSku: data => Promise.resolve(defaultMessage()),
  listParentProjects: [],
  getListParentProjects: name => Promise.resolve(defaultMessage()),
  getProjectSkus: projectId => Promise.resolve(defaultMessage()),
  getSkuEditionReasons: projectId => Promise.resolve(defaultMessage()),
};

const ManagementContext = createContext(ManagementDefaultValue);

export const ManagementContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(ManagementDefaultValue.loading);
  const [error, setError] = useState(ManagementDefaultValue.error);
  const [user, setUser] = useState(null);
  const [optionsClients, setOptionsClients] = useState([]);
  const [optionsProjectTypes, setOptionsProjectTypes] = useState([]);
  const [optionsStatus, setOptionsStatus] = useState([]);
  const [listManagers, setListManagers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [project, setProject] = useState(null);
  const [stages, setStages] = useState([]);
  const [projectId, setProjectId] = useState(null);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [listParentProjects, setListParentProjects] = useState([]);
  const [projectSkus, setProjectSkus] = useState([]);
  const [skuEditionReasons, setSkuEditionReasons] = useState([]);

  const getHeaders = (extras = null) => ({
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      ...(extras && { ...extras }),
    },
  });

  const getUser = async email => {
    if (!loading.user && email) {
      setLoading(l => ({
        ...l,
        user: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnect.get(`/users/?email=${email}`, headers);
        setUser(resp.data[0]);
        return defaultMessage(null, resp.data[0]);
      } catch (e) {
        console.log('error', e);
        setUser(null);
        setError(err => ({
          ...err,
          user: e.message || 'ocorreu um erro ao carregar users',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          user: false,
        }));
      }
    }
  };

  const getOptionsClients = async () => {
    if (!loading.optionsClients && user) {
      setLoading(l => ({
        ...l,
        optionsClients: true,
      }));
      try {
        const headers = getHeaders();
        if (
          user.department_name === 'VAREJISTA' ||
          user.department_name === 'NOVOS CLIENTES'
        ) {
          const res = await apiConnect.get(
            `/users/${user.id}/retailers`,
            headers
          );
          const options = res.data.map(r => ({
            label: r.retailer_name,
            value: r.retailer,
          }));
          setOptionsClients(options);
          return defaultMessage(null, options);
        } else {
          const res = await apiConnectManagement.get('/clients');
          const client = res.data.clients;
          // console.log('client', client);
          const options = client.map(ob => ({
            label: ob.client_name,
            value: ob.client_id,
          }));
          setOptionsClients(options);
          return defaultMessage(null, options);
        }
      } catch (e) {
        console.log('error', e);
        setOptionsClients([]);
        setError(err => ({
          ...err,
          optionsClients: e.message || 'ocorreu um erro ao carregar clientes',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          optionsClients: false,
        }));
      }
    }
  };

  const getOptionsProjectTypes = async () => {
    if (!loading.optionsProjectTypes) {
      setLoading(l => ({
        ...l,
        optionsProjectTypes: true,
      }));
      try {
        const resp = await apiConnectManagement.get('/project_types');
        const options = resp.data.project_types.map(pt => ({
          label: pt.name,
          value: pt.id,
        }));
        setOptionsProjectTypes(options);
        return defaultMessage(null, options);
      } catch (e) {
        console.log('error', e);
        setOptionsProjectTypes([]);
        setError(err => ({
          ...err,
          optionsProjectTypes: e.message || 'ocorreu um erro ao carregar tipo',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          optionsProjectTypes: false,
        }));
      }
    }
  };

  const getOptionsStatus = async () => {
    if (!loading.optionsStatus) {
      setLoading(l => ({
        ...l,
        optionsStatus: true,
      }));
      try {
        const resp = await apiConnectManagement.get('/statuses_project');
        const options = resp.data.statuses_projects.map(pt => ({
          label: pt.name,
          value: pt.id,
        }));
        setOptionsStatus(options);
        return defaultMessage(null, options);
      } catch (e) {
        console.log('error', e);
        setOptionsStatus([]);
        setError(err => ({
          ...err,
          optionsStatus: e.message || 'ocorreu um erro ao carregar tipo',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          optionsStatus: false,
        }));
      }
    }
  };

  const getListManagers = async () => {
    if (!loading.listManagers) {
      setLoading(l => ({
        ...l,
        listManagers: true,
      }));
      try {
        const resp = await apiConnectManagement.get('/managers_clients');
        setListManagers(resp.data.managers);
        return defaultMessage(null, resp.data.managers);
      } catch (e) {
        console.log('error', e);
        setListManagers([]);
        setError(err => ({
          ...err,
          listManagers: e.message || 'ocorreu um erro ao carregar responsavel',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          listManagers: false,
        }));
      }
    }
  };

  const getProjects = async (page = 1, filters = {}) => {
    if (!loading.projects && user) {
      setLoading(l => ({
        ...l,
        projects: true,
      }));
      try {
        let url = '';
        if (filters.project) {
          url = `/projects_by_name?page=${page}&name=${filters.project}`;
        } else {
          url = `/projects?page=${page}`;
        }
        if (filters.client) {
          url += `&client_id=${filters.client}`;
        } else if (
          !filters.client &&
          (user.department_name === 'VAREJISTA' ||
            user.department_name === 'NOVOS CLIENTES')
        ) {
          url += `&client_id=${optionsClients.map(o => o.value).join(',')}`;
        }
        if (filters.delivery_status) {
          url += `&delivery_status_id=${filters.delivery_status}`;
        }
        if (filters.project) {
          url += `&name=${filters.project}`;
        }
        if (filters.project_type) {
          url += `&project_type_id=${filters.project_type}`;
        }
        if (filters.status) {
          url += `&status_project_id=${filters.status}`;
        }
        if (filters.manager_user_id) {
          url += `&manager_user_id=${filters.manager_user_id}`;
        }
        if (filters.start_forecast_date) {
          url += `&start_forecast_date=${filters.start_forecast_date}`;
        }
        if (filters.launch_forecast_date) {
          url += `&launch_forecast_date=${filters.launch_forecast_date}`;
        }
        const resp = await apiConnectManagement.get(url);
        setProjects(resp.data.results);
        setCurrentPage(resp.data.current_page);
        setPages(resp.data.pages);
        setTotalProjects(resp.data.count);
        return defaultMessage(null, resp.data.results);
      } catch (e) {
        console.log('error', e);
        setProjects([]);
        setPages(1);
        setCurrentPage(1);
        setError(err => ({
          ...err,
          projects:
            e.message || e.error || 'ocorreu um erro ao carregar projetos',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          projects: false,
        }));
      }
    }
  };

  const getProject = async (id, basicData = false) => {
    if (!loading.project) {
      setLoading(l => ({
        ...l,
        project: true,
      }));
      try {
        const url = basicData ? `/project-basic-data/${id}` : `/project/${id}`;
        const { data } = await apiConnectManagement.get(url);
        setProject(data);
        return defaultMessage(null, data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          project:
            e.message ||
            e.error ||
            'Aconteceu um erro inesperado, tente novamente mais tarde!',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          project: false,
        }));
      }
    }
  };

  const getStages = async (moduleTypeId, moduleId) => {
    if (!loading.stages && moduleTypeId && moduleId) {
      setLoading(l => ({
        ...l,
        stages: true,
      }));
      try {
        const resp = await apiConnectManagement.get(
          `/module_type/${moduleTypeId}/project_module/${moduleId}/show_module_data`
        );
        setStages(resp.data.module_stages);
        return defaultMessage(null, resp.data.module_stages);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          stages:
            e.message ||
            e.error ||
            'Aconteceu um erro inesperado, tente novamente mais tarde!',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          stages: false,
        }));
      }
    }
  };

  const reloadProjectAndModule = async () => {
    window.location.reload();
    // if (!loading.project && !loading.stages && projectId && selectedModuleId) {
    //   setProject(null);
    //   setStages([]);
    //   await sleep(800);
    //   const pj = await getProject(projectId);
    //   if (pj.success) {
    //     const md = pj.data.modules.find(m => m.id === Number(selectedModuleId));
    //     await getStages(md.module_type.id, md.id);
    //   }
    // }
  };

  const changeStatus = async (
    moduleType,
    projectModule,
    stageId,
    newModuleStatus,
    data
  ) => {
    if (
      !loading.changeStatus &&
      projectModule &&
      stageId &&
      newModuleStatus &&
      data
    ) {
      setLoading(l => ({
        ...l,
        changeStatus: true,
      }));
      try {
        // const resp = await apiConnectManagement.put(
        //   `/module_type/${moduleType}/project_module/${projectModule}/stage/${stageId}/toggle_status`,
        //   data
        // );
        const resp = await apiConnectManagement.put(
          `/module_type/${moduleType}/module/${projectModule}/stage_module_id/${stageId}/new_module_status/${newModuleStatus}`,
          data
        );
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          changeStatus:
            e.message ||
            e.error ||
            'Aconteceu um erro inesperado, tente novamente mais tarde!',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          changeStatus: false,
        }));
      }
    }
  };

  const restartStageModule = async (
    moduleType,
    projectModule,
    stageId,
    data
  ) => {
    if (
      !loading.restartStageModule &&
      moduleType &&
      projectModule &&
      stageId &&
      data
    ) {
      setLoading(l => ({
        ...l,
        restartStageModule: true,
      }));
      try {
        const resp = await apiConnectManagement.put(
          `/module_type/${moduleType}/project_module/${projectModule}/stage/${stageId}/restart_stage_module`,
          data
        );
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          restartStageModule:
            e.message ||
            e.error ||
            'Aconteceu um erro inesperado, tente novamente mais tarde!',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          restartStageModule: false,
        }));
      }
    }
  };

  const getPredecessorSteps = async stageId => {
    if (!loading.predecessorSteps && stageId) {
      setLoading(l => ({
        ...l,
        predecessorSteps: true,
      }));
      try {
        const resp = await apiConnectManagement.get(
          `project_module_prodecessora/${stageId}`
        );
        setError(err => ({
          ...err,
          predecessorSteps: null,
        }));
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          predecessorSteps:
            e.message ||
            e.error ||
            'Aconteceu um erro inesperado, tente novamente mais tarde!',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          predecessorSteps: false,
        }));
      }
    }
  };

  const changeModuleStatus = async (
    moduleType,
    projectModule,
    status,
    data
  ) => {
    if (!loading.changeModuleStatus && projectModule && status && data) {
      setLoading(l => ({
        ...l,
        changeModuleStatus: true,
      }));
      try {
        // const resp = await apiConnectManagement.put(
        //   `/module_type/${moduleType}/project_module/${projectModule}/change_module_status/${status}`,
        //   data
        // );
        const resp = await apiConnectManagement.put(
          `/module_type/${moduleType}/module/${projectModule}/new_module_status/${status}`,
          data
        );
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          changeModuleStatus:
            e.message ||
            e.error ||
            'Aconteceu um erro inesperado, tente novamente mais tarde!',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          changeModuleStatus: false,
        }));
      }
    }
  };

  const setDisableSku = async data => {
    if (!loading.disableSku && data) {
      setLoading(l => ({
        ...l,
        disableSku: true,
      }));
      try {
        const resp = await apiConnectManagement.post(`/disable_sku`, data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          disableSku:
            e.message ||
            e.error ||
            'Aconteceu um erro inesperado, tente novamente mais tarde!',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          disableSku: false,
        }));
      }
    }
  };

  const getListParentProjects = async (name = '') => {
    if (!loading.listParentProjects) {
      setLoading(l => ({
        ...l,
        listManagers: true,
      }));
      try {
        const resp = await apiConnectManagement.get(
          `/list-parent-projects?name=${name}`
        );
        setListParentProjects(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setListParentProjects([]);
        setError(err => ({
          ...err,
          listParentProjects:
            e.message || 'ocorreu um erro ao carregar projetos pais',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          listParentProjects: false,
        }));
      }
    }
  };

  const getProjectSkus = async projectId => {
    if (!loading.projectSkus) {
      setLoading(l => ({
        ...l,
        projectSkus: true,
      }));
      try {
        const resp = await apiConnectManagement.get(
          `/project-skus/${projectId}`
        );
        setProjectSkus(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setListParentProjects([]);
        setError(err => ({
          ...err,
          projectSkus: e.message || 'ocorreu um erro ao carregar projetos pais',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          projectSkus: false,
        }));
      }
    }
  };

  const getSkuEditionReasons = async projectId => {
    if (!loading.projectSkus) {
      setLoading(l => ({
        ...l,
        skuEditionReasons: true,
      }));
      try {
        const resp = await apiConnectManagement.get(
          `/sku-edition-reasons/${projectId}`
        );
        setSkuEditionReasons(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setListParentProjects([]);
        setError(err => ({
          ...err,
          skuEditionReasons:
            e.message || 'ocorreu um erro ao carregar projetos pais',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          skuEditionReasons: false,
        }));
      }
    }
  };

  return (
    <ManagementContext.Provider
      value={{
        loading,
        error,
        user,
        getUser,
        optionsClients,
        getOptionsClients,
        optionsProjectTypes,
        getOptionsProjectTypes,
        optionsStatus,
        getOptionsStatus,
        listManagers,
        getListManagers,
        projects,
        totalProjects,
        pages,
        currentPage,
        getProjects,
        project,
        getProject,
        stages,
        getStages,
        projectId,
        setProjectId,
        selectedModuleId,
        setSelectedModuleId,
        selectedModule,
        setSelectedModule,
        reloadProjectAndModule,
        changeStatus,
        restartStageModule,
        getPredecessorSteps,
        changeModuleStatus,
        setDisableSku,
        listParentProjects,
        getListParentProjects,
        getProjectSkus,
        projectSkus,
        getSkuEditionReasons,
        skuEditionReasons,
      }}>
      {children}
    </ManagementContext.Provider>
  );
};

export const useManagementContext = () => React.useContext(ManagementContext);
