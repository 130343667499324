export default function floatMask(value, toNumber = false) {
  if (value !== null && value !== undefined) {
    if (!/[,.]/.test(value)) {
      return String(value).replace(/[^\d]/g, '');
    } else {
      const [last, ...others] = String(value) // converte qualquer valor p/ string
        .replace(/,/g, '.') // substitui qualquer virgula por ponto
        .replace(/[^\d.]/g, '') // remove outros caracteres nao numericos
        .split('.') // separa pelo ponto
        .reverse(); // inverte a ordem para separar o ultimo conjunto
      const cleared = `${others.reverse().join('')}.${last}`;
      return toNumber ? Number(cleared) : cleared;
    }
  }
  return '';
}
