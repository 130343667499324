/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Modal, ModalBody, UncontrolledTooltip } from 'reactstrap';
import './ModalDetailSkus.scss';
import { AiOutlineCloseCircle, AiOutlineEye } from 'react-icons/ai';
import SkuChangeRecordModal from '../ModalSkuChangeRecord/ModalSkuChangeRecord';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';
import { classNames } from '../../../../../utils/utils';

const ModalDetailSkus = ({ projectId, projectSkus }, ref) => {
  const modalSkuChangeRecord = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const { getSkuEditionReasons, skuEditionReasons } = useManagementContext();

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  useEffect(() => {
    if (projectId) {
      getSkuEditionReasons(projectId);
    }
  }, [projectId]);

  return (
    <Modal
      backdrop="static"
      isOpen={showModal}
      toggle={toggleModal}
      centered
      size="lg"
      className="details-sku-modal">
      <div className="modal-header-sku">
        <div>
          <p className="title-modal-details-skus">SKUs</p>
          <p className="subtitle-modal-details-skus">
            Quantidade no projeto: {projectSkus?.length}
          </p>
        </div>
        <button type="button" className="button-close" onClick={toggleModal}>
          <AiOutlineCloseCircle />
        </button>
      </div>
      <div className="div-change-record">
        <button
          type="button"
          disabled={skuEditionReasons?.length === 0}
          className={classNames({
            linkChangeRecord: true,
            changeRecordOrange: skuEditionReasons?.length > 0,
            changeRecordGray: skuEditionReasons?.length === 0,
          })}
          onClick={() => modalSkuChangeRecord.current.toggleModal()}>
          <AiOutlineEye />
          Registro de alterações
        </button>
      </div>
      <ModalBody>
        <div className="form-edit-sku">
          {projectSkus?.map(sku => (
            <div className="line-sku" key={`sku-${sku.id}`}>
              <div
                className="sku"
                id={`product-${sku.id}`}
                type="text"
                disabled>
                {sku?.product}
              </div>
              <div className="sku" type="text" disabled>
                {sku?.variant}
              </div>
              <div className="sku" type="text" disabled>
                {sku?.package}
              </div>
              <div className="sku" type="text" disabled>
                {sku?.weight}
              </div>
              <div className="sku" type="text" disabled>
                {sku?.ean || 'EAN'}
              </div>
              {!sku?.available && (
                <span className="sku-cancelled">Cancelado</span>
              )}
              <UncontrolledTooltip placement="top" target={`product-${sku.id}`}>
                {sku?.product}
              </UncontrolledTooltip>
            </div>
          ))}
        </div>
        <div className="btn-save-sku">
          <button type="button" onClick={toggleModal}>
            Fechar
          </button>
        </div>
      </ModalBody>
      <SkuChangeRecordModal
        ref={modalSkuChangeRecord}
        data={skuEditionReasons}
      />
    </Modal>
  );
};

export default forwardRef(ModalDetailSkus);
