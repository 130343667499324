/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
// import ReactGA from 'react-ga';
import HeaderModule from '../../../../Components/HeaderModule/HeaderModule';
import './BriefingList.scss';
import {
  //DateRangePicker,
  SelectPicker,
  Input,
  Alert,
  Row,
  Col,
  Button,
} from 'rsuite';
import TableList from '../../../../Components/TableList/TableList';
import Pagination from '../../../../Components/Pagination/Pagination';
import IconBriefing from '../../../../Assets/briefing-icon.png';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Link } from 'react-router-dom';
import apiConnect from '../../../../services/apiConnect';
import { useCognitoAuth } from '../../../../Contexts/AuthCognitoContext';
import useLocalStorage from '../../../../utils/hooks/useLocalStorage';
import ModalDownloadDoc from './ModalDownloadDoc/ModalDownloadDoc';

// const pgSize = 20; // seta o valor aqui pq o back não esta enviando esse valor

function BriefingList() {
  // ReactGA.pageview('/pdm/briefing');

  const { user } = useCognitoAuth();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [retailers, setRetailers] = useState([]);
  const [valuesFilter, setValuesFilter] = useLocalStorage('briefing-filters', {
    dates: [],
    retailer: '',
    status: '',
    typeProject: '',
    name: '',
  });

  const [briefing, setBriefing] = useState([]);
  const [departmentName, setDepartmentName] = useState('');
  const [info, setInfo] = useState([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [userId, setUserId] = useState(null);
  const emailUser = user?.attributes?.email;

  const modalDownloadDocRef = useRef(null);

  const loadDepartment = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiConnect.get(`/users/?email=${emailUser}`, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        },
      });
      if (response) {
        // console.log('loadDepartment', response.data);
        setDepartmentName(response.data[0].department_name);
        setUserId(response.data[0]);
      }
    } catch (error) {
      console.log('error', error);
      Alert.error('Ocorreu um erro ao carregar o perfil do usuário!', 10000);
    }
  }, [emailUser]);

  const loadRetailers = useCallback(async () => {
    try {
      let url = '';
      if (
        departmentName === 'VAREJISTA' ||
        departmentName === 'NOVOS CLIENTES'
      ) {
        url = `/users/${userId.id}/retailers`;
      } else {
        url = '/retailers';
      }

      const response = await apiConnect.get(url, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        },
      });
      // console.log('loadRetailers', response.data);
      const arrayRetailers = response.data;

      if (
        departmentName === 'VAREJISTA' ||
        departmentName === 'NOVOS CLIENTES'
      ) {
        for (let i = 0; i < arrayRetailers.length; i++) {
          arrayRetailers[i].value = arrayRetailers[i]['retailer'];
          arrayRetailers[i].label = arrayRetailers[i]['retailer_name'];
        }
      } else {
        for (let i = 0; i < arrayRetailers.length; i++) {
          arrayRetailers[i].value = arrayRetailers[i]['id'];
          arrayRetailers[i].label = arrayRetailers[i]['name'];
        }
      }
      setRetailers(arrayRetailers);
    } catch (error) {
      console.log('error', error);
      Alert.error('Ocorreu um erro ao carregar a lista de clientes!', 10000);
    }
  }, [departmentName, userId]);

  const returnSearch = (page, clear = false) => {
    if (departmentName && retailers.length > 0) {
      setLoading(true);
      let queryParams = '';
      if (valuesFilter.retailer && !clear) {
        queryParams += `&retailer=${valuesFilter.retailer}`;
      } else if (
        !valuesFilter.retailer &&
        (departmentName === 'VAREJISTA' || departmentName === 'NOVOS CLIENTES')
      ) {
        queryParams += `&retailer=${retailers.map(r => r.value).join(',')}`;
      }
      if (valuesFilter.dates && valuesFilter.dates[0]) {
        queryParams += `&start_date=${convert(
          valuesFilter.dates[0].toString()
        )}`;
      }
      if (valuesFilter.dates && valuesFilter.dates[1]) {
        queryParams += `&end_date=${convert(valuesFilter.dates[1].toString())}`;
      }
      if (valuesFilter.status) {
        queryParams += `&status_code=${valuesFilter.status}`;
      }
      if (valuesFilter.typeProject) {
        queryParams += `&project_type=${valuesFilter.typeProject}`;
      }
      if (valuesFilter.name) {
        queryParams += `&briefing_name=${valuesFilter.name}`;
      }

      let url = '/briefings/';
      if (page) {
        url += `?page=${page}`;
      } else {
        url += `?page=1`;
      }
      url += queryParams;
      apiConnect
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
          },
        })
        .then(response => {
          if (response?.data) {
            setInfo(response.data);
            setCurrentPage(page);
            setBriefing(response.data.results);
            if (page === 1) {
              setPages(
                response.data.pages ||
                  Math.ceil(response.data.count / response.data.results.length)
              );
            }
          }
        })
        .catch(error => {
          if (error?.response?.status === 403) {
            Alert.error(error.response.data.message, 50000);
          } else if (error?.response?.status === 400) {
            Alert.error(error.response.data.error, 50000);
          } else {
            Alert.error(
              'Aconteceu um erro inesperado, tente novamente mais tarde!'
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  function cleanFilters() {
    setValuesFilter({
      dates: [],
      retailer: '',
      status: '',
      typeProject: '',
      name: '',
    });
    setDisabled(true);
    setLoading(true);
    let queryParams = '?page=1';
    if (departmentName === 'VAREJISTA' || departmentName === 'NOVOS CLIENTES') {
      queryParams += `&retailer=${retailers.map(r => r.value).join(',')}`;
    }
    apiConnect
      .get(`/briefings/${queryParams}`, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        },
      })
      .then(response => {
        if (response?.data) {
          setInfo(response.data);
          setCurrentPage(1);
          setPages(
            response.data.pages ||
              Math.ceil(response.data.count / response.data.results.length)
          );
          setBriefing(response.data.results);
        }
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else if (error?.response?.status === 400) {
          Alert.error(error.response.data.error, 50000);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado, tente novamente mais tarde!'
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleBriefings(e) {
    e.preventDefault();
    returnSearch(1);
  }

  const handlePagination = page => {
    returnSearch(page);
  };

  useEffect(() => {
    if (
      valuesFilter?.dates.length > 0 ||
      valuesFilter?.retailer ||
      valuesFilter?.status ||
      valuesFilter?.typeProject ||
      valuesFilter?.name
    ) {
      setDisabled(false);
    }
  }, []);

  useEffect(() => {
    loadDepartment();
  }, [loadDepartment]);

  useEffect(() => {
    if (departmentName && userId) {
      loadRetailers();
    }
  }, [departmentName, userId, loadRetailers]);

  useEffect(() => {
    if (retailers.length > 0) returnSearch(1);
  }, [retailers]);

  const dataStatus = [
    {
      label: 'Iniciado',
      value: 'I',
      role: 'Iniciado',
    },
    {
      label: 'Em Andamento',
      value: 'A',
      role: 'Em Andamento',
    },
    {
      label: 'Finalizado',
      value: 'F',
      role: 'Finalizado',
    },
    {
      label: 'Stand By',
      value: 'E',
      role: 'Stand By',
    },
    {
      label: 'Cancelado',
      value: 'C',
      role: 'Cancelado',
    },
    // {
    //   label: 'Pendente de Finalização',
    //   value: 'N',
    //   role: 'Pendente de Finalização',
    // },
  ];

  const dataTypeProject = [
    {
      label: 'Novo Produto',
      value: '1',
      role: 'Novo Produto',
    },
    {
      label: 'Nova Carta Gráfica',
      value: '2',
      role: 'Nova Carta Gráfica',
    },
    {
      label: 'Reformulação',
      value: '3',
      role: 'Reformulação',
    },
    {
      label: 'Ext. de Linha',
      value: '4',
      role: 'Ext. de Linha',
    },
    {
      label: 'Ext. de Linha Outro Fornecedor',
      value: '5',
      role: 'Ext. de Linha Outro Fornecedor',
    },
    {
      label: 'Troca de fornecedor',
      value: '6',
      role: 'Troca de fornecedor',
    },
  ];

  function convert(str) {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  function convertToBr(str) {
    const date = new Date(str);
    return date.toLocaleDateString('pt-Br');
  }

  const projectByType = ({ project_type }) => {
    const types = {
      1: 'Novo Produto',
      2: 'Nova Carta Gráfica',
      3: 'Reformulação',
      4: 'Extensão de Linha',
      5: 'Extensão de Linha Outro Fornecedor',
      6: 'Troca de Fornecedor',
    };
    return types[project_type] || '';
  };

  const statusByCode = ({ status_code }) => {
    const status = {
      I: 'Iniciado',
      A: 'Em Andamento',
      F: 'Finalizado',
      E: 'Stand By',
      C: 'Cancelado',
    };
    return status[status_code] || 'error';
  };

  const stageByCode = ({ stage_code }) => {
    const stages = {
      R: 'Rascunho',
      G: 'Em Validação do Gestor',
      D: 'Em Validação do DTI',
      E: 'Enviado para Sourcing',
      A: 'Analisado pelo Sourcing',
      V: 'Validação pelo Gestor',
      P: 'Em Prospecção',
      H: 'Pendente de Envio',
      B: 'Pronto para Prospecção',
      C: 'Em Prospecção (Cliente)',
      N: 'Pendente de Finalização',
      F: 'Finalizado',
      S: 'Aguardando seleção de Fornecedor',
    };
    return stages[stage_code] || 'error';
  };

  const linkBriefing = ({ id, project_type }) => (
    <Link
      className="linkBriefing"
      to={
        id.toString() + (project_type !== 1 ? `/?newType=${project_type}` : '')
      }
      id={id}>
      <img src={IconBriefing} alt="Briefing" />
      <p>Briefing</p>
    </Link>
  );

  const columHeaderTable = [
    { retailerName: 'CLIENTE' },
    { name: 'PROJETO' },
    { projectType: 'TIPO DE PROJETO' },
    { createdAt: 'DATA' },
    { status: 'STATUS' },
    { stage: 'ETAPA' },
    { retailerManager: 'RESPONSÁVEL DO PROJETO' },
    { dtiUser: 'RESPONSÁVEL DTI' },
    { itemsCount: 'QUANTIDADE SKU' },
    { action: '' },
  ];

  const listTable = useMemo(() => {
    if (briefing.length > 0) {
      return briefing
        .filter(b => b.available)
        .map(b => ({
          retailerName: b.retailer_name,
          name: b.full_name,
          projectType: projectByType(b),
          createdAt: convertToBr(b.created_at),
          status: statusByCode(b),
          stage: stageByCode(b),
          retailerManager: b.retailer_manager_user_name,
          dtiUser: b.dti_user_name,
          itemsCount: b.items_count,
          action: linkBriefing(b),
        }));
    }
    return [];
  }, [briefing]);

  /*const toDates = dates => {
    if (dates[0] && typeof dates[0] === 'string') {
      return [new Date(dates[0]), new Date(dates[1])];
    }
    return dates;
  };*/

  return (
    <>
      <HeaderModule classHeader="pdmHeaderModule" afterText="BRIEFING">
        VISÃO DE BRIEFING
      </HeaderModule>

      <Row className="mainDiv2">
        <Col md={4}>
          <h2 className="countProject">
            Contagem total
            <br />
            de projeto: <span className="projectsNumber">{info.count}</span>
          </h2>
        </Col>
        <Col md={3}>
          <h2 className="countProject" style={{ marginLeft: '-55px' }}>
            Total Skus: <br />
            <span className="projectsNumber">{info.total_sku}</span>
          </h2>
        </Col>
        <Col md={4}>
          <button
            onClick={() => modalDownloadDocRef.current?.toggle()}
            className="buttonBlue"
            style={{ marginLeft: '-100px' }}>
            Documentos de Briefing
          </button>
        </Col>
        {/*
          <Col md={4}>
            <DateRangePicker
              format="DD/MM/YYYY"
              placeholder="DD/MM/YYYY - DD/MM/YYYY"
              onChange={(value, ev) => {
                setValuesFilter({
                  ...valuesFilter,
                  dates: value,
                });
                setDisabled(false);
              }}
              value={toDates(valuesFilter.dates)}
              name="dateRangeInput"
              locale={{
                sunday: 'D',
                monday: 'S',
                tuesday: 'T',
                wednesday: 'Q',
                thursday: 'Q',
                friday: 'S',
                saturday: 'S',
                ok: 'OK',
                today: 'Hoje',
                yesterday: 'Ontem',
                hours: 'Horas',
                minutes: 'Minutos',
                seconds: 'Segundos',
              }}
            />
          </Col>
            */}
        <Col md={2}>
          <SelectPicker
            className="border-0"
            data={retailers}
            onChange={(value, event) => {
              setValuesFilter({
                ...valuesFilter,
                retailer: value,
              });
              setDisabled(false);
            }}
            value={valuesFilter.retailer}
            appearance="default"
            placeholder="Cliente"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
          />
        </Col>
        <Col md={2}>
          <SelectPicker
            className="border-0"
            data={dataStatus}
            onChange={(value, event) => {
              setValuesFilter({
                ...valuesFilter,
                status: value,
              });
              setDisabled(false);
            }}
            value={valuesFilter.status}
            appearance="default"
            placeholder="Status"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
            searchable={false}
          />
        </Col>
        <Col md={3}>
          <SelectPicker
            className="border-0"
            data={dataTypeProject}
            onChange={(value, event) => {
              setValuesFilter({
                ...valuesFilter,
                typeProject: value,
              });
              setDisabled(false);
            }}
            value={valuesFilter.typeProject}
            appearance="default"
            placeholder="Tipo de projeto"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
            searchable={false}
          />
        </Col>
        <Col md={2}>
          <Input
            placeholder="Projeto.."
            value={valuesFilter.name}
            onChange={(value, event) => {
              setValuesFilter({
                ...valuesFilter,
                name: value,
              });
              setDisabled(false);
            }}
            className="textInput"
          />
        </Col>
        <Col md={2}>
          <Button
            disabled={disabled || loading}
            style={{ minHeight: 38 }}
            onClick={handleBriefings}
            color="blue"
            block>
            Filtrar
          </Button>
        </Col>
        <Col md={2}>
          <Button
            disabled={disabled || loading}
            style={{ minHeight: 38 }}
            onClick={cleanFilters}
            // color="grey"
            block>
            Limpar
          </Button>
        </Col>
      </Row>

      <TableList
        columns={columHeaderTable}
        lines={listTable}
        loading={loading}
      />

      {!loading && (
        <div style={{ margin: '0 15px' }}>
          <Pagination
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={handlePagination}
            loading={loading}
          />
        </div>
      )}
      <ModalDownloadDoc ref={modalDownloadDocRef} />
    </>
  );
}

export default BriefingList;
