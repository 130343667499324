import React, { useState, forwardRef, useImperativeHandle } from 'react';
import InputText from '../../../../../Components/InputText/InputText';
import moment from 'moment';
import { classNames } from '../../../../../utils/utils';
import 'moment/locale/pt-br';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { Alert } from 'rsuite';
import ButtonDefault from '../../../../../Components/ButtonDefault/ButtonDefault';
import './ModalModuleReopening.scss';
// import apiConnectManagement from '../../../../../services/apiConnectManagement';
import { useCognitoAuth } from '../../../../../Contexts/AuthCognitoContext';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ModalModuleReopening = (
  {
    moduleId = null,
    moduleTypeId = null,
    moduleName = null,
    displayOnly = false,
    displayObs = null,
    displayDate = null,
    project,
    setProject = () => {},
    setModuleStatus = () => {},
    onSubmit = () => {},
  },
  ref
) => {
  const now = new Date();
  const { changeModuleStatus, reloadProjectAndModule } = useManagementContext();
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const { user } = useCognitoAuth();
  const validationSchema = Yup.object({
    obs: Yup.string()
      .max(500, ' Limite de 500 caracteres')
      .required('Campo Obrigatório'),
  });

  const { handleSubmit, handleChange, values, errors, resetForm } = useFormik({
    initialValues: {
      obs: '',
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      onHandleConfirm(values);
    },
  });

  const onHandleConfirm = async values => {
    setLoadingConfirm(true);
    const body = {
      user: user,
      obs: values.obs,
      reopen: true,
    };
    const resp = await changeModuleStatus(moduleTypeId, moduleId, 2, body);
    setLoadingConfirm(false);
    if (resp?.success) {
      onHandleUpdateModules(resp.data?.project_module);
      setModuleStatus(resp.data?.project_module.status_module);
      onSubmit();
      closeModal();
      resetForm();
      reloadProjectAndModule();
    } else {
      Alert.error(
        resp.message ||
          'Aconteceu um erro inesperado, tente novamente mais tarde!',
        50000
      );
    }
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  function closeModal() {
    setShowModal(!showModal);
  }

  const onHandleUpdateModules = projectModule => {
    let tempProject = { ...project };
    const moduleIndex = tempProject.modules.findIndex(moduleObj => {
      return moduleObj.id === projectModule.id;
    });
    tempProject.modules[moduleIndex] = projectModule;
    setProject(tempProject);
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={false}
      centered={true}
      className="custom-modal">
      <ModalHeader>
        <Row>
          <Col md={1} className="icon-head-reopen">
            <svg width="43" height="43.003" viewBox="0 0 40 40.003">
              <defs>
                {/* <linearGradient
                  id="linear-gradient"
                  x1="0.5"
                  x2="0.5"
                  y2="1"
                  gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#699bc5" />
                  <stop offset="1" stop-color="#f39332" />
                </linearGradient> */}
              </defs>
              <path
                id="issuereopened"
                d="M37.529,22.5a2.469,2.469,0,0,1-2.5-2.5,14.688,14.688,0,0,0-2.012-7.52,14.893,14.893,0,0,0-5.469-5.469A14.688,14.688,0,0,0,20.028,5,14.559,14.559,0,0,0,10.3,8.593l2.227,2.227a1.621,1.621,0,0,1-.488,1.191,1.576,1.576,0,0,1-1.152.488H4.168a1.576,1.576,0,0,1-1.152-.488,1.623,1.623,0,0,1-.489-1.19V4.18a1.621,1.621,0,0,1,.488-1.191A1.576,1.576,0,0,1,4.167,2.5L6.745,5.079A19.9,19.9,0,0,1,27.8,1.582,19.569,19.569,0,0,1,38.445,12.226,19.623,19.623,0,0,1,40.027,20a2.469,2.469,0,0,1-2.5,2.5ZM21.278,25h-2.5a1.269,1.269,0,0,1-1.25-1.25v-15a1.269,1.269,0,0,1,1.25-1.25h2.5a1.269,1.269,0,0,1,1.25,1.25v15A1.269,1.269,0,0,1,21.278,25Zm1.25,6.251a1.269,1.269,0,0,1-1.25,1.25h-2.5a1.269,1.269,0,0,1-1.25-1.25v-2.5a1.269,1.269,0,0,1,1.25-1.25h2.5a1.269,1.269,0,0,1,1.25,1.25ZM5.027,20a14.688,14.688,0,0,0,2.012,7.52,14.893,14.893,0,0,0,5.469,5.469A14.688,14.688,0,0,0,20.028,35a14.559,14.559,0,0,0,9.727-3.594l-2.227-2.227a1.621,1.621,0,0,1,.488-1.191,1.576,1.576,0,0,1,1.152-.488h6.719a1.576,1.576,0,0,1,1.152.488,1.621,1.621,0,0,1,.488,1.191v6.641a1.621,1.621,0,0,1-.488,1.191,1.576,1.576,0,0,1-1.152.488l-2.578-2.578a19.9,19.9,0,0,1-21.056,3.5A19.569,19.569,0,0,1,1.609,27.777,19.631,19.631,0,0,1,.027,20a2.4,2.4,0,0,1,.742-1.757A2.4,2.4,0,0,1,2.526,17.5a2.4,2.4,0,0,1,1.757.742A2.4,2.4,0,0,1,5.025,20Z"
                transform="translate(-0.027)"
                // fill="url(#linear-gradient)"
              />
            </svg>
          </Col>
          <Col md={7} className="texts-modal">
            <h2 className="text-modal-reopening">Reabertura do módulo</h2>
            <h3 className="sub-text-reopening">{moduleName}</h3>
          </Col>
          <Col md={4} className="date-modal">
            {displayOnly ? (
              <>
                {moment(displayDate).format('DD/MM/YYYY')}{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {moment(displayDate).format('dddd').split('-')[0]}{' '}
                </span>{' '}
                {moment(displayDate).format('HH:mm:ss').toUpperCase()}
              </>
            ) : (
              <>
                {moment(now).format('DD/MM/YYYY')}{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {moment(now).format('dddd').split('-')[0]}{' '}
                </span>{' '}
                {moment(now).format('HH:mm')}
              </>
            )}
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {!displayOnly && (
          <p>
            Você está reabrindo um módulo. Qualquer reabertura de etapa terá os
            dias contabilizados com os que já foram realizados. Por favor,
            preencha a observação dos motivos de reabertura para continuar.
            Esperamos que corra tudo bem dessa vez!
          </p>
        )}
        <section className="custom-input-component">
          <label className="text-observation">Observações</label>
          <InputText
            maxLength={500}
            readOnly={displayOnly}
            value={displayOnly ? displayObs : values.obs}
            type="textarea"
            onChange={handleChange('obs')}
            className={classNames({
              'error-input': Boolean(errors.obs),
            })}
            placeholder="Ex: O etapa foi reaberta por conta de um erro na documentação enviada anteriormente. Nova é a correta."
          />
          <small className="text-limit">Limite de 500 caracteres</small>
          <br />
          <span className="error">{errors.obs ? errors.obs : null}</span>
        </section>
      </ModalBody>
      {displayOnly ? (
        <ModalFooter>
          <ButtonDefault color="blue" radius onClick={closeModal}>
            Fechar
          </ButtonDefault>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <ButtonDefault
            color="red"
            radius
            onClick={() => {
              closeModal();
              resetForm();
            }}>
            Cancelar
          </ButtonDefault>
          <ButtonDefault
            disabled
            loading={loadingConfirm}
            onClick={handleSubmit}
            color="green"
            radius>
            Confirmar
          </ButtonDefault>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default forwardRef(ModalModuleReopening);
