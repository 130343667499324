import React from 'react';
import './Header.scss';
import { classNames } from '../../utils/utils';

// se fixed for passado, o cabecalho ficara fixado no topo
const Header = ({
  children,
  fixed,
  center,
  bgColor = '#fff',
  txtColor = '#425ba6',
  subTxt = '', //Sub texto de teste
}) => {
  return (
    <header
      className={classNames({
        header: true,
        fixed: !!fixed,
        center: !!center,
      })}
      style={{
        backgroundColor: bgColor,
      }}>
      <h2 style={{ color: txtColor }}>{children}</h2>
      {subTxt && <h3 style={{ color: txtColor }}>{subTxt}</h3>}
    </header>
  );
};

export default Header;
