/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import HeaderModule from '../../../../Components/HeaderModule/HeaderModule';
import styles from './SourcingList.module.css';
import Moment from 'moment';
import Pagination from '../../../../Components/Pagination/Pagination';
import { SelectPicker, Input, Row, Col, Button, Alert } from 'rsuite';
import TableList from '../../../../Components/TableList/TableList';
import apiConnect from '../../../../services/apiConnect';
import apiConnectSourcing from '../../../../services/apiConnectSourcing';
import { useCognitoAuth } from '../../../../Contexts/AuthCognitoContext';
import useLocalStorage from '../../../../utils/hooks/useLocalStorage';

// const pgSize = 10;

const SourcingList = () => {
  const { user } = useCognitoAuth();
  const emailUser = user?.attributes?.email;
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [valuesFilter, setValuesFilter] = useLocalStorage('sourcing-filters', {
    search: '',
    type: '',
    retailer: '',
    dateInterval: '',
  });

  const [source, setSource] = useState([]);
  const [sourceCount, setSourceCount] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);

  const [retailers, setRetailers] = useState([]);
  const [briefingTypes, setBriefingTypes] = useState([]);

  const intervals = [
    {
      label: 'Total',
      value: '',
    },
    {
      label: 'Últimos 7 dias',
      value: '7',
    },
    {
      label: 'Último mês',
      value: '30',
    },
  ];

  function getRetailers() {
    setLoading(true);
    apiConnect
      .get(`/retailers/`, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        },
      })
      .then(ret => {
        // console.log('retailers', ret.data);
        setRetailers(
          ret.data
            .filter(r => r.available)
            .map(r => ({
              label: r.name,
              value: r.id,
            }))
        );
      })
      .catch(e => {
        console.log(e);
        setRetailers([]);
      });
  }

  function getBriefingTypes() {
    apiConnectSourcing
      .get(`/project-types/`, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        },
      })
      .then(ret => {
        setBriefingTypes(
          ret.data.map(b => ({
            label: b.name,
            value: b.id,
          }))
        );
      })
      .catch(e => {
        console.log(e);
        setBriefingTypes([]);
      });
  }

  async function postEnviar(el) {
    apiConnect
      .post(
        `/briefing/sourcing/manager-validation/`,
        {
          briefing: el.id,
          current_user: emailUser,
        },
        {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
          },
        }
      )
      .then(() => {
        returnSearch(currentPage);
      });
  }

  useEffect(() => {
    getRetailers();
    getBriefingTypes();
    returnSearch();
    if (
      valuesFilter?.search ||
      valuesFilter?.type ||
      valuesFilter?.retailer ||
      valuesFilter?.dateInterval
    ) {
      setDisabled(false);
    }
  }, []);

  function getButtonText(code) {
    const codeList = {
      V: 'Aguardando aprovação',
      P: 'Aprovado',
    };
    return codeList[code] || 'Enviar';
  }

  function getBtnClass(code) {
    const listCodes = {
      E: styles.btnDisabledSend,
      A: styles.btnGreen,
      V: styles.btnBlue,
      P: styles.btnBlue,
    };
    return listCodes[code] || styles.btnDisabledSend;
  }

  function handleClick(el) {
    return e => {
      e.preventDefault();
      postEnviar(el);
    };
  }

  const returnSearch = (page = 1) => {
    setLoading(true);
    let url = `/briefing/sourcing?page=${page}`;
    if (valuesFilter.dateInterval) {
      url += `&date_interval=${valuesFilter.dateInterval}`;
    }
    if (valuesFilter.retailer) {
      url += `&retailer=${valuesFilter.retailer}`;
    }
    if (valuesFilter.type) {
      url += `&project_type=${valuesFilter.type}`;
    }
    if (valuesFilter.search) {
      url += `&search=${valuesFilter.search}`;
    }

    apiConnect
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        },
      })
      .then(response => {
        if (response?.data) {
          setSource(response.data.results);
          setSourceCount(response.data.count);
          setCurrentPage(page || 1);
          if (page === 1) {
            setPages(
              response.data.pages ||
                Math.ceil(response.data.count / response.data.results.length)
            );
          }
        }
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else if (error?.response?.status === 400) {
          Alert.error(error.response.data.error, 50000);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado, tente novamente mais tarde!'
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClear = () => {
    setLoading(true);
    setDisabled(true);
    setValuesFilter({
      search: '',
      type: '',
      retailer: '',
      dateInterval: '',
    });
    apiConnect
      .get('/briefing/sourcing?page=1', {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        },
      })
      .then(response => {
        if (response?.data) {
          setSource(response.data.results);
          setSourceCount(response.data.count);
          setCurrentPage(1);
          setPages(
            response.data.pages ||
              Math.ceil(response.data.count / response.data.results.length)
          );
        }
        setLoading(false);
      });
  };

  const handleFiltered = () => {
    returnSearch();
  };

  const handlePagination = page => {
    returnSearch(page);
  };

  const btnLink = ({ id, name }) => (
    <Button
      onClick={() => (window.location.href = `/pdm/sourcing/${id}`)}
      className={styles.btnProject}>
      {name}
    </Button>
  );

  const btnSend = sourcing => (
    <Button
      onClick={handleClick(sourcing)}
      className={getBtnClass(sourcing.stage_code)}>
      {getButtonText(sourcing.stage_code)}
    </Button>
  );

  const columHeaderTable = [
    { retailerName: 'CLIENTE' },
    { managerUser: 'GESTOR RESPONSÁVEL' },
    { typeName: 'TIPO DE PROJETO' },
    { dtiUser: 'RESPONSÁVEL DTI' },
    { sentAt: 'DATA DE ENTRADA' },
    { project: 'PROJETO' },
    { sendToAp: 'ENVIAR PARA APROVAÇÃO' },
  ];

  const listTable = useMemo(() => {
    if (source.length > 0) {
      return source.map(s => ({
        retailerName: s.retailer_name,
        managerUser: s.retailer_manager_user_name,
        typeName: s.project_type_name || '-',
        dtiUser: s.dti_user_name,
        sentAt: Moment(s.sent_to_sourcing_at).format('DD/MM/YYYY'),
        project: btnLink(s),
        sendToAp: btnSend(s),
      }));
    }
    return [];
  }, [source]);

  return (
    <>
      <HeaderModule classHeader="pdmHeaderModule" afterText="SOURCING">
        VISÃO DE SOURCING
      </HeaderModule>

      <Row className={styles.mainDiv2}>
        <Col md={5} mdOffset={4}>
          <h2 className={styles.countProject}>
            Total de Briefings em sourcing:{' '}
            <span className={styles.projectsNumber}>{sourceCount}</span>
          </h2>
        </Col>
        <Col md={3}>
          <SelectPicker
            className="border-0"
            data={intervals}
            onChange={(value, event) => {
              setValuesFilter({
                ...valuesFilter,
                dateInterval: value,
              });
              setDisabled(false);
            }}
            value={valuesFilter.dateInterval}
            appearance="default"
            placeholder="Intervalo"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
            searchable={false}
          />
        </Col>
        <Col md={3}>
          <SelectPicker
            className="border-0"
            data={retailers}
            onChange={(value, event) => {
              setValuesFilter({
                ...valuesFilter,
                retailer: value,
              });
              setDisabled(false);
            }}
            value={valuesFilter.retailer}
            appearance="default"
            placeholder="Varejista"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
          />
        </Col>
        <Col md={3}>
          <SelectPicker
            className="border-0"
            data={briefingTypes}
            onChange={(value, event) => {
              setValuesFilter({
                ...valuesFilter,
                type: value,
              });
              setDisabled(false);
            }}
            value={valuesFilter.type}
            appearance="default"
            placeholder="Tipo"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
            searchable={false}
          />
        </Col>
        <Col md={2}>
          <Input
            placeholder="Projeto.."
            value={valuesFilter.search}
            onChange={(value, event) => {
              setValuesFilter({
                ...valuesFilter,
                search: value,
              });
              setDisabled(false);
            }}
            className={styles.textInput}
          />
        </Col>
        <Col md={2}>
          <Button
            disabled={disabled || loading}
            style={{ minHeight: 38 }}
            onClick={handleFiltered}
            color="blue"
            block>
            Filtrar
          </Button>
        </Col>
        <Col md={2}>
          <Button
            disabled={disabled || loading}
            style={{ minHeight: 38 }}
            onClick={handleClear}
            color="grey"
            block>
            Limpar
          </Button>
        </Col>
      </Row>

      <TableList
        columns={columHeaderTable}
        lines={listTable}
        loading={loading}
      />

      {!loading && (
        <div style={{ margin: '0 15px' }}>
          <Pagination
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={handlePagination}
            loading={loading}
          />
        </div>
      )}
    </>
  );
};

export default SourcingList;
