/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import './ModalNotes.scss';
import { Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import useToggle from '../../../../../../utils/hooks/useToggle';

const ModalNotes = (
  { header, inputNote = '', setInputNote, statusCode },
  ref
) => {
  const [modal, toggle] = useToggle();
  const [value, setValue] = useState('');

  useImperativeHandle(ref, () => ({
    toggle,
  }));

  useEffect(() => {
    if (modal && inputNote) {
      setValue(inputNote);
    } else {
      setValue('');
    }
  }, [modal]);

  const saveNote = () => {
    setInputNote(value);
    toggle();
  };

  return (
    <Modal size="lg" toggle={toggle} isOpen={modal}>
      <ModalHeader toggle={toggle}>{header}</ModalHeader>
      <ModalBody className="specialPaddingModal">
        <div className="notesProviders">
          <p className="attentionMessage">
            Atenção! Essa informação será vista pelo fornecedor.
          </p>
          <Input
            name="notes"
            className="inputText textArea"
            type="textarea"
            value={value}
            onChange={ev => setValue(ev.target.value)}
          />
        </div>
        <div className="flex-center m-t-7">
          <button
            onClick={saveNote}
            className="button-confirmar"
            disabled={statusCode === 'F'}>
            Salvar
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default forwardRef(ModalNotes);
