/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import styles from './Sidebar.module.css';
import './Sidebar.scss';
import SupportIcon from '../../Assets/suport-icon.png';
import { CgMenuLeft } from 'react-icons/cg';
// import { FaHome } from 'react-icons/fa'
import { Drawer } from 'rsuite';
// import { useLocation } from 'react-router-dom'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import logo from '../../Assets/logo.png';
import { PopupButton } from '@typeform/embed-react';
import { useCognitoAuth } from '../../Contexts/AuthCognitoContext';
import sairMenuPdm from '../../Assets/sairMenuPdm.svg';
import computerImage from '../../Assets/computer-line.svg';
import questionLine from '../../Assets/question-line.svg';
// import './MenuPdm.scss';
import { classNames } from '../../utils/utils';
import linkPlatform from '../../utils/linkPlataform';

function Sidebar({ children, hideLogo, local, fixedIcon }) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  // const location = useLocation();
  // const [url, setUrl] = React.useState();
  // const urlPath = location.pathname
  const [show, setShow] = useState(false);

  //const [newFormatName, setNewFormatName] = useState();
  //const [avatar, setAvatar] = useState();
  const navigate = useNavigate();

  const { signOut } = useCognitoAuth();

  // useEffect(() => {
  //     if(urlPath === "/pdm") setUrl(true)
  // }, [])

  function showDrawer() {
    setShow(true);
  }

  function closeDrawer() {
    setShow(false);
  }

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  const redirectToPlatform = () => {
    window.open(`${linkPlatform}/list-projects`, '_blank');
  };

  const redirectToFAQ = () => {
    window.open('https://sites.google.com/amicci.com.br/faq/faq', '_blank');
  };

  //console.log('usersidebar', user.username);

  /*const newName = () => {
    const name = user?.username;
    const formatName = name.split(".");
    const firstName = formatName[0];
    setNewFormatName(firstName[0].toUpperCase()+firstName.substring(1));
    return newFormatName;  
  }

  useEffect(() => {
    newName();
  }, [])*/

  return (
    <>
      <div className={classNames({ menuImg: true, fixedIcon: !!fixedIcon })}>
        <CgMenuLeft
          // color={local === 'management' ? '#f39332' : '#fff'}
          color="#f39332"
          className="svgMenu"
          onClick={showDrawer}
          size={36}
        />
      </div>
      <Drawer
        onHide={closeDrawer}
        keyboard={true}
        show={show}
        placement="left"
        size="xs">
        <Drawer.Body>
          <aside className="sidebar">
            <div
              className="closeMenu"
              style={{ marginTop: '-40px' }}
              id="closeMenuButton"
              onClick={closeDrawer}>
              X
            </div>
            {!hideLogo && (
              <div className="logoDiv">
                <Link to="/home">
                  <div className="logo">
                    <img src={logo} alt="Amicci Digital" />
                    <span className="logoSpan">Digital</span>
                  </div>
                </Link>
                <div className="clear"></div>
              </div>
            )}
            <div className="titleMenu"></div>

            <div className="MenuItens">{children}</div>

            <div className="sectionButtonsMenu">
              <div className="buttonCloseDiv">
                <div className="buttonCloseText">
                  <button onClick={redirectToPlatform}>
                    <img src={computerImage} alt="Plataforma" />
                    Plataforma Amicci
                  </button>
                </div>
              </div>
              <div className="buttonCloseDiv" style={{ paddingTop: '0' }}>
                <div className="buttonCloseText">
                  <button onClick={redirectToFAQ}>
                    <img src={questionLine} alt="FAQ" />
                    FAQ Amicci
                  </button>
                </div>
              </div>
              <PopupButton id="lVI9XJHS" className="btnSidebarPopover">
                <img
                  src={SupportIcon}
                  className="iconLinkSmall"
                  alt="Suporte"
                />
                Ajuda
              </PopupButton>
              <div className="buttonCloseDiv">
                <div className="buttonCloseText">
                  <button onClick={handleSignOut}>
                    <img src={sairMenuPdm} alt="Sair" />
                    Sair
                  </button>
                </div>
              </div>
            </div>
            {/* <p onClick={toggle} className={styles.linkMenuSuport}>
              <div className={styles.supportLink}>
                <img
                  src={SupportIcon}
                  className={styles.iconLinkSmall}
                  alt="Suporte"
                />
                <span>Suporte</span>
              </div>
            </p> */}
          </aside>
        </Drawer.Body>
      </Drawer>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Service-Desk</ModalHeader>
        <ModalBody>
          <p className="textSupport">
            Em caso de dúvidas/suporte relacionados a plataforma Amicci, entre
            em contato com:
            <strong className="orange"> plm-duvidas@amicci.com.br</strong>
          </p>
          <p className="textSupport">
            Para os demais atendimentos, entre em contato com:
            <strong className="orange"> helpdesk@amicci.com.br</strong>
          </p>
          <br />
        </ModalBody>
      </Modal>
    </>
  );
}
export default Sidebar;
