import React from 'react';
import './ModalSaving.scss';
import { Modal } from 'reactstrap';
import { Loader } from 'rsuite';

const ModalSaving = ({ isOpen }) => {
  return (
    <Modal
      size="sm"
      centered
      fade={false}
      toggle={() => {}}
      isOpen={isOpen}
      className="modalSaving">
      <div className="modalSavingContent">
        <Loader speed="slow" size="sm" />
        <span className="modalSavingText">Salvando...</span>
      </div>
    </Modal>
  );
};

export default ModalSaving;
