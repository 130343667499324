/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import InputText from '../../../../../Components/InputText/InputText';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { Form, Alert } from 'rsuite';
import ButtonDefault from '../../../../../Components/ButtonDefault/ButtonDefault';
import './ModalStatusProject.scss';
import { classNames } from '../../../../../utils/utils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import apiConnectManagement from '../../../../../services/apiConnectManagement';
import { useCognitoAuth } from '../../../../../Contexts/AuthCognitoContext';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';
import { PROJECT_STATUS } from '../../constants/constants';

const ModalStatusProject = (
  {
    setProject = () => {},
    moduleId = null,
    displayOnly = false,
    displayStatusText = null,
    displayObs = '',
    onSubmit = () => {},
  },
  ref
) => {
  const { user: authUser } = useCognitoAuth();
  const { user, getUser, project, optionsStatus, getOptionsStatus } =
    useManagementContext();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [statusesProject, setStatusesProject] = useState(null);
  const validationSchema = Yup.object({
    status: Yup.string().required('Campo Obrigatório').nullable(),
    obs: Yup.string()
      .max(500, ' Limite de 500 caracteres')
      .required('Campo Obrigatório'),
  });

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  const { handleSubmit, handleChange, values, errors, resetForm } = useFormik({
    initialValues: {
      obs: '',
      status: null,
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      onHandleConfirm(values);
    },
  });

  function closeModal() {
    setShowModal(!showModal);
    resetForm();
  }

  const onChangeStatusProject = status => {
    setProject({ ...project, status_project: status });
  };

  const onHandleConfirm = values => {
    setLoadingConfirm(true);
    const body = {
      user: authUser,
      status_id: values.status,
      obs: values.obs,
    };
    apiConnectManagement
      .put(`project/${project?.id}/change_project_status`, body)
      .then(response => {
        if (response?.data) {
          onChangeStatusProject(response?.data.status);
          if (moduleId) onSubmit();

          closeModal();
          resetForm();
        }
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else if (error?.response?.status === 400) {
          Alert.error(error.response.data.error, 50000);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado, tente novamente mais tarde!'
          );
        }
      })
      .finally(() => {
        setLoadingConfirm(false);
      });
  };

  useEffect(() => {
    if (!user && authUser) {
      getUser(authUser.attributes.email);
    }

    if (optionsStatus.length > 0) {
      setStatusesProject(optionsStatus.filter(st => Number(st.value) !== 1));
    } else {
      getOptionsStatus().then(resp => {
        if (resp?.success) {
          setStatusesProject(resp?.data.filter(st => Number(st.value) !== 1));
        }
      });
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Modal
        isOpen={showModal}
        toggle={toggleModal}
        backdrop={false}
        centered={true}
        className="custom-modal modal-dialog-large">
        <ModalHeader>
          <Row>
            <Col
              md={1}
              className={classNames({
                'icon-head-modal-status': true,
                blue:
                  project?.status_project?.id === PROJECT_STATUS.IN_PROGRESS ||
                  displayOnly,
                orange: project?.status_project?.id === PROJECT_STATUS.STANDBY,
                red: project?.status_project?.id === PROJECT_STATUS.UNFEASIBLE,
                black: project?.status_project?.id === PROJECT_STATUS.CANCELLED,
                green:
                  project?.status_project?.id === PROJECT_STATUS.FINISHED ||
                  project?.status_project?.id === PROJECT_STATUS.RELEASED,
              })}>
              <svg width="54" height="54" viewBox="0 0 44 44">
                <defs>
                  {/* <linearGradient
                    className={classNames({
                      'linear-gradient-blue':
                        project?.status_project?.id === PROJECT_STATUS.IN_PROGRESS || displayOnly,
                      'linear-gradient-orange':
                        project?.status_project?.id === PROJECT_STATUS.STANDBY,
                      'linear-gradient-pink': project?.status_project?.id === PROJECT_STATUS.UNFEASIBLE,
                      'linear-gradient-black':
                        project?.status_project?.id === PROJECT_STATUS.CANCELLED,
                      'linear-gradient-green':
                        project?.status_project?.id === PROJECT_STATUS.FINISHED,
                    })}
                    x1="0.5"
                    x2="0.5"
                    y2="1"
                    gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="var(--color-stop)" />
                    <stop offset="1" stop-color="var(--color-bot)" />
                  </linearGradient> */}
                </defs>
                <g
                  id="Group_11634"
                  data-name="Group 11634"
                  transform="translate(-0.837 -1.529)">
                  <rect
                    id="Rectangle_6"
                    data-name="Rectangle 6"
                    width="44"
                    height="44"
                    rx="6"
                    transform="translate(0.837 1.529)"
                    // fill="url(#linear-gradient)"
                  />
                  <g
                    id="Group_14"
                    data-name="Group 14"
                    transform="translate(9.121 8.432)">
                    <g id="Group_13" data-name="Group 13">
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M1007.8,346.436l-13.612,7.86-13.613-7.86,13.613-7.86Z"
                        transform="translate(-980.565 -338.576)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_5"
                        data-name="Path 5"
                        d="M1007.747,397.5v15.74h-.011l-13.613,7.86-13.612-7.86H980.5V397.5"
                        transform="translate(-980.5 -389.64)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                      />
                      <line
                        id="Line_2"
                        data-name="Line 2"
                        y1="15.74"
                        transform="translate(13.624 15.73)"
                        fill="#fff"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_6"
                        data-name="Path 6"
                        d="M1014.5,408.376v-15.74l6.96-4.135,3.85,2.134-6.664,4.269v15.759"
                        transform="translate(-1009.465 -381.84)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </Col>
            <Col md={5} className="texts-modal">
              <h2
                className={classNames({
                  'text-status-project': true,
                  blue:
                    project?.status_project?.id ===
                      PROJECT_STATUS.IN_PROGRESS || displayOnly,
                  orange:
                    project?.status_project?.id === PROJECT_STATUS.STANDBY,
                  red:
                    project?.status_project?.id === PROJECT_STATUS.UNFEASIBLE,
                  black:
                    project?.status_project?.id === PROJECT_STATUS.CANCELLED,
                  green:
                    project?.status_project?.id === PROJECT_STATUS.FINISHED ||
                    project?.status_project?.id === PROJECT_STATUS.RELEASED,
                })}>
                Status do projeto
              </h2>
              <h3
                className={classNames({
                  'sub-status-project': true,
                  blue:
                    project?.status_project?.id ===
                      PROJECT_STATUS.IN_PROGRESS || displayOnly,
                  orange:
                    project?.status_project?.id === PROJECT_STATUS.STANDBY,
                  red:
                    project?.status_project?.id === PROJECT_STATUS.UNFEASIBLE,
                  black:
                    project?.status_project?.id === PROJECT_STATUS.CANCELLED,
                  green:
                    project?.status_project?.id === PROJECT_STATUS.FINISHED ||
                    project?.status_project?.id === PROJECT_STATUS.RELEASED,
                })}>
                {displayOnly
                  ? displayStatusText
                  : project?.status_project?.name}
              </h3>
            </Col>
            <Col md={6}>
              {displayOnly ? (
                <></>
              ) : project?.status_project?.id === PROJECT_STATUS.CANCELLED ? (
                <section className="box-modal-red">
                  Este projeto foi <strong>CANCELADO</strong>. Infelizmente você
                  não pode retomá-lo. Será preciso criar um novo projeto caso
                  queira realizá-lo novamente. Obrigado pela compreensão.
                </section>
              ) : project?.status_project?.id === PROJECT_STATUS.FINISHED ? (
                <section className="box-modal-green">
                  Parabéns, seu projeto foi finalizado! Esperamos que os
                  próximos tenham o mesmo destino de sucesso. Obrigado!
                </section>
              ) : (
                <section className="box-modal-blue">
                  Esteja ciente que ao modificar o{' '}
                  <strong>status do projeto</strong> você o está modificando
                  como um todo, incluindo os próximos módulos. Esperamos que seu
                  trabalho até aqui tenha sido de muitos aprendizados. Obrigado.
                </section>
              )}
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <Row className="hold-radio-button">
            <Col md={4}>
              <section>
                <div>
                  <label>Status</label>
                </div>
              </section>
              {statusesProject !== null &&
              (project?.status_project?.id === PROJECT_STATUS.IN_PROGRESS ||
                project?.status_project?.id === PROJECT_STATUS.RELEASED) ? (
                statusesProject?.map(ob => {
                  return (
                    <section key={`status-pj-${ob.value}`}>
                      <input
                        type="radio"
                        onChange={handleChange('status')}
                        name="status"
                        value={ob?.value}
                      />{' '}
                      {ob?.label}
                    </section>
                  );
                })
              ) : project?.status_project?.id === PROJECT_STATUS.STANDBY ||
                project?.status_project?.id === PROJECT_STATUS.UNFEASIBLE ? (
                <section>
                  <input
                    type="radio"
                    onChange={handleChange('status')}
                    name="status"
                    value={1}
                  />{' '}
                  Retomar Projeto
                </section>
              ) : project?.status_project?.id === PROJECT_STATUS.FINISHED &&
                user?.id === project?.manager_user_id ? (
                <section>
                  <input
                    type="radio"
                    onChange={handleChange('status')}
                    name="status"
                    value={1}
                  />{' '}
                  Reabrir Projeto
                </section>
              ) : displayOnly ? (
                <section>{displayStatusText} </section>
              ) : (
                <section>Nenhuma ação disponível </section>
              )}
              <section>
                <span className="error">
                  {errors.status ? errors.status : null}
                </span>
              </section>
            </Col>
            <Col md={8}>
              <label className="text-observation">
                Observações <span>adicionais</span>
              </label>
              <InputText
                maxLength={500}
                value={displayOnly ? displayObs : values.obs}
                onChange={handleChange('obs')}
                className={classNames({
                  'custom-textarea': true,
                  'error-input': Boolean(errors.obs),
                })}
                readOnly={
                  displayOnly ||
                  project?.status_project?.id === PROJECT_STATUS.CANCELLED ||
                  (project?.status_project?.id === PROJECT_STATUS.FINISHED &&
                    user?.id !== project?.manager_user_id)
                }
                type="textarea"
                placeholder={
                  project?.status_project?.id === PROJECT_STATUS.FINISHED
                    ? 'O projeto está sendo finalizado com sucesso........'
                    : project?.status_project?.id === PROJECT_STATUS.CANCELLED
                    ? 'O projeto está sendo cancelado devido o não atingimento das necessidades para que pudéssemos dar continuidade ao mesmo........'
                    : 'Ex: O projeto entrou em stand by devido a inconsistência de informações, até que sejam validadas as informações pelo cliente o mesmo permanecerá em stand by........'
                }></InputText>
              <small className="text-limit">Limite de 500 caracteres</small>
              <br></br>
              <span className="error">{errors.obs ? errors.obs : null}</span>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {displayOnly ||
          project?.status_project?.id === PROJECT_STATUS.CANCELLED ||
          (project?.status_project?.id === PROJECT_STATUS.FINISHED &&
            user?.id !== project?.manager_user_id) ? (
            <ButtonDefault color="blue" radius onClick={closeModal}>
              Fechar
            </ButtonDefault>
          ) : (
            <>
              <ButtonDefault color="red" radius onClick={closeModal}>
                Cancelar
              </ButtonDefault>
              <ButtonDefault
                onClick={handleSubmit}
                loading={loadingConfirm}
                color="green"
                radius>
                Confirmar
              </ButtonDefault>
            </>
          )}
        </ModalFooter>
      </Modal>
    </Form>
  );
};

export default forwardRef(ModalStatusProject);
