/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, useMemo } from 'react';
// import ReactGA from 'react-ga';
import { useNavigate, Link } from 'react-router-dom';
import styles from './styles.module.css';
import HeaderModule from '../../../../../../Components/HeaderModule/HeaderModule';
import apiConnectSourcing from '../../../../../../services/apiConnectSourcing';
import apiConnect from '../../../../../../services/apiConnect';
import moment from 'moment';
import ModalProspectionExtension from './Components/ModalProspectionExtension';
import Tabs from '../../../../../../Components/Tabs';
import Pagination from '../../../../../../Components/Pagination/Pagination';
import { storageIsValid } from '../../../utils/utils';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import 'react-circular-progressbar/dist/styles.css';
import {
  Alert,
  SelectPicker,
  Col,
  FlexboxGrid,
  Button,
  Icon,
  Whisper,
  Tooltip,
  Input,
} from 'rsuite';
import TableList, {
  GroupButtons,
} from '../../../../../../Components/TableList/TableList';
import ModalResultHelp from './Components/ModalResultHelp/ModalResultHelp';
import useLocalStorage from '../../../../../../utils/hooks/useLocalStorage';

function ProspectionList() {
  // ReactGA.pageview('/pdm');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [prospections, setProspections] = useState([]);
  const [prospection, setProspection] = useState();

  const [disabled, setDisabled] = useState(true);
  const [valuesFilter, setValuesFilter] = useLocalStorage(
    'prospection-filters',
    {
      status: '',
      client: '',
      project: '',
      responsible: '',
      project_type: '',
    }
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [optionsStatus, setOptionsStatus] = useState([]);
  const [optionsClients, setOptionsClients] = useState([]);
  const [optionsProjectType, setOptionsProjectType] = useState([]);
  const [optionsResponsibles, setOptionsResponsibles] = useState([]);

  const modalResultHelpRef = useRef(null);
  const [pages, setPages] = useState(1);
  const [prospectionIndex, setProspectionIndex] = useState();
  const [responsibleName, setResponsibleName] = useState();
  const [showModal, setShowModal] = useState(false);
  const [headers] = useState({
    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
  });

  const tooltip = text => {
    return <Tooltip>{text}</Tooltip>;
  };

  const prospectionsList = page => {
    setLoading(true);
    var queryParams = '';
    if (valuesFilter.status) {
      queryParams += `&status=${valuesFilter.status}`;
    }
    if (valuesFilter.client) {
      queryParams += `&client=${valuesFilter.client}`;
    }
    if (valuesFilter.project) {
      queryParams += `&prospection=${valuesFilter.project}`;
    }
    if (valuesFilter.project_type) {
      queryParams += `&project_type=${valuesFilter.project_type}`;
    }
    if (valuesFilter.responsible) {
      queryParams += `&responsible=${valuesFilter.responsible}`;
    }

    let url = '/prospections/';
    if (page) {
      url += `?page=${page}`;
    } else {
      url += `?page=1`;
    }
    url += queryParams;

    apiConnectSourcing
      .get(url)
      .then(response => {
        if (response.data && response.data.results) {
          setCurrentPage(response.data.current_page);
          setPages(response.data.pages);
          setProspections(response.data.results);
        } else {
          Alert.error(
            'Ocorreu um erro inesperado. Tente novamente, mais tarde!'
          );
        }
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          Alert.error(error.response.data.message, 5000);
          navigate('/pdm');
        } else {
          Alert.error(
            'Aconteceu um erro inesperado, tente novamente mais tarde!'
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const includeResponsible = () => {
    apiConnect.get('/sourcing-users/', { headers }).then(response => {
      if (response.data) {
        const responsibles = response.data;
        responsibles.forEach(ob => {
          const result = {
            label: ob.name,
            value: ob.id,
          };
          setOptionsResponsibles(optionsResponsibles => [
            ...optionsResponsibles,
            result,
          ]);
        });
      }
    });
  };

  const includeProjectTypes = () => {
    apiConnectSourcing.get('/project-types/', { headers }).then(response => {
      if (response.data) {
        const projectType = response.data;
        projectType.forEach(ob => {
          const result = {
            label: ob.name,
            value: ob.id,
          };
          setOptionsProjectType(optionsProjectType => [
            ...optionsProjectType,
            result,
          ]);
        });
      }
    });
  };

  const includeStatus = () => {
    apiConnectSourcing.get('/status/').then(response => {
      if (response?.data && response.data.results) {
        const status = response.data.results;

        status.forEach(ob => {
          const result = {
            label: ob.name,
            value: ob.id,
          };
          setOptionsStatus(optionsStatus => [...optionsStatus, result]);
        });
      }
    });
  };

  const includeClients = () => {
    const [isvalid, timeOrStorage] = storageIsValid('clients');
    if (!isvalid) {
      apiConnect
        .get('/retailers/', { headers })
        .then(response => {
          if (response.data) {
            window.localStorage.setItem(
              'clients',
              JSON.stringify({
                data: response.data,
                expired: timeOrStorage,
              })
            );
            const clients = response.data;

            clients.forEach(ob => {
              const result = {
                label: ob.name,
                value: ob.id,
              };
              setOptionsClients(optionsClients => [...optionsClients, result]);
            });
          }
        })
        .catch(error => {});
    } else {
      const clients = timeOrStorage.data;

      clients.forEach(ob => {
        const result = {
          label: ob.name,
          value: ob.id,
        };
        setOptionsClients(optionsClients => [...optionsClients, result]);
      });
    }
  };

  useEffect(() => {
    includeResponsible();
    includeStatus();
    includeClients();
    includeProjectTypes();
    prospectionsList();
    if (
      valuesFilter?.status ||
      valuesFilter?.client ||
      valuesFilter?.project ||
      valuesFilter?.responsible ||
      valuesFilter?.project_type
    ) {
      setDisabled(false);
    }
  }, []);

  const handleFiltered = () => {
    setCurrentPage(1);
    prospectionsList();
  };

  const handlePagination = page => {
    setCurrentPage(page);
    prospectionsList(page);
  };

  const handleClear = () => {
    setLoading(true);
    setValuesFilter({
      status: '',
      client: '',
      project: '',
      responsible: '',
      project_type: '',
    });
    setDisabled(true);
    apiConnectSourcing
      .get(`/prospections/?page=1`)
      .then(response => {
        if (response.data && response.data.results) {
          setCurrentPage(response.data.current_page);
          setPages(response.data.pages);
          setProspections(response.data.results);
        } else {
          Alert.error(
            'Ocorreu um erro inesperado. Tente novamente, mais tarde!'
          );
        }
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado, tente novamente mais tarde!'
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openModal = (prospection, prospectionIndex) => {
    setResponsibleName(prospection.userInfo.name);
    setProspection(prospection);
    setProspectionIndex(prospectionIndex);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const navigateProspectionDetails = id => {
    navigate(`/pdm/prospection/${id}`, { replace: false });
  };

  const linkDetails = ({ id, briefing_name }) => (
    <Link
      onClick={() => navigateProspectionDetails(id)}
      to={`/pdm/prospection/${id}`}>
      {briefing_name}
    </Link>
  );

  const startDate = ({ start_date, created_at }) => {
    return start_date
      ? Math.trunc(
          moment
            .duration(
              moment(start_date).diff(moment(created_at).format('YYYY-MM-DD'))
            )
            .asDays()
        ).toString() +
          '-' +
          moment(start_date).format('DD/MM/YYYY')
      : null;
  };

  const extensions = ({ extensions }) => {
    return extensions.length > 0
      ? extensions[extensions.length - 1].days +
          ' - ' +
          moment(extensions[extensions.length - 1].created_at).format(
            'DD/MM/YYYY'
          )
      : 'Sem prorrogação';
  };

  const finishForecast = ({ finish_forecast, start_date }) => {
    return finish_forecast && start_date
      ? Math.trunc(
          moment
            .duration(
              moment(finish_forecast).diff(
                moment(start_date).format('YYYY-MM-DD')
              )
            )
            .asDays()
        ).toString() +
          ' - ' +
          moment(finish_forecast).format('DD/MM/YYYY').toString()
      : finish_forecast
      ? moment(finish_forecast).format('DD/MM/YYYY').toString()
      : 'Sem previsão';
  };

  const finishedAt = ({ status, finished_at }) => {
    return (status?.id !== 1 || status?.id !== 3) && finished_at
      ? moment(finished_at).format('DD/MM/YYYY').toString()
      : '';
  };

  const result = ({
    total_positive_suppliers,
    total_suppliers,
    cost_sheets_count,
  }) => (
    <div className={styles.circProgBox}>
      <CircularProgressbar
        value={(total_positive_suppliers * 100) / total_suppliers}
        text={`${total_positive_suppliers}/${total_suppliers}`}
        strokeWidth={10}
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)
          rotation: 0.25,
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'butt',
          // How long animation takes to go from one percentage to another, in seconds
          pathTransitionDuration: 0.5,
          // Can specify path transition in more detail, or remove it entirely
          // pathTransition: 'none',
          // Colors
          pathColor: `rgba(2, 153, 58, ${
            (total_positive_suppliers * 100) / total_suppliers
          })`,
          textColor: '#02993A',
          textSize: '25px',
          trailColor: '#32F37A',
          backgroundColor: '#02993A',
        })}
      />
      {cost_sheets_count && (
        <CircularProgressbar
          value={
            (cost_sheets_count.cost_uploaded_suppliers_count * 100) /
            total_suppliers
          }
          text={`${cost_sheets_count.cost_uploaded_suppliers_count}/${total_suppliers}`}
          strokeWidth={10}
          styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0.25,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,
            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',
            // Colors
            pathColor: `rgba(66, 91, 166, ${
              (cost_sheets_count.cost_uploaded_suppliers_count * 100) /
              total_suppliers
            })`,
            textColor: '#425BA6',
            textSize: '25px',
            trailColor: '#407BFF',
            backgroundColor: '#02993A',
          })}
        />
      )}
    </div>
  );

  const actions = (ob, i) => (
    <GroupButtons>
      <Whisper placement="top" trigger="hover" speaker={tooltip('Prorrogar')}>
        <Button
          onClick={() => openModal(ob, i)}
          disabled={!Boolean(ob.complexity)}>
          <Icon icon="plus-square-o" size="lg" />
        </Button>
      </Whisper>
      <Whisper placement="top" trigger="hover" speaker={tooltip('detalhes')}>
        <Button onClick={() => navigateProspectionDetails(ob.id)}>
          <Icon icon="edit2" size="lg" />
        </Button>
      </Whisper>
    </GroupButtons>
  );

  const columHeaderTable = [
    { status: 'STATUS' },
    { clientInfo: 'CLIENTE' },
    { briefing_name: 'PROJETO' },
    { project_type: 'TIPO DE PROJETO' },
    { userInfo: 'RESPONSÁVEL' },
    { complexity: 'COMPLEXIDADE' },
    { start_date: 'INÍCIO' },
    { extensions: 'PRORROGAÇÃO' },
    { finish_forecast: 'PREVISÃO DE ENTREGA' },
    { finished_at: 'FINALIZAÇÃO' },
    {
      result: (
        <>
          RESULTADO
          <button
            type="button"
            className={styles.helpBtn}
            onClick={() => modalResultHelpRef.current?.toggleOpen()}>
            <HiQuestionMarkCircle />
          </button>
        </>
      ),
    },
    { actions: 'AÇÕES' },
  ];

  const listTable = useMemo(() => {
    if (prospections.length > 0) {
      return prospections.map((ob, i) => ({
        status: ob.status?.name,
        clientInfo: ob.clientInfo.name,
        briefing_name: linkDetails(ob),
        project_type: ob.project_type ? ob.project_type.name : '-',
        userInfo: ob.userInfo.name,
        complexity: ob.complexity,
        start_date: startDate(ob),
        extensions: extensions(ob),
        finish_forecast: finishForecast(ob),
        finished_at: finishedAt(ob),
        result: result(ob),
        actions: actions(ob),
      }));
    }
    return [];
  }, [prospections]);

  return (
    <>
      <HeaderModule classHeader="pdmHeaderModule" afterText="PROJETOS">
        Controle de Projetos
      </HeaderModule>
      <Tabs tabActive={1}>
        <FlexboxGrid>
          <FlexboxGrid.Item componentClass={Col} sm={12} md={5} lg={3}>
            <SelectPicker
              className="border-0"
              data={optionsStatus}
              onChange={(value, event) => {
                setValuesFilter({ ...valuesFilter, status: value });
                setDisabled(false);
              }}
              value={valuesFilter.status}
              appearance="default"
              placeholder="Status"
              renderMenuItem={(label, item) => {
                return <div>{label}</div>;
              }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item componentClass={Col} sm={12} md={5} lg={3}>
            <SelectPicker
              className="border-0"
              data={optionsClients}
              onChange={(value, event) => {
                setValuesFilter({ ...valuesFilter, client: value });
                setDisabled(false);
              }}
              value={valuesFilter.client}
              appearance="default"
              placeholder="Cliente"
              renderMenuItem={(label, item) => {
                return <div>{label}</div>;
              }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item componentClass={Col} sm={12} md={5} lg={4}>
            <Input
              placeholder="Projeto"
              value={valuesFilter.project}
              onChange={(value, event) => {
                setValuesFilter({ ...valuesFilter, project: value });
                setDisabled(false);
              }}
              className={styles.textInput}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item componentClass={Col} sm={12} md={5} lg={3}>
            <SelectPicker
              className="border-0"
              data={optionsProjectType}
              value={valuesFilter.project_type}
              onChange={(value, event) => {
                setValuesFilter({ ...valuesFilter, project_type: value });
                setDisabled(false);
              }}
              appearance="default"
              placeholder="Tipo"
              renderMenuItem={(label, item) => {
                return <div>{label}</div>;
              }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item componentClass={Col} sm={12} md={5} lg={3}>
            <SelectPicker
              className="border-0"
              data={optionsResponsibles}
              onChange={(value, event) => {
                setValuesFilter({ ...valuesFilter, responsible: value });
                console.log(value);
                setDisabled(false);
              }}
              value={valuesFilter.responsible}
              appearance="default"
              placeholder="Responsável"
              renderMenuItem={(label, item) => {
                return <div>{label}</div>;
              }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item componentClass={Col} sm={12} md={4} lg={8}>
            <FlexboxGrid>
              <FlexboxGrid.Item componentClass={Col} sm={12}>
                <Button
                  style={{ minHeight: '38px' }}
                  disabled={disabled || loading}
                  onClick={handleFiltered}
                  color="blue"
                  block>
                  Filtrar
                </Button>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item componentClass={Col} sm={12}>
                <Button
                  style={{ minHeight: '38px' }}
                  disabled={disabled || loading}
                  onClick={handleClear}
                  color="grey"
                  block>
                  Limpar
                </Button>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Tabs>

      <TableList
        columns={columHeaderTable}
        lines={listTable}
        loading={loading}
      />

      {prospections.length > 0 && !loading && (
        <div style={{ marginRight: '15px' }}>
          <Pagination
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={handlePagination}
            loading={loading}
          />
        </div>
      )}
      <ModalProspectionExtension
        show={showModal}
        prospection={prospection}
        prospections={prospections}
        prospectionIndex={prospectionIndex}
        closeModal={closeModal}
        setProspections={setProspections}
        responsibleName={responsibleName}
      />
      <ModalResultHelp ref={modalResultHelpRef} />
    </>
  );
}

export default ProspectionList;
