import React, { useEffect } from 'react';
import { classNames } from '../../../utils/utils';
// import ReactGA from 'react-ga';
import { useCognitoAuth } from '../../../Contexts/AuthCognitoContext';
import styles from './style.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Input, Alert, Col, Message } from 'rsuite';

function ForgotPasswordSubmitCognito({ username }) {
  // ReactGA.pageview('/login');
  const passwordPolicyText =
    'É obrigatório que a senha contenha ao menos um número, uma letra maiúscula e uma letra minúscula com no mínimo oito caracteres.';

  const { forgotPasswordSubmit, loading, setStepForgotPassword } =
    useCognitoAuth();
  useEffect(() => {
    Alert.info(
      'Foi enviado um e-mail para você com um código para ser inserido abaixo!',
      10000
    );
  }, []);

  const validationSchema = Yup.object({
    code: Yup.string().required('Campo Obrigatório'),
    password: Yup.string()
      .required('Campo Obrigatório')
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z$*&@#\-\!\?\%\(\)\[\]\{\}\_\^\+\=\|\'\"]{8,}$/,
        passwordPolicyText
      ),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'A senha precisa ser igual')
      .required('Campo Obrigatório'),
  });
  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      code: '',
      password: '',
      passwordConfirmation: '',
    },
    validateOnChange: true,
    validationSchema,
    onSubmit: async values => {
      const response = await forgotPasswordSubmit(
        username,
        values.code,
        values.password
      );
      if (response) {
        setTimeout(() => {
          setStepForgotPassword(0);
        }, 1000);
      }
    },
  });
  return (
    <>
      <div className={styles.loginInfo}>
        <p>Recuperar Senha</p>
      </div>
      <div>
        <Message
          style={{ margin: '0 15px 20px 15px' }}
          showIcon
          type="warning"
          title="Atenção"
          description={passwordPolicyText}
        />
      </div>
      <form className={styles.formLogin} autoComplete="off">
        <Input
          type="text"
          name="code"
          placeholder="CÓDIGO"
          onChange={handleChange('code')}
          className={styles.inputLogin}
          value={values.code}
          autoComplete="off"
        />
        <Row className={styles.space}>
          <span className={styles.error}>
            {errors.code ? errors.code : null}
          </span>
        </Row>
        <Input
          type="password"
          name="password"
          placeholder="SENHA"
          onChange={handleChange('password')}
          className={styles.inputLogin}
          value={values.password}
          autoComplete="off"
        />
        <Row className={styles.space}>
          <span className={styles.error}>
            {errors.password ? errors.password : null}
          </span>
        </Row>
        <Input
          type="password"
          name="passwordConfirmation"
          placeholder="CONFIRMAÇÃO DE SENHA"
          onChange={handleChange('passwordConfirmation')}
          className={styles.inputLogin}
          value={values.passwordConfirmation}
          autoComplete="off"
        />
        <Row className={styles.space}>
          <span className={styles.error}>
            {errors.passwordConfirmation ? errors.passwordConfirmation : null}
          </span>
        </Row>
        <Row>
          <Col md={8} className={styles.send}>
            <Button
              block
              appearance="default"
              onClick={() => setStepForgotPassword(1)}
              className={styles.buttonSentBack}>
              Voltar
            </Button>
          </Col>
          <Col md={8} mdPush={8} className={styles.send}>
            <Button
              block
              type="submit"
              onClick={handleSubmit}
              className={classNames({
                [styles.buttonSent]: !loading, //always applies
                [styles.buttonSentLoading]: loading, //only when open === true
              })}
              loading={loading}>
              Confirmar
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
}

export default ForgotPasswordSubmitCognito;
