/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ButtonDefault from '../../../../../../Components/ButtonDefault/ButtonDefault';
import SelectPicker from '../../../../../../Components/SelectPicker/SelectPicker';
import { useBriefingContext } from '../../../../../../Contexts/BriefingContext';
import useToggle from '../../../../../../utils/hooks/useToggle';
import './ModalCategory.scss';

const ModalCategory = (
  {
    //inputCategory,
    setInputCategory,
    setProductsList,
    productsList,
    onClosed,
    setUpperLevels,
  },
  ref
) => {
  const {
    //loading,
    sectors,
    getSectors,
    departaments,
    getDepartaments,
    categories,
    getCategories,
    products,
    getProducts,
  } = useBriefingContext();
  const [modal, toggle] = useToggle();
  const [sector, setSector] = useState(null);
  const [depart, setDepart] = useState(null);
  const [category, setCategory] = useState(null);
  const [sectorsList, setSectorsList] = useState([]);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  useImperativeHandle(ref, () => ({ toggle }));

  const sort = (a, b) => {
    if (a.name > b.name) return 1;
    else if (a.name < b.name) return -1;
    else return 0;
  };

  useEffect(() => {
    getSectors();
  }, []);

  useEffect(() => {
    console.log('sectors', sectors);
    if (sectors && sectors.length > 0) {
      setSectorsList(
        sectors.sort(sort).map(s => ({
          label: s.name,
          value: s.id,
        }))
      );
    }
  }, [sectors]);

  useEffect(() => {
    if (sector) {
      setDepart(null);
      setDepartmentsList([]);
      getDepartaments(sector);
    }
  }, [sector]);

  useEffect(() => {
    if (departaments && departaments.length > 0) {
      setDepartmentsList(
        departaments.sort(sort).map(d => ({
          label: d.name,
          value: d.id,
        }))
      );
    }
  }, [departaments]);

  useEffect(() => {
    if (depart) {
      setCategory(null);
      setCategoriesList([]);
      getCategories(depart);
    }
  }, [depart]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      setCategoriesList(
        categories.sort(sort).map(d => ({
          label: d.name,
          value: d.id,
        }))
      );
    }
  }, [categories]);

  useEffect(() => {
    if (category) {
      // const uL = categories.find(c => c.name === category)?.id;
      // setCategorieId(uL);
      getProducts(category);
    } else {
      // setCategorieId(null);
    }
  }, [category]);

  useEffect(() => {
    console.log('products', products);
  }, [products]);

  const setSelectedProducts = () => {
    setProductsList(products.sort(sort).map(p => p.name));
  };

  const saveCategory = () => {
    if (sector && depart && category) {
      const sectorName = sectors.find(s => s.id === sector)?.name;
      const departName = departaments.find(d => d.id === depart)?.name;
      const categoryName = categories.find(c => c.id === category)?.name;
      // console.log('sector, depart, category', sector, depart, category);
      setInputCategory(`${sectorName} / ${departName} / ${categoryName}`);
      setSelectedProducts();
    }
    if (category) {
      setUpperLevels(category);
    }
    toggle();
  };

  useEffect(() => {
    if (products && products.length > 0) {
      setSelectedProducts();
    }
  }, [products]);

  return (
    <Modal
      className="modalCategory"
      toggle={toggle}
      isOpen={modal}
      onClosed={() => {
        if (onClosed) onClosed();
      }}>
      <ModalBody style={{ display: 'flex' }}>
        <div className="selectCategoryEsq">
          <p className="titleCategory">MERCADOLÓGICO AMICCI</p>
          <p className="subtitleCategoryEsq">
            Escolha Setor, Departamento e Categoria para posteriormente escolher
            seus SKUs quando iniciar o Briefing.
          </p>
          <div className="categoryColumn">
            <div className="categorySelectGroup" style={{ marginTop: '10px' }}>
              <label className="legendField">Setor</label>
              <SelectPicker
                name="sector"
                value={sector}
                onChange={setSector}
                data={sectorsList}
                className="selectPicker selectPickerCustom"
                placeholder="Selecione o Setor"
              />
            </div>
            <div className="categorySelectGroup">
              <label className="legendField">Departamento</label>
              <SelectPicker
                name="department"
                value={depart}
                onChange={setDepart}
                data={departmentsList}
                className="selectPicker selectPickerCustom"
                placeholder="Selecione o Departamento"
                disabled={sector === null}
              />
            </div>
            <div className="categorySelectGroup">
              <label className="legendField">Categoria</label>
              <SelectPicker
                name="category"
                value={category}
                onChange={setCategory}
                data={categoriesList}
                className="selectPicker selectPickerCustom"
                placeholder="Selecione a Categoria"
                disabled={depart === null}
              />
            </div>
          </div>
          <div className="categoryButtons">
            <ButtonDefault
              onClick={toggle}
              size="small"
              color="red"
              radius
              bold>
              Cancelar
            </ButtonDefault>
            <ButtonDefault
              onClick={saveCategory}
              radius
              bold
              size="small"
              color="primary3"
              disabled={category === ''}>
              Confirmar
            </ButtonDefault>
          </div>
        </div>
        <div className="selectCategoryDir">
          <p className="subtitleCategoryDir">
            Visualize os produtos conectados para Categoria selecionada. Se seu
            produto estiver na lista, confirme.
          </p>
          {productsList && productsList.length > 0 && (
            <ul className="listProducts">
              {productsList.map(prod => (
                <li key={`prod-${prod}`}>{prod}</li>
              ))}
            </ul>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default forwardRef(ModalCategory);
