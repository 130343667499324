/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Modal } from 'reactstrap';
import useToggle from '../../../../../../utils/hooks/useToggle';
import { BsCloudUpload } from 'react-icons/bs';
import { BsFileEarmarkText } from 'react-icons/bs';
import { BsXCircle } from 'react-icons/bs';
import { AiOutlineStar } from 'react-icons/ai';
import { BsFillExclamationOctagonFill } from 'react-icons/bs';
import { BiX } from 'react-icons/bi';
import { BiUpload } from 'react-icons/bi';
import './ModalUploadDoc.scss';
import { Alert } from 'rsuite';

const ModalUploadDoc = ({ newFiles = [], setNewFiles, onClosed }, ref) => {
  const [show, toggle] = useToggle();
  const [listFiles, setListFiles] = useState([]);
  const [presentationFile, setPresentationFile] = useState([]);
  const [tableFile, setTableFile] = useState([]);
  const [othersFiles, setOthersFiles] = useState([]);

  useImperativeHandle(ref, () => ({ toggle }));

  useEffect(() => {
    if (show) {
      setListFiles(newFiles);
    }
  }, [show]);

  const validateFile = file => {
    const fileTypes = [
      'image/jpg',
      'application/vnd.ms-excel',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.spreadsheet-template',
      'text/csv',
      'text/plain',
      'application/msword',
      'image/png',
      'image/jpeg',
      'image/bmp',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/pdf',
    ];
    if (fileTypes.includes(file.type)) {
      if (file.size >= 30000000) {
        Alert.error('Seu arquivo precisa ter menos de 30MB');
        return false;
      }
    } else {
      Alert.error('Arquivo inválido!');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (presentationFile.length > 0) {
      const copy = [...presentationFile][0];
      if (validateFile(copy)) {
        copy['_type'] = 1;
        setListFiles(lf => {
          const filtered = lf.filter(fl => fl._type !== 1);
          return [...filtered, copy];
        });
      }
    }
  }, [presentationFile]);

  useEffect(() => {
    if (tableFile.length > 0) {
      const copy = [...tableFile][0];
      if (validateFile(copy)) {
        copy['_type'] = 2;
        setListFiles(lf => {
          const filtered = lf.filter(fl => fl._type !== 2);
          return [...filtered, copy];
        });
      }
    }
  }, [tableFile]);

  useEffect(() => {
    if (othersFiles.length > 0) {
      const copies = [...othersFiles].filter(otf => validateFile(otf));
      copies.forEach(cp => {
        cp['_type'] = 3;
      });
      setListFiles(l => [...l, ...copies]);
    }
  }, [othersFiles]);

  const removeFile = file => {
    const filtered = listFiles.filter(lf => lf.name !== file.name);
    setListFiles(filtered);
  };

  const setFiles = () => {
    setNewFiles(listFiles);
    toggle();
  };

  return (
    <Modal
      backdrop={true}
      centered={true}
      toggle={toggle}
      isOpen={show}
      onClosed={onClosed}
      size="lg">
      <div className="upload-modal">
        <div className="div-header">
          <div className="div-icon-upload">
            <BsCloudUpload className="icon-upload" />
          </div>
          <span className="title-header">
            Upload de <span style={{ color: '#E9A662' }}>Documentos</span>
            <br></br>
            <span className="subtitle-header">
              Suba os documentos necessários para aprovação do Briefing do
              projeto para seu cliente <br />e os que serão utilizados pelas
              outras áreas da Amicci.
            </span>
          </span>
          <div>
            <BsXCircle className="icon-close" onClick={toggle} />
          </div>
        </div>
        <br></br>
        <div className="div-body">
          <p className="title-body">Orientação para subida do documento</p>
          <p className="body-modal">
            <AiOutlineStar className="star-icon" />
            <span>
              “Apresentação para Cliente” aceita apenas arquivos .pptx, .pdf,
              .ppt, a <br />
              “Planilha de cliente” aceita apenas arquivos .xls, .xlsx.
              <br />
              Esses são arquivos destinados para visualização do Cliente.
            </span>
            <br></br>
            <br></br>
            <AiOutlineStar className="star-icon" />
            <span>
              “Outros arquivos” aceita quaisquer outros tipos de arquivos que
              são <br />
              destinados a áreas internas da Amicci (Ex: DTI, Sourcing).
            </span>
            <br></br>
            <br></br>
            <BsFillExclamationOctagonFill className="warning-icon" />
            <span className="body-message">
              <span style={{ color: '#F29232' }}>ATENÇÃO!</span> É obrigatória a
              subida de, ao menos, a{' '}
              <span style={{ color: '#F29232' }}>apresentação</span> ou a{' '}
              <span style={{ color: '#F29232' }}>planilha</span>
              <br />
              para que nossos clientes tenham a visualização do Briefing do seu
              projeto.
            </span>
          </p>
          <br />
          <div
            style={{
              border: '1px solid #9B9B9B',
              marginRight: '50px',
              background: '#F4F4F4',
              borderRadius: '4px',
              height: '200px',
              overflowY: 'scroll',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <div className="div-icon-doc">
              <label className="link-doc">
                <BsFileEarmarkText className="icon-doc" />
                Apresentação para cliente
                <BiUpload className="upload-icon" />
                <input
                  type="file"
                  style={{ display: 'none' }}
                  accept=".pptx"
                  onChange={e => setPresentationFile(e.target.files)}
                />
              </label>
              <ul className="name-doc">
                {listFiles
                  .filter(l => l._type === 1)
                  .map(l => (
                    <li key={l.name + l._type}>
                      {l.name}
                      <button type="button" onClick={() => removeFile(l)}>
                        <BiX size={24} />
                      </button>
                    </li>
                  ))}
              </ul>
            </div>

            <div className="div-icon-doc">
              <label className="link-doc">
                <BsFileEarmarkText className="icon-doc" />
                Planilha para cliente
                <BiUpload className="upload-icon" />
                <input
                  type="file"
                  style={{ display: 'none' }}
                  accept=".xlsx"
                  onChange={e => setTableFile(e.target.files)}
                />
              </label>
              <ul className="name-doc">
                {listFiles
                  .filter(l => l._type === 2)
                  .map(l => (
                    <li key={l.name + l._type}>
                      {l.name}
                      <button type="button" onClick={() => removeFile(l)}>
                        <BiX size={24} />
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="div-icon-doc">
              <label className="link-doc">
                <BsFileEarmarkText className="icon-doc" />
                Outros arquivos
                <BiUpload className="upload-icon" />
                <input
                  type="file"
                  style={{ display: 'none' }}
                  accept=".ppt,.pptx,.xls,.xlsx,.pdf,.jpeg,.doc,.docx,.jpg,.png"
                  onChange={e => setOthersFiles(e.target.files)}
                  multiple
                />
              </label>
              <ul className="name-doc">
                {listFiles
                  .filter(l => l._type === 3)
                  .map(l => (
                    <li key={l.name + l._type}>
                      {l.name}
                      <button type="button" onClick={() => removeFile(l)}>
                        <BiX size={24} />
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <br />
        <div className="footer-modal">
          <button className="button-footer" type="button" onClick={setFiles}>
            Enviar arquivos
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(ModalUploadDoc);
