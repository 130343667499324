/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import InputText from '../../../../../Components/InputText/InputText';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { Alert } from 'rsuite';
import ButtonDefault from '../../../../../Components/ButtonDefault/ButtonDefault';
import './ModalComment.scss';
import apiConnectManagement from '../../../../../services/apiConnectManagement';
import { useCognitoAuth } from '../../../../../Contexts/AuthCognitoContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { classNames } from '../../../../../utils/utils';

const ModalComment = (
  {
    initialDataObs = {},
    setInitialDataObs = () => {},
    moduleId = null,
    moduleType = null,
    moduleStage = null,
    moduleStages = [],
    setModuleStages = () => {},
    displayOnly = false,
    displayStatusText = null,
    displayStageText = null,
    displayObs = '',
    fetchRegActivities,
    onSubmit = () => {},
  },
  ref
) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [obsTypeList, setObsTypeList] = useState([]);
  const { user } = useCognitoAuth();
  const validationSchema = Yup.object({
    obs: Yup.string()
      .max(500, ' Limite de 500 caracteres')
      .required('Campo Obrigatório'),
    obsType: Yup.string().required('Campo Obrigatório').nullable(),
  });

  useEffect(() => {
    apiConnectManagement
      .get(
        `/module_type/${moduleType}/project_module/${moduleId}/show_module_name`
      )
      .then(response => {
        console.log('response', response.data);
        if (response?.data) {
          setObsTypeList(response?.data?.stages_list);
        }
      });
  }, []);

  const { handleSubmit, handleChange, values, errors, resetForm } = useFormik({
    initialValues: initialDataObs,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      onHandleConfirm(values);
    },
  });

  const onHandleConfirm = values => {
    setLoadingConfirm(true);
    const body = {
      user: user,
      obs: values.obs,
      obs_type: 12,
      activity: 5,
    };
    apiConnectManagement
      .post(
        `/module_type/${moduleType}/project_module/${moduleId}/stage/${values.obsType}/add_comment`,
        body
      )
      .then(response => {
        if (response?.data) {
          // onHandleUpdateStages(response?.data.stage_module);
          onSubmit();
          closeModal();
          resetForm();
          fetchRegActivities();
        }
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else if (error?.response?.status === 400) {
          Alert.error(error.response.data.error, 50000);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado, tente novamente mais tarde!'
          );
        }
      })
      .finally(() => {
        setLoadingConfirm(false);
      });
  };

  // const onHandleUpdateStages = stage => {
  //   const stageIndex = moduleStages.findIndex(moduleStage => {
  //     return moduleStage.id === stage.id;
  //   });
  //   const tempStages = [...moduleStages];
  //   tempStages[stageIndex] = stage;

  //   setModuleStages(tempStages);
  // };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  function closeModal() {
    setShowModal(!showModal);
    setInitialDataObs({ obs: '', obsType: null });
    resetForm();
  }

  // const onHandleNewObs = () => {
  //   setInitialDataObs({ obs: '', obsType: null });
  //   let node = document.getElementsByName('obsType');
  //   console.log(node);
  //   if (node) {
  //     node.forEach(ob => {
  //       ob.removeAttribute('checked');
  //     });
  //   }
  // };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={false}
      centered={true}
      className="custom-modal modal-dialog-large">
      <ModalHeader>
        <Row>
          <Col md={1} className="icon-head-observation">
            <svg
              id="Icon"
              width="43.9"
              height="45.589"
              viewBox="0 0 43.9 45.589">
              <defs>
                {/* <linearGradient
                  id="linear-gradient"
                  x1="0.808"
                  y1="0.102"
                  x2="0.258"
                  y2="0.89"
                  gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#e5af76" />
                  <stop offset="1" stop-color="#f29435" />
                </linearGradient> */}
              </defs>
              <ellipse
                id="Ellipse_13"
                data-name="Ellipse 13"
                cx="21.95"
                cy="22.794"
                rx="21.95"
                ry="22.794"
                // fill="url(#linear-gradient)"
              />
              <path
                id="Subtraction_1"
                data-name="Subtraction 1"
                d="M-3857-1563a15.9,15.9,0,0,1-11.314-4.686A15.9,15.9,0,0,1-3873-1579a15.9,15.9,0,0,1,4.686-11.314A15.9,15.9,0,0,1-3857-1595a15.9,15.9,0,0,1,11.314,4.686A15.9,15.9,0,0,1-3841-1579a15.9,15.9,0,0,1-4.686,11.314A15.9,15.9,0,0,1-3857-1563Zm-.04-13.186a2.107,2.107,0,0,0-2.28,2.061,2.147,2.147,0,0,0,2.28,2.1,2.136,2.136,0,0,0,2.26-2.1A2.1,2.1,0,0,0-3857.04-1576.186Zm-2.26-10.019h0l.74,8.9h3.02l.76-8.9Z"
                transform="translate(3879 1602)"
                fill="#fff"
              />
            </svg>
          </Col>
          <Col md={6} className="texts-modal texts-modal-v2">
            <h2 className="text-title-observation">Observação da etapa</h2>
            <h3 className="sub-title-observation"> </h3>
          </Col>
          <Col md={5}>
            {!displayOnly && (
              <section className="box-modal-blue">
                Selecione a etapa a qual deseja inserir uma observação. Após
                isso, escreva a observação e confirme. Ela ficará disponível no
                Registro de Atividades.
              </section>
            )}
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row className="hold-radio-button">
          <Col md={5}>
            <section>
              <div>
                <label>Etapa da Observação</label>
              </div>
            </section>
            <section>
              <select
                placeholder="Etapa"
                className="select-etapa"
                onChange={handleChange('obsType')}
                value={values.obsType}
                defaultValue=""
                readOnly={displayOnly}
                disabled={displayOnly}>
                <option value="" disabled>
                  Etapa
                </option>
                {obsTypeList.map(obsType => (
                  <option value={obsType.id} key={obsType}>
                    {obsType.name}
                  </option>
                ))}
              </select>
            </section>
            <span className="error">
              {errors.obsType ? errors.obsType : null}
            </span>
          </Col>
          <Col md={7}>
            <label className="text-observation">Observações adicionais</label>
            <InputText
              maxLength={500}
              className={classNames({
                'custom-textarea': true,
                'error-input': Boolean(errors.obs),
              })}
              readOnly={displayOnly}
              type="textarea"
              value={values.obs}
              onChange={handleChange('obs')}
              placeholder="Ex: O etapa entrou em atraso devido a demora para o cliente efetuar a validação dos documentos ........"></InputText>
            <small className="text-limit">Limite de 500 caracteres</small>
            <br />
            <span className="error">{errors.obs ? errors.obs : null}</span>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        {displayOnly ? (
          <ButtonDefault color="gradBlue2" onClick={closeModal}>
            Fechar
          </ButtonDefault>
        ) : (
          <>
            <ButtonDefault color="red" onClick={closeModal} radius>
              Cancelar
            </ButtonDefault>
            <ButtonDefault
              color="green"
              radius
              onClick={handleSubmit}
              disabled={loadingConfirm}
              loading={loadingConfirm}>
              Confirmar
            </ButtonDefault>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default forwardRef(ModalComment);
