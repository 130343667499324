import React, { forwardRef, useImperativeHandle } from 'react';
import classes from './styles.module.css';
import { Modal, ModalBody } from 'reactstrap';
import clsx from 'clsx';
import { CgCloseO } from 'react-icons/cg';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import useToggle from '../../../../../../../../utils/hooks/useToggle';

// eslint-disable-next-line no-empty-pattern
const ModalReasons = ({}, ref) => {
  const [open, toggleOpen] = useToggle(false, true);

  useImperativeHandle(ref, () => ({
    toggleOpen,
  }));

  return (
    <Modal centered size="lg" toggle={toggleOpen} isOpen={open}>
      <ModalBody className={clsx(classes.content, classes.modalBody)}>
        <button
          type="button"
          className={classes.btnCloseCustom}
          onClick={toggleOpen}>
          <CgCloseO />
        </button>
        <div className={clsx('col-12', classes.guideCol)}>
          <div className={clsx(classes.compGuideSquare, classes.blue)}>
            <HiQuestionMarkCircle />
          </div>
          <p>
            <strong>
              Coluna <span className={classes.orange}>Resultado</span>
            </strong>
            <br />
            Dentro da coluna{' '}
            <span className={classes.highlight}>Resultado</span> você irá
            visualizar duas informações, uma sobre o{' '}
            <span className={classes.orange}>resultado</span> da concorrência e
            a outra sobre o envio da{' '}
            <span className={classes.orange}>planilha de custo</span>.
          </p>
        </div>
        <div className={classes.list}>
          <p className={classes.midHead}>
            Orientação para leitura da informação
          </p>
          <div className="row">
            <div className="col">
              <div className={classes.item}>
                <div className={classes.icoBox}>
                  <CircularProgressbar
                    value={(2 * 100) / 5}
                    text={`${2}/${5}`}
                    strokeWidth={10}
                    styles={buildStyles({
                      rotation: 0.25,
                      strokeLinecap: 'butt',
                      pathTransitionDuration: 0.5,
                      pathColor: `rgba(2, 153, 58, ${(2 * 100) / 5})`,
                      textColor: '#02993A',
                      textSize: '25px',
                      trailColor: '#32F37A',
                      backgroundColor: '#02993A',
                    })}
                  />
                </div>
                <p>
                  A primeira informação mostra o resultada da concorrência!
                  <br />
                  <span className={classes.orange}>
                    EX: 2 de 5 fornecedores já tiveram retorno da concorrência.
                  </span>
                </p>
              </div>

              <div className={classes.item}>
                <div className={classes.icoBox}>
                  <CircularProgressbar
                    value={(0 * 100) / 5}
                    text={`${0}/${5}`}
                    strokeWidth={10}
                    styles={buildStyles({
                      rotation: 0.25,
                      strokeLinecap: 'butt',
                      pathTransitionDuration: 0.5,
                      pathColor: `rgba(2, 153, 58, ${(0 * 100) / 5})`,
                      textColor: '#425BA6',
                      textSize: '25px',
                      trailColor: '#407BFF',
                      backgroundColor: '#02993A',
                    })}
                  />
                </div>
                <p>
                  A segunda informação mostra a quantidade de planilha de custos
                  recebida!
                  <br />
                  <span className={classes.orange}>
                    EX: 0 de 5 fornecedores já enviaram a planilha de custo.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default forwardRef(ModalReasons);
