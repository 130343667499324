import React, {
  forwardRef,
  useImperativeHandle,
  useRef
} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import useToggle from '../../../../utils/hooks/useToggle';
import apiConnectViability from '../../../../services/apiConnectViability';
import './ModalDefinitionSupplier.scss';
import ModalFinalSelect from './ModalFinalSelect';
import { useParams } from 'react-router-dom';


const ModalDefinitionSupplier = ({ duplicateSkus, definitionList }, ref) => {
  const [show, toggle] = useToggle();
 const modalFinalSelectRef = useRef(null);
 const { id } = useParams();

  useImperativeHandle(ref, () => ({ toggle }));

  function sendSupplier() {
    const chosed = definitionList.find(d => d.chosen_supplier);
    if(chosed) {
      const data = {
        supplier_id: chosed.supplier_id
      }
      apiConnectViability
        .post(`/projects/${id}/seleciona_fornecedor/`, data, {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            id_token: window.localStorage.getItem('id-token-cognito'),
            access_token: window.localStorage.getItem('access-token-cognito'),
          },
        })
        .then(ret => {
          console.log(ret.data);
          console.log('definição', definitionList);
          toggle()
        })
        .catch(e => {
          console.log(e);
        })
    }
  }

  return (
    <>
      <Button color="danger" onClick={toggle}>
        Confirmação de fornecedor
      </Button>

      <Modal
        backdrop={true}
        centered={true}
        className="modal-select-supplier"
        size="md"
        toggle={toggle}
        isOpen={show}>
        <ModalHeader toggle={toggle}>
          <p>
            Seleção de fornecedores para <span>Definição de Fornecedor</span>
          </p>
        </ModalHeader>
        <ModalBody>
          <p className="text-copy-sku">
            O seguinte fornecedor foi selecionado para a Definição de Fornecedor.<br />
            Por favor, revise sua escolha e clique em Confirmar. <br />Caso o
            fornecedor esteja incorreto, <span style={{color: "#FF6666", fontWeight: "bold"}}>cancele</span> e faça nova escolha.<br />
            <span style={{color: "#F08723", fontWeight: "bold"}}>Atenção</span>, ao confirmar, você só poderá trocar de fornecedor criando um novo projeto e cancelando o atual.
          </p>
          <section className="sectionSupplierSelected">
            <p className="text-copy-sku">
              Fornecedor selecionado
              <br></br>
            </p>
            <ul>
              {definitionList
                .filter(sp => sp.chosen_supplier === true)
                .map(t => (
                  <li key={t.id + t.supplier_name}>{t.supplier_name}</li>
                ))}
            </ul>
          </section>
        </ModalBody>
        <ModalFooter>
          <div className="divButtonFooter">
            <button onClick={toggle} className="btn btn-danger">
              Cancelar
            </button>
            <button onClick={() => sendSupplier()} className="btn btn-primary">
              Confirmar
            </button>
            <ModalFinalSelect ref={modalFinalSelectRef}/>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default forwardRef(ModalDefinitionSupplier);