import React, {
  forwardRef,
  useImperativeHandle,
  useRef
} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import useToggle from '../../../../utils/hooks/useToggle';
import apiConnectViability from '../../../../services/apiConnectViability';
import './ModalSelectSupplier.scss';
import ModalFinalSelect from './ModalFinalSelect';


const ModalSelectSupplier = ({ duplicateSkus, supplierList, dataProject, getClients }, ref) => {
  const [show, toggle] = useToggle();
 const modalFinalSelectRef = useRef(null);

  useImperativeHandle(ref, () => ({ toggle }));

  function sendSuppliers() {
    apiConnectViability
      .put(`/projects/filtro_comercial`, supplierList, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
          id_token: window.localStorage.getItem('id-token-cognito'),
          access_token: window.localStorage.getItem('access-token-cognito'),
        },
      })
      .then(ret => {
        console.log(ret.data);
        console.log(supplierList);
        getClients();
        toggle()
      })
      .catch(e => {
        console.log(e);
      })
  }

  return (
    <>
      <Button color="danger" onClick={toggle}>
        Confirmação de fornecedor
      </Button>

      <Modal
        backdrop={true}
        centered={true}
        className="modal-select-supplier"
        size="md"
        toggle={toggle}
        isOpen={show}>
        <ModalHeader toggle={toggle}>
          <p>
            Seleção de fornecedores para <span>Definição de Fornecedor</span>
          </p>
        </ModalHeader>
        <ModalBody>
          <p className="text-copy-sku">
            O seguintes fornecedores foram selecionados para o <span style={{color: "#F08723", fontWeight: "bold"}}>Filtro Comercial</span>.
            <br />Por favor, revise sua escolha e clique em Confirmar. <br />Caso os
            fornecedores estejam errados, <span style={{color: "#FF6666", fontWeight: "bold"}}>cancele</span> e faça nova escolha. <br />
            <span style={{color: "#F08723", fontWeight: "bold"}}>Atenção</span>, ao confirmar, você só poderá fazer uma nova seleção abrindo um <span style={{color: "#425BA6"}}>suporte de ajuda</span> com nossa equipe e justificando a escolha.
          </p>
          <section className="sectionSupplierSelected">
            <p className="text-copy-sku">
              Fornecedores selecionados
              <br></br>
            </p>
            <ul>
              {supplierList
                .filter(sp => sp.status)
                .map(t => (
                  <li key={t.id + t.supplier_name}>{t.supplier_name}</li>
                ))}
            </ul>
          </section>
        </ModalBody>
        <ModalFooter>
          <div className="divButtonFooter">
            <button onClick={toggle} className="btn btn-danger">
              Cancelar
            </button>
            <button onClick={() => sendSuppliers()} className="btn btn-primary">
              Confirmar
            </button>
            <ModalFinalSelect ref={modalFinalSelectRef}/>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default forwardRef(ModalSelectSupplier);
