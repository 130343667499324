import React, { useState } from 'react';
// import ReactGA from 'react-ga';
import styles from './styles.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Col,
  Button,
  Modal,
  Icon,
  Row,
  Container,
  Form,
  SelectPicker,
  Alert,
  Loader,
} from 'rsuite';
import apiConnectSourcing from '../../../../../../../../services/apiConnectSourcing';

function ModalTransferDemand({
  projectName,
  clientName,
  responsibleName,
  closeModal,
  show,
  optionsResponsibles,
  taskId,
  tasks,
  setTasks,
  taskIndex,
}) {
  // ReactGA.pageview('/pdm');

  const validationSchema = Yup.object({
    demandResponsible: Yup.string().required('Campo Obrigatório'),
  });

  const [loading, setLoading] = useState(false);

  const { handleSubmit, values, errors, isValid, resetForm, setFieldValue } =
    useFormik({
      initialValues: {
        demandResponsible: '',
      },
      validateOnChange: false,
      validationSchema,
      onSubmit: values => {
        setLoading(true);
        apiConnectSourcing
          .put(`/tasks/${taskId}/update-manager/`, {
            sourcing_manager_user_id: Number(values.demandResponsible),
          })
          .then(response => {
            if (response.data) {
              Alert.success(
                'Responsável da demanda atualizado com Sucesso!',
                3000
              );
            } else {
              Alert.error(
                'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
                3000
              );
            }

            handleUpdateTask(taskIndex, response.data);
            setLoading(false);
            handleCancel();
          })
          .catch(error => {
            if (error.response?.status === 403) {
              Alert.error(error.response.data.message, 50000);
            } else {
              Alert.error(error.response.data.message, 5000);
            }
            setLoading(false);
            handleCancel();
          });
      },
    });

  const handleUpdateTask = (taskIndex, responseTask) => {
    const newTasks = [...tasks];
    newTasks[taskIndex] = {
      ...newTasks[taskIndex],
      userInfo: responseTask,
      sourcing_manager_user_id: responseTask.id,
    };
    setTasks(newTasks);
  };

  const handleCancel = () => {
    closeModal();
    resetForm();
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Modal show={show} onHide={closeModal}>
          <Modal.Header>
            <Container>
              <Row>
                <Col sm={12} md={12}>
                  <Row className={styles.iconTittle}>
                    <Col className={styles.modalIcon} md={5}>
                      <Icon icon="refresh" size="lg" />
                    </Col>
                    <Col className={styles.modalTittle} md={19}>
                      Transferir Demanda
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} md={12} style={{ textAlign: 'right' }}>
                  <Row>
                    <Col md={18}>
                      <span className={styles.modalDescription}>
                        Projeto:{' '}
                        <strong className={styles.modalDescriptionClient}>
                          {projectName}
                        </strong>
                      </span>
                    </Col>
                    <Col md={18}>
                      <span className={styles.modalDescription}>
                        Cliente:{' '}
                        <strong className={styles.modalDescriptionClient}>
                          {clientName}
                        </strong>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Header>

          <Modal.Body className={styles.body}>
            <Container>
              <Row style={{ marginTop: '10px' }}>
                <Col md={24}>
                  <span className={styles.modalDescription}>
                    Responsável:{' '}
                    <strong className={styles.modalDescriptionClient}>
                      {responsibleName}
                    </strong>
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col md={12}>
                  <label className={styles.modalDescription}>
                    Novo responsável pela demanda:
                  </label>
                  <SelectPicker
                    data={optionsResponsibles}
                    onChange={value => {
                      setFieldValue('demandResponsible', value);
                    }}
                    value={values.demandResponsible}
                    appearance="default"
                    placeholder="Responsável"
                    renderMenuItem={(label, item) => {
                      return <div>{label}</div>;
                    }}
                  />
                  <span className={styles.error}>
                    {errors.demandResponsible ? errors.demandResponsible : null}
                  </span>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disable={!isValid}
              type="submit"
              onClick={handleSubmit}
              color={'blue'}>
              {loading ? <Loader /> : 'Confirmar'}
            </Button>
            <Button onClick={handleCancel} appearance="subtle">
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
}

export default ModalTransferDemand;
