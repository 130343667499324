import React, { useState } from 'react';
// import ReactGA from 'react-ga';
import { classNames } from '../../../../../../../../utils/utils';
import styles from './styles.module.css';
import moment from 'moment';
import {
  Col,
  Button,
  Modal,
  Icon,
  Row,
  Container,
  Animation,
  Alert,
  Form,
  TagPicker,
} from 'rsuite';
import { InputGroup, InputGroupAddon, Input } from 'reactstrap';
import apiConnectSourcing from '../../../../../../../../services/apiConnectSourcing';
import { BiSearch } from 'react-icons/bi';
import { useCognitoAuth } from '../../../../../../../../Contexts/AuthCognitoContext';
import { ReactComponent as LinkedIndicator } from '../../../../../../../Pdm/Sourcing/Assets/icons/color-circ-linked.svg';
import { ReactComponent as ReleasedWithAmicciIndicator } from '../../../../../../../Pdm/Sourcing/Assets/icons/color-circ-released-with-amicci.svg';
import { ReactComponent as NotReleasedWithAmicciIndicator } from '../../../../../../../Pdm/Sourcing/Assets/icons/color-circ-not-released-with-amicci.svg';
import { ReactComponent as MadeOwnBrandIndicator } from '../../../../../../../Pdm/Sourcing/Assets/icons/color-circ-made-own-brand.svg';
import { ReactComponent as NotInterestedIndicator } from '../../../../../../../Pdm/Sourcing/Assets/icons/color-circ-not-interested.svg';
import { ReactComponent as NotMadeOwnBrandIndicator } from '../../../../../../../Pdm/Sourcing/Assets/icons/color-circ-not-made-own-brand.svg';

const PanelDetails = React.forwardRef(({ responsibleName, ...props }, ref) => (
  <Row
    {...props}
    ref={ref}
    style={{
      overflow: 'hidden',
      paddingleft: '200px',
    }}>
    <Col md={24}>
      <p>{responsibleName}</p>
      <p>Data: {moment(new Date()).format('DD/MM/YYYY')}</p>
    </Col>
  </Row>
));

function ModalInsertSupplier({
  show,
  closeModal,
  prospection,
  setProspection,
  responsibleName,
  setFieldValue,
  getProspection,
}) {
  // ReactGA.pageview('/pdm');

  const [showCollapse, setShowCollapse] = useState(false);
  const { Collapse } = Animation;
  const [suplierNames, setSuplierNames] = useState([]);
  const [suppliersInput, setSuppliersInput] = useState();
  const [suppliersData, setSuppliersData] = React.useState([]);
  const [suppliersDataCache, setSuppliersDataCache] = React.useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const { user } = useCognitoAuth();

  const handleUpdateSupplier = responseSupplier => {
    setProspection({
      ...prospection,
      suppliers: responseSupplier.suppliers,
      supplierInfo: responseSupplier.supplierInfo,
      complexity: responseSupplier.complexity.id,
    });
    setFieldValue('complexity', responseSupplier.complexity.id);
  };

  const handleToogleCollpase = () => {
    setShowCollapse(!showCollapse);
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    apiConnectSourcing
      .post(`prospections/${prospection.id}/suppliers/`, {
        supplier_id: suplierNames,
        user: user,
      })
      .then(async response => {
        if (response.data) {
          if (response.data.suppliers.length > 0) {
            handleUpdateSupplier(response.data);
            Alert.success('Fornecedor(es) cadastrado(s) com Sucesso!', 3000);
            setTimeout(() => {
              if (getProspection) getProspection();
            }, 1000);
          }
        } else {
          Alert.error(
            'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
            3000
          );
        }
        handleCancel();
        setIsSubmit(false);
      })
      .catch(error => {
        if (error.response?.data?.not_started) {
          Alert.error(error.response.data.error, 5000);
        } else if (error.response?.status === 403) {
          Alert.error(error.response.data.message, 5000);
        } else {
          Alert.error(
            'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
            5000
          );
        }

        setIsSubmit(false);
      });
  };

  const handleCancel = () => {
    closeModal();
    setSuppliersData([]);
    setSuplierNames([]);
  };

  const handleTagPicker = supplierIds => {
    setSuplierNames(supplierIds);
    const selectedSuppliers = [];
    for (let id of supplierIds) {
      const supplier = suppliersDataCache.find(s => s.id === id);
      if (supplier) {
        selectedSuppliers.push(supplier);
      }
    }
  };

  const handleSuppliers = () => {
    const value = suppliersInput;
    const input = document.getElementById('tagpick');
    if (value && value.length < 2) {
      Alert.error('No minimo 2 letras');
    } else {
      searchProviders(value);
      input.click();
    }
  };

  const searchProviders = async (term, page = 1) => {
    try {
      const response = await apiConnectSourcing.get(
        `search-providers/?filter=${term}&page=${page}`
      );
      setSuppliersDataCache(sup => [...sup, ...response.data.results]);
      if (page === 1) {
        setSuppliersData(response.data.results);
      } else {
        setSuppliersData(sup => [...sup, ...response.data.results]);
      }
      if (response.data.next) {
        searchProviders(term, page + 1);
      }
    } catch (error) {
      Alert.error('Não encontramos nenhum fornecedor');
    }
  };
  return (
    <>
      <Form>
        <Modal show={show} onHide={closeModal}>
          <Modal.Header>
            <Container>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Col sm={2}>
                  <Row className={styles.iconTittle}>
                    <Col className={styles.modalIcon} md={5}>
                      <Icon icon="bell-o" size="lg" />
                    </Col>
                  </Row>
                </Col>
                <Col sm={11}>
                  <span className={styles.modalDescription}>
                    Projeto:{' '}
                    <strong className={styles.modalDescriptionClient}>
                      {prospection?.briefing_name}
                    </strong>
                  </span>
                </Col>
                <Col sm={11}>
                  <span className={styles.modalDescription}>
                    Cliente:{' '}
                    <strong className={styles.modalDescriptionClient}>
                      {prospection?.clientInfo.name}
                    </strong>
                  </span>
                </Col>
              </Row>
            </Container>
          </Modal.Header>

          <Modal.Body className={styles.body}>
            <Container>
              <Row>
                <Col md={24}>
                  <InputGroup>
                    <Input
                      className="inputText noShadow"
                      placeholder="Buscar fornecedores"
                      name="suppliersInput"
                      type="text"
                      value={suppliersInput}
                      onChange={e => {
                        setSuppliersInput(e.target.value);
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <Button onClick={() => handleSuppliers()} color="light">
                        <BiSearch size={20} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <TagPicker
                    onChange={e => handleTagPicker(e)}
                    value={suplierNames}
                    creatable={true}
                    name="suplierNames"
                    label="name"
                    defaultValue="id"
                    labelKey="name"
                    valueKey="id"
                    size="lg"
                    block
                    searchable={false}
                    childrenKey="name"
                    id="tagpick"
                    data={suppliersData}
                    placeholder="Selecione os fornecedores"
                    className="tagPicker"
                    renderMenuItem={(label, item) => {
                      return (
                        <div
                          className={classNames({
                            [styles.selItemLabel]: true,
                            tagLabels: true,
                          })}>
                          {item.name}
                          <div className={styles.linkIcon}>
                            {item.is_seller_linked && <LinkedIndicator />}

                            {item.has_amicci_products ? (
                              <ReleasedWithAmicciIndicator />
                            ) : (
                              <NotReleasedWithAmicciIndicator />
                            )}

                            {item?.make_mp === 1 ? (
                              <MadeOwnBrandIndicator />
                            ) : item?.make_mp === 2 ? (
                              <NotInterestedIndicator />
                            ) : (
                              item?.make_mp === 3 && (
                                <NotMadeOwnBrandIndicator />
                              )
                            )}
                          </div>
                        </div>
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col sm={6} md={6}>
                  <span
                    className={styles.buttonDetails}
                    onClick={handleToogleCollpase}>
                    {' '}
                    Detalhes{' '}
                    <Icon
                      style={{ color: '615b56', fontWeight: 700 }}
                      icon={classNames({
                        'angle-right': !showCollapse,
                        'angle-down': showCollapse,
                      })}
                    />
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col md={24}>
                  <Collapse in={showCollapse}>
                    <PanelDetails responsibleName={responsibleName} />
                  </Collapse>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={suplierNames.length === 0}
              type="submit"
              onClick={handleSubmit}
              color={'blue'}
              loading={isSubmit}>
              Confirmar
            </Button>
            <Button onClick={handleCancel} appearance="subtle">
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
}

export default ModalInsertSupplier;
