import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import styles from '../../Sourcing/Sourcing.module.css';
import ProspectionList from './Components/ProspectionList';
import ProspectionDetails from './Components/ProspectionDetails';
import globalStyle from '../styles.module.css';
import MenuPlm from '../../../../Components/Sidebar/MenuPdm';

function Prospection() {
  return (
    <>
      <Sidebar hideLogo>
        <MenuPlm opened="sourcing" />
      </Sidebar>
      <main className={styles.homeBriefing}>
        <Routes>
          <Route
            path="/"
            element={<ProspectionList globalStyle={globalStyle} />}
          />
          <Route
            path="/:id"
            element={<ProspectionDetails globalStyle={globalStyle} />}
          />
        </Routes>
      </main>
    </>
  );
}

export default Prospection;
