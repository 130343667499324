import React from 'react';
import './Pagination.scss';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { classNames } from '../../utils/utils';

const MAX_BUTTONS = 5;
const MAX_LEFT = (MAX_BUTTONS - 1) / 2;

const Pagination = ({
  pages,
  currentPage,
  setCurrentPage,
  loading,
  newType,
}) => {
  const maxFirst = Math.max(pages - (MAX_BUTTONS - 1), 1);
  const first = Math.min(Math.max(currentPage - MAX_LEFT, 1), maxFirst);
  const onChangePage = page => {
    setCurrentPage(page);
  };
  return (
    <div
      className={classNames({
        'pagination-area': true,
        'new-type': !!newType,
      })}>
      <ul className="pagination">
        {!newType && (
          <li>
            <button
              className="arrows"
              onClick={() => onChangePage(1)}
              disabled={loading || currentPage === 1}>
              Primeira
            </button>
          </li>
        )}
        <li>
          <button
            className="arrows"
            onClick={() => onChangePage(currentPage - 1)}
            disabled={loading || currentPage === 1}>
            {newType ? <AiOutlineLeft /> : 'Anterior'}
          </button>
        </li>
        {Array.from({ length: Math.min(MAX_BUTTONS, pages) })
          .map((_, i) => i + first)
          .map(page => (
            <li key={page}>
              <button
                className={classNames({
                  active: currentPage === page,
                })}
                onClick={() => onChangePage(page)}
                disabled={loading}>
                {page}
              </button>
            </li>
          ))}
        <li>
          <button
            className="arrows"
            onClick={() => onChangePage(currentPage + 1)}
            disabled={loading || currentPage === pages}>
            {newType ? <AiOutlineRight /> : 'Próxima'}
          </button>
        </li>
        {!newType && (
          <li>
            <button
              className="arrows"
              onClick={() => onChangePage(pages)}
              disabled={loading || currentPage === pages}>
              Última
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Pagination;
