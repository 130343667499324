import React, { forwardRef, useImperativeHandle } from 'react';
import './ModalStepNotEnabled.scss';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import useToggle from '../../../../utils/hooks/useToggle';

import { BsExclamationTriangleFill } from "react-icons/bs";

const ModalStepNotEnabled = ({ duplicateSkus }, ref) => {
  const [show, toggle] = useToggle();

  useImperativeHandle(ref, () => ({ toggle }));

  return (
    <>
    <Button color="danger" onClick={toggle}>Etapa não habilitada</Button>
    <Modal
      backdrop={true}
      centered={true}
      className="modal-cd-destiny-sku"
      size="sm"
      toggle={toggle}
      isOpen={show}>
      <ModalHeader toggle={toggle}>
        <div>
          Etapa ainda não habilitada
          <svg><BsExclamationTriangleFill /></svg>
        </div>
      </ModalHeader>
      <ModalBody >
        <p className="text-copy-sku">
        A aba de Definição de Fornecedor ainda não está habilitada para esse projeto. Para ter acesso a essa ação o projeto precisa já estar na fase de <span>Definição de Fornecedor</span>. Assim que essa fase for iniciada em seu Módulo de Gestão, a aba estará disponível para uso.
        </p>
      </ModalBody>
      <ModalFooter>
        <div className='divButtonFooter'>
          <button onClick={toggle} className="btn btn-primary">
            Voltar
          </button>
        </div>
      </ModalFooter>
    </Modal>
    </>
  );
};

export default forwardRef(ModalStepNotEnabled);