import React, { useState } from 'react';
// import ReactGA from 'react-ga';
import { classNames } from '../../../../../../../../utils/utils';
import styles from './styles.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {
  Col,
  Button,
  Modal,
  Icon,
  Row,
  Container,
  InputNumber,
  Animation,
  Alert,
  Form,
} from 'rsuite';
import apiConnectSourcing from '../../../../../../../../services/apiConnectSourcing';
import { useCognitoAuth } from '../../../../../../../../Contexts/AuthCognitoContext';
const PanelDetails = React.forwardRef(({ responsibleName, ...props }, ref) => (
  <Row
    {...props}
    ref={ref}
    style={{
      overflow: 'hidden',
      paddingleft: '200px',
    }}>
    <Col md={24}>
      <p>{responsibleName}</p>
      <p>Data: {moment(new Date()).format('DD/MM/YYYY')}</p>
    </Col>
  </Row>
));

function ModalProspectionExtension({
  show,
  prospection,
  closeModal,
  prospectionIndex,
  prospections,
  setProspections,
  responsibleName,
}) {
  // ReactGA.pageview('/pdm');

  const [showCollapse, setShowCollapse] = useState(false);
  const [loading, setLoading] = useState(false);
  const { Collapse } = Animation;
  const { user } = useCognitoAuth();
  const validationSchema = Yup.object({
    days: Yup.number()
      .min(1, 'A quantidade de dias não pode ser 0')
      .required('Campo Obrigatório'),
    note: Yup.string().required('Campo Obrigatório'),
  });

  const { handleSubmit, handleChange, values, errors, isValid, resetForm } =
    useFormik({
      initialValues: {
        days: 0,
        note: '',
      },
      validationSchema,
      validateOnChange: false,
      onSubmit: values => {
        setLoading(true);
        apiConnectSourcing
          .post(`/extensions/`, {
            prospection: prospection?.id,
            days: parseInt(values.days),
            note: values.note,
            user: user,
          })
          .then(response => {
            if (response.data) {
              console.log(response.data);
              handleUpdateExtension(
                response.data.extension,
                response.data.finish_forecast
              );
              closeModal();
              Alert.success('Prorrogação adicionado com Sucesso!', 3000);
            } else {
              Alert.error(
                'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
                3000
              );
            }
            setLoading(false);
          })
          .catch(error => {
            if (error.response?.status === 403) {
              Alert.error(error.response.data.message, 50000);
            } else {
              Alert.error(
                'Aconteceu um erro inesperado. Tente novamente, mais tarde!'
              );
            }
            setLoading(false);
          });
      },
    });

  const handleUpdateExtension = (responseExtension, finishForecast) => {
    let newProspection = [...prospections];
    newProspection[prospectionIndex] = {
      ...newProspection[prospectionIndex],
      extensions: [
        ...newProspection[prospectionIndex].extensions,
        responseExtension,
      ],
      finish_forecast: finishForecast,
    };
    setProspections(newProspection);
  };

  const handleToogleCollpase = () => {
    setShowCollapse(!showCollapse);
  };

  const handleCancel = () => {
    closeModal();
    resetForm();
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Modal show={show} onHide={closeModal}>
          <Modal.Header>
            <Container>
              <Row>
                <Col sm={12} md={12}>
                  <Row className={styles.iconTittle}>
                    <Col className={styles.modalIcon} md={5}>
                      <Icon icon="bell-o" size="lg" />
                    </Col>
                    <Col className={styles.modalTittle} md={8}>
                      Prorrogação
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} md={12}>
                  <Row>
                    <Col md={24}>
                      <span className={styles.modalDescription}>
                        Projeto:{' '}
                        <strong className={styles.modalDescriptionClient}>
                          {prospection?.briefing_name}
                        </strong>
                      </span>
                    </Col>
                    <Col md={24}>
                      <span className={styles.modalDescription}>
                        Cliente:{' '}
                        <strong className={styles.modalDescriptionClient}>
                          {prospection?.clientInfo.name}
                        </strong>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Header>

          <Modal.Body className={styles.body}>
            <Container>
              <Row>
                <Col md={24}>
                  <textarea
                    value={values.note}
                    placeholder="Digite aqui"
                    className={styles.bodyCard}
                    onChange={handleChange('note')}></textarea>
                  <span className={styles.error}>
                    {errors.note ? errors.note : null}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '10px' }}>
                <Col sm={6} md={6}>
                  <span
                    className={styles.buttonDetails}
                    onClick={handleToogleCollpase}>
                    {' '}
                    Detalhes{' '}
                    <Icon
                      style={{ color: '615b56', fontWeight: 700 }}
                      icon={classNames({
                        'angle-right': !showCollapse,
                        'angle-down': showCollapse,
                      })}
                    />
                  </span>
                </Col>

                <Col sm={6} md={6} smPush={12}>
                  <InputNumber
                    defaultValue={0}
                    max={100}
                    min={0}
                    value={values.days}
                    postfix="dia(s)"
                    onChange={handleChange('days')}
                  />
                  <span className={styles.error}>
                    {errors.days ? errors.days : null}
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col md={24}>
                  <Collapse in={showCollapse}>
                    <PanelDetails responsibleName={responsibleName} />
                  </Collapse>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disable={!isValid}
              type="submit"
              onClick={handleSubmit}
              appearance="primary"
              loading={loading}>
              Confirmar
            </Button>
            <Button onClick={handleCancel} appearance="subtle">
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
}

export default ModalProspectionExtension;
