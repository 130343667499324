import React, { createContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import apiConnectSourcing from '../services/apiConnectSourcing';
import apiConnectManagement from '../services/apiConnectManagement';
import { Alert } from 'rsuite';
import setCognitoCredentials from '../Components/Helpers/setCognitoCredentials';
// import removeCognitoCredentials from '../Components/Helpers/removeCognitoCredentials';
import getCognitoCredentials from '../Components/Helpers/getCognitoCredentials';
const AuthCognitoContext = createContext({});

export function AuthCognitoProvider({ children }) {
  const [loading, setLoading] = useState(null);
  const [user, setUser] = useState(null);
  const [userGroup, setUserGroup] = useState([]);
  const [stepForgotPassword, setStepForgotPassword] = useState(0);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    setLoading(true);

    async function loadStoregedData() {
      const { idToken, accessToken, userCognito, userGroupCognito } =
        getCognitoCredentials();

      if (idToken && accessToken && userCognito && userGroupCognito) {
        setUser(JSON.parse(userCognito));
        setUserGroup(JSON.parse(userGroupCognito));
        apiConnectSourcing.defaults.headers.common['authorization'] = 'x';
        apiConnectSourcing.defaults.headers.common['id_token'] = idToken;

        apiConnectSourcing.defaults.headers.common['access_token'] =
          accessToken;

        apiConnectManagement.defaults.headers.common['authorization'] = 'x';
        apiConnectManagement.defaults.headers.common['id_token'] = idToken;
        apiConnectManagement.defaults.headers.common['access_token'] =
          accessToken;
      }
      setLoading(false);
      setIsFinished(true);
    }
    loadStoregedData();
  }, []);

  const signIn = (username, password) => {
    setLoading(true);
    Auth.signIn({
      username,
      password,
    })
      .then(cognitoUser => {
        setIsFinished(true);
        Auth.currentSession()
          .then(userSession => {
            localStorage.setItem(
              'user-cognito',
              JSON.stringify({
                username: cognitoUser.username,
                attributes: cognitoUser.attributes,
              })
            );
            setUser({
              username: cognitoUser.username,
              attributes: cognitoUser.attributes,
            });
            setCognitoCredentials(userSession, apiConnectSourcing);
            setLoading(false);
          })
          .catch(err => {
            Alert.error(
              'Error ao fazer login, contate o administrador do sistema!'
            );
            setLoading(false);
          });
      })
      .catch(err => {
        if (err.code === 'UserNotFoundException') {
          Alert.error('Usuário não existe!');
        } else {
          if (err.code === 'NotAuthorizedException') {
            Alert.error('Usuário ou senha incorretos!');
          } else {
            Alert.error(
              'Error ao fazer login, contate o administrador do sistema!'
            );
          }
        }
        setLoading(false);
      });
  };

  const signOut = () => {
    setLoading(true);
    setIsFinished(false);
    Auth.signOut();
    setUser(null);
    localStorage.clear();
    setLoading(false);
  };
  const forgotPassword = username => {
    setLoading(true);
    Auth.forgotPassword(username)
      .then(response => {
        setStepForgotPassword(2);
        setLoading(false);
      })
      .catch(err => {
        if (err.code === 'UserNotFoundException') {
          Alert.error('Usuário não encontrado!');
        } else {
          if (err.code === 'LimitExceededException') {
            Alert.error(
              'Limite de tentativas excedido, tente novamente mais tarde.'
            );
          } else {
            Alert.error(
              'Error ao recuperar senha, contate o administrador do sistema!'
            );
          }
        }
        setLoading(false);
      });
  };

  const forgotPasswordSubmit = (username, code, password) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      Auth.forgotPasswordSubmit(username, code, password)
        .then(response => {
          Alert.success(
            'Senha modificada com sucesso, em alguns instantes você será redirecionado para a tela de login',
            30000
          );
          setLoading(false);
          return resolve(true);
        })
        .catch(err => {
          if (err.code === 'InvalidPasswordException') {
            Alert.error(
              'A senha não está em conformidade com a política: a senha não é longa o suficiente',
              30000
            );
          } else {
            if (err.code === 'CodeMismatchException') {
              Alert.error(
                'Código de verificação fornecido inválido , tente novamente.',
                30000
              );
            } else {
              if (err.code === 'LimitExceededException') {
                Alert.error(
                  'Limite de tentativas excedido, tente depois de algum tempo.',
                  30000
                );
              } else {
                Alert.error(
                  'Error ao recuperar senha, contate o administrador do sistema!',
                  30000
                );
              }
            }
          }

          setLoading(false);
          return reject(false);
        });
    });
  };
  
  return (
    <AuthCognitoContext.Provider
      value={{
        authenticated: !!user,
        isFinished,
        user,
        userGroup,
        loading,
        stepForgotPassword,
        setStepForgotPassword,
        signIn,
        signOut,
        forgotPassword,
        forgotPasswordSubmit,
      }}>
      {children}
    </AuthCognitoContext.Provider>
  );
}
export const useCognitoAuth = () => React.useContext(AuthCognitoContext);
