/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
// import ReactGA from 'react-ga';
import { Link, Routes, Route } from 'react-router-dom';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import Loading from '../../../Components/Helpers/Loading';
import classSide from '../../../Components/Sidebar/Sidebar.module.css';
import supportIcon from '../../../Assets/supportIcon.png';
import api_permission from '../../../services/api-permission';
import { useEffect } from 'react';
import { useCognitoAuth } from '../../../Contexts/AuthCognitoContext';
import OpeningCallsReport from './OpeningCallsReport';

function OpeningCalls() {
  // ReactGA.pageview('/gestao-comercial/carrefour');
  const { user } = useCognitoAuth();
  const email = user.attributes.email;

  const [terminou_chamada_api, setTerminouChamadaAPI] = useState(true);
  const all_reports = [];

  useEffect(() => {
    api_permission
      .get(email, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        },
      })
      .then(response => {
        // setDataCarrefour(response.data);
        // alert(1);
        // alert(response.data[0].reportname);
        for (let perm in response.data) {
          // text += person[x];
          // alert(perm+response.data[perm]+response.data[perm].reportname);
          // alert(response.data[perm].reportname);
          let str = response.data[perm].reportname;
          all_reports.push(str);
        }
        // response.data.each( (thisitem) => {alert(thisitem);}  );

        // localStorage.setItem('permissionReports', JSON.stringify(all_reports));
        // localStorage.setItem('permissionKeys', JSON.stringify(response.data));

        setTerminouChamadaAPI(false); //aqui marca que pegou tudo das APIS e decide se dá mensagem de erro ou não pro user para permissão
      });
  }, []);

  if (terminou_chamada_api) return <Loading />;

  var names = all_reports;
  var namesList = names.map(function (name, i) {
    return (
      <li key={i}>
        <Link
          to={'../' + name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')}
          className={classSide.linkMenu}>
          <img src={supportIcon} alt={name} />
          {name.slice(0, 1).toUpperCase() + name.slice(1, name.length)}
        </Link>
      </li>
    );
  });

  return (
    <>
      <Sidebar link="/gestao-comercial" title="GESTÃO COMERCIAL">
        <Link
          className="btn-opening-calls"
          to="/gestao-comercial/abertura-chamados">
          Abertura de Chamados IC
        </Link>
        <ul className={classSide.ulMenuSidebar}>{namesList}</ul>
      </Sidebar>
      <main>
        <Routes>
          <Route path="/" element={<OpeningCallsReport />} />
        </Routes>
      </main>
    </>
  );
}

export default OpeningCalls;
