import React, { useState, forwardRef, useImperativeHandle } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import ButtonDefault from '../../../../../Components/ButtonDefault/ButtonDefault';
import './ModalApplyStep.scss';
// import apiConnectManagement from '../../../../../services/apiConnectManagement';
import { useCognitoAuth } from '../../../../../Contexts/AuthCognitoContext';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';
import { Alert } from 'rsuite';

const ModalApplyStep = (
  {
    moduleType,
    moduleStage,
    // setModuleStages,
    // onSubmit = () => {}
  },
  ref
) => {
  const { user } = useCognitoAuth();
  const { changeStatus, reloadProjectAndModule } = useManagementContext();
  const [showModal, setShowModal] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const now = new Date();
  const toggleModal = () => setShowModal(!showModal);

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  function closeModal() {
    setShowModal(!showModal);
  }

  const onHandleConfirm = async () => {
    setLoadingConfirm(true);
    const body = {
      user: user,
      date: moment(new Date()).format('YYYY-MM-DD').toString(),
    };
    const response = await changeStatus(
      moduleType,
      moduleStage?.project_module,
      moduleStage?.id,
      1,
      body
    );
    setLoadingConfirm(false);
    if (response?.success) {
      // onHandleUpdateStages(response?.data);
      // onHandleUpdateProjectModule(response?.data.project_module);
      // onSubmit();
      closeModal();
      reloadProjectAndModule();
    } else {
      Alert.error(response.message, 50000);
    }
  };

  // const onHandleUpdateStages = stage => {
  //   const stageIndex = stages.findIndex(moduleStage => {
  //     return moduleStage.id === stage.id;
  //   });
  //   const tempStages = [...stages];
  //   tempStages[stageIndex] = stage;

  //   setModuleStages(tempStages);
  // };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={false}
      centered={true}
      className="custom-modal">
      <ModalHeader>
        <Row>
          <Col md={1} className="icon-head-apply-step">
            <svg width="42" height="42.999" viewBox="0 0 50 49.999">
              <defs>
                {/* <linearGradient
                  id="linear-gradient"
                  x1="0.5"
                  x2="0.5"
                  y2="1"
                  gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#78f0a8" />
                  <stop offset="1" stop-color="#8dc6a5" />
                </linearGradient> */}
              </defs>
              <g id="bx-layer-plus" transform="translate(-3 -3)">
                <path
                  id="Path_13250"
                  data-name="Path 13250"
                  d="M51.709,20.788,29.155,9.288a2.691,2.691,0,0,0-2.42,0L4.29,20.693a2.215,2.215,0,0,0-.008,4.022L26.837,36.307a2.676,2.676,0,0,0,2.433,0l22.445-11.5a2.215,2.215,0,0,0,0-4.022ZM28.057,31.667,10.647,22.72,27.938,13.93,45.348,22.8Z"
                  transform="translate(0 5.199)"
                  // fill="url(#linear-gradient)"
                />
                <path
                  id="Path_13251"
                  data-name="Path 13251"
                  d="M27.41,53a2.471,2.471,0,0,0,1.209-.315l22.428-12.5-2.419-4.37L27.41,47.639,6.19,35.814l-2.419,4.37L26.2,52.684A2.486,2.486,0,0,0,27.41,53ZM47.347,3H42.363V8H37.379v5h4.984v5h4.984V13h4.984V8H47.347Z"
                  transform="translate(0.669 0)"
                  // fill="url(#linear-gradient)"
                />
              </g>
            </svg>
          </Col>
          <Col md={6} className="texts-modal">
            <h4 className="text-apply-step">Aplicar Etapa</h4>
          </Col>
          <Col md={5} className="date-modal">
            Data: {moment(now).locale('pt-br').format('DD/MM/YYYY')} <br />
            <span style={{ textTransform: 'capitalize' }}>
              {moment(now).format('dddd').split('-')[0]}
            </span>{' '}
            {moment(now).format('HH:mm')}
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <p>
          Você optou por
          <span className="text-color-blue"> aplicar esta etapa</span>. Ela irá
          aparecer no relatório final, porém, para contabilizar os dias você
          precisa dar início ao processo clicando em Iniciar. Obrigado.
        </p>
      </ModalBody>
      <ModalFooter>
        <ButtonDefault color="red" radius onClick={closeModal}>
          Cancelar
        </ButtonDefault>
        <ButtonDefault
          loading={loadingConfirm}
          disabled={loadingConfirm}
          color="green"
          radius
          onClick={onHandleConfirm}>
          Confirmar
        </ButtonDefault>
      </ModalFooter>
    </Modal>
  );
};

export default forwardRef(ModalApplyStep);
