/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useImperativeHandle } from 'react';
import '../sourcing.css';
import { CheckPicker, SelectPicker } from 'rsuite';
import { Modal, ModalBody } from 'reactstrap';
import useToggle from '../../../../utils/hooks/useToggle';
import { ReactComponent as OCloseIcon } from '../../../../Assets/o-close.svg';

const ModalProviderSearch = (
  {
    emptyProviderFilter,
    searchFilterProvider,
    setSearchFilterProvider,
    searchProviders,
  },
  ref
) => {
  const [modal, toggle] = useToggle();
  const ufList = [
    {
      label: 'AC',
      value: 'AC',
    },
    {
      label: 'AL',
      value: 'AL',
    },
    {
      label: 'AM',
      value: 'AM',
    },
    {
      label: 'AP',
      value: 'AP',
    },
    {
      label: 'BA',
      value: 'BA',
    },
    {
      label: 'CE',
      value: 'CE',
    },
    {
      label: 'DF',
      value: 'DF',
    },
    {
      label: 'ES',
      value: 'ES',
    },
    {
      label: 'GO',
      value: 'GO',
    },
    {
      label: 'MA',
      value: 'MA',
    },
    {
      label: 'MG',
      value: 'MG',
    },
    {
      label: 'MS',
      value: 'MS',
    },
    {
      label: 'MT',
      value: 'MT',
    },
    {
      label: 'PA',
      value: 'PA',
    },
    {
      label: 'PB',
      value: 'PB',
    },
    {
      label: 'PE',
      value: 'PE',
    },
    {
      label: 'PI',
      value: 'PI',
    },
    {
      label: 'PR',
      value: 'PR',
    },
    {
      label: 'RJ',
      value: 'RJ',
    },
    {
      label: 'RN',
      value: 'RN',
    },
    {
      label: 'RO',
      value: 'RO',
    },
    {
      label: 'RR',
      value: 'RR',
    },
    {
      label: 'RS',
      value: 'RS',
    },
    {
      label: 'SC',
      value: 'SC',
    },
    {
      label: 'SE',
      value: 'SE',
    },
    {
      label: 'SP',
      value: 'SP',
    },
    {
      label: 'TO',
      value: 'TO',
    },
  ];

  useImperativeHandle(ref, () => ({
    toggle,
  }));

  const setFieldFilterProvider = (field, value) => {
    const tp = { ...searchFilterProvider };
    tp[field] = value;

    setSearchFilterProvider(tp);
  };

  const cleanFilterFields = () => {
    setSearchFilterProvider({ ...emptyProviderFilter });
  };

  const applyFilterClicked = () => {
    searchProviders();
    toggle();
  };

  return (
    <Modal id="filter-modal" size="sm" toggle={toggle} isOpen={modal}>
      <div className="modal-header">
        <button
          type="button"
          className="close"
          onClick={toggle}
          aria-label="Close">
          <OCloseIcon />
        </button>
      </div>
      <ModalBody>
        <div>
          <CheckPicker
            data={ufList}
            onChange={value => {
              setFieldFilterProvider('states', value);
            }}
            value={searchFilterProvider.states}
            appearance="default"
            placeholder="Estados"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
          />

          <SelectPicker
            data={[]}
            appearance="default"
            placeholder="Categoria Amicci"
            renderMenuItem={(label, item) => {
              return <div>{label}</div>;
            }}
          />

          <input
            placeholder="Segmento"
            value={searchFilterProvider.segment}
            onChange={e =>
              setFieldFilterProvider('segment', e.currentTarget.value)
            }
          />

          <div className="box-labeled">
            <div className="b-label mr-3">
              <span>Produtos com RAT?</span>
            </div>

            <label htmlFor="ft-y-has_rat" className="my-0 mr-3">
              <input
                type="checkbox"
                id="ft-y-has_rat"
                className="form-check-input"
                value="Y"
                checked={searchFilterProvider?.has_rat === 'Y'}
                onChange={e => {
                  const val = e.currentTarget.checked
                    ? e.currentTarget.value
                    : '';
                  setFieldFilterProvider('has_rat', val);
                }}
              />
              <br />
              Sim
            </label>
            <label htmlFor="ft-n-has_rat" className="my-0">
              <input
                type="checkbox"
                id="ft-n-has_rat"
                className="form-check-input"
                value="N"
                checked={searchFilterProvider?.has_rat === 'N'}
                onChange={e => {
                  const val = e.currentTarget.checked
                    ? e.currentTarget.value
                    : '';
                  setFieldFilterProvider('has_rat', val);
                }}
              />
              <br />
              Não
            </label>
          </div>

          <div className="box-labeled">
            <div className="b-label mr-3">
              <span>Faz Marca Própria?</span>
            </div>

            <label htmlFor="ft-y-make_mp" className="my-0 mr-3">
              <input
                type="checkbox"
                id="ft-y-make_mp"
                className="form-check-input"
                value="Y"
                checked={searchFilterProvider?.make_mp === 'Y'}
                onChange={e => {
                  const val = e.currentTarget.checked
                    ? e.currentTarget.value
                    : '';
                  setFieldFilterProvider('make_mp', val);
                }}
              />
              <br />
              Sim
            </label>
            <label htmlFor="ft-n-make_mp" className="my-0">
              <input
                type="checkbox"
                id="ft-n-make_mp"
                className="form-check-input"
                value="N"
                checked={searchFilterProvider?.make_mp === 'N'}
                onChange={e => {
                  const val = e.currentTarget.checked
                    ? e.currentTarget.value
                    : '';
                  setFieldFilterProvider('make_mp', val);
                }}
              />
              <br />
              Não
            </label>
          </div>

          <div className="box-labeled">
            <div className="b-label mr-3">
              <span>Produtos com Amicci?</span>
            </div>
            <label htmlFor="ft-y-amicci_products" className="my-0 mr-3">
              <input
                type="checkbox"
                id="ft-y-amicci_products"
                className="form-check-input"
                value="Y"
                checked={searchFilterProvider?.amicci_products === 'Y'}
                onChange={e => {
                  const val = e.currentTarget.checked
                    ? e.currentTarget.value
                    : '';
                  setFieldFilterProvider('amicci_products', val);
                }}
              />
              <br />
              Sim
            </label>
            <label htmlFor="ft-n-amicci_products" className="my-0">
              <input
                type="checkbox"
                id="ft-n-amicci_products"
                className="form-check-input"
                value="N"
                checked={searchFilterProvider?.amicci_products === 'N'}
                onChange={e => {
                  const val = e.currentTarget.checked
                    ? e.currentTarget.value
                    : '';
                  setFieldFilterProvider('amicci_products', val);
                }}
              />
              <br />
              Não
            </label>
          </div>

          <div className="box-labeled">
            <div className="b-label mr-3">
              <span>Produtos cadastrados?</span>
            </div>
            <label htmlFor="ft-y-has_products" className="my-0 mr-3">
              <input
                type="checkbox"
                id="ft-y-has_products"
                className="form-check-input"
                value="Y"
                checked={searchFilterProvider?.has_products === 'Y'}
                onChange={e => {
                  const val = e.currentTarget.checked
                    ? e.currentTarget.value
                    : '';
                  setFieldFilterProvider('has_products', val);
                }}
              />
              <br />
              Sim
            </label>
            <label htmlFor="ft-n-has_products" className="my-0">
              <input
                type="checkbox"
                id="ft-n-has_products"
                className="form-check-input"
                value="N"
                checked={searchFilterProvider?.has_products === 'N'}
                onChange={e => {
                  const val = e.currentTarget.checked
                    ? e.currentTarget.value
                    : '';
                  setFieldFilterProvider('has_products', val);
                }}
              />
              <br />
              Não
            </label>
          </div>

          <div className="box-labeled">
            <div className="b-label mr-3">
              <span>Fornecedor Vinculado?</span>
            </div>
            <label htmlFor="ft-y-linked" className="my-0 mr-3">
              <input
                type="checkbox"
                id="ft-y-linked"
                className="form-check-input"
                value="Y"
                checked={searchFilterProvider?.linked === 'Y'}
                onChange={e => {
                  const val = e.currentTarget.checked
                    ? e.currentTarget.value
                    : '';
                  setFieldFilterProvider('linked', val);
                }}
              />
              <br />
              Sim
            </label>
            <label htmlFor="ft-n-linked" className="my-0">
              <input
                type="checkbox"
                id="ft-n-linked"
                className="form-check-input"
                value="N"
                checked={searchFilterProvider?.linked === 'N'}
                onChange={e => {
                  const val = e.currentTarget.checked
                    ? e.currentTarget.value
                    : '';
                  setFieldFilterProvider('linked', val);
                }}
              />
              <br />
              Não
            </label>
          </div>

          <div id="filter-modal-btns" className="text-center mt-5">
            <button
              type="button"
              className="btn btn-multi-action text mr-4"
              onClick={cleanFilterFields}>
              Limpar filtro
            </button>
            <button
              type="button"
              className="btn btn-multi-action blue"
              onClick={applyFilterClicked}>
              Aplicar filtro
            </button>
          </div>
          <div className="d-none text-center">
            <i className="fa fa-spinner fa-pulse fa-2x fa-fw text-center my-5"></i>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default forwardRef(ModalProviderSearch);
