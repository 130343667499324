/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import './DragAndDropFileInput.scss';

const DragAndDropFileInput = ({
  setFile,
  children,
  inline,
  inlineText,
  acceptedTypes,
  file,
  errorMsg,
}) => {
  const [dragging, setDragging] = useState(false);

  const inputRef = createRef(null);
  const dropRef = createRef(null);
  let dragCounter = 0;

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer?.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) {
      setDragging(false);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
      setFile(e.dataTransfer.files[0]);
      // e.dataTransfer.clearData();
      dragCounter = 0;
    }
  };

  const handleOpenDialog = () => {
    inputRef?.current?.click();
  };

  useEffect(() => {
    const div = dropRef.current;
    div?.addEventListener('dragenter', handleDragIn);
    div?.addEventListener('dragleave', handleDragOut);
    div?.addEventListener('dragover', handleDrag);
    div?.addEventListener('drop', handleDrop);
    return () => {
      div?.removeEventListener('dragenter', handleDragIn);
      div?.removeEventListener('dragleave', handleDragOut);
      div?.removeEventListener('dragover', handleDrag);
      div?.removeEventListener('drop', handleDrop);
    };
  }, []);

  return (
    <div
      className={clsx({
        'dndfi-file-area': true,
        error: !dragging && errorMsg,
      })}
      ref={dropRef}>
      {dragging && <div className="dndfi-overlay" />}
      {inline ? (
        <div className="inline-text">
          {errorMsg ? <span>{errorMsg} - </span> : ''}
          <span>
            {file && file.name ? <strong>{file.name}</strong> : inlineText}
          </span>{' '}
          <button type="button" onClick={() => handleOpenDialog()}>
            {file && file.name
              ? 'clique aqui para alterar'
              : 'clique aqui para carregar'}
          </button>
        </div>
      ) : (
        <>{file && file.name ? file.name : children}</>
      )}
      <input
        type="file"
        style={{ display: 'none' }}
        ref={inputRef}
        {...(acceptedTypes && acceptedTypes.length
          ? { accept: acceptedTypes.join(',') }
          : {})}
        onChange={event => {
          const fileList = event?.target?.files;
          if (fileList && fileList[0]) {
            setFile(fileList[0]);
          }
        }}
      />
    </div>
  );
};

export default DragAndDropFileInput;
