import React, { useState, useEffect, createContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import Sidebar from '../../Components/Sidebar/Sidebar';
import MenuManagement from '../../Components/Sidebar/MenuManagement';
import HomeDash from './HomeDash';
import OpeningCalls from './OpeningCalls/OpeningCalls';
import GraphPage from './GraphPage';
import api_permission from '../../services/api-permission';
import { useCognitoAuth } from '../../Contexts/AuthCognitoContext';

const ReportContext = createContext([[], () => {}]);

function CommercialManagement() {
  const [reports, setReports] = useState([]);
  const { user } = useCognitoAuth();
  const email = user.attributes.email;

  useEffect(() => {
    api_permission
      .get(email, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        },
      })
      .then(response => {
        // console.log('report', response.data);
        setReports(response.data);
      });
  }, [email]);

  return (
    <>
      <Header classHeader="header-gestao-comercial" />
      <ReportContext.Provider value={[reports, setReports]}>
        {/* <TokenRefresh /> */}
        <Sidebar link="/gestao-comercial" title="GESTÃO COMERCIAL">
          <MenuManagement />
        </Sidebar>
        <main>
          <Routes>
            <Route path="/" exact element={<HomeDash />} />
            <Route
              path="/abertura-chamados/"
              exact
              element={<OpeningCalls />}
            />
            <Route path="/grafico/:name" element={<GraphPage />} />
          </Routes>
        </main>
      </ReportContext.Provider>
    </>
  );
}

export { ReportContext };
export default CommercialManagement;
