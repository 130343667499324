import React, { useState, useEffect, useCallback } from 'react';
// import ReactGA from 'react-ga';
import { classNames } from '../../../../../../../../utils/utils';
import styles from './styles.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {
  Col,
  Button,
  Modal,
  Icon,
  Row,
  Container,
  Animation,
  Alert,
  Form,
  SelectPicker,
} from 'rsuite';
import apiConnectSourcing from '../../../../../../../../services/apiConnectSourcing';
import { useCognitoAuth } from '../../../../../../../../Contexts/AuthCognitoContext';

const PanelDetails = React.forwardRef(({ responsibleName, ...props }, ref) => (
  <Row
    {...props}
    ref={ref}
    style={{
      overflow: 'hidden',
      paddingleft: '200px',
    }}>
    <Col md={24}>
      <p>{responsibleName}</p>
      <p>Data: {moment(new Date()).format('DD/MM/YYYY')}</p>
    </Col>
  </Row>
));

function ModalChangeStatus({
  show,
  closeModal,
  prospection,
  setProspection,
  responsibleName,
  getProspection,
}) {
  // ReactGA.pageview('/pdm');

  const [showCollapse, setShowCollapse] = useState(false);
  const { Collapse } = Animation;
  const validationSchema = Yup.object({
    status: Yup.number().required('Campo Obrigatório'),
  });
  const { user } = useCognitoAuth();

  const [optionsStatus, setOptionsStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, values, errors, resetForm, setFieldValue } = useFormik({
    initialValues: {
      status: 0,
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: values => {
      setLoading(true);
      apiConnectSourcing
        .put(`/prospections/${prospection?.id}/change-status/`, {
          status: values.status,
          user: user,
        })
        .then(response => {
          if (response.data) {
            Alert.success('Status atualizada com Sucesso!', 3000);
            if (prospection?.status?.id === 5) {
              setProspection({
                ...prospection,
                costSimulatorFile: null,
                status: response.data,
              });
            } else {
              setProspection({ ...prospection, status: response.data });
            }
            if (getProspection) {
              getProspection();
            }
          } else {
            Alert.error(
              'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
              3000
            );
          }
          setLoading(false);
          handleCancel();
        })
        .catch(error => {
          if (error.response?.status === 403) {
            Alert.error(error.response.data.message, 50000);
          } else {
            Alert.error(
              'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
              3000
            );
          }
          setLoading(false);
        });
    },
  });

  const handleToogleCollpase = () => {
    setShowCollapse(!showCollapse);
  };

  const handleCancel = () => {
    closeModal();
    resetForm();
  };

  const getStatus = useCallback(() => {
    apiConnectSourcing.get(`/status/`).then(response => {
      if (response.data) {
        if (response.data.results) {
          let results = response.data.results.filter(x => x.id !== 5);
          results.forEach(ob => {
            const result = {
              label: ob.name,
              value: ob.id,
            };
            setOptionsStatus(optionsStatus => [...optionsStatus, result]);
          });
        }
      }
    });
  }, []);

  const updateValues = useCallback(() => {
    setFieldValue('status', prospection?.status.id);
  }, [setFieldValue, prospection]);
  useEffect(() => {
    updateValues();
  }, [updateValues]);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Modal show={show} onHide={closeModal}>
          <Modal.Header>
            <Container>
              <Row>
                <Col sm={2}>
                  <Row className={styles.iconTittle}>
                    <Col className={styles.modalIcon} md={5}>
                      <Icon icon="bell-o" size="lg" />
                    </Col>
                  </Row>
                </Col>
                <Col sm={22}>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <Col sm={14}>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Projeto:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {prospection?.briefing_name}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Status Atual:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {prospection?.status.name}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={10}>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Cliente:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {prospection?.clientInfo.name}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={24}></Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Header>

          <Modal.Body className={styles.body}>
            <Container>
              <Row style={{ marginTop: '10px' }}>
                <Col md={16}>
                  <label>Selecionar Status:</label>
                  <SelectPicker
                    data={optionsStatus}
                    onChange={value => {
                      setFieldValue('status', value);
                    }}
                    value={values.status}
                    appearance="default"
                    placeholder="Status"
                    renderMenuItem={(label, item) => {
                      return <div>{label}</div>;
                    }}
                  />

                  <span className={styles.error}>
                    {errors.status ? errors.status : null}
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col sm={6} md={6}>
                  <span
                    className={styles.buttonDetails}
                    onClick={handleToogleCollpase}>
                    {' '}
                    Detalhes{' '}
                    <Icon
                      style={{ color: '#615b56', fontWeight: 700 }}
                      icon={classNames({
                        'angle-right': !showCollapse,
                        'angle-down': showCollapse,
                      })}
                    />
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col md={24}>
                  <Collapse in={showCollapse}>
                    <PanelDetails responsibleName={responsibleName} />
                  </Collapse>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col md={8} mdOffset={16}>
                <Row>
                  <Col md={12}>
                    <Button onClick={handleCancel} appearance="default">
                      Cancelar
                    </Button>
                  </Col>
                  <Col md={12}>
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color="blue"
                      appearance="primary"
                      loading={loading}>
                      Confirmar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
}

export default ModalChangeStatus;
