import axios from 'axios';

let suppliers_db = '';

if (process.env.REACT_APP_FRONT_ENV === 'LOCAL') {
  suppliers_db = 'https://vendor-dev.amicci-digital.com.br/api/v2';
}

if (process.env.REACT_APP_FRONT_ENV === 'DEV') {
  suppliers_db = 'https://vendor-dev.amicci-digital.com.br/api/v2';
}

if (process.env.REACT_APP_FRONT_ENV === 'HMG') {
  suppliers_db = 'https://vendor-hmg.amicci-digital.com.br/api/v2';
}

if (process.env.REACT_APP_FRONT_ENV === 'PROD') {
  suppliers_db = 'https://vendor.amicci-digital.com.br/api/v2';
}

const apiConnectSuppliersDB = axios.create({
  baseURL: suppliers_db,
  options: {
    headers: {
      Authorization:
        'Bearer ' + window.localStorage.getItem('suppliers-db-token'),
    },
  },
});

export default apiConnectSuppliersDB;
