/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
// import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// import Loading from '../../../../../../Components/Loading';
import styles from './styles.module.css';
import HeaderModule from '../../../../../../Components/HeaderModule/HeaderModule';
// import Table from 'reactstrap/lib/Table';
import TableList from '../../../../../../Components/TableList/TableList';
import apiConnectSourcing from '../../../../../../services/apiConnectSourcing';
import apiConnect from '../../../../../../services/apiConnect';
import Pagination from '../../../../../../Components/Pagination/Pagination';
import Tabs from '../../../../../../Components/Tabs';
import ModalTransferDemand from './Components/ModalTransferDemand';
import { Alert, SelectPicker, Col, Button, FlexboxGrid, Input } from 'rsuite';
import ModalDemandsSummary from './Components/ModalDemandsSummary';

function DemandsList({ globalStyle, setValuesFilter, valuesFilter }) {
  // ReactGA.pageview('/pdm');
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [optionsResponsibles, setOptionsResponsibles] = useState([]);
  const [optionsClients, setOptionsClients] = useState([]);
  const [optionsStages, setOptionsStages] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showModalSummary, setShowModalSummary] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [clientName, setClientName] = useState('');
  const [responsibleName, setResponsibleName] = useState('');
  const [taskId, setTaskId] = useState('');
  const [taskIndex, setTaskIndex] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);

  const headers = {
    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
  };

  const navigate = useNavigate();

  const loadTasks = page => {
    setLoading(true);
    // setDisabled(true);
    let queryParams = '';
    // console.log(valuesFilter);
    if (valuesFilter.responsible) {
      queryParams += `&responsible=${valuesFilter.responsible}`;
    }
    if (valuesFilter.client) {
      queryParams += `&client=${valuesFilter.client}`;
    }
    if (valuesFilter.project) {
      queryParams += `&project=${valuesFilter.project}`;
    }

    if (valuesFilter.supplier) {
      queryParams += `&supplier=${valuesFilter.supplier}`;
    }
    if (valuesFilter.stage) {
      queryParams += `&stage=${valuesFilter.stage}`;
    }
    if (valuesFilter.currDateLimit) {
      queryParams += `&curr_date_limit=${Number(valuesFilter.currDateLimit)}`;
    }
    let url = '/tasks/';
    if (page) {
      url += `?page=${page}`;
    } else {
      url += `?page=1`;
    }
    url += queryParams;

    apiConnectSourcing
      .get(url)
      .then(response => {
        if (response.data) {
          let tasksResponse = response.data.results;
          setCurrentPage(response.data.current_page);
          setPages(response.data.pages);
          setTasks(tasksResponse);
        }
        setLoading(false);
        // setDisabled(false);
      })
      .catch(error => {
        if (error.response?.status === 403) {
          Alert.error(error.response.data.message, 5000);
          navigate('/pdm');
        } else {
          Alert.error(
            'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
            5000
          );
        }

        // setDisabled(false);
        setLoading(false);
      });
  };

  const handleShowModal = (
    projectName,
    clientName,
    responsibleName,
    taskId,
    taskIndex
  ) => {
    setProjectName(projectName);
    setClientName(clientName);
    setResponsibleName(responsibleName);
    setTaskId(taskId);
    setTaskIndex(taskIndex);
    setShowModal(true);
  };

  const handleShowModalSummary = () => {
    setShowModalSummary(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const closeModalSummary = () => {
    setShowModalSummary(false);
  };

  const handleFiltered = () => {
    setCurrentPage(1);
    loadTasks();
  };

  const handlePagination = page => {
    setCurrentPage(page);
    loadTasks(page);
  };

  const handleClear = () => {
    setLoading(true);
    setValuesFilter({
      responsible: '',
      client: '',
      project: '',
      supplier: '',
      stage: '',
    });
    setDisabled(true);
    apiConnectSourcing
      .get(`/tasks/?page=1`)
      .then(response => {
        if (response.data && response.data.results) {
          const tasksResponse = response.data.results;

          setCurrentPage(response.data.current_page);
          setPages(response.data.pages);
          setTasks(tasksResponse);
        } else {
          Alert.error(
            'Ocorreu um erro inesperado. Tente novamente, mais tarde!'
          );
        }
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          Alert.error(error.response.data.message, 50000);
        } else {
          Alert.error(
            'Aconteceu um erro inesperado, tente novamente mais tarde!'
          );
        }
      })
      .finally(() => {
        // setDisabled(false);
        setLoading(false);
      });
  };

  const includeResponsible = () => {
    apiConnect.get('/sourcing-users/', { headers }).then(response => {
      if (response.data) {
        const responsibles = response.data;
        if (optionsResponsibles.length === 0) {
          responsibles.forEach(ob => {
            const result = {
              label: ob.name,
              value: ob.id,
            };
            setOptionsResponsibles(optionsResponsibles => [
              ...optionsResponsibles,
              result,
            ]);
          });
        }
      }
    });
  };

  const includeStage = () => {
    apiConnectSourcing
      .get('/all-prospection-supplier-stage/')
      .then(response => {
        if (response.data) {
          const stagesResponse = response.data;
          setOptionsStages([]);
          stagesResponse.forEach(ob => {
            const result = {
              label: ob.name,
              value: ob.id,
            };
            setOptionsStages(optionsStages => [...optionsStages, result]);
          });
        }
      });
  };

  const includeClients = () => {
    apiConnect
      .get('/retailers/', { headers })
      .then(response => {
        if (response.data) {
          const clients = response.data;

          clients.forEach(ob => {
            const result = {
              label: ob.name,
              value: ob.id,
            };
            setOptionsClients(optionsClients => [...optionsClients, result]);
          });
        }
      })
      .catch(error => {});
  };

  useEffect(() => {
    loadTasks();
    includeResponsible();
    includeClients();
    includeStage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resumo = () => (
    <Button
      size="md"
      className={globalStyle.btnWhite}
      onClick={handleShowModalSummary}>
      Resumo
    </Button>
  );

  const action = (ob, i) => (
    <Button
      onClick={() =>
        handleShowModal(
          ob.supplier.prospection.briefing_name,
          ob.supplier.prospection.clientInfo.name,
          ob.supplier.prospection.userInfo.name,
          ob.id,
          i
        )
      }
      className={globalStyle.btnPrimary}>
      {' '}
      Transferir demanda
    </Button>
  );

  const columHeaderTable = [
    { userInfo: 'RESPONSÁVEL' },
    { clientInfo: 'CLIENTE' },
    { briefingName: 'PROJETO' },
    { supplierName: 'FORNECEDOR' },
    { stage: 'ETAPA PENDENTE ATÉ O DIA ATUAL' },
    { modifiedAt: 'DATA' },
    { action: 'AÇÕES' },
    { summary: resumo() },
  ];

  const listTable = useMemo(() => {
    if (tasks.length > 0) {
      return tasks
        .filter(ob => !ob.supplier?.supplierInfo[0]?.hasOwnProperty('invalid'))
        .map((ob, i) => ({
          userInfo: ob.userInfo?.name,
          clientInfo: ob.supplier?.prospection.clientInfo.name,
          briefingName: ob.supplier?.prospection.briefing_name,
          supplierName: ob.supplier?.supplierInfo[0]?.name,
          stage: ob.stage.name,
          modifiedAt: moment(
            ob.concluded ? ob.modified_at : ob.scheduled_to
          ).format('DD/MM/YYYY'),
          action: action(ob, i),
          summary: '',
        }));
    }
    return [];
  }, [tasks]);

  return (
    <>
      <HeaderModule classHeader="pdmHeaderModule" afterText="DEMANDAS">
        Controle de Projetos
      </HeaderModule>
      <Tabs tabActive={2}>
        <FlexboxGrid>
          <FlexboxGrid.Item
            componentClass={Col}
            sm={12}
            md={4}
            style={{ padding: '10px 0 0 0' }}
            className="text-right">
            <span className="text-white mr-2">Data atual</span>
            <input
              disabled={loading}
              checked={valuesFilter.currDateLimit}
              type="checkbox"
              style={{ top: 0 }}
              onChange={event => {
                console.log(event);
                setValuesFilter({
                  ...valuesFilter,
                  currDateLimit: !valuesFilter.currDateLimit,
                });
                setDisabled(false);
              }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            componentClass={Col}
            sm={12}
            md={3}
            style={{ padding: 0, paddingRight: '5px' }}>
            <SelectPicker
              className="border-0"
              data={optionsResponsibles}
              onChange={(value, event) => {
                setValuesFilter({ ...valuesFilter, responsible: value });
                setDisabled(false);
              }}
              value={valuesFilter.responsible}
              appearance="default"
              placeholder="Responsável"
              renderMenuItem={(label, item) => {
                return <div>{label}</div>;
              }}
              disabled={loading}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            componentClass={Col}
            sm={12}
            md={3}
            style={{ padding: 0, paddingRight: '5px' }}>
            <SelectPicker
              className="border-0"
              data={optionsClients}
              onChange={(value, event) => {
                setValuesFilter({ ...valuesFilter, client: value });
                setDisabled(false);
              }}
              value={valuesFilter.client}
              appearance="default"
              placeholder="Cliente"
              renderMenuItem={(label, item) => {
                return <div>{label}</div>;
              }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            componentClass={Col}
            sm={12}
            md={3}
            style={{ padding: 0, paddingRight: '5px' }}>
            <Input
              placeholder="Projeto"
              value={valuesFilter.project}
              onChange={(value, event) => {
                setValuesFilter({ ...valuesFilter, project: value });
                setDisabled(false);
              }}
              className={styles.textInput}
              disabled={loading}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            componentClass={Col}
            sm={12}
            md={3}
            style={{ padding: 0, paddingRight: '5px' }}>
            <Input
              placeholder="Fornecedor"
              value={valuesFilter.supplier}
              onChange={(value, event) => {
                setValuesFilter({ ...valuesFilter, supplier: value });
                setDisabled(false);
              }}
              className={styles.textInput}
              disabled={loading}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            componentClass={Col}
            sm={12}
            md={3}
            style={{ padding: 0 }}>
            <SelectPicker
              className="border-0"
              data={optionsStages}
              onChange={(value, event) => {
                setValuesFilter({ ...valuesFilter, stage: value });
                setDisabled(false);
              }}
              value={valuesFilter.stage}
              appearance="default"
              placeholder="Etapa"
              renderMenuItem={(label, item) => {
                return <div>{label}</div>;
              }}
              disabled={loading}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            componentClass={Col}
            sm={12}
            md={5}
            style={{ paddingLeft: '5px' }}>
            <FlexboxGrid>
              <FlexboxGrid.Item componentClass={Col} sm={12}>
                <Button
                  disabled={disabled || loading}
                  onClick={handleFiltered}
                  color="blue"
                  style={{ minHeight: '38px' }}
                  size="sm"
                  block>
                  Filtrar
                </Button>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item
                componentClass={Col}
                sm={12}
                style={{ padding: 0 }}>
                <Button
                  disabled={disabled || loading}
                  onClick={handleClear}
                  color="grey"
                  size="sm"
                  style={{ minHeight: '38px' }}
                  block>
                  Limpar
                </Button>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Tabs>

      <TableList
        columns={columHeaderTable}
        lines={listTable}
        loading={loading}
      />
      {tasks.length > 0 && !loading && (
        <div style={{ marginRight: '15px' }}>
          <Pagination
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={handlePagination}
            loading={loading}
          />
        </div>
      )}

      <ModalTransferDemand
        projectName={projectName}
        clientName={clientName}
        responsibleName={responsibleName}
        taskId={taskId}
        closeModal={closeModal}
        show={showModal}
        optionsResponsibles={optionsResponsibles}
        tasks={tasks}
        setTasks={setTasks}
        taskIndex={taskIndex}
      />
      <ModalDemandsSummary
        closeModal={closeModalSummary}
        show={showModalSummary}
      />
      {/* </div> */}
    </>
  );
}

export default DemandsList;
