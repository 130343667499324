import React, { useEffect, useRef } from 'react';
import './Charts.scss';

const Charts = ({ data, modulo }) => {
  const concluido = data?.concluido ? Math.round(data.concluido * 3.6) : 0;
  const iniciado = data?.iniciado ? Math.round(data.iniciado * 3.6) : 0;
  const naoIniciado = data?.nao_iniciado
    ? Math.round(data.nao_iniciado * 3.6)
    : 0;
  const scaledWrapper = useRef();
  const scaledContent = useRef();

  const applyScaling = (scaleW, scaleC) => {
    scaleC.style.transform = 'scale(1)';

    const { width: cw, height: ch } = scaleC.getBoundingClientRect();
    const { width: ww, height: wh } = scaleW.getBoundingClientRect();
    const newScale = Math.min(ww / cw, wh / ch);
    scaleC.style.transform = `scale(${newScale})`;
  };

  useEffect(() => {
    if (scaledWrapper.current && scaledContent.current) {
      applyScaling(scaledWrapper.current, scaledContent.current);
    }
  }, [scaledWrapper, scaledContent]);

  return (
    <div className="donuts-chart">
      <div className="col-esq">
        <div>
          Fase Atual: <span style={{ color: 'blue' }}>{modulo}</span>
        </div>
        <ul className="legends">
          <li>
            <div className="legendBlue" />
            <span>Em andamento</span>
          </li>
          <li>
            <div className="legendGreen" />
            <span>Finalizada</span>
          </li>
          <li>
            <div className="legendGray" />
            <span>Não Iniciada</span>
          </li>
        </ul>
      </div>
      <div className="col-dir">
        <div className="areaChart" ref={scaledWrapper}>
          <div className="chart" ref={scaledContent}>
            <div
              className="area"
              style={{
                background: `conic-gradient(
                #425ba6 ${iniciado}deg,
                #98a1b1 ${iniciado}deg ${naoIniciado}deg,
                #4baf5a ${naoIniciado}deg 360deg
              )`,
              }}>
              <div
                className="valores"
                style={{
                  transform: `translateX(-20px) rotate(${Math.round(
                    iniciado / 2
                  )}deg)`,
                }}>
                <span
                  className="valor"
                  style={{
                    transform: `rotate(-${Math.round(iniciado / 2)}deg)`,
                  }}>
                  {data?.iniciado}%
                </span>
                <div className="linha" style={{ backgroundColor: '#425ba6' }} />
              </div>
              <div
                className="valores"
                style={{
                  transform: `translateX(-20px) rotate(${
                    Math.round(naoIniciado / 2) + iniciado
                  }deg)`,
                }}>
                <span
                  className="valor"
                  style={{
                    transform: `rotate(-${
                      Math.round(naoIniciado / 2) + iniciado
                    }deg)`,
                  }}>
                  {data?.nao_iniciado}%
                </span>
                <div className="linha" style={{ backgroundColor: '#98a1b1' }} />
              </div>
              <div
                className="valores"
                style={{
                  transform: `translateX(-20px) rotate(${
                    Math.round(concluido / 2) + naoIniciado + iniciado
                  }deg)`,
                }}>
                <span
                  className="valor"
                  style={{
                    transform: `rotate(-${
                      Math.round(concluido / 2) + naoIniciado + iniciado
                    }deg)`,
                  }}>
                  {data?.concluido}%
                </span>
                <div className="linha" style={{ backgroundColor: '#4baf5a' }} />
              </div>
              <div className="dentro"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charts;
