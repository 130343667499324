import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthCognitoProvider } from './Contexts/AuthCognitoContext';
ReactDOM.render(
  <AuthCognitoProvider>
    <App />
  </AuthCognitoProvider>,
  document.getElementById('root')
);
