const getCognitoCredentials = () => {
  const idToken = localStorage.getItem('id-token-cognito');
  const accessToken = localStorage.getItem('access-token-cognito');
  const expiredToken = localStorage.getItem('expired-token-cognito');
  const userCognito = localStorage.getItem('user-cognito');
  const userGroupCognito = localStorage.getItem('user-group-cognito');

  return { idToken, accessToken, expiredToken, userCognito, userGroupCognito };
};

export default getCognitoCredentials;
