import React, { useState } from 'react';
// import ReactGA from 'react-ga';
import { classNames } from '../../../../../../../../utils/utils';
import styles from './styles.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {
  Col,
  Button,
  Modal,
  Icon,
  Row,
  Container,
  Animation,
  Alert,
  Form,
} from 'rsuite';
import apiConnectSourcing from '../../../../../../../../services/apiConnectSourcing';

const PanelDetails = React.forwardRef(({ responsibleName, ...props }, ref) => (
  <Row
    {...props}
    ref={ref}
    style={{
      overflow: 'hidden',
      paddingleft: '200px',
    }}>
    <Col md={24}>
      <p>{responsibleName}</p>
      <p>Data: {moment(new Date()).format('DD/MM/YYYY')}</p>
    </Col>
  </Row>
));

function ModalProspectionSupplierRestart({
  show,
  closeModal,
  prospection,
  supplierName,
  supplierId,
  setProspection,
  supplierIndex,
  responsibleName,
}) {
  // ReactGA.pageview('/pdm');

  const [showCollapse, setShowCollapse] = useState(false);
  const { Collapse } = Animation;
  const validationSchema = Yup.object({
    note: Yup.string().required('Campo Obrigatório'),
  });

  const [loading, setLoading] = useState(false);

  const { handleSubmit, handleChange, values, errors, isValid, resetForm } =
    useFormik({
      initialValues: {
        note: '',
      },
      validateOnChange: false,
      validationSchema,
      onSubmit: values => {
        setLoading(true);
        apiConnectSourcing
          .post(
            `/prospections/${prospection.id}/suppliers/${supplierId}/cancel-tasks/`,
            {
              note: values.note,
              document_id: prospection?.cost_sheets_data?.find(
                sh => sh.provider === prospection?.suppliers?.[supplierIndex]?.supplier_id
              )?.id || null,
            }
          )
          .then(response => {
            if (response.data) {
              Alert.success('Demandas Canceladas com Sucesso!', 3000);
              handleUpdateSupplier(supplierIndex, response.data);
            } else {
              Alert.error(
                'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
                3000
              );
            }

            setLoading(false);
            handleCancel();
          })
          .catch(error => {
            Alert.error(
              error?.response?.data?.message
              || 'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
              3000
            );
            handleCancel();
            setLoading(false);
          });
      },
    });

  const handleToogleCollpase = () => {
    setShowCollapse(!showCollapse);
  };

  const handleCancel = () => {
    closeModal();
    resetForm();
  };

  const handleUpdateSupplier = (supplierIndex, responseSupplier) => {
    const newProspection = { ...prospection };
    const oldFileIndex = newProspection.cost_sheets_data.findIndex(
      p => Number(p.provider) === 
        Number(newProspection.suppliers[supplierIndex].supplier_id)
    );

    if (oldFileIndex > -1) {
      newProspection.cost_sheets_data.splice(oldFileIndex, 1);
    }

    newProspection.suppliers[supplierIndex] = responseSupplier;

    setTimeout(() => {
      setProspection(newProspection);
    }, 1000);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Modal show={show} onHide={closeModal}>
          <Modal.Header>
            <Container>
              <Row>
                <Col sm={2}>
                  <Row className={styles.iconTittle}>
                    <Col
                      className={classNames({ [styles.modalIcon]: true })}
                      md={5}>
                      <Icon icon="bell-o" size="lg" />
                    </Col>
                  </Row>
                </Col>
                <Col sm={22}>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <Col sm={14}>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Fornecedor:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {supplierName}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Projeto:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {prospection?.briefing_name}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={10}>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Cliente:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {prospection?.clientInfo.name}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Etapa:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              Reiniciar demandas
                            </strong>
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Header>

          <Modal.Body className={styles.body}>
            <Container>
              <Row>
                <Col md={24}>
                  <textarea
                    value={values.notes}
                    placeholder="Digite aqui"
                    className={styles.bodyCard}
                    onChange={handleChange('note')}></textarea>
                  <span className={styles.error}>
                    {errors.note ? errors.note : null}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '10px' }}>
                <Col sm={6} md={6}>
                  <span
                    className={styles.buttonDetails}
                    onClick={handleToogleCollpase}>
                    {' '}
                    Detalhes{' '}
                    <Icon
                      style={{ color: '615b56', fontWeight: 700 }}
                      icon={classNames({
                        'angle-right': !showCollapse,
                        'angle-down': showCollapse,
                      })}
                    />
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col md={24}>
                  <Collapse in={showCollapse}>
                    <PanelDetails responsibleName={responsibleName} />
                  </Collapse>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disable={!isValid}
              type="submit"
              onClick={handleSubmit}
              color={'blue'}
              loading={loading}>
              Confirmar
            </Button>
            <Button onClick={handleCancel} appearance="subtle">
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
}

export default ModalProspectionSupplierRestart;
