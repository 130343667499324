/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
// import ReactGA from 'react-ga';
import styles from './Login.module.css';
import imgLogin from '../../Assets/imgLogin.png';
import logo from '../../Assets/logo.png';
import { useCognitoAuth } from '../../Contexts/AuthCognitoContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import LoginCognito from './LoginCognito';
import ForgoutPasswordCognito from './ForgoutPasswordCognito';
import ForgotPasswordSubmitCognito from './ForgotPasswordSubmitCognito';

function Login() {
  // ReactGA.pageview('/login');

  const { authenticated, stepForgotPassword, setStepForgotPassword } =
    useCognitoAuth();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [usernameSubmit, setUsernameSubmit] = useState('');
  const toggle = () => {
    if (modal === true) {
      setStepForgotPassword(0);
    }
    setModal(!modal);
  };

  useEffect(() => {
    if (authenticated === true) {
      navigate('/home');
    }
  }, [authenticated]);

  return (
    <>
      <main className={styles.login}>
        <div className="container-pdm">
          <div className={styles.headerHome}>
            <div className={styles.logoDigital}>
              <img src={logo} alt="Amicci Digital" />
              <span>Digital</span>
            </div>
          </div>
          <div className="flex-space animeLeft">
            <div className={styles.slogan}>
              <h1>
                <strong>Inovação e</strong> <br />
                desenvolvimento
              </h1>
              <h2>
                A tecnologia auxiliando na entrega de melhores resultados.
              </h2>
              <button onClick={() => toggle()} className={styles.buttonLogin}>
                LOGIN
              </button>
            </div>
            <div className={styles.imgLogin}>
              <img src={imgLogin} alt="Amicci Digital" />
            </div>
          </div>
        </div>
      </main>

      <Modal
        className={styles.modalLogin}
        toggle={toggle}
        isOpen={modal}
        backdrop="static">
        <ModalHeader toggle={toggle} className={styles.loginLogo}>
          <img size={80} src={logo} alt="Amicci Digital" />
          <span className={styles.text}>Digital</span>
        </ModalHeader>
        <ModalBody>
          {stepForgotPassword === 0 && <LoginCognito />}
          {stepForgotPassword === 1 && (
            <ForgoutPasswordCognito setUsernameSubmit={setUsernameSubmit} />
          )}
          {stepForgotPassword === 2 && (
            <ForgotPasswordSubmitCognito username={usernameSubmit} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default Login;
