/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './InputSearch.scss';
import { BiSearch } from 'react-icons/bi';
import { BsX } from 'react-icons/bs';

const InputSearch = ({
  value,
  onChange,
  onSearch,
  onClear,
  cleanable,
  placeholder = 'Buscar..',
}) => {
  const [innerValue, setInnerValue] = useState(value || '');

  useEffect(() => {
    if (innerValue !== value && onChange) {
      onChange(innerValue);
    }
  }, [innerValue]);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const handleSearch = () => {
    if (onSearch) onSearch();
  };

  const handleClear = () => {
    setInnerValue('');
    if (onClear) onClear();
  };

  return (
    <div className="InputSearchAmicci">
      <input
        className="isInput"
        placeholder={placeholder}
        value={innerValue}
        onChange={e => setInnerValue(e.target.value)}
        onKeyUp={e => {
          if (e.key === 'Enter') handleSearch();
        }}
      />
      <button type="button" className="isBtnSearch" onClick={handleSearch}>
        <BiSearch className="isIconSearch" />
      </button>
      {cleanable && innerValue && (
        <button
          title="Limpar"
          type="button"
          className="isBtnClear"
          onClick={handleClear}>
          <BsX />
        </button>
      )}
    </div>
  );
};

export default InputSearch;
