import React from 'react';
// import ReactGA from 'react-ga'
import styles from './NotFound.module.css';

function NotFound() {
  // ReactGA.pageview('/404');
  return <main className={styles.notFound}>OPS!!!</main>;
}

export default NotFound;
