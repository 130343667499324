/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import './ModalWeight.scss';
import { Modal, ModalBody, Input } from 'reactstrap';
import { SelectPicker } from 'rsuite';
import useToggle from '../../../../../../utils/hooks/useToggle';
import { useBriefingContext } from '../../../../../../Contexts/BriefingContext';

const ModalWeight = ({ inputWeight, setInputWeight, onClosed }, ref) => {
  const [modalWeight, toggleWeight] = useToggle(false, true);
  const [weight, setWeight] = useState('');
  const [measure, setMeasure] = useState('');
  const { loading, measures, getMeasures } = useBriefingContext();

  const setPosX = posX => {
    const el = document.getElementsByClassName('modalWeight')[0];
    if (el) {
      const w = window.innerWidth;
      const pos = 65 - Math.round((posX * 100) / w);
      el.style.transform = `translateX(-${pos}%)`;
      document.getElementById('weight').focus();
    } else {
      setTimeout(() => {
        setPosX(posX);
      }, 50);
    }
  };

  useImperativeHandle(ref, () => ({
    toggleWeight,
    setPosX,
  }));

  useEffect(() => {
    if (measures.length === 0 && !loading.measures) {
      getMeasures();
    }
  }, []);

  useEffect(() => {
    if (modalWeight && inputWeight) {
      const arrIW = inputWeight.split(' ');
      const m = arrIW.pop();
      setWeight(arrIW.join(' ') || '');
      setMeasure(m || '');
    }
  }, [modalWeight]);

  const saveWeight = () => {
    setInputWeight(`${weight} ${measure}`);
    // setInputMeasure(measure);
    toggleWeight();
  };

  const arrayMeasures = useMemo(() => {
    return measures.map(m => ({
      ...m,
      label: `${m.name} (${m.measure})`,
      value: m.measure,
      role: m.group,
    }));
  }, [measures]);

  return (
    <Modal
      className="modalWeight"
      toggle={toggleWeight}
      isOpen={modalWeight}
      centered
      onClosed={() => {
        if (onClosed) onClosed();
      }}>
      <ModalBody>
        <p>Insira a quantidade e escolha a Unidade de Medida</p>
        <div className="weightInputs">
          <div className="weightInputColumn">
            <label className="legendField">Valor</label>
            <Input
              id="weight"
              type="number"
              value={weight}
              className="inputText input12"
              name="weight"
              min="0"
              max="999999"
              step="1"
              onChange={e => setWeight(e.target.value)}
              placeholder="Ex: 100"
            />
          </div>
          <div className="weightInputColumn">
            <label className="legendField">Unidade de Medida</label>
            <SelectPicker
              name="measure"
              value={measure}
              onChange={setMeasure}
              data={arrayMeasures}
              groupBy="role"
              className={`selectPickerBlue`}
              placeholder="Selecione"
            />
          </div>
        </div>
        <div className="weightButtons">
          <button
            onClick={toggleWeight}
            className="button-cancelar"
            style={{ width: '85px' }}>
            Cancelar
          </button>
          <button
            onClick={saveWeight}
            className="button-confirmar"
            disabled={!weight || !measure}>
            Confirmar
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default forwardRef(ModalWeight);
