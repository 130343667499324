import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import MenuPdm from '../../../Components/Sidebar/MenuPdm';
import SupplierDefinitionFilter from './SupplierDefinitionFilter/SupplierDefinitionFilter';
import ListViewProjects from './ListViewProjects/ListViewCommercialFilter';

function Management() {
  return (
    <>
      <Sidebar hideLogo>
        <MenuPdm opened="select-supplier" />
      </Sidebar>
      <main>
        <Routes>
          <Route path="/" element={<ListViewProjects />} />
          <Route path="/:id" element={<SupplierDefinitionFilter />} />
        </Routes>
      </main>
    </>
  );
}

export default Management;
