import React, { useState, forwardRef, useImperativeHandle } from 'react';
import InputText from '../../../../../Components/InputText/InputText';
import moment from 'moment';
import { classNames } from '../../../../../utils/utils';
import 'moment/locale/pt-br';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { Alert } from 'rsuite';
import ButtonDefault from '../../../../../Components/ButtonDefault/ButtonDefault';
import { ReactComponent as FolderIcon } from '../../../../../Assets/icone-pasta-abertura-modulo.svg';
import './ModalOpenModule.scss';
import { useCognitoAuth } from '../../../../../Contexts/AuthCognitoContext';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ModalOpenModule = (
  {
    moduleId = null,
    moduleTypeId = null,
    moduleName = null,
    displayOnly = false,
    displayObs = null,
    displayDate = null,
    setProject = () => {},
    setModuleStatus = () => {},
    onSubmit = () => {},
  },
  ref
) => {
  const { user } = useCognitoAuth();
  const { project, changeModuleStatus, reloadProjectAndModule } =
    useManagementContext();
  const now = new Date();
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const validationSchema = Yup.object({
    obs: Yup.string()
      .max(500, ' Limite de 500 caracteres')
      .required('Campo Obrigatório'),
  });

  const { handleSubmit, handleChange, values, errors, resetForm } = useFormik({
    initialValues: {
      obs: '',
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      onHandleConfirm(values);
    },
  });

  const onHandleConfirm = async values => {
    setLoadingConfirm(true);
    const body = {
      user: user,
      obs: values.obs,
      reopen: true,
    };
    const resp = await changeModuleStatus(moduleTypeId, moduleId, 1, body);
    setLoadingConfirm(false);
    if (resp?.success) {
      onHandleUpdateModules(resp.data?.project_module);
      setModuleStatus(resp.data?.project_module.status_module);
      onSubmit();
      closeModal();
      resetForm();
      reloadProjectAndModule();
    } else {
      Alert.error(
        resp.message ||
          'Aconteceu um erro inesperado, tente novamente mais tarde!',
        50000
      );
    }
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  function closeModal() {
    setShowModal(!showModal);
  }

  const onHandleUpdateModules = projectModule => {
    let tempProject = { ...project };
    const moduleIndex = tempProject.modules.findIndex(moduleObj => {
      return moduleObj.id === projectModule.id;
    });
    tempProject.modules[moduleIndex] = projectModule;
    setProject(tempProject);
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={false}
      centered={true}
      className="custom-modal">
      <ModalHeader>
        <Row>
          <Col md={1}>
            <FolderIcon />
          </Col>
          <Col md={7} className="texts-modal">
            <h2 className="text-reopening">Abertura do módulo</h2>
            <h3 className="sub-reopening">{moduleName}</h3>
          </Col>
          <Col md={4} className="date-modal">
            {displayOnly ? (
              <>
                {moment(displayDate).format('DD/MM/YYYY')}{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {moment(displayDate).format('dddd').split('-')[0]}{' '}
                </span>{' '}
                {moment(displayDate).format('HH:mm:ss').toUpperCase()}
              </>
            ) : (
              <>
                {moment(now).format('DD/MM/YYYY')}{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {moment(now).format('dddd').split('-')[0]}{' '}
                </span>{' '}
                {moment(now).format('HH:mm')}
              </>
            )}
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {!displayOnly && (
          <p>
            Este módulo não é aplicável para esse tipo de projeto. Porém, se há
            necessidade de abertura, por favor, justifique essa ação para fins
            de registro e esperamos que corra tudo bem na sua jornada.
          </p>
        )}
        <section className="custom-input-component">
          <label className="text-observation">Observações</label>
          <InputText
            maxLength={500}
            readOnly={displayOnly}
            value={displayOnly ? displayObs : values.obs}
            type="textarea"
            onChange={handleChange('obs')}
            className={classNames({
              'error-input': Boolean(errors.obs),
            })}
            placeholder="Ex: O etapa foi reaberta por conta de um erro na documentação enviada anteriormente. Nova é a correta."
          />
          <small className="text-limit">Limite de 500 caracteres</small>
          <br />
          <span className="error">{errors.obs ? errors.obs : null}</span>
        </section>
      </ModalBody>
      {displayOnly ? (
        <ModalFooter>
          <ButtonDefault radius color="blue" onClick={closeModal}>
            Fechar
          </ButtonDefault>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <ButtonDefault
            color="red"
            radius
            onClick={() => {
              closeModal();
              resetForm();
            }}>
            Cancelar
          </ButtonDefault>
          <ButtonDefault
            radius
            disabled
            loading={loadingConfirm}
            onClick={handleSubmit}
            color="green">
            Confirmar
          </ButtonDefault>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default forwardRef(ModalOpenModule);
