/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react';

/**
 * hook que alerta quando clica fora do elemento ref
 */
export default function useOutsideAlerter(
  ref,
  callbackClickOut,
  callbackClickIn
) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (callbackClickOut) callbackClickOut();
      } else {
        if (callbackClickIn) callbackClickIn();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

const OutsideAlerter = ({ onClickOutside, onClickInside, children }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClickOutside, onClickInside);

  return <div ref={wrapperRef}>{children}</div>;
};

export { OutsideAlerter };
