import axios from 'axios';

let ambiente = '';
if (process.env.REACT_APP_FRONT_ENV === 'LOCAL') {
  ambiente = 'https://briefing-api-dev.amicci-digital.com.br/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'DEV') {
  ambiente = 'https://briefing-api-dev.amicci-digital.com.br/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'HMG') {
  ambiente = 'https://briefing-api-hmg.amicci-digital.com.br/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'PROD') {
  ambiente = 'https://briefing-api.amicci-digital.com.br/api';
}

const apiConnect = axios.create({
  baseURL: ambiente,
  options: {
    headers: {
      Authorization: 'Bearer ' + window.localStorage.getItem('token'),
    },
  },
});

export default apiConnect;
