import React, { useState, useEffect, useMemo } from 'react';
import './TableList.scss';
// import Table from 'reactstrap/lib/Table';
import Loading from '../Helpers/Loading';
import { Message } from 'rsuite';
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from 'react-icons/ai';
import { generateUniqueKey } from '../../utils/utils';

export function GroupButtons({ children }) {
  return <div className="groupButtons">{children}</div>;
}

/**
 * componente de tabela para listagens
 * @param {flag} newTable // se setado, exibe o novo padrao visual
 * @param {Array<{[string]:string}>} columns // lista de colunas, Ex [{colName: 'Titulo A'}]
 * @param {Array<[string]:string>} lines // lista de dados apresentados, Ex: [{colName: 'Valor A'}]
 * @param {String} openableColumn // se setado, a coluna passada terá um botao para expandir
 * @param {String} openColumnLabel // texto exibido no botao de expandir no cabecalho
 * @param {String} closeColumnLabel // texto exibido no botao de fechar no cabecalho
 * @param {Boolean} loading // exibe/esconde componente de loading
 * @param {String} defaultMessageEmpty // Mensagem para exibir caso nao tenha dados
 * @returns Tabela de dados
 */
export default function TableList({
  newTable,
  columns = [],
  lines = [],
  openableColumn = null,
  openColumnLabel = 'Abrir todos',
  closeColumnLabel = 'Fechar todos',
  loading = false,
  defaultMessageEmpty = 'Sem resultados',
}) {
  // definicoes gerais
  const nCols = columns.length, // n total de colunas
    keysCols = [], // lista de chaves das colunas
    valCols = []; // lista de labels das colunas
  // array com os estados de cada linha, todos iniciam como fechados
  const [openedLines, setOpenedLines] = useState(() =>
    new Array(lines?.length || 0).fill(false)
  );

  /**
   * separa as chaves e valores das colunas
   */
  columns.forEach(c => {
    const [k, v] = Object.entries(c)[0];
    keysCols.push(k);
    valCols.push(v);
  });

  useEffect(() => {
    // se as linhas mudarem, sempre recria o array com as linhas fechadas
    setOpenedLines(new Array(lines?.length || 0).fill(false));
  }, [lines]);

  /**
   * cria uma variavel para servir como flag se todos estao abertos
   */
  const isAllOpened = useMemo(() => {
    return openedLines.every(v => v);
  }, [openedLines]);

  // seta uma classe para a 1º e ultima colunas para arrendondar as bordas
  const classTh = pos => {
    if (pos === 0) return 'borderLeftRadius';
    else if (pos === nCols - 1) return 'borderRightRadius';
    return '';
  };

  /**
   * abre/fecha todas as linhas
   */
  const handleHideExpandAll = () => {
    if (isAllOpened) {
      setOpenedLines(new Array(lines?.length).fill(false));
    } else {
      setOpenedLines(new Array(lines?.length).fill(true));
    }
  };

  /**
   * abre/fecha uma linha
   * @param {number} i - contador da linha
   */
  const handleHideExpandLine = i => {
    setOpenedLines(
      openedLines.map((opened, key) => (key === i ? !opened : opened))
    );
  };

  /**
   * esta funcao verifica se deve exibir apenas
   * um texto simples no cabecalho da coluna ou o
   * texto com um botao para expandir o conteudo
   * @param {string} val - valor exibido dentro da coluna
   * @param {number} i - contador da linha na lista de colunas
   * @returns um texto simples ou um texto com um botao para expandir
   */
  const showLabelColumn = (val, i) => {
    // verifica se a coluna retornada corresponde com a coluna selecionada
    if (openableColumn && keysCols[i] === openableColumn) {
      return (
        <div className="expansiveHead">
          {val}
          <button type="button" onClick={handleHideExpandAll}>
            (<span>{isAllOpened ? closeColumnLabel : openColumnLabel}</span>)
          </button>
        </div>
      );
    }
    return val;
  };

  /**
   * retorna os valores de style para setar se a linha esta aberta ou fechada
   * @param {Array<any>} vals - lista de valores que sao apresentados qd aberto
   * @param {number} i - contador da linha aberta/fechada
   * @returns retorna os valores de style para abrir/fechar
   */
  const getHeightByLines = (vals, i) => {
    const isOpened = openedLines[i] || false;
    const heightArea = vals.length * 18 + 16;
    return isOpened
      ? { height: `${heightArea}px`, paddingTop: '16px' }
      : { height: '0', paddingTop: '0' };
  };

  /**
   * cria o conteudo da celula da tabela de acordo com seu conteudo
   * @param {any|Array<any>} vals - valor ou lista de valores
   * @param {string} col - chave da coluna
   * @param {number} i - contador da linha
   * @returns se for um valor comum, retorna esse valor,
   * se for uma linha expansiva
   * retorna o primeiro valor se tiver apenas 1 linha na lista
   * retorna um botao para expandir e os valores abaixo caso seja a coluna que expande
   * retorna o 1 primeiro valor seguido pela lista de valores caso tenha uma lista
   */
  const showData = (vals, col, i) => {
    if (Array.isArray(vals)) {
      const n = vals.length;
      if (n === 0) {
        return '-';
      } else {
        return (
          <div className="expansiveItem">
            {col === openableColumn ? (
              <>
                <button
                  type="button"
                  className="closedLabel"
                  onClick={() => handleHideExpandLine(i)}>
                  {n}{' '}
                  <span className="expansiveIcon">
                    {openedLines[i] ? (
                      <AiOutlineMinusSquare />
                    ) : (
                      <AiOutlinePlusSquare />
                    )}
                  </span>
                </button>
                <div
                  className="expansiveArea"
                  style={getHeightByLines(vals, i)}>
                  {vals.map(val => (
                    <p key={generateUniqueKey()}>{val}</p>
                  ))}
                </div>
              </>
            ) : (
              <>
                <p className="closedLabel">{vals[0]}</p>
                <div
                  className="expansiveArea"
                  style={getHeightByLines(vals, i)}>
                  {vals.map((val, i) =>
                    // aqui exibe a partir do 2º valor pois o 1º já foi exibido acima
                    i !== 0 ? <p key={generateUniqueKey()}>{val}</p> : null
                  )}
                </div>
              </>
            )}
          </div>
        );
      }
    } else {
      return vals;
    }
  };

  return (
    <div className={`TableList ${newTable ? 'newTable' : ''}`}>
      <table className="table">
        {/* responsive hover borderless */}
        {nCols > 0 && (
          <thead>
            <tr>
              {valCols.map((v, i) => (
                <th key={`col-${i}`} className={classTh(i)}>
                  {showLabelColumn(v, i)}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {lines.length === 0 && !loading && (
            <tr>
              <td colSpan={nCols}>
                <Message
                  style={{ textAlign: 'center' }}
                  type="warning"
                  description={defaultMessageEmpty}
                />
              </td>
            </tr>
          )}
          {loading ? (
            <tr>
              <td colSpan={nCols}>
                <Loading />
              </td>
            </tr>
          ) : (
            lines &&
            lines.length > 0 &&
            lines.map((line, i) => (
              <tr key={`line-${i}`}>
                {keysCols.map((col, k) => (
                  <td key={`cel-${i}-${k}`}>{showData(line[col], col, i)}</td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
