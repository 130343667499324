/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import './ModalRefactObs.scss';
import { Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import useToggle from '../../../../../../utils/hooks/useToggle';

const ModalRefactObs = (
  { inputRefactObs, setInputRefactObs, statusCode },
  ref
) => {
  const [show, toggle] = useToggle();
  const [obs, setObs] = useState('');

  useImperativeHandle(ref, () => ({ toggle }));

  const saveObs = () => {
    if (setInputRefactObs) {
      setInputRefactObs(obs);
    }
    toggle();
  };

  useEffect(() => {
    if (show) {
      if (inputRefactObs) {
        setObs(inputRefactObs);
      }
    } else {
      setObs('');
    }
  }, [show]);

  return (
    <Modal size="lg" toggle={toggle} isOpen={show}>
      <ModalHeader toggle={toggle}>
        Orientação para reformulação
        <p>Por favor, insira as orientações para reformulação</p>
      </ModalHeader>
      <ModalBody className="specialPaddingModal">
        <div className="notesProviders">
          <Input
            name="refact_obs"
            className="inputText textArea"
            type="textarea"
            value={obs}
            onChange={ev => setObs(ev.target.value)}
          />
        </div>
        <div className="flex-center m-t-7">
          <button
            onClick={saveObs}
            // closeToModalOrientation
            className="btn-one"
            disabled={statusCode === 'F'}>
            Salvar
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default forwardRef(ModalRefactObs);
