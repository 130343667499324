import React, { useState, useEffect } from 'react';
// import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import Sidebar from '../../Components/Sidebar/Sidebar';
import HeaderModule from '../../Components/HeaderModule/HeaderModule';
import homeImgManagament from '../../Assets/homeImgManagament.png';
import noAcceess from '../../Assets/noAceess.png';
import MenuManagement from '../../Components/Sidebar/MenuManagement';
import api_permission from '../../services/api-permission';
import { useCognitoAuth } from '../../Contexts/AuthCognitoContext';

function HomeDash() {
  // ReactGA.pageview('/gestao-comercial');
  const { user } = useCognitoAuth();
  const email = user.attributes.email;

  const [terminou_chamada_api, setTerminouChamadaAPI] = useState(true);
  const [getAccess, setGetAccess] = useState(false);

  useEffect(() => {
    api_permission
      .get(email, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        },
      })
      .then(response => {
        setTerminouChamadaAPI(false); //aqui marca que pegou tudo das APIS e decide se dá mensagem de erro ou não pro user para permissão
        setGetAccess(true);
      });
  }, [email]);

  if (terminou_chamada_api && !getAccess) {
    return (
      <>
        <HeaderModule classHeader="commercialHeaderModule">
          GESTÃO COMERCIAL
        </HeaderModule>
        <main>
          <div className="mainDiv">
            <div className="flex-center-gap">
              <Link to="/home">
                <img src={noAcceess} alt="Sem Acesso" />
              </Link>
            </div>
          </div>
        </main>
      </>
    );
  } else {
    return (
      <>
        <HeaderModule classHeader="commercialHeaderModule">
          GESTÃO COMERCIAL
        </HeaderModule>
        <Sidebar link="/gestao-comercial" title="GESTÃO COMERCIAL">
          <MenuManagement />
        </Sidebar>
        <main>
          <div className="mainDiv">
            <div className="flex-center-gap homeImg">
              <img src={homeImgManagament} alt="Home" />
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default HomeDash;
