import React from 'react';
// import ReactGA from 'react-ga';
import './Header.scss';
import Bell from '../../Assets/bell-icon.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from '../../Assets/logo.png';
import { useCognitoAuth } from '../../Contexts/AuthCognitoContext';
// import GitInfo from 'react-git-info/macro';

const Header = ({ classHeader }) => {
  // nesses paths o header nao deve ser renderizado pois esta em processo para ser substituido
  const hidePaths = ['management', '/briefing/\\w+'];
  // paths onde a logo com link para a home devem aparecer
  const pathsShowLogo = ['inov'];
  // ReactGA.pageview(window.location.pathname + window.location.search);
  const { authenticated, signOut /* , user */ } = useCognitoAuth();
  // const [popoverOpen, setPopoverOpen] = useState(false);
  // const toggle = () => setPopoverOpen(!popoverOpen);
  const navigate = useNavigate();
  const location = useLocation();

  const showHeader = hidePaths.reduce((acc, cur) => {
    return !RegExp(cur).test(location?.pathname) && acc;
  }, true);

  // const gitInfo = GitInfo();
  // console.log('gitInfo', gitInfo);
  // const version = `${gitInfo?.commit?.shortHash} ${new Date(
  //   gitInfo?.commit?.date
  // ).toLocaleDateString('pt-BR')}`;

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  const linkHome = () => {
    if (location?.pathname) {
      for (const path of pathsShowLogo) {
        if (RegExp(path).test(location.pathname))
          return (
            <Link to="/home">
              <div className="logo">
                <img src={logo} alt="Amicci Digital" />
                <span className="logoSpan">Digital</span>
              </div>
            </Link>
          );
      }
    }
    return null;
  };

  return showHeader ? (
    <>
      <header className={`headerContainer ${classHeader}`}>
        <div className="logoDiv">{linkHome()}</div>
        <div className="headerItems">
          <div className="headerNotifications">
            <img
              id="notifications"
              // onClick={handleNotifications}
              src={Bell}
              alt="Notificações"
            />
          </div>
          {/* {isSignedIn && (
						<>
							<div className="whiteLogo">
								<img src={googleUser.profileObj.imageUrl} alt={googleUser.profileObj.name} />
							</div>
							<div className="userLog">
								<p>
									Usuário: {googleUser.profileObj.name}
								</p>
								<p>
									E-mail: {googleUser.profileObj.email}
								</p>
							</div>
						</>
					)} */}

          {authenticated && (
            <>
              <div className="whiteLogo">
                {/* <img src={googleUser.profileObj.imageUrl} alt={googleUser.profileObj.name} /> */}
              </div>
              <div className="userLog">
                {/* <p>Usuário: {user?.username}</p>
                <p>E-mail: {user?.attributes?.email}</p> */}
              </div>
            </>
          )}
          <div className="logOut">
            {authenticated && <button onClick={handleSignOut}>Sair</button>}
            {/* <span className="versionGit">
              {process.env.REACT_APP_FRONT_ENV} - V: {version}
            </span> */}
          </div>
        </div>
        {/* <div className="clear"></div> */}

        {/* <UncontrolledPopover
          trigger="click"
          placement="right-start"
          target="notifications">
          <div className="notifications">
            <p className="titleNotifications">Últimas Notificações</p>
            <div className="border-light"></div>
            {notifications.map((notification, i) => (
              <div className="notificationsArea" key={i}>
                <div
                  onClick={() => openToModal(notification.id)}
                  id={notification.id}
                  className="notificationsBox">
                  {notification.type === 'A' ? (
                    <div className="notificationsIcon">
                      <img src={Star} alt="Star" />
                    </div>
                  ) : (
                    <div className="notificationsIcon">
                      <img src={GreenBell} alt="GreenBell" />
                    </div>
                  )}
                  <div className="alertNotification">
                    {(() => {
                      if (notification.type === 'N') {
                        return <p>Novo Briefing</p>;
                      } else if (notification.type === 'A') {
                        return <p>Briefing Atualizado</p>;
                      } else if (notification.type === 'C') {
                        return <p>Cancelado</p>;
                      } else if (notification.type === 'F') {
                        return <p>Finalizado</p>;
                      } else {
                        return <div>catch all</div>;
                      }
                    })()}
                  </div>
                  <div className="dateNotification">
                    {convert(notification.created_at)}
                  </div>
                  <div className="clear"></div>
                  <div className="nameProject">
                    <p>Projeto: {notification.briefing_name} </p>
                    <p>Cliente: {notification.retailer_name}</p>
                  </div>
                </div>
                <Modal
                  size="lg"
                  isOpen={modalId === notification.id}
                  modalid={notification.id}
                  id={notification.id}>
                  <ModalHeader toggle={closeToModal}>
                    <p className="font18 bold line-regular">Notificação</p>
                    <p className="font18 line-regular">
                      Projeto: {notification.briefing_name}
                    </p>
                  </ModalHeader>
                  <ModalBody>
                    <div className="flex-start pd-20">
                      {notification.description === 'Alteração Briefing' ? (
                        <div className="iconModal">
                          <img width="60" src={Star} alt="Star" />
                        </div>
                      ) : (
                        <div className="iconModal">
                          <img width="60" src={GreenBell} alt="GreenBell" />
                        </div>
                      )}
                      <div className="textForModal">
                        <div className="textTitleModal">
                          {(() => {
                            if (notification.type === 'N') {
                              return <p>Novo Briefing</p>;
                            } else if (notification.type === 'A') {
                              return <p>Briefing Atualizado</p>;
                            } else if (notification.type === 'C') {
                              return <p>Cancelado</p>;
                            } else if (notification.type === 'F') {
                              return <p>Finalizado</p>;
                            } else {
                              return <div>catch all</div>;
                            }
                          })()}
                        </div>
                        <div className="textModal">
                          <p>{notification.message}</p>
                        </div>
                        <div className="textModal">
                          <p>
                            Notificação emitida por:
                            <span>{notification.user_name}</span>
                          </p>
                        </div>
                        <div className="textModal">
                          <p>Data: {convert(notification.created_at)}</p>
                        </div>
                        <p onClick={closeToModal}>Fechar</p>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            ))}
          </div>
        </UncontrolledPopover> */}
      </header>
    </>
  ) : null;
};

export default Header;
