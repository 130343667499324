import React from 'react';
import { classNames } from '../../utils/utils';
// import ReactGA from 'react-ga';
import { useCognitoAuth } from '../../Contexts/AuthCognitoContext';
import styles from './LoginCognito.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Col, Button, Row, Input } from 'rsuite';
function LoginCognito(props) {
  // ReactGA.pageview('/login');

  const { signIn, loading, setStepForgotPassword } = useCognitoAuth();

  const validationSchema = Yup.object({
    username: Yup.string().required('Campo Obrigatório'),
    password: Yup.string().required('Campo Obrigatório'),
  });

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: values => {
      signIn(values.username, values.password);
    },
  });
  return (
    <>
      <div className={styles.loginInfo}>
        <p>
          Bem vindo de volta!
          <br /> Por favor faça o login para continuar.
        </p>
      </div>
      <form className={styles.formLogin} autoComplete="off">
        <Input
          type="text"
          name="email"
          placeholder="EMAIL ou USERNAME"
          onChange={handleChange('username')}
          className={styles.inputLogin}
          value={values.username}
          autoComplete="off"
        />
        <Row className={styles.space}>
          <span className={styles.error}>
            {errors.username ? errors.username : null}
          </span>
        </Row>
        <Input
          type="password"
          name="senha"
          placeholder="SENHA"
          className={styles.inputLogin}
          onChange={handleChange('password')}
          value={values.password}
          autoComplete="off"
        />
        <Row className={styles.space}>
          <span className={styles.error}>
            {errors.password ? errors.password : null}
          </span>
        </Row>
        <div className={styles.forgot}>
          <span onClick={() => setStepForgotPassword(1)}>
            Esqueceu a senha?
          </span>
        </div>
        <Row className={styles.send}>
          <Col md={12}>
            {' '}
            <Button
              block
              appearance="default"
              type="submit"
              onClick={handleSubmit}
              className={classNames({
                [styles.buttonSent]: !loading, //always applies
                [styles.buttonSentLoading]: loading, //only when open === true
              })}
              loading={loading}>
              Entrar
            </Button>
          </Col>
        </Row>
      </form>
      {/* <div className={styles.infoEnter}>
        <p>Não tem conta? inscrever-se</p>
      </div> */}
    </>
  );
}

export default LoginCognito;
