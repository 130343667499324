import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import 'moment/locale/pt-br';
import { Modal } from 'reactstrap';
import './ModalRedirectsInViability.scss';
import Character from '../../../../../Assets/character-ean-dun.svg';
import linkPlataform from '../../../../../utils/linkPlataform';

const ModalRedirectsInViability = ({ projectId }, ref) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  const redirectToPlattaform = () => {
    window.open(`${linkPlataform}/details-supplier/${projectId}/`, '_blank');
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      centered={true}
      size="large"
      className="modal-redirects-in-viability">
        <div className="header-modal-redirects-in-viability">
          <div className="text-modal">
            <p className="title">Atenção, você será redirecionado para o novo ambiente!</p>
              <p className="subtitle">
              Essas etapas estão refletindo as ações realizadas em platform.amicci.com.br.
              <br />
              Você deve ir para a página de fornecedores e executar as tarefas de acordo com as instruções para cada etapa.
              </p>
          </div>
            <img src={Character} alt="Personagem" />
          </div>
          <div className="div-supplier-plattaform">
            <button
              onClick={redirectToPlattaform}
              className="btn-redirect-plattaform">
                Ir para Página de Fornecedores
            </button>
            <button onClick={toggleModal} className="btn-close-mrv">Fechar</button>
        </div>
    </Modal>
  );
};

export default forwardRef(ModalRedirectsInViability);
