/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import './ModalReasons.scss';
import { Modal, ModalBody, Input } from 'reactstrap';
import useToggle from '../../../../../../utils/hooks/useToggle';

const ModalReasons = (
  {
    headerText,
    reasonList,
    inputReason,
    setInputReason,
    inputReasonOther,
    setInputReasonOther,
    statusCode = '',
  },
  ref
) => {
  const [modalReason, toggleReason] = useToggle(false, true);
  const [reason, setReason] = useState(inputReason || '');
  const [other, setOther] = useState(inputReasonOther || '');

  useImperativeHandle(ref, () => ({
    toggleReason,
  }));

  useEffect(() => {
    if (modalReason) {
      setReason(inputReason || '');
      setOther(inputReasonOther || '');
    }
  }, [modalReason]);

  const saveReason = () => {
    setInputReason(reason);
    setInputReasonOther(other);
    toggleReason();
  };

  return (
    <Modal
      //className={classes.modalReason}
      centered
      toggle={toggleReason}
      isOpen={modalReason}>
      {modalReason && (
        <ModalBody>
          <p style={{ fontWeight: '500px' }}>
            Por favor, escolha qual o motivo da <strong>{headerText}</strong>
          </p>
          <ul className="reasonListItens">
            {reasonList
              .sort((a, b) => {
                if (a.order > b.order) return 1;
                else if (a.order < b.order) return -1;
                else return 0;
              })
              .map(k => (
                <li key={`rl-${k.id}`}>
                  <label>
                    <input
                      className="reasonListInput"
                      type="radio"
                      value={k.id}
                      checked={reason === String(k.id)}
                      onChange={e => setReason(e.target.value)}
                    />
                    {k.label}
                  </label>
                  {k.label === 'Outro' && (
                    <Input
                      type="text"
                      value={other}
                      className="inputText input12"
                      onChange={e => setOther(e.target.value)}
                      disabled={reason !== String(k.id)}
                    />
                  )}
                </li>
              ))}
            <li>
              <div className="textLimited">Limite de 100 caracteres</div>
            </li>
          </ul>
          <div className="div-buttons">
            <button onClick={toggleReason} className="button-cancelar">
              Cancelar
            </button>
            <button
              onClick={saveReason}
              className="button-confirmar"
              disabled={statusCode === 'F'}>
              Confirmar
            </button>
          </div>
        </ModalBody>
      )}
    </Modal>
  );
};

export default forwardRef(ModalReasons);
