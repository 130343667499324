import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import ButtonDefault from '../../../../../Components/ButtonDefault/ButtonDefault';
import moment from 'moment';
import 'moment/locale/pt-br';
import './ModalNotApplyModule.scss';
// import apiConnectManagement from '../../../../../services/apiConnectManagement';
import { useCognitoAuth } from '../../../../../Contexts/AuthCognitoContext';
import { Alert } from 'rsuite';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';

const ModalNoApplicable = (
  {
    moduleId = null,
    moduleTypeId = null,
    moduleName = null,
    displayOnly = false,
    setModuleStatus = () => {},
    onSubmit = () => {},
  },
  ref
) => {
  const { user } = useCognitoAuth();
  const { changeModuleStatus, reloadProjectAndModule } = useManagementContext();
  const [showModal, setShowModal] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const now = new Date();
  const toggleModal = () => setShowModal(!showModal);

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  function closeModal() {
    setShowModal(!showModal);
  }

  const onHandleConfirm = async () => {
    setLoadingConfirm(true);
    const body = {
      user: {
        email: user?.attributes?.email,
        phone_number: '+5521999999999',
        sub: user?.attributes?.sub,
        username: user?.username,
      },
      obs: 'texto',
      reopen: true,
    };
    const resp = await changeModuleStatus(moduleTypeId, moduleId, 8, body);
    setLoadingConfirm(false);
    if (resp?.success) {
      setModuleStatus(resp.data?.project_module.status_module);
      reloadProjectAndModule();
      onSubmit();
      closeModal();
    } else {
      Alert.error(
        resp.message ||
          'Aconteceu um erro inesperado, tente novamente mais tarde!',
        50000
      );
    }
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={false}
      centered={true}
      className="custom-modal">
      <ModalHeader>
        <Row>
          <Col md={1} className="icon-head-no-applicable">
            <svg width="43.191" height="43.191" viewBox="0 0 36.191 36.191">
              <defs>
                {/* <linearGradient
                  id="linear-gradient"
                  x1="0.5"
                  x2="0.5"
                  y2="1"
                  gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#9a9b9b" />
                  <stop offset="1" stop-color="#545454" />
                </linearGradient> */}
              </defs>
              <path
                id="Icon_material-do-not-disturb-alt"
                data-name="Icon material-do-not-disturb-alt"
                d="M21.1,3a18.1,18.1,0,1,0,18.1,18.1A18.149,18.149,0,0,0,21.1,3ZM6.619,21.1A14.519,14.519,0,0,1,21.1,6.619,14.261,14.261,0,0,1,29.963,9.7L9.7,29.963A14.261,14.261,0,0,1,6.619,21.1ZM21.1,35.572A14.261,14.261,0,0,1,12.229,32.5L32.5,12.229A14.261,14.261,0,0,1,35.572,21.1,14.519,14.519,0,0,1,21.1,35.572Z"
                transform="translate(-3 -3)"
                // fill="url(#linear-gradient)"
              />
            </svg>
          </Col>
          <Col md={6} className="texts-modal">
            <h2 className="text-no-applicable">Não se aplica</h2>
            <h3 className="sub-text-no-applicable">{moduleName}</h3>
          </Col>
          <Col md={5} className="date-modal">
            Data: {moment(now).locale('pt-br').format('DD/MM/YYYY')} <br />
            <span style={{ textTransform: 'capitalize' }}>
              {moment(now).format('dddd').split('-')[0]}{' '}
            </span>{' '}
            {moment(now).format('HH:mm')}
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <p className="text-color-blue mt-2">
          <strong>Observação</strong>
        </p>
        <p>
          Você optou por
          <span className="text-color-orange"> não aplicar o módulo</span>
          . Esteja ciente que ao realizar essa ação todas as etapas irão passar
          ao estado de NÃO APLICADA, mesmo as que já tenha iniciado ou
          finalizado.
          <br />
          Deseja continuar?
        </p>
      </ModalBody>
      <ModalFooter>
        <ButtonDefault color="red" radius onClick={closeModal}>
          Cancelar
        </ButtonDefault>
        <ButtonDefault
          color="green"
          radius
          loading={loadingConfirm}
          onClick={onHandleConfirm}
          disabled={loadingConfirm}>
          Confirmar
        </ButtonDefault>
      </ModalFooter>
    </Modal>
  );
};

export default forwardRef(ModalNoApplicable);
