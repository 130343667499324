import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import 'moment/locale/pt-br';
import { Modal } from 'reactstrap';
import './ModalStartEanDun.scss';
import Character from '../../../../../Assets/character-ean-dun.svg';
import linkPlataform from '../../../../../utils/linkPlataform';

const ModalStartEanDun = ({ projectId }, ref) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  const redirectToPlattaform = () => {
    window.open(`${linkPlataform}/details-project/${projectId}/?sku=true`, '_blank');
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      centered={true}
      size="large"
      className="modal-start-ean-dun">
        <div className="header-modal-ean-dun">
          <div className="text-modal">
            <p className="title">Atenção, você precisa ir para outro lugar!</p>
              <p className="subtitle">
                Para iniciar e/ou finalizar a etapa de EAN/DUN, você deve
                ir para a gestão de SKU e preencher as etapas destinadas ao EAN.
              </p>
          </div>
            <img src={Character} alt="Personagem ean dun" />
          </div>
        <div className="div-sku-plattaform">
          <button onClick={redirectToPlattaform}>Ir para gestão de SKU</button>
        </div>
    </Modal>
  );
};

export default forwardRef(ModalStartEanDun);
