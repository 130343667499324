import React, { useState, useEffect } from 'react';
// import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Modal } from 'reactstrap';
import Loading from '../../Components/Helpers/Loading';
import TokenRefresh from '../../Components/TokenRefresh/TokenRefresh';
import styles from './Home.module.css';
import logo from '../../Assets/logo.png';
import GestaoComercial from '../../Assets/images/gestao-comercial-color.svg';
import Indicadores from '../../Assets/images/indicadores-color.svg';
import PrivateLabelManager from '../../Assets/images/private-label-manager-color.svg';
import InovaAmicci from '../../Assets/images/inova-amicci-color.svg';
import BaseFornecedores from '../../Assets/images/base-fornecedores-color.svg';
import linkVendors from '../../utils/linkVendors';
import desenhoHome from '../../Assets/images/desenho-home.svg';

function Home() {
  // ReactGA.pageview('/home');
  const [modal, setModal] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  function closeModal() {
    setModal(!modal);
  }

  // if (loading) return <Loading />;

  return (
    <>
      <TokenRefresh />
      {loading ? (
        <Loading />
      ) : (
        <>
          <main className={styles.home}>
            <div className="container-pdm">
              <div className={styles.headerHome}>
                <div className={styles.logoDigital}>
                  <img alt="logo" src={logo} atl="Amicci Digital" />
                  <span>Digital</span>
                </div>
              </div>
              <div className={styles.bemVindo}>
                <p>BEM VINDO</p>
              </div>
              <div className="flex-space p-t-3">
                <div className={styles.slogan}>
                  <h1 className="animeLeft">
                    <span>D</span>igital
                  </h1>
                  <h2 className="animeLeft">
                    Soluções integradas para decisões <br />
                    estratégicas, com foco na excelência e <br />
                    inovação.
                  </h2>
                  <button onClick={toggle} className={styles.buttonSaibaMais}>
                    Saiba Mais
                  </button>
                </div>
                <div className={`${styles.loginImg} animeRight`}>
                  <img src={desenhoHome} alt="grupo-home" />
                </div>
              </div>
              <div className={`${styles.botoesArea} animeLeft`}>
                <div className={styles.divBtns}>
                  <Link
                    to="/gestao-comercial"
                    className={styles.btnGestaoComercial}>
                    <div className={styles.imgArea}>
                      <div className={styles.img}></div>
                    </div>
                    <p>Gestão Comercial</p>
                  </Link>
                </div>
                <div className={styles.divBtns}>
                  <Link to="/indicadores" className={styles.btnIndicators}>
                    <div className={styles.imgArea}>
                      <div className={styles.img}></div>
                    </div>
                    <p>Indicadores</p>
                  </Link>
                </div>
                <div className={styles.divBtns}>
                  <Link to="/pdm" className={styles.btnPlm}>
                    <div className={styles.imgArea}>
                      <div className={styles.img}></div>
                    </div>
                    <p>Product Development Manager (PDM)</p>
                  </Link>
                </div>
                <div className={styles.divBtns}>
                  <a
                    href={linkVendors}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.btnBaseFor}>
                    <div className={styles.imgArea}>
                      <div className={styles.img}></div>
                    </div>
                    <p>Base de Fornecedores</p>
                  </a>
                </div>
                <div className={styles.divBtns}>
                  <Link to="/inov" className={styles.btnInov}>
                    <div className={styles.imgArea}>
                      <div className={styles.img}></div>
                    </div>
                    <p>Inova Amicci</p>
                  </Link>
                </div>
              </div>
            </div>
          </main>
          <Modal
            isOpen={modal}
            className={styles.modalSaibaMais}
            size="lg"
            toggle={toggle}>
            <div className={styles.paddingModal}>
              <div className={styles.closeModal} onClick={closeModal}>
                X
              </div>
              <h3>
                Descubra: <span className="orange">Amicci Digital</span>
              </h3>
              <h4>
                Construímos três ferramentas com foco em auxiliar o crescimento
                da empresa com decisões baseadas em dados e automação de
                processos.
              </h4>
              <div className={styles.infoArea}>
                <div className="flex-space m-t-2">
                  <div className={styles.imgModal}>
                    <img src={GestaoComercial} alt="Gestão Comercial" />
                  </div>
                  <div className={styles.textForModal}>
                    <h5>Gestão comercial</h5>
                    <p>
                      Acompanhe os números , resultados e insights de
                      previsibilidade
                      <br /> dos seus varejistas.
                    </p>
                  </div>
                </div>
                <div className="flex-space m-t-2">
                  <div className={styles.imgModal}>
                    <img src={Indicadores} alt="Indicadores" />
                  </div>
                  <div className={styles.textForModal}>
                    <h5>Indicadores</h5>
                    <p>
                      Tenha de maneira prática os números de cada departamento e
                      <br />
                      uma visão consolidada de performance de suas equipes.
                    </p>
                  </div>
                </div>
                <div className="flex-space m-t-2">
                  <div className={styles.imgModal}>
                    <img src={PrivateLabelManager} alt="PLM" />
                  </div>
                  <div className={styles.textForModal}>
                    <h5>Product Development Manager</h5>
                    <p>
                      Sistema Amicci que está sendo construído com a colaboração
                      de cada departamento
                      <br /> da empresa com objetivo de garantir a visibilidade
                      de cada etapa necessária para o<br /> desenvolvimento da
                      marca própria com segurança.
                    </p>
                  </div>
                </div>
                <div className="flex-space m-t-2">
                  <div className={styles.imgModal}>
                    <img src={BaseFornecedores} alt="Base" />
                  </div>
                  <div className={styles.textForModal}>
                    <h5>Base de Fornecedores</h5>
                    <p>
                      Veja quem são nossos Fornecedores. Aqui você pode ter
                      informações detalhadas,
                      <br /> possibilitando um acompanhamento das suas
                      qualificações e atualizações do seu interesse.
                    </p>
                  </div>
                </div>
                <div className="flex-space m-t-2">
                  <div className={styles.imgModal}>
                    <img src={InovaAmicci} alt="Inov" />
                  </div>
                  <div className={styles.textForModal}>
                    <h5>Amicci Digital</h5>
                    <p>
                      Metodologia de melhoria continua, um espaço destinado a
                      todos os colaboradores com o<br /> objetivo de idealizar e
                      implementar soluções inovadoras a para Amicci.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
}

export default Home;
