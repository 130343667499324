import React from 'react';
// import ReactGA from 'react-ga';
import styles from './styles.module.css';
import { Col, Row } from 'rsuite';
import image1 from './images/image1.png';
import image2 from './images/image2.png';
import text from './images/text.png';

function Home() {
  // ReactGA.pageview('/pdm');

  return (
    <>
      <Row className={styles.alignCenter}>
        <>
          <Col
            md={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img
              style={{
                position: 'relative',
                top: '50px',
              }}
              src={image2}
              alt="inov"
            />
            <img
              style={{
                position: 'absolute',
                top: '150px',
              }}
              src={text}
              alt="texto"
            />
          </Col>

          <Col md={12}>
            <img src={image1} alt="inov" />
          </Col>
        </>
      </Row>
    </>
  );
}

export default Home;
