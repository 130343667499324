/* eslint-disable no-empty-pattern */
import React, { forwardRef, useImperativeHandle } from 'react';
import { Modal } from 'reactstrap';
import useToggle from '../../../../../utils/hooks/useToggle';
import { BsCloudDownload } from 'react-icons/bs';
import { BsFileEarmarkText } from 'react-icons/bs';
import { BsXCircle } from 'react-icons/bs';
import './ModalDownloadDoc.scss';

const ModalDownloadDoc = ({}, ref) => {
  const [show, toggle] = useToggle();

  useImperativeHandle(ref, () => ({ toggle }));

  const pathPpt =
    'https://buyer-platform-images.s3.amazonaws.com/Apresentacao_de_Briefing_v3.pptx';

  const pathExcel =
    'https://buyer-platform-images.s3.amazonaws.com/Excel_Briefing.xlsx';

  return (
    <>
      <Modal
        backdrop={true}
        centered={true}
        toggle={toggle}
        isOpen={show}
        size="md">
        <div className="download-modal">
          <div className="div-header">
            <div className="div-icon-download">
              <BsCloudDownload className="icon-download" />
            </div>
            <span className="title-header">Download de documentos</span>
            <div>
              <BsXCircle className="icon-close" onClick={toggle} />
            </div>
          </div>
          <br></br>
          <p className="body-modal">
            Os documentos dispostos são modelos disponibilizados pela Amicci
            para que haja um padrão na hora da apresentação do briefing para
            nossos clientes. <br />
            Após o preenchimento, suba os arquivos no botão de{' '}
            <span style={{ color: '#425BA6', fontWeight: '600' }}>
              DOCUMENTOS.
            </span>
            <br></br>
            <br></br>
            <p>Clique abaixo para fazer download dos documentos:</p>
          </p>
          <br></br>
          <div className="div-icon-doc">
            <BsFileEarmarkText className="icon-doc" />
            <a href={pathPpt} className="link-doc">
              Modelo de apresentação para cliente
            </a>
            <br></br>
            <br></br>
          </div>
          <div className="div-icon-doc">
            <BsFileEarmarkText className="icon-doc" />
            <a href={pathExcel} className="link-doc">
              Modelo de planilha para cliente
            </a>
            <br></br>
            <br></br>
          </div>
          <br></br>
        </div>
      </Modal>
    </>
  );
};

export default forwardRef(ModalDownloadDoc);
