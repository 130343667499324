import React from 'react';

const Checklist = props => {
  return (
    <div style={{ display: 'flex', columnGap: '10px', flexWrap: 'wrap' }}>
      {props.vendordata &&
        props.vendordata.map(vendordata => {
          return (
            <label
              key={vendordata.id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
              }}>
              <input
                type="checkbox"
                className="btnBlue"
                style={{ marginRight: '5px' }}
                defaultChecked={props.selectedProviders.includes(vendordata.id)}
                onChange={ev => {
                  props.handleUpdateSelectedProviders(
                    vendordata.id,
                    ev.target.checked
                  );
                }}
              />
              <span className="btnBlue">{vendordata.name}</span>
            </label>
          );
        })}
    </div>
  );
};

export default Checklist;
