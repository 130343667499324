import React from 'react';
import { Input, FormGroup, Label } from 'reactstrap';
import './InputText.scss';
import { classNames } from '../../utils/utils';

const InputText = ({
  type = 'text',
  label,
  colorLabel = 'primary1',
  noBorder,
  invalid,
  icon,
  inputMode,
  onKeyUp,
  children,
  noMargin,
  ...otherProps
}) => {
  const classes = classNames({
    inputTextComponent: true,
    'with-label': !!label,
    invalid: !!invalid,
    'with-icon': !!icon,
    'no-border': noBorder,
    'no-margin': noMargin,
  });
  return (
    <FormGroup className={classes}>
      {label && <Label className={`${colorLabel}`}>{label}</Label>}
      {icon && <div className="input-icon">{icon}</div>}
      {type === 'select' && children ? (
        <Input
          type={type}
          onKeyUp={onKeyUp}
          inputMode={inputMode}
          {...otherProps}
          invalid={!!invalid}>
          {children}
        </Input>
      ) : (
        <Input
          type={type}
          onKeyUp={onKeyUp}
          inputMode={inputMode}
          {...otherProps}
          invalid={!!invalid}
        />
      )}
      {invalid && typeof invalid === 'string' && <span>{invalid}</span>}
    </FormGroup>
  );
};

export default InputText;
