/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from 'react';

function useToggle(value1 = false, value2 = true) {
  const [state, setState] = useState(value1);

  const toggle = useCallback(() => {
    setState(prevState => (prevState === value1 ? value2 : value1));
  }, []);

  return [state, toggle];
}

export default useToggle;
