/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useCognitoAuth } from '../../Contexts/AuthCognitoContext';
import { useNavigate } from 'react-router-dom';
import style from './styles.module.css';
import Loading from '../../Components/Helpers/LoadingDiv';
import logo from '../../Assets/logo.png';

function SessionExpired() {
  const { signOut } = useCognitoAuth();
  const navigate = useNavigate();

  useEffect(() => {
    signOut();
    setTimeout(() => {
      navigate('/');
    }, 3000);
  }, []);

  return (
    <div className={style.containerCard}>
      <div className={style.card}>
        <div className={style.logoDigital}>
          <img src={logo} alt="Amicci Digital" />
          <span>Digital</span>
        </div>

        <p className={style.cardTitle}>Sessão Expirada</p>
        <p>Você será redirecionado para a tela de login em instantes.</p>
        <div className={style.bodyCard}>
          <Loading />
        </div>
      </div>
    </div>
  );
}

export default SessionExpired;
