import React from 'react';
import { Routes, useParams, Route } from 'react-router-dom';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import ListViewProjects from './ListViewProjects/ListViewProjects';
import MenuPdm from '../../../Components/Sidebar/MenuPdm';
import NewProject from './NewProject/NewProject';
import DetailsViewProject from './DetailsViewProject/DetailsViewProject';

function Management() {
  // usa o id para saber se esta dentro de uma pagina de detalhes
  // na listagem o icone do menu deve se fixo
  // nos detalhes o icone não deve ser fixo
  const { id } = useParams();
  return (
    <>
      <Sidebar hideLogo fixedIcon={!!id} local="management">
        <MenuPdm opened="management" />
      </Sidebar>
      <main>
        <Routes>
          <Route path="/" element={<ListViewProjects />} />
          <Route path="/:id" element={<DetailsViewProject />} />
          <Route path="/:id/:urlModuleId" element={<DetailsViewProject />} />
          <Route path="/new-project" element={<NewProject />} />
        </Routes>
      </main>
    </>
  );
}

export default Management;
