import React, { useState } from 'react';
// import ReactGA from 'react-ga';
import { classNames } from '../../../../../../../../utils/utils';
import styles from './styles.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {
  Col,
  Button,
  Modal,
  Icon,
  Row,
  Container,
  Animation,
  Alert,
  Form,
} from 'rsuite';
import { useCognitoAuth } from '../../../../../../../../Contexts/AuthCognitoContext';
import apiConnectSourcing from '../../../../../../../../services/apiConnectSourcing';
import apiConnectSellerPublic from '../../../../../../../../services/apiConnectSellerPublic';

const PanelDetails = React.forwardRef(({
  responsibleName,
  notes,
  isRestart,
  ...props
}, ref) => (
  <Row
    {...props}
    ref={ref}
    style={{
      overflow: 'hidden',
      paddingleft: '200px',
    }}>
    <Col md={24}>
      <p>{responsibleName}</p>
      <p><strong>Data:</strong> {moment(new Date()).format('DD/MM/YYYY')}</p>
      {isRestart && (
        <p>
          <strong>Descrição:</strong> {notes || 'Sem descrição fornecida.'}
        </p>
      )}
    </Col>
  </Row>
));

function ModalProspectionResult({
  show,
  closeModal,
  prospection,
  supplierName,
  supplierId,
  modalType,
  setProspection,
  supplierIndex,
  responsibleName,
}) {
  // ReactGA.pageview('/pdm');

  const { user } = useCognitoAuth();
  const email = user.attributes.email;
  const [showCollapse, setShowCollapse] = useState(false);
  const [loading, setLoading] = useState(false);

  const { Collapse } = Animation;

  const validationSchema = Yup.object({
    note: Yup.string().required('Campo Obrigatório'),
  });

  const logSellerCostUploadSourcing = docId => {
    if (modalType && !docId) {
      apiConnectSellerPublic.post(`/new-log-entry`, {
        user: email,
        action: 'Upload Custos Sourcing',
        entity: 'Prospecção',
        value: prospection.id,
      });
    }
  };

  const { handleSubmit, handleChange, values, errors, isValid, resetForm } =
    useFormik({
      initialValues: {
        note: '',
      },
      validateOnChange: false,
      validationSchema: modalType === false ? validationSchema : false,
      onSubmit: values => {
        const documentId = prospection?.cost_sheets_data?.find(
          sh => sh.provider === prospection?.suppliers?.[supplierIndex]?.supplier_id
        )?.id || null;
  
        setLoading(true);
        apiConnectSourcing
          .post(
            `/prospections/${prospection.id}/suppliers/${supplierId}/change-result/`,
            {
              result: modalType,
              note: modalType ? null : values.note,
              is_seller_linked: prospection?.supplierInfo?.[supplierIndex]?.is_seller_linked,
              document_id: documentId,
            }
          )
          .then(response => {
            if (response.data) {
              logSellerCostUploadSourcing(documentId);
              Alert.success('Fornecedor atualizado com Sucesso!', 3000);
              handleUpdateProspection(supplierIndex, response.data, values.note);
            } else {
              Alert.error(
                'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
                3000
              );
            }

            setLoading(false);
            handleCancel();
          })
          .catch(error => {
            console.log([error]);
            Alert.error(
              error?.response?.data?.message
              || 'Ocorreu um erro inesperado. Tente novamente, mais tarde!',
              3000
            );
            handleCancel();
            setLoading(false);
          });
      },
    });

  const handleToogleCollpase = () => {
    setShowCollapse(!showCollapse);
  };

  const handleCancel = () => {
    closeModal();
    resetForm();
  };

  const handleUpdateProspection = (supplierIndex, responseProspection, note) => {
    const newProspection = { ...prospection };
    const isRestart = responseProspection.result === null;
    const isThumbsDown = responseProspection.result === false;

    if (isRestart) {
      return window.location.reload();
    }

    newProspection.suppliers[supplierIndex] = {
      ...newProspection.suppliers[supplierIndex],
      result: responseProspection.result,
      accepted: responseProspection.accepted,
      is_delete_ok: responseProspection.is_delete_ok,
      notes: note || null
    };

    if (isThumbsDown) {
      newProspection.suppliers[supplierIndex].tasks.forEach(t => {
        if (t) {
          t.cancelled = true;
        }
      });
    }

    setTimeout(() => {
      setProspection(newProspection);
    }, 1000);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Modal show={show} onHide={closeModal}>
          <Modal.Header>
            <Container>
              <Row>
                <Col sm={2}>
                  <Row className={styles.iconTittle}>
                    <Col
                      className={classNames({
                        [styles.modalIcon]: !modalType,
                        [styles.modalIconSuccess]: modalType,
                      })}
                      md={5}>
                      <Icon icon="bell-o" size="lg" />
                    </Col>
                  </Row>
                </Col>
                <Col sm={22}>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <Col sm={14}>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Fornecedor:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {supplierName}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Projeto:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {prospection?.briefing_name}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={10}>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Cliente:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {prospection?.clientInfo.name}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={24}>
                          <span className={styles.modalDescription}>
                            Etapa:{' '}
                            <strong className={styles.modalDescriptionClient}>
                              {modalType === null
                                ? 'Retomar'
                                : modalType
                                ? 'Positiva'
                                : 'Negativa'}
                            </strong>
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Header>

          <Modal.Body className={styles.body}>
            <Container>
              <Row>
                <Col md={24}>
                  {modalType === null ? (
                    <span className={styles.textConfirmation}>
                      Deseja retornar o fornecedor{' '}
                      <strong style={{ color: '#F39332' }}>
                        {supplierName}
                      </strong>{' '}
                      ao projeto?
                    </span>
                  ) : modalType ? (
                    <span className={styles.textConfirmation}>
                      Deseja confirmar a continuidade do fornecedor{' '}
                      <strong style={{ color: '#F39332' }}>
                        {supplierName}
                      </strong>{' '}
                      no projeto?
                    </span>
                  ) : (
                    <>
                      <textarea
                        value={values.notes}
                        placeholder="Digite aqui"
                        className={styles.bodyCard}
                        onChange={handleChange('note')}></textarea>
                      <span className={styles.error}>
                        {errors.note ? errors.note : null}
                      </span>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={{ marginTop: '10px' }}>
                <Col sm={6} md={6}>
                  <span
                    className={styles.buttonDetails}
                    onClick={handleToogleCollpase}>
                    {' '}
                    Detalhes{' '}
                    <Icon
                      style={{ color: '615b56', fontWeight: 700 }}
                      icon={classNames({
                        'angle-right': !showCollapse,
                        'angle-down': showCollapse,
                      })}
                    />
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col md={24}>
                  <Collapse in={showCollapse}>
                    <PanelDetails
                      responsibleName={responsibleName}
                      notes={prospection?.suppliers?.[supplierIndex]?.notes}
                      isRestart={modalType === null}
                    />
                  </Collapse>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disable={!isValid}
              type="submit"
              onClick={handleSubmit}
              color={'blue'}
              loading={loading}>
              Confirmar
            </Button>
            <Button onClick={handleCancel} appearance="subtle">
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
}

export default ModalProspectionResult;
