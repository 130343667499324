/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useManagementContext } from '../../../../../Contexts/ManagementContext';
import { useCognitoAuth } from '../../../../../Contexts/AuthCognitoContext';
import './ModalEditProject.scss';
import useToggle from '../../../../../utils/hooks/useToggle';
import SelectPicker from '../../../../../Components/SelectPicker/SelectPicker';
import { useBriefingContext } from '../../../../../Contexts/BriefingContext';

const ModalEditProject = ({ project, onSave }, ref) => {
  const { user: authUser } = useCognitoAuth();
  const { user, getUser } = useManagementContext();
  const [show, toggle] = useToggle();
  // eslint-disable-next-line no-unused-vars
  const [checkUser, setCheckUser] = useState(null);
  const [nameProject, setNameProject] = useState();

  const [clientResponsibleSelect, setClientResponsibleSelect] = useState(null);

  const [developmentResponsibleSelect, setDevelopmentResponsibleSelect] =
    useState(null);

  const [serviceResponsibleSelect, setServiceResponsibleSelect] =
    useState(null);

  const [dtiResponsibleSelect, setDtiResponsibleSelect] = useState(null);

  const [brandSelect, setBrandSelect] = useState(null);

  const {
    loading,
    managers,
    serviceManagers,
    managerProject,
    clientResponsibles,
    searchBriefings,
    updateBriefing,
    dtiUsers,
    listBrands,
    ...briefingContext
  } = useBriefingContext();

  useEffect(() => {
    if (project) {
      briefingContext.getClientResponsibles(project.client_id);
      briefingContext.getServiceManagers(project.client_id);
      briefingContext.getManagers(project.client_id);
      briefingContext.getDtiUsers();
      briefingContext.getListBrands(project.client_id);
      setNameProject(project.name);
      setClientResponsibleSelect(project.client_responsible);
      setDevelopmentResponsibleSelect(project.development_responsible_id);
      setServiceResponsibleSelect(project.service_responsible_id);
      setDtiResponsibleSelect(project.dti_responsible_id);
      setBrandSelect(project.client_brand);
    }
  }, [project]);

  useImperativeHandle(ref, () => ({ toggle }));

  useEffect(() => {
    if (!user) {
      getUser(authUser.attributes.email).then(resp => {
        if (resp?.success) {
          setCheckUser(resp?.data);
        }
      });
    } else {
      setCheckUser(user);
    }
  }, []);

  const filterNameById = (id, list) => {
    return list.find(item => item.value === id)?.label;
  };

  const filteredDtiUsers = useMemo(
    () => dtiUsers.filter(dti => dti.department === 4),
    [dtiUsers]
  );

  const arrayManagerCustomer = useMemo(() => {
    return clientResponsibles.map(c => ({
      value: c.id,
      label: `${c.given_name} ${c.family_name}`,
    }));
  }, [clientResponsibles]);

  const arrayServiceManagers = useMemo(() => {
    return serviceManagers.map(ms => ({
      value: ms.user,
      label: ms.user_name,
    }));
  }, [serviceManagers]);

  const arrayManagers = useMemo(() => {
    return managers.map(m => ({
      value: m.user,
      label: m.user_name,
    }));
  }, [managers]);

  const arrayDTISelecteds = useMemo(() => {
    return filteredDtiUsers.map(dti => ({
      value: dti.id,
      label: dti.name,
    }));
  }, [filteredDtiUsers]);

  const arrayBrands = useMemo(() => {
    if (listBrands && listBrands.brands) {
      return listBrands.brands.map(brand => ({
        value: brand,
        label: brand,
      }));
    }
    return [];
  }, [listBrands]);

  const newProject = {
    name: nameProject,
    development_responsible_id: developmentResponsibleSelect,
    development_responsible: filterNameById(
      developmentResponsibleSelect,
      arrayServiceManagers
    ),
    service_responsible: filterNameById(
      serviceResponsibleSelect,
      arrayManagers
    ),
    service_responsible_id: serviceResponsibleSelect,
    client_responsible_id: clientResponsibleSelect,
    client_responsible: filterNameById(clientResponsibleSelect, arrayManagerCustomer),
    dti_responsible: filterNameById(dtiResponsibleSelect, arrayDTISelecteds),
    dti_responsible_id: dtiResponsibleSelect,
    client_brand: brandSelect,
    user: {
      username: authUser.username,
      attributes: {
        sub: authUser.attributes.sub,
        email: authUser.attributes.email,
        phone_number: null,
      },
    },
  };

  const getNewProject = () => {
    let newProjectObj = { ...newProject };
    if (!developmentResponsibleSelect) {
      delete newProjectObj['development_responsible_id'];
      delete newProjectObj['development_responsible'];
    }
    if (!serviceResponsibleSelect) {
      delete newProjectObj['service_responsible_id'];
      delete newProjectObj['service_responsible'];
    }
    if (!dtiResponsibleSelect) {
      delete newProjectObj['dti_responsible_id'];
      delete newProjectObj['dti_responsible'];
    }

    return newProjectObj;
  };

  return (
    <>
      <Modal
        backdrop={true}
        centered={true}
        className="modal-edit-briefing"
        size="md"
        toggle={toggle}
        isOpen={show}>
        <ModalHeader>
          <p className="title">Edição do projeto</p>
        </ModalHeader>
        <ModalBody>
          <div className="divForm">
            <label>Nome do projeto</label>
            <input
              className="inputNamePr"
              type="text"
              placeholder="Nome atual do projeto"
              value={nameProject}
              onChange={e => setNameProject(e.target.value)}></input>
          </div>
          <div className="divForm">
            <label>Responsável Cliente</label>
            <SelectPicker
              value={clientResponsibleSelect}
              onChange={e => setClientResponsibleSelect(e)}
              data={arrayManagerCustomer}
              className="selectPicker selectPickerCustom"
              placeholder="Responsável atual"
              disabled={
                loading.managerCustomer || arrayManagerCustomer.length === 0
              }
            />
          </div>
          <div className="divForm">
            <label>Responsável Desenvolvimento</label>
            <SelectPicker
              value={developmentResponsibleSelect}
              onChange={e => setDevelopmentResponsibleSelect(e)}
              data={arrayServiceManagers}
              className="selectPicker selectPickerCustom"
              placeholder="Selecione"
              disabled={
                loading.managerCustomer || arrayServiceManagers.length === 0
              }
            />
          </div>
          <div className="divForm">
            <label>Responsável Atendimento</label>
            <SelectPicker
              value={serviceResponsibleSelect}
              onChange={e => setServiceResponsibleSelect(e)}
              data={arrayManagers}
              className="selectPicker selectPickerCustom"
              placeholder="Selecione"
              disabled={loading.managers || arrayManagers.length === 0}
            />
          </div>
          <div className="divForm">
            <label>Responsável DTI</label>
            <SelectPicker
              value={dtiResponsibleSelect}
              onChange={e => setDtiResponsibleSelect(e)}
              data={arrayDTISelecteds}
              className="selectPicker selectPickerCustom"
              placeholder="Selecione"
              disabled={loading.dtiUsers || arrayDTISelecteds.length === 0}
            />
          </div>
          <div className="divForm">
            <label>Marca</label>
            <SelectPicker
              value={brandSelect}
              onChange={e => setBrandSelect(e)}
              data={arrayBrands}
              className="selectPicker selectPickerCustom"
              placeholder="Selecione"
              disabled={loading.listBrand || arrayBrands.length === 0}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="divButtons">
            <button className="btnCancel" type="button" onClick={toggle}>
              Cancelar
            </button>
            <button
              className="btnSave"
              type="button"
              onClick={() => onSave(getNewProject())}
              disabled={loading.updateBriefing}>
              Salvar
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default forwardRef(ModalEditProject);
