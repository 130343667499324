import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import classSide from '../Sidebar/Sidebar.module.css';
import supportIcon from '../../Assets/managerIcon.png';
import { ReportContext } from '../../Pages/CommercialManagement/CommercialManagement';

function MenuManagement() {
  const [reports] = useContext(ReportContext);

  const namesList = reports.map((rep, i) => (
    <li key={rep.reportname + i}>
      <Link
        to={`/gestao-comercial/grafico/${rep.reportname}`}
        className={classSide.linkMenu}>
        <img src={supportIcon} alt={rep.reportlabel} />
        {rep.reportlabel}
      </Link>
    </li>
  ));

  return <ul className={classSide.ulMenuSidebar}>{namesList}</ul>;
}

export default MenuManagement;
