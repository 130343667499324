import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import useToggle from '../../../../../../utils/hooks/useToggle';
import ModalResponseAddSupplier from '../ModalResponseAddSupplier/ModalResponseAddSupplier';
import './ModalSupplierRegistration.scss';
import { useBriefingContext } from '../../../../../../Contexts/BriefingContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cnpjMask from '../../../../../../utils/masks/cnpjMask';
import phoneMask from '../../../../../../utils/masks/phoneMask';

const ModalSupplierRegistration = ({ briefing, managers, emailUser }, ref) => {
  const { requestAddSupplier } = useBriefingContext();
  const [modal, toggle] = useToggle();
  const modalResponseAddSupplierRef = useRef(null);
  const [sending, setSending] = useState(false);

  useImperativeHandle(ref, () => ({ toggle }));

  const validationSchema = Yup.object({
    cnpj: Yup.string().required('CNPJ é obrigatório'),
    razao_social: Yup.string().required('Razão Social é obrigatório'),
    contact_name: Yup.string().required('Nome do Contato é obrigatório'),
    email: Yup.string()
      .required('E-mail é obrigatório')
      .email('E-mail inválido'),
    phone: Yup.string().required('Telefone 01 é obrigatório'),
  });

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      cnpj: '',
      razao_social: '',
      contact_name: '',
      email: '',
      phone: '',
      phone_two: '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: values => {
      sendDataSupplier(values);
    },
  });

  const sendDataSupplier = async vals => {
    setSending(true);
    const resp = await requestAddSupplier({
      briefing: briefing?.id,
      requested_by: emailUser,
      ...vals,
    });
    setSending(false);
    toggle();
    modalResponseAddSupplierRef.current.toggleModal(resp?.success);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader>
          <span>
            Solicitação de{' '}
            <span style={{ color: '#FF725E' }}>Cadastro de Fornecedor</span>
          </span>
        </ModalHeader>
        <ModalBody>
          <form autoComplete="off" className="">
            <Row>
              <Col md={6}>
                <label style={{ fontFamily: 'Poppins', color: '#3C3C3B' }}>
                  Projeto
                </label>
                <Input
                  placeholder="Nome do Projeto"
                  name="Projeto"
                  type="text"
                  disabled
                  value={briefing?.name || ''}
                />
              </Col>
              <Col md={6}>
                <label style={{ fontFamily: 'Poppins', color: '#3C3C3B' }}>
                  Categoria
                </label>
                <Input
                  placeholder="Categoria"
                  name="Categoria"
                  type="text"
                  disabled
                  value={briefing?.division || ''}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <label
                  style={{
                    marginTop: '10px',
                    fontFamily: 'Poppins',
                    color: '#3C3C3B',
                  }}>
                  Responsável do Projeto
                </label>
                <Input
                  placeholder="Responsável"
                  name="Responsável do Projeto"
                  type="text"
                  disabled
                  value={
                    managers.find(
                      m => m.user === briefing?.retailer_manager_user
                    )?.user_name || ''
                  }
                />
              </Col>
            </Row>{' '}
            <br />
            <p>Preencha as informações a seguir</p>
            <br />
            <Row>
              <Col md={6}>
                <label
                  style={{
                    marginTop: '10px',
                    fontFamily: 'Poppins',
                    color: '#3C3C3B',
                  }}>
                  CNPJ do Fornecedor
                </label>
                <Input
                  placeholder="XX.XXX.XXX/0001-XX"
                  name="CNPJ do Fornecedor"
                  type="text"
                  onChange={handleChange('cnpj')}
                  value={cnpjMask(values.cnpj)}
                />
              </Col>
              <Col md={6}>
                <label
                  style={{
                    marginTop: '10px',
                    fontFamily: 'Poppins',
                    color: '#3C3C3B',
                  }}>
                  Razão Social
                </label>
                <Input
                  placeholder="Digite Aqui"
                  name="Razão Social"
                  type="text"
                  onChange={handleChange('razao_social')}
                  value={values.razao_social}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <label
                  style={{
                    marginTop: '10px',
                    fontFamily: 'Poppins',
                    color: '#3C3C3B',
                  }}>
                  Nome do Contato
                </label>
                <Input
                  placeholder="Digite Aqui"
                  name="Nome do Contato"
                  type="text"
                  onChange={handleChange('contact_name')}
                  value={values.contact_name}
                />
              </Col>
              <Col md={6}>
                <label
                  style={{
                    marginTop: '10px',
                    fontFamily: 'Poppins',
                    color: '#3C3C3B',
                  }}>
                  E-mail
                </label>
                <Input
                  placeholder="email@email.com"
                  name="E-mail"
                  type="text"
                  onChange={handleChange('email')}
                  value={values.email}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label
                  style={{
                    marginTop: '10px',
                    fontFamily: 'Poppins',
                    color: '#3C3C3B',
                  }}>
                  Telefone 01
                </label>
                <Input
                  placeholder="(00)00000-0000"
                  name="Telefone 01"
                  type="text"
                  onChange={handleChange('phone')}
                  value={phoneMask(values.phone)}
                />
              </Col>
              <Col>
                <label
                  style={{
                    marginTop: '10px',
                    fontFamily: 'Poppins',
                    color: '#3C3C3B',
                  }}>
                  Telefone 02 (opcional)
                </label>
                <Input
                  placeholder="(00)00000-0000"
                  name="Telefone 02 (opcional)"
                  type="text"
                  onChange={handleChange('phone_two')}
                  value={phoneMask(values.phone_two)}
                />
              </Col>
            </Row>
          </form>
          {Object.keys(errors).length > 0 && (
            <Row>
              <div
                style={{ width: '80%', marginLeft: '10%' }}
                className="alert alert-danger mt-4">
                <ul>
                  {Object.keys(errors).map(key => (
                    <li key={`error-${key}`}>{errors[key]}</li>
                  ))}
                </ul>
              </div>
            </Row>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '15px',
            }}>
            <button className="buttonRed" onClick={toggle} disabled={sending}>
              Cancelar
            </button>
            <button
              className="buttonBlue"
              style={{ width: '10%', marginLeft: '15px' }}
              onClick={handleSubmit}
              disabled={sending}>
              Enviar
            </button>
          </div>
        </ModalBody>
      </Modal>
      <ModalResponseAddSupplier ref={modalResponseAddSupplierRef} />
    </>
  );
};

export default forwardRef(ModalSupplierRegistration);
