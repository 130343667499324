/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Checklist from './Checklist';
import titleIcon from '../../../../Assets/titleIcon.png';
import { useBriefingContext } from '../../../../Contexts/BriefingContext';

const ModalProductDetail = props => {
  const { id } = useParams();
  const { getProduct } = useBriefingContext();

  const [sourceSupplierVendor, setSourceSupplierVendor] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  var modalProduto = document.getElementsByClassName('new1');

  function closePro() {
    for (var i = 0; i < modalProduto.length; i += 1) {
      modalProduto[i].style.display = 'none';
    }
  }

  async function getData() {
    const resp = await getProduct(props.id);
    setSourceSupplierVendor(resp.data.providers);
  }

  useEffect(() => {
    getData();
  }, []);

  const handleUpdateSelectedProviders = (id, checked) => {
    if (checked) {
      setSelectedProviders(s => [...s, id]);
    } else {
      setSelectedProviders(s => [...s.filter(v => v !== id)]);
    }
  };

  const addProviders = () => {
    const ids = selectedProviders.join(',');
    window.location.href = `/pdm/sourcing/${id}?providers=${ids}`;
  };

  return (
    <div id={props.id} className="modal new1 modal-sourcing">
      <div
        className="modal-content modal-content1"
        id="bgProduto"
        style={{ minHeight: '396px' }}>
        <button
          id="closeProduto"
          className="btn btnOrange btnLarge modal-close"
          onClick={closePro}>
          Voltar
        </button>
        <br clear="all" />
        <span>
          <img alt="titulo" src={titleIcon} /> {props.name}
        </span>{' '}
        <br />
        <br />
        <span>Fornecedores</span>
        <br />
        <br />
        <button
          style={{ position: 'absolute', marginTop: '210px' }}
          disabled={selectedProviders.length === 0}
          className="btn btnGray btnAdd"
          onClick={addProviders}>
          Adicionar
        </button>
        <Checklist
          key={props.id}
          vendordata={sourceSupplierVendor}
          selectedProviders={selectedProviders}
          handleUpdateSelectedProviders={handleUpdateSelectedProviders}
        />
      </div>
    </div>
  );
};

export default ModalProductDetail;
