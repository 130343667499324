import React, { useState } from 'react';
// import ReactGA from 'react-ga';
import MenuPdm from '../../Components/Sidebar/MenuPdm';
import Loading from '../../Components/Helpers/Loading';
import Sidebar from '../../Components/Sidebar/Sidebar';
import HeaderModule from '../../Components/HeaderModule/HeaderModule';
import homeImgPlm from '../../Assets/homeImgPlm.png';
// import GuiaAmicci from '../../Components/GuiaAmicci/GuiaAmicci';

function HomeDash() {
  // ReactGA.pageview('/pdm');
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 1000);

  if (loading) return <Loading />;
  return (
    <>
      <HeaderModule classHeader="pdmHeaderModule">
        PRODUCT DEVELOPMENT MANAGER
      </HeaderModule>
      <Sidebar hideLogo>
        <MenuPdm />
      </Sidebar>
      <main>
        <div className="mainDiv">
          <div className="flex-center-gap homeImgPlm">
            <img src={homeImgPlm} alt="Home" />
          </div>
        </div>
      </main>
      {/* <GuiaAmicci /> */}
    </>
  );
}

export default HomeDash;
