import axios from 'axios';

let ambiente = '';
if (process.env.REACT_APP_FRONT_ENV === 'LOCAL') {
  ambiente =
  'https://twlmvcmlnd.execute-api.us-east-1.amazonaws.com/publics/sellers/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'DEV') {
  ambiente =
    'https://twlmvcmlnd.execute-api.us-east-1.amazonaws.com/publics/sellers/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'HMG') {
  ambiente =
    'https://apis-hmg.amicci-digital.com.br/publics/sellers/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'PROD') {
  ambiente =
    'https://apis.amicci.com.br/publics/sellers/api';
}

const apiConnectSellerPublic = axios.create({
  baseURL: ambiente,
});

export default apiConnectSellerPublic;