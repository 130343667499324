import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import HeaderModule from '../../Components/HeaderModule/HeaderModule';
import { IndicatorsContext } from './Indicators';

function GraphIndicatorsPage() {
  const { name } = useParams();
  const [indicators] = useContext(IndicatorsContext);

  const h = window.innerHeight;
  const height = h - 200;

  const indicator = indicators.find(ind => ind.name === name);

  return (
    <>
      <HeaderModule classHeader="indicatorsHeaderModule">
        {indicator?.label}
      </HeaderModule>
      <div className="mainDiv">
        <center>
          <iframe
            key={name}
            title={indicator?.label}
            width="100%"
            height={height}
            src={indicator?.link}
            frameBorder="0"></iframe>
        </center>
      </div>
    </>
  );
}

export default GraphIndicatorsPage;
