/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import ReactGA from 'react-ga';
import styles from './styles.module.css';
import {
  Col,
  Icon,
  Row,
  Container,
  InputGroup,
  Input,
  Divider,
  SelectPicker,
  Button,
  Alert,
} from 'rsuite';
import { parsePhoneNumber } from 'react-phone-number-input';
import { classNames } from '../../../../../../../../utils/utils';
import { ReactComponent as LinkIcon } from '../../../../../../../../Assets/link-ico.svg';
import apiConnectSourcing from '../../../../../../../../services/apiConnectSourcing';

function SupplierContactCollapse({
  contactsSupplier,
  supplierContactId,
  supplierId,
  prospectionId,
  index,
  setProspection,
  prospection,
}) {
  // ReactGA.pageview('/pdm');

  const [selectContactData, setSelectContactData] = useState([]);

  const [mainContact, setMainContact] = useState(supplierContactId);
  const [loading, setLoading] = useState();

  useEffect(() => {
    handleSelectContactData();
  }, []);

  const handleSelectContactData = () => {
    if (selectContactData.length === 0) {
      contactsSupplier.forEach(contact => {
        const result = {
          label: contact.name,
          value: contact.id,
          seller_user_id: contact?.seller_user_id
        };
        setSelectContactData(selectContactData => [
          ...selectContactData,
          result,
        ]);
      });
      const result = {
        label: 'Nenhum contato vinculado',
        value: null,
      };
      setSelectContactData(selectContactData => [...selectContactData, result]);
    }
  };

  const handleUpdateMainContact = mainContact => {
    let editProspection = { ...prospection };
    editProspection.suppliers[index].supplier_contact_id = Number(mainContact);
    setProspection(editProspection);
  };

  const handleLinkContact = () => {
    setLoading(true);
    apiConnectSourcing
      .put(
        `/prospections/${prospectionId}/suppliers/${supplierId}/link-contact/`,
        {
          contact_id: Number(mainContact),
          supplierInfo: prospection?.supplierInfo?.[index],
          supplier: prospection?.suppliers?.[index]
        }
      )
      .then(response => {
        if (response) {
          handleUpdateMainContact(mainContact);
          Alert.success('Contato Vinculado com Sucesso!');
        } else {
          setMainContact(supplierContactId);
          Alert.error('Erro ao vincular contato!');
        }
        setLoading(false);
      })
      .catch(() => {
        Alert.error('Erro ao vincular contato!');
        setLoading(false);
        setMainContact(supplierContactId);
      });
  };

  const phoneNumberDisplay = (value) => { 
    return parsePhoneNumber(value, "BR")?.formatInternational();
  }

  return (
    <Container className={styles.containerCollapse}>
      <Row className={styles.rowCollapse}>
        <Col md={3}>
          <strong className={styles.titleCollapse}>Contatos Fornecedor</strong>
          <Divider className={styles.dividerCollapse} />
        </Col>

        <Col md={5} mdOffset={1}>
          <label className={styles.labelCollapse}>
            {' '}
            Vincular contato ao projeto
          </label>
          <SelectPicker
            // onClick={handleSelectContactData}
            data={selectContactData}
            onChange={value => {
              setMainContact(value);
            }}
            value={mainContact}
            appearance="default"
            placeholder="Escolha o contato"
            renderMenuItem={(label, item) => {
              return (
                <div className='d-flex justify-content-between'>
                  {label}

                  {item.seller_user_id && (
                    <LinkIcon className={classNames({[styles.linkIconCombo]: true})} />
                  )}
                </div>
              );
            }}
          />
        </Col>
        <Col md={2}>
          <Button
            disabled={mainContact === null}
            style={{ marginTop: 32 }}
            // appearance="primary"
            color="blue"
            loading={loading}
            onClick={handleLinkContact}>
            Vincular
          </Button>
        </Col>
      </Row>

      {contactsSupplier?.map((obj, i) => {
        return (
          <>
            <Row className={classNames({[styles.rowCollapse]: true, "d-flex": true})}>
              <Col md={6}>
                <label className={styles.labelCollapse}> Nome </label>
                <InputGroup className={styles.inputGroupCollapse}>
                  <InputGroup.Addon>
                    <Icon
                      className={styles.iconCollapse}
                      icon="avatar"
                      size="lg"
                    />
                  </InputGroup.Addon>
                  <Input
                    className={styles.inputCollapse}
                    value={obj.name}
                    disabled
                  />
                </InputGroup>
              </Col>
              <Col md={6}>
                <label className={styles.labelCollapse}>Cargo</label>
                <InputGroup className={styles.inputGroupCollapse}>
                  <InputGroup.Addon>
                    <Icon
                      className={styles.iconCollapse}
                      icon="profile"
                      size="lg"
                    />
                  </InputGroup.Addon>
                  <Input
                    className={styles.inputCollapse}
                    value={obj.office || 'Não registrado'}
                    disabled
                  />
                </InputGroup>
              </Col>
              <Col className="d-flex">
                {obj?.seller_user_id && (
                  <LinkIcon className={styles.linkIcon} />
                )}
              </Col>
            </Row>
            <Row className={styles.rowCollapse}>
              {obj.contacts_info?.sort((a,b) => b.info_type - a.info_type)?.map((obj1, j) => {
                return (
                  <Col md={6}>
                    <label className={styles.labelCollapse}>
                      {obj1.contact_type}{' '}
                    </label>
                    <InputGroup className={styles.inputGroupCollapse}>
                      <InputGroup.Addon>
                        <Icon
                          className={styles.iconCollapse}
                          icon={obj1.info_type === 1 ? 'envelope' : 'phone'}
                          size="lg"
                        />
                      </InputGroup.Addon>
                      <Input
                        className={styles.inputCollapse}
                        disabled
                        value={
                          obj1.info_type === 2
                            ? phoneNumberDisplay(obj1.value)
                            : obj1.value
                        }
                      />
                    </InputGroup>
                  </Col>
                );
              })}
            </Row>
            <Divider />
          </>
        );
      })}
    </Container>
  );
}

export default SupplierContactCollapse;
