/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import apiConnect from '../../services/apiConnect';
import moment from 'moment';

const USERNAME = process.env.REACT_APP_REFRESH_TOKEN_USER;
const PASSWORD = process.env.REACT_APP_REFRESH_TOKEN_PASS;

// eslint-disable-next-line no-empty-pattern
const TokenRefresh = ({}, ref) => {
  useEffect(() => {
    const timestampNow = moment(new Date()).unix();
    const token = localStorage.getItem('token');
    const expired = localStorage.getItem('token-expired');
    if (!token || Number(expired) <= timestampNow) {
      apiConnect
        .post('/token/', {
          username: USERNAME,
          password: PASSWORD,
        })
        .then(function (res) {
          window.localStorage.setItem('token', res.data.access);
          window.localStorage.setItem('token-expired', timestampNow + 1740);
          const lastPath = window.sessionStorage.getItem('last-path');
          if (lastPath && !/home/.test(lastPath)) {
            window.sessionStorage.removeItem('last-path');
            window.location.href = lastPath;
          }
        })
        .catch(function (res) {
          console.log(res.response);
        });
    }
  }, []);

  return <></>;
};

export default TokenRefresh;
