import React, { useState } from 'react';
// import ReactGA from 'react-ga';
import moment from 'moment';
import styles from './styles.module.css';
import { Col, Row, Icon, Container, Animation } from 'rsuite';

const PanelDetails = React.forwardRef(({ note, ...props }, ref) => (
  <p
    {...props}
    ref={ref}
    style={{
      paddingleft: '200px',
      textOverflow: 'clip',
      margin: 0,
    }}>
    {note}
  </p>
));

function NoteCard({ note, projectName }) {
  // ReactGA.pageview('/pdm');

  const [showCollapse, setShowCollapse] = useState(false);
  const { Collapse } = Animation;

  const handleToogleCollpase = () => {
    setShowCollapse(!showCollapse);
  };

  return (
    <>
      <Col md={8} lg={5} className={styles.card}>
        <Row>
          <Col md={3} lg={3}>
            <Row className={styles.iconTittle}>
              <Col className={styles.cardIcon} md={5}>
                <Icon icon="bell-o" size="lg" />
              </Col>
            </Row>
          </Col>
          <Col md={21} lg={21}>
            <Row className={styles.rowProject}>
              <Col md={12} lg={8}>
                <Row>
                  <span className={styles.cardDescription}>
                    Projeto:{' '}
                    <strong className={styles.cardDescriptionClient}>
                      {projectName}
                    </strong>
                  </span>
                </Row>
              </Col>
              <Col md={12} lg={16} hidden={!('stage' in note)}>
                <Row>
                  <Col sm={24}>
                    <span className={styles.cardDescription}>
                      Etapa:{' '}
                      <strong className={styles.cardDescriptionClient}>
                        {note?.stage}
                      </strong>
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              className={styles.rowProject}
              hidden={!('supplier_name' in note)}>
              <Col md={24} style={{ paddingLeft: 0 }}>
                <span className={styles.cardDescription}>
                  Fornecedor:{' '}
                  <strong className={styles.cardDescriptionClient}>
                    {note?.supplier_name}
                  </strong>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Container style={{ padding: '0 10px 0 30px' }}>
          <Row style={{ paddingTop: '20px' }}>
            <Col md={24} className={styles.noteDescription}>
              <p>{note.note.substr(0, 49)}</p>
              <Collapse in={showCollapse}>
                <PanelDetails note={note.note.substr(49)} />
              </Collapse>
              <span onClick={handleToogleCollpase}>
                {note.note.length > 49
                  ? showCollapse
                    ? 'Ler menos'
                    : 'Ler mais'
                  : ''}
              </span>
            </Col>
          </Row>
          <Row style={{ paddingTop: '20px' }}>
            <Col md={24} className={styles.noteDate}>
              Notificação Emitida por: {note.user_name.name}
            </Col>
            <Col md={24} className={styles.noteDate}>
              Data: {moment(note.note_date).format('DD/MM/YYYY')}
            </Col>
            <Col md={24} className={styles.noteCancelled}>
              {note.cancelled ? 'Demanda cancelada' : ''}
            </Col>
          </Row>
        </Container>
      </Col>
    </>
  );
}

export default NoteCard;
