/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './BriefingForm.scss';
// import ReactGA from 'react-ga';
// import HeaderModule from '../../../../Components/HeaderModule/HeaderModule';
import { AiOutlineEye } from 'react-icons/ai';
import { BiSearch, BiUpload, BiX } from 'react-icons/bi';
import { FaRegEdit } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';
import { RiRoadMapFill } from 'react-icons/ri';
import {
  Input,
  Label,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from 'reactstrap';
import {
  Alert,
  DatePicker,
  Notification,
  SelectPicker,
  Tooltip,
  Whisper,
} from 'rsuite';
import MoreIcon from '../../../../Assets/more-icon.png';
import FakeButton from '../../../../Components/FakeButton/FakeButton';
import Header from '../../../../Components/Header2/Header';
import Loading from '../../../../Components/Helpers/Loading';
import { useCognitoAuth } from '../../../../Contexts/AuthCognitoContext';
import { useBriefingContext } from '../../../../Contexts/BriefingContext';
import { useManagementContext } from '../../../../Contexts/ManagementContext';
import { floatMask } from '../../../../utils/masks/';
import integerMask from '../../../../utils/masks/integerMask';
import {
  classNames,
  dateStringToTimezoneDate,
  dateToLocalJSON,
  generateUniqueKey,
} from '../../../../utils/utils';

import ModalBriefingDelete from './Modals/ModalBriefingDelete/ModalBriefingDelete';
import ModalBriefingReview from './Modals/ModalBriefingReview/ModalBriefingReview';
import ModalCategory from './Modals/ModalCategory/ModalCategory';
import ModalCdDestiny from './Modals/ModalCdDestiny/ModalCdDestiny';
import ModalCopyCdDestiny from './Modals/ModalCopyCdDestiny/ModalCopyCdDestiny';
import ModalNotes from './Modals/ModalNotes/ModalNotes';
import ModalReasons from './Modals/ModalReasons/ModalReasons';
import ModalRefactObs from './Modals/ModalRefactObs/ModalRefactObs';
import ModalSaving from './Modals/ModalSaving/ModalSaving';
import ModalSuppliers from './Modals/ModalSuppliers/ModalSuppliers';
import ModalUploadDoc from './Modals/ModalUploadDoc/ModalUploadDoc';
import ModalWeight from './Modals/ModalWeight/ModalWeight';

import PopoverAddAndExit from './PopoverAddAndExit';

const BriefingForm = () => {
  const { user: _user } = useCognitoAuth();
  const emailUser = _user?.attributes?.email;
  const navigate = useNavigate();
  const { id } = useParams();

  const [projectId, setProjectId] = useState(-1);
  // 1    Novo Produto
  // 2    Nova Carta Gráfica
  // 3    Reformulação
  // 4    Extensão de Linha
  // 5    Extensão de Linha Outro Fornecedor
  // 6    Troca de Fornecedor
  const [typeBriefing, setTypeBriefing] = useState(1);
  const [briefing, setBriefing] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [parentBriefing, setParentBriefing] = useState(null);
  const [headerText, setHeaderText] = useState(
    `${id ? 'EDITAR' : 'NOVO'} PRODUTO`
  );
  const [headerSubText, setHeaderSubText] = useState('');
  const [headerBgColor, setHeaderBgColor] = useState('#3d435c');
  const [supplierBtnTxt, setSupplierBtnTxt] = useState(
    'Recomendação de Fornecedor'
  );
  const [parentBriefingId, setParentBriefingId] = useState(null);
  const [managers, setManagers] = useState([]);
  // const [brands, setBrands] = useState([]);
  // const [clientResps, setClientResps] = useState([]);
  const [selectedClientResp, setSelectedClientResp] = useState('');
  const [selectedServiceResp, setSelectedServiceResp] = useState('');
  const [selectedDtiResp, setSelectedDtiResp] = useState('');
  const [managersProject, setManagersProject] = useState([]);
  // const [serviceManagers, setServiceManagers] = useState([]);
  const [selectedServiceManager, setSelectedServiceManager] = useState();
  const [suppliersArray, setSuppliersArray] = useState([]);
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [toExcludeFiles, setToExcludeFiles] = useState([]);
  const [inputDivision, setInputDivision] = useState('');
  const [productsList, setProductsList] = useState([]); // a logica para popular esse campo esta no ModalCategory.js
  const [upperLevels, setUpperLevels] = useState(null);
  const [inputProject, setInputProject] = useState('');
  const [inputAmicciContract, setInputAmicciContract] = useState('');
  const [inputRetailerContract, setInputRetailerContract] = useState('');
  const [inputPaymentTerm, setInputPaymentTerm] = useState('');
  const [externalBriefingName, setExternalBriefingName] = useState(null);
  const [dateValue, setDateValue] = useState(new Date());
  const [managerSelect, setManagerSelect] = useState();
  // const [dtiSelect, setDtiSelect] = useState(null);
  const [selectPicker, setSelectPicker] = useState([]);
  const [nameSelectPicker, setNameSelectPicker] = useState('');
  const [briefingValidator, setBriefingValidator] = useState(null);
  const [textAreaObs, setTextAreaObs] = useState('');
  const [modalNotesFocus, setModalNotesFocus] = useState('geneal');
  const [txtHeaderNotes, setTxtHeaderNotes] = useState('Observação Geral');
  const [modalNotesId, setModalNotesId] = useState(0);
  const [modalOrientationId, setModalOrientationId] = useState();
  const [modalCdDestinyId, setModalCdDestinyId] = useState(0);
  const [modalSaving, setModalSaving] = useState(false);
  const [selectProspection, setSelectProspection] = useState();
  const [selectDistribution, setSelectDistribution] = useState();
  const [inputReason, setInputReason] = useState('');
  const [inputReasonOther, setInputReasonOther] = useState('');
  const [inputReasonChgSupp, setInputReasonChgSupp] = useState('');
  const [inputReasonOtherChgSupp, setInputReasonOtherChgSupp] = useState('');
  const [selectedWeight, setSelectedWeight] = useState(0);
  const [classBtnDocs, setClassBtnDocs] = useState('buttonUploadBlue');
  const [clientBrand, setClientBrand] = useState('');
  const [project, setProject] = useState(null);
  const [idLastFile, setIdLastFile] = useState([]);

  // Responsável por habilitar ou desabilitar botão de salvar caso não hajam mudanças
  const [changesMade, setChangesMade] = useState(false);
  const [loadState, loadDispatch] = useReducer(
    (state, action) => {
      switch (action) {
        case 'PROJECT':
          return { ...state, project: true };
        case 'BRIEFING':
          return { ...state, briefing: true };
        default:
          return state;
      }
    },
    { project: false, briefing: false }
  );

  const modalNotesRef = useRef(null);
  const modalCdDestinyRef = useRef(null);
  const modalReasonRef = useRef(null);
  const modalSuppliersRef = useRef(null);
  const modalChangeSuppRef = useRef(null);
  const modalWeightRef = useRef(null);
  const modalCategoryRef = useRef(null);
  const modalCopyCdDestinyRef = useRef(null);
  const modalRefactObsRef = useRef(null);
  const modalUploadDocRef = useRef(null);
  const modalBriefingReviewRef = useRef(null);
  const modalBriefingDeleteRef = useRef(null);

  //const tooltipPack = <Tooltip>Pacote/Caixa/Sachê/Garrafa/Lata</Tooltip>;
  //const tooltipGr = <Tooltip>Grama/Kg/Ml/L/Unit</Tooltip>;
  //const tooltipProduct = <Tooltip>Sabor/Fragrância</Tooltip>;
  const tooltipVariant = <Tooltip>Sabor/Cor/Aroma</Tooltip>;
  const tooltipQuality = <Tooltip>Qualidade</Tooltip>;
  const tooltipPrice = <Tooltip>Marca</Tooltip>;
  const tooltipDuplicateSku = <Tooltip>Copiar para todas as SKU'S?</Tooltip>;
  const tooltipStatusBriefing = <Tooltip>Visualizar</Tooltip>;

  // Para "Novo Produto", "Extensão de Linha" e "Troca de Fornecedor", desabilitar edição
  const isFieldDisabled = useMemo(() => {
    if ([1,5,6].includes(typeBriefing)) {
      return true;
    }
    return false;
  }, [typeBriefing]) 

  const inputData = {
    product: '',
    variant: '',
    package: '',
    weight: '',
    quality_target: '',
    price_target: '',
    sale_price_goal: '',
    margin_goal: '',
    volume: '',
    // amicci_contract: '',
    // retailer_contract: '',
    // payment_term: null,
    notes: '',
    cd_states: [],
    current_brand: '',
    package_stock_volume: '',
    package_stock_date: null,
    refact_obs: '',
  };

  const verifyAndRemoveModalClass = () => {
    // por algum motivo, não esta removendo essa classe
    // ao salvar, então é preciso remover manualmente
    const body = document.querySelector('body');
    if (body.classList.contains('modal-open')) {
      body.classList.remove('modal-open');
    }
  };

  const navigateBack = () => {
    if (fromManagement) {
      navigate(`/pdm/management/${projectId}`);
    } else if (briefing && briefing.visualization_created) {
      navigate(`/pdm/management/${briefing.project_id}`);
    } else {
      navigate('/pdm/briefing');
    }
  };

  const getNewInputData = () => {
    return {
      _id: generateUniqueKey(),
      ...inputData,
    };
  };

  const [inputList, setInputList] = useState(id ? [] : [getNewInputData()]);

  const fieldsList = {
    retailer: 'Clientes',
    retailer_id: 'Clientes',
    retailer_manager_user: 'Gestor Cliente',
    retailer_manager_user_id: 'Gestor Cliente',
    dti_user: 'Responsável DTI',
    dti_user_id: 'Responsável DTI',
    distribution_type: 'Tipo de distribuição',
    division: 'Categoria',
    prospection_type: 'Prospecção',
    name: 'Projeto',
    release_date: 'Previsão de lançamento',
    current_user: 'Usuário atual',
    suppliers: 'Recomendação de Fornecedor/Insira o Fornecedor Atual',
    parent_briefing_id: 'Id do projeto base',
    external_briefing_name: 'Nome derivado',
    derived_from_name: 'Nome derivado',
    new_package_print_reason_id: 'Motivo da nova carta gráfica',
    new_package_print_reason_note: 'Motivo da nova carta gráfica "Outro"',
    refact_reason_id: 'Motivo da reformulação',
    refact_reason_note: 'Motivo da reformulação "Outro"',
    extension_reason_id: 'Motivo da extensão de linha',
    extension_reason_note: 'Motivo da extensão de linha "Outro"',
    new_supplier_reason_id: 'Motivo da troca de fornecedor',
    new_supplier_reason_note: 'Motivo da troca de fornecedor "Outro"',
    product: 'Produtos',
    variant: 'Variante',
    package: 'Embalagem',
    weight: 'Gramatura',
    current_brand: 'Marca Atual',
    quality_target: 'Target Marca',
    price_target: 'Target Preço',
    package_stock_volume: 'Quantidade em Estoque',
    package_stock_date: 'Data do Estoque',
    sale_price_goal: 'PV Objetivo',
    margin_goal: 'Margem Objetiva (%)',
    volume: 'Volume Unitário (mensal)',
    amicci_contract: 'Contrato Amicci (%)',
    retailer_contract: 'Contrato Varejista (%)',
    payment_term: 'Prazo de Pagamento',
    refact_obs: 'Orientação Reformulação',
    notes: 'Observação/Observação Geral',
    cd_states: 'CD Destino',
  };

  const reasonLists = {
    2: [
      // Nova Carta Gráfica
      {
        id: 6,
        label: 'Adequação de Legislação - Rotulagem de produtos',
        order: 1,
      },
      { id: 4, label: 'Ajuste de Texto Legal/Claim', order: 2 },
      { id: 1, label: 'Alteração de Marca', order: 3 },
      { id: 3, label: 'Atualização da Carta Gráfica', order: 4 },
      { id: 8, label: 'Atualização do Layout de Embalagem', order: 5 },
      { id: 5, label: 'Outro', order: 6 },
    ],
    3: [
      // Reformulação
      { id: 1, label: 'Custo', order: 0 },
      { id: 2, label: 'Qualidade', order: 1 },
      { id: 3, label: 'Adequação de Legislação', order: 2 },
      { id: 4, label: 'Outro', order: 3 },
    ],
    4: [
      // Ext. de Linha
      { id: 1, label: 'Aumento de Sortimento', order: 0 },
      { id: 2, label: 'Inovação', order: 1 },
      { id: 3, label: 'Oportunidade de Mercado', order: 2 },
      { id: 4, label: 'Outro', order: 3 },
    ],
    5: [
      // Extensão de Linha Outro Fornecedor
      { id: 1, label: 'Aumento de Sortimento', order: 0 },
      { id: 2, label: 'Inovação', order: 1 },
      { id: 3, label: 'Oportunidade de Mercado', order: 2 },
      { id: 4, label: 'Outro', order: 3 },
    ],
    6: [
      // Troca de Fornecedor
      { id: 1, label: 'Custo', order: 0 },
      { id: 2, label: 'Qualidade', order: 1 },
      { id: 3, label: 'Descontinuidade do Fornecedor', order: 2 },
      { id: 4, label: 'Relação Comercial', order: 3 },
      { id: 5, label: 'Outro', order: 4 },
    ],
  };

  const {
    loading,
    prospectionType,
    distributionType,
    user: bUser,
    dtiUsers,
    retailers,
    states,
    packages,
    serviceManagers,
    reviewBriefing,
    getProducts,
    products,
    ...briefingContext
  } = useBriefingContext();

  const { loading: loadingMng, getProject: getManagementProject } =
    useManagementContext();

  useEffect(() => {
    setDateValue(new Date());
    // cargas iniciais
    briefingContext.getProspectionType();
    briefingContext.getDistributionType();
    briefingContext.getUser(emailUser);
    briefingContext.getDtiUsers();
    // briefingContext.getRetailers();
    briefingContext.getStates();
    if (packages.length === 0) {
      briefingContext.getPackages();
    }

    // return () => {
    //   // ao sair da pagina volta o cabecalho para as cores normais
    //   [...document.getElementsByClassName('header-pdm')].forEach(el => {
    //     el.style['background-color'] = '#1a213f';
    //   });
    //   [...document.getElementsByClassName('headerModule')].forEach(el => {
    //     el.style['background-color'] = '#1a213f';
    //   });
    //   [...document.getElementsByClassName('headerModule-before')].forEach(
    //     el => {
    //       el.style['background'] =
    //         'linear-gradient(to bottom, #1a213f, #65697d)';
    //     }
    //   );
    // };
  }, []);

  const loadUrlParams = () => {
    // verifica se passou variaveis pela url
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlProjectId = urlParams.get('projectId');
    const urlNewType = urlParams.get('newType');
    const urlRetailer = urlParams.get('retailer');
    const urlProject = urlParams.get('project');
    const urlName = urlParams.get('name');
    const urlDate = urlParams.get('date');
    setDateValue(new Date());
    if (urlProjectId) {
      setProjectId(Number(urlProjectId));
    } else {
      loadDispatch('PROJECT');
    }
    if (urlNewType) {
      setTypeBriefing(Number(urlNewType));
    }
    if (urlRetailer) {
      setSelectPicker(Number(urlRetailer));
    }
    if (urlProject && !urlProjectId) {
      setParentBriefingId(Number(urlProject));
      briefingContext.getBriefing(urlProject).then(resp => {
        // console.log('urlProject', resp);
        if (resp?.data) {
          // setManagerSelect(resp.data.retailer_manager_user);
          // setDtiSelect(resp.data.dti_user);
          setParentBriefing(resp.data);
          if (resp.data.name && !urlName) {
            setExternalBriefingName(resp.data.name);
          }
        }
      });
    }
    if (urlName) {
      setExternalBriefingName(urlName);
    }
    if (urlDate) {
      setDateValue(new Date(urlDate));
    }
  };

  const fromManagement = projectId !== -1;

  const loadManagementProject = async () => {
    console.log('LOADING PROJECT');
    const resp = await getManagementProject(projectId);
    if (resp?.success && resp?.data) {
      console.log('LOADED PROJECT');
      console.log('project', resp?.data);
      const {
        name,
        division,
        client_name,
        client_id,
        client_brand,
        manager_user_id,
        client_responsible,
        service_responsible,
        development_responsible,
        dti_responsible,
        // dti_responsible_id,
        project_classification_id,
      } = resp?.data;
      setInputProject(name); // PROJETO
      setInputDivision(division); // CATEGORIA
      setManagerSelect(manager_user_id); // GESTOR
      setSelectedClientResp(client_responsible); // RESPONSAVEL CLIENTE
      setSelectedServiceResp(service_responsible); // RESPONSAVEL ATENDIMENTO
      setSelectedServiceManager(development_responsible); // RESPONSAVEL DESENVOLVIMENTO
      setSelectedDtiResp(dti_responsible); // RESPONSAVEL DTI
      // setDtiSelect(dti_responsible_id); // RESPONSAVEL DTI (ID)
      setNameSelectPicker(client_name); // CLIENTE
      setProject(resp?.data);
      setUpperLevels(project_classification_id);

      const brandsRes = await briefingContext.getListBrands(client_id);
      console.log('LOADED BRANDS', brandsRes?.success);
      if (brandsRes?.success) {
        // setBrands(brandsRes?.data?.brands);
        setClientBrand(client_brand);
      }
      const managersRes = await briefingContext.getManagerProject(
        client_id,
        true
      );
      console.log('LOADED MANAGERS', managersRes?.success);
      if (managersRes?.success) {
        setManagersProject(managersRes?.data);
      }
      loadDispatch('PROJECT');
    }
  };

  useEffect(() => {
    if (upperLevels) {
      getProducts(upperLevels);
    }
  }, [upperLevels]);

  useEffect(() => {
    console.log('prod', products);
    if (products.length > 0) {
      setProductsList(products.map(p => p.name));
    }
  }, [products]);

  // Load project if coming from management
  useEffect(() => {
    if (projectId !== -1) {
      loadManagementProject();
    }
  }, [projectId]);

  useEffect(() => {
    loadUrlParams();
  }, [window.location.search]);

  useEffect(() => {
    if (
      loading.dtiApproval ||
      loading.managerApproval ||
      loading.statusBriefing ||
      loading.removeFile ||
      loading.deleteBriefing ||
      loading.submitToProspect ||
      loading.submitEmail ||
      loading.submitFinishWithoutProspection ||
      loading.submitStartProspection ||
      loading.saveBriefing ||
      loading.uploadFiles ||
      loadingMng.comercialFiltersByProject
    ) {
      setModalSaving(true);
    } else {
      setModalSaving(false);
    }
  }, [loading, loadingMng.comercialFiltersByProject]);

  useEffect(() => {
    if (id) {
      // ReactGA.pageview(`/pdm/briefing/${id}`);
      // carrega dados pare editar
      briefingContext.getBriefing(id).then(resp => {
        if (resp?.success) {
          setBriefing(resp.data);
          setInputReason(
            String(
              resp.data.new_package_print_reason_id ||
                resp.data.refact_reason_id ||
                resp.data.extension_reason_id ||
                resp.data.new_supplier_reason_id
            )
          );
          setInputReasonOther(
            resp.data.new_package_print_reason_note ||
              resp.data.refact_reason_note ||
              resp.data.extension_reason_note ||
              resp.data.new_supplier_reason_note
          );
          if (resp.data.project_type === 5) {
            setInputReasonChgSupp(String(resp.data.new_supplier_reason_id));
            setInputReasonOtherChgSupp(resp.data.new_supplier_reason_note);
          }
          if (resp.data.project_type !== 1) {
            // setInputRefactReason(String(resp.data.refact_reason_id));
            setExternalBriefingName(
              resp.data.derived_from_name || resp.data.name
            );
          }
          setParentBriefing(resp.data);
          console.log('LOADED BRIEFING');
          loadDispatch('BRIEFING');
        }
      });
    } else {
      // ReactGA.pageview('/pdm/novo-briefing');
      loadDispatch('BRIEFING');
    }
  }, [id]);

  useEffect(() => {
    switch (typeBriefing) {
      case 2:
        setHeaderText('NOVA EMBALAGEM');
        setSupplierBtnTxt('Insira o Fornecedor Atual');
        setHeaderBgColor('#3789d9');
        break;
      case 3:
        setHeaderText('REFORMULAÇÃO');
        setSupplierBtnTxt('Insira o Fornecedor Atual');
        setHeaderBgColor('#30468a');
        break;
      case 4:
        setHeaderText('EXTENSÃO DE LINHA');
        setSupplierBtnTxt('Insira o Fornecedor Atual');
        setHeaderBgColor('#84cdd6');
        break;
      case 5:
        setHeaderText('EXTENSÃO DE LINHA');
        setHeaderSubText('Outro Fornecedor');
        setSupplierBtnTxt('Recomendação de Fornecedor');
        setHeaderBgColor('#84cdd6');
        break;
      case 6:
        setHeaderText('TROCA DE FORNECEDOR');
        setSupplierBtnTxt('Recomendação de Fornecedor');
        setHeaderBgColor('#64687c');
        break;
      default:
        setHeaderText(`${id ? 'EDITAR' : 'NOVO'} PRODUTO`);
        setSupplierBtnTxt('Recomendação de Fornecedor');
        setHeaderBgColor('#3d435c');
        break;
    }
  }, [typeBriefing]);

  // Responsável pela lógica de desabilitar botão de salvar
  useEffect(() => {
    if (loadState.project && loadState.briefing) {
      setChangesMade(false);
    }
  }, [loadState]);

  useEffect(() => {
    if (!changesMade) {
      setChangesMade(true);
    }
  }, [
    selectPicker,
    inputDivision,
    inputProject,
    managerSelect,
    selectProspection,
    // dtiSelect,
    selectDistribution,
    inputAmicciContract,
    inputRetailerContract,
    inputPaymentTerm,
    inputList,
    files,
    newFiles,
    suppliersArray,
    textAreaObs,
  ]);

  // useEffect(() => {
  //   if (typeBriefing > 1) {
  //     switch (typeBriefing) {
  //       case 2:
  //         [...document.getElementsByClassName('header-pdm')].forEach(el => {
  //           el.style['background-color'] = '#338ce0';
  //         });
  //         [...document.getElementsByClassName('headerModule')].forEach(el => {
  //           el.style['background-color'] = '#338ce0';
  //         });
  //         [...document.getElementsByClassName('headerModule-before')].forEach(
  //           el => {
  //             el.style['background'] =
  //               'linear-gradient(to bottom, #338ce0, #666a7f)';
  //           }
  //         );
  //         break;
  //       case 3:
  //         [...document.getElementsByClassName('header-pdm')].forEach(el => {
  //           el.style['background-color'] = '#28418c';
  //         });
  //         [...document.getElementsByClassName('headerModule')].forEach(el => {
  //           el.style['background-color'] = '#28418c';
  //         });
  //         [...document.getElementsByClassName('headerModule-before')].forEach(
  //           el => {
  //             el.style['background'] =
  //               'linear-gradient(to bottom, #28418c, #666a7f)';
  //           }
  //         );
  //         break;
  //       case 4:
  //       case 5:
  //         [...document.getElementsByClassName('header-pdm')].forEach(el => {
  //           el.style['background-color'] = '#5f7084';
  //         });
  //         [...document.getElementsByClassName('headerModule')].forEach(el => {
  //           el.style['background-color'] = '#5f7084';
  //         });
  //         [...document.getElementsByClassName('headerModule-before')].forEach(
  //           el => {
  //             el.style['background'] =
  //               'linear-gradient(to bottom, #5f7084, #2bcadb)';
  //           }
  //         );
  //         break;
  //       case 6:
  //         [...document.getElementsByClassName('header-pdm')].forEach(el => {
  //           el.style['background-color'] = '#64687c';
  //         });
  //         [...document.getElementsByClassName('headerModule')].forEach(el => {
  //           el.style['background-color'] = '#64687c';
  //         });
  //         [...document.getElementsByClassName('headerModule-before')].forEach(
  //           el => {
  //             el.style['background'] =
  //               'linear-gradient(to bottom, #64687c, #aeb1be)';
  //           }
  //         );
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }, [typeBriefing, parentBriefing]);

  const listProspectionType = useMemo(() => {
    return prospectionType
      .filter(pt => pt.name !== null)
      .map(pt => ({ id: pt.id, name: pt.name }));
  }, [prospectionType]);

  const listDistributionType = useMemo(() => {
    return distributionType
      .filter(dt => dt.name !== null)
      .map(dt => ({ id: dt.id, name: dt.name }));
  }, [distributionType]);

  useEffect(() => {
    if (briefing) {
      if (briefing.visualization_created && briefing.project_id) {
        setProjectId(briefing.project_id);
      }
      if (briefing.project_id) {
        // faz uma consulta para trazer os fornecedores que
        // estão associados a esse briefing
        briefingContext
          .getSuppliersByProject(briefing.project_id)
          .then(resp => {
            const commercial_filters = resp?.data;
            if (commercial_filters?.length > 0) {
              const suppliers = commercial_filters?.map(cf => ({
                supplier: cf.supplier_id,
              }));
              briefingContext.getSuppliersNames(suppliers).then(resp => {
                if (resp?.success) {
                  setSuppliersArray(resp.data);
                }
              });
            }
          });
      }
      if (briefing.items) {
        setInputList(
          briefing.items.map(i => ({
            ...i,
            package_stock_date: i.package_stock_date
              ? dateStringToTimezoneDate(i.package_stock_date)
              : i.package_stock_date,
          }))
        );
      }
      if (briefing.amicci_contract)
        setInputAmicciContract(floatMask(briefing.amicci_contract));
      if (briefing.retailer_contract)
        setInputRetailerContract(floatMask(briefing.retailer_contract));
      if (briefing.payment_term !== null && briefing.payment_term !== '')
        setInputPaymentTerm(integerMask(briefing.payment_term));
      if (briefing.files) setFiles(briefing.files);
      if (briefing.division) setInputDivision(briefing.division);
      if (briefing.name) setInputProject(briefing.name);
      if (briefing.retailer_manager_user)
        setManagerSelect(briefing.retailer_manager_user);
      // if (briefing.dti_user) setDtiSelect(briefing.dti_user);
      // if (briefing.release_date) {
      //   setDateValue(dateStringToTimezoneDate(briefing.release_date));
      // }
      if (briefing.retailer) setSelectPicker(briefing.retailer);
      if (briefing.retailer) setNameSelectPicker(briefing.retailer_name);
      if (briefing.notes) setTextAreaObs(briefing.notes);
      if (briefing.prospection_type)
        setSelectProspection(briefing.prospection_type);
      if (briefing.distribution_type)
        setSelectDistribution(briefing.distribution_type);
      if (briefing.retailer)
        briefingContext.getManagers(briefing.retailer).then(resp => {
          if (resp?.success) {
            setManagers(resp.data);
          }
        });

      const btnCancel = document.getElementById('btn-cancel');
      const btnStandBy = document.getElementById('btn-standBy');
      const btnSendToSourcing = document.getElementById('btnSendToSourcing');
      const btnSave = document.getElementById('save-button');
      const btnDelete = document.getElementById('btn-delete');
      const btnInitial = document.getElementById('btn-initial');

      if (btnInitial !== null) {
        if (briefing.status_code === 'A') {
          btnInitial.disabled = true;
          btnDelete.disabled = true;
        } else if (briefing.status_code === 'E') {
          btnStandBy.disabled = true;
          if (btnSendToSourcing) btnSendToSourcing.disabled = true;
        } else if (
          briefing.status_code === 'F' &&
          briefing.stage_code !== 'B'
        ) {
          btnInitial.disabled = true;
          btnCancel.disabled = true;
          btnStandBy.disabled = true;
          // btnSave.disabled = true;
          btnDelete.disabled = true;
          if (btnSendToSourcing) btnSendToSourcing.disabled = true;
        } else if (briefing.status_code === 'C') {
          btnInitial.disabled = true;
          btnCancel.disabled = true;
          btnStandBy.disabled = true;
          btnSave.disabled = true;
          if (btnSendToSourcing) btnSendToSourcing.disabled = true;
        } else if (briefing.status_code === 'I') {
          btnInitial.disabled = true;
        }
        if (briefing.project_type !== 1) {
          btnDelete.disabled = false;
        }
      }
    }
  }, [briefing]);

  useEffect(() => {
    if (!fromManagement && selectPicker && !Array.isArray(selectPicker)) {
      briefingContext.getManagers(selectPicker).then(resp => {
        if (resp?.success) {
          setManagers(resp.data);
        }
      });
      briefingContext.getListBrands(selectPicker).then(resp => {
        if (resp?.success) {
          // setBrands(resp?.data?.brands);
        }
      });
      briefingContext.getManagerProject(selectPicker).then(resp => {
        if (resp?.success) {
          setManagersProject(resp?.data);
        }
      });
      briefingContext.getClientResponsibles(selectPicker).then(resp => {
        if (resp?.success) {
          // setClientResps(resp?.data);
        }
      });
      briefingContext.getServiceManagers(selectPicker).then(resp => {
        if (resp?.success) {
          // setServiceManagers(resp?.data);
        }
      });
    }
  }, [selectPicker]);

  const productsOptions = useMemo(() => {
    return productsList.map(p => ({
      label: p,
      value: p,
    }));
  }, [productsList]);

  useEffect(() => {
    // Only set products empty if there is no project and id
    if (projectId === -1 && !id) {
      setInputList(
        inputList.map(ip => ({
          ...ip,
          product: '',
        }))
      );
    }
  }, [inputDivision, projectId]);

  useEffect(() => {
    if (bUser && managers.length > 0) {
      if (
        bUser.department_name === 'VAREJISTA' ||
        bUser.department_name === 'NOVOS CLIENTES'
      ) {
        if (
          managers.find(mail => mail.user_email === emailUser) === undefined
        ) {
          navigate(-1);
          Alert.error('Você não tem acesso a esse Briefing.');
        }
      }
      setBriefingValidator(
        managers.find(mail => mail.user_email === emailUser)
          ?.briefing_validator || null
      );
      // getRetailersUser();
    }
  }, [managers, bUser]);

  useEffect(() => {
    // getRetailersUser();
    if (bUser && retailers.length === 0) {
      briefingContext.getRetailers();
    }
  }, [retailers, bUser]);

  const handleAddClick = () => {
    setInputList([...inputList, getNewInputData()]);
  };

  /*const changeStatusFinal = e => {
    questionChangeStatus(e, handleFinalBriefing);
  };*/

  const changeApproval = e => {
    questionChangeStatus(e, handleManagerApproval);
  };

  const changeApprovalDti = e => {
    questionChangeStatus(e, handleDtiApproval);
  };

  const handleDtiApproval = async () => {
    try {
      const resp = await briefingContext.setDtiApproval(id, emailUser);
      if (resp?.success) {
        Alert.success('Briefing Aprovado com Sucesso!');
        navigateBack();
      } else {
        switch (resp.code) {
          case 400:
            Alert.error('Briefing não disponível para validação técnica');
            break;
          case 401:
            Alert.error('Você não tem autorização para aprovar esse briefing.');
            break;
          default:
            Alert.error('Erro ao aprovar o Briefing');
            break;
        }
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      Notification.close();
    }
  };

  const handleManagerApproval = async () => {
    try {
      const resp = await briefingContext.setManagerApproval(id, emailUser);
      if (resp?.success) {
        Alert.success('Briefing Aprovado com Sucesso!');
        navigateBack();
      } else {
        switch (resp.code) {
          case 204:
            Alert.error('Informe esse erro ao suporte: ' + resp.message);
            break;
          case 400:
            Alert.error('Briefing não disponível para validação do Gestor');
            break;
          case 401:
            Alert.error('Você não tem autorização para aprovar esse briefing.');
            break;
          default:
            Alert.error('Erro ao aprovar o Briefing');
            break;
        }
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      Notification.close();
    }
  };

  /*const handleFinalBriefing = async () => {
    try {
      const resp = await briefingContext.setStatusBriefing(id, emailUser, 'F');
      if (resp?.success) {
        Alert.success('Status Alterado com Sucesso!');
        navigate('/pdm/briefing/');
      } else {
        Alert.error('Erro ao alterar o status.');
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      Notification.close();
    }
  };*/

  /*const changeStatusCancel = async () => {
    try {
      const resp = await briefingContext.setStatusBriefing(id, emailUser, 'C');
      if (resp?.success) {
        Alert.success('Status Alterado com Sucesso!');
        navigate('/pdm/briefing/');
      } else {
        Alert.error('Erro ao alterar o status.');
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      Notification.close();
    }
  };*/

  /*const changeStatusInitial = async () => {
    try {
      const resp = await briefingContext.setStatusBriefing(id, emailUser, 'I');
      if (resp?.success) {
        Alert.success('Status Alterado com Sucesso!');
        navigate('/pdm/briefing/');
      } else {
        Alert.error('Erro ao alterar o status.');
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      Notification.close();
    }
  };*/

  /*const changeStatusStandBy = async () => {
    try {
      const resp = await briefingContext.setStatusBriefing(id, emailUser, 'E');
      if (resp?.success) {
        Alert.success('Status Alterado com Sucesso!');
        navigate('/pdm/briefing/');
      } else {
        Alert.error('Erro ao alterar o status.');
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      Notification.close();
    }
  };*/

  /*const QuestionChangeStatusCancel = e => {
    questionChangeStatus(e, changeStatusCancel);
  };*/

  /*const QuestionChangeStatusInitial = e => {
    questionChangeStatus(e, changeStatusInitial);
  };*/

  /*const QuestionChangeStatusStandBy = e => {
    questionChangeStatus(e, changeStatusStandBy);
  };*/

  const questionChangeStatus = (e, fn) => {
    e.preventDefault();
    Notification.warning({
      title: 'Você tem certeza disso?',
      placement: 'topStart',
      description: (
        <>
          <div>
            <p>Você tem certeza?.</p>
          </div>
          <div className="flex-start m-t-2">
            <button className="btn-delete" onClick={() => fn()}>
              Sim
            </button>
            <button
              className="btn-one"
              onClick={() => {
                Notification.close();
              }}>
              Não
            </button>
          </div>
        </>
      ),
    });
  };

  const duplicateSkus = () => {
    const { cd_states } = inputList[modalCdDestinyId];
    const list = [...inputList];
    for (let index in list) {
      list[index]['cd_states'] = [...cd_states];
    }
    setInputList(list);
    setModalCdDestinyId(null);
    // closeConfirmSkus();
  };

  // const handleChangeFile = e => {
  //   const fileTypes = [
  //     'application/vnd.ms-excel',
  //     'application/msword',
  //     'image/jpeg',
  //     'application/pdf',
  //     'image/png',
  //     'image/jpg',
  //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //   ];
  //   let okFiles = true;
  //   [...e.target.files].forEach(file => {
  //     if (fileTypes.includes(file.type)) {
  //       if (file.size >= 100000000) {
  //         okFiles = false;
  //         Alert.error('Seu arquivo precisa ter menos de 100MB');
  //         e.target.value = null;
  //         setNewFiles(null);
  //       }
  //     } else {
  //       okFiles = false;
  //       Alert.error('Arquivo inválido!');
  //       e.target.value = null;
  //       setNewFiles(null);
  //     }
  //   });
  //   if (okFiles) setNewFiles(e.target.files);
  // };

  const handleDeleteFile = file => {
    Notification.warning({
      title: 'Você tem certeza disso?',
      placement: 'topStart',
      description: (
        <>
          <div>
            <p>Você perderá o arquivo!</p>
          </div>
          <div className="flex-start m-t-2">
            <button
              className="btn-delete"
              onClick={() => {
                moveFileToExcludeList(file);
                Notification.close();
              }}>
              Sim
            </button>
            <button
              className="btn-one"
              onClick={() => {
                Notification.close();
              }}>
              Não
            </button>
          </div>
        </>
      ),
    });
  };

  const moveFileToExcludeList = file => {
    setToExcludeFiles(lf => [...lf, file]);
    const filtered = files.filter(fl => fl.name !== file.name);
    setFiles(filtered);
  };

  const removeNewFile = file => {
    const filtered = newFiles.filter(nf => nf.name !== file.name);
    setNewFiles(filtered);
    updateClassBtnDocs(filtered);
  };

  // const convert = str => {
  //   return new Date(str).toLocaleDateString('pt-Br');
  // };

  const handleDeleteBriefing = async () => {
    try {
      const resp = await briefingContext.deleteBriefingFull(id);
      if (resp?.success) {
        Alert.success('Briefing excluído com sucesso!');
        navigate(-1);
      } else {
        Alert.error('Erro ao excluir o briefing.');
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const closePage = e => {
    e.preventDefault();
    Notification.warning({
      title: 'Você tem certeza disso?',
      description: (
        <>
          <div>
            <p>Você perderá todas as alterações feitas.</p>
          </div>
          <div className="flex-start m-t-2">
            <button
              className="btn-delete"
              onClick={() => {
                Notification.close();
                navigateBack();
              }}>
              Sim
            </button>
            <button
              className="btn-one"
              onClick={() => {
                Notification.close();
              }}>
              Não
            </button>
          </div>
        </>
      ),
    });
  };

  const handleDuplicateLine = index => {
    const { id, ...line } = { ...inputList[index], _id: generateUniqueKey() };
    const newList = [...inputList];
    newList.splice(index + 1, 0, line);
    setInputList(newList);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // filtra pelo departamento
  const filteredDtiUsers = useMemo(
    () => dtiUsers.filter(dti => dti.department === 4),
    [dtiUsers]
  );

  const resultDti = useMemo(
    () => filteredDtiUsers.some(dti => dti.email === emailUser),
    [filteredDtiUsers]
  );

  const devResponsible = useMemo(
    () => serviceManagers?.some(m => m?.user_email === emailUser),
    [serviceManagers]
  );

  // const handleDtiUsers = e => {
  //   setDtiSelect(e.target.value);
  // };

  const handleInputChange = (e, index, mask = null) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = mask ? mask(value) : value;
    setInputList(list);
  };

  const handleInputDateChange = (field, value, index) => {
    const list = [...inputList];
    list[index][field] = value;
    setInputList(list);
  };

  const arrayRetailers = useMemo(() => {
    return retailers.map(ret => ({
      ...ret,
      value: ret.id,
      label: ret.name,
    }));
  }, [retailers]);

  const arrayPackages = useMemo(() => {
    return packages.map(p => ({
      ...p,
      value: p.name,
      label: p.name,
    }));
  }, [packages]);

  const handleSubmitToProspect = async () => {
    try {
      const resp = await briefingContext.submitToProspect(id, emailUser);
      if (resp?.success) {
        Alert.success('Enviado para Prospecção com sucesso!');
        navigateBack();
      } else {
        Alert.error(
          'Briefing não está disponível para ser enviado para  Prospecção.'
        );
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      Notification.close();
    }
  };

  const handleSubmitEmail = async () => {
    try {
      const resp = await briefingContext.submitEmail(id, emailUser);
      if (resp?.success) {
        Alert.success('E-mail enviado com Sucesso!');
        navigateBack();
      } else {
        Alert.error(
          'Briefing não está disponível para ser enviado ao Sourcing.'
        );
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      Notification.close();
    }
  };

  const handleFinishWithoutProspection = async () => {
    try {
      const resp = await briefingContext.submitFinishWithoutProspection(
        id,
        emailUser
      );
      if (resp?.success) {
        Alert.success('Finalizado com Sucesso!');
        navigateBack();
      } else {
        Alert.error('Carta Gráfica não esta disponível para ser finalizada.');
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      Notification.close();
    }
  };

  const handleSubmitStartProspection = async () => {
    try {
      const resp = await briefingContext.submitStartProspection(id, emailUser);
      if (resp?.success) {
        Alert.success('E-mail enviado com Sucesso!');
        navigateBack();
      } else {
        Alert.error(
          'Briefing não está disponível para ser enviado ao Sourcing.'
        );
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      Notification.close();
    }
  };

  const validateBlur = e => {
    e.preventDefault();
    const style = e.target;
    const result = e.target.value;
    const attr = e.target.getAttribute('data-name');
    if (result === '') {
      Alert.error(`${attr}`, 5000);
      style.classList.add('errorBlur');
    } else {
      style.classList.remove('errorBlur');
    }
  };

  const validateBlurSelPicker = e => {
    const el = document.getElementsByClassName(e.class)[0];
    if (!e.value) {
      el.style.borderColor = '#f5000069';
      Alert.error(`${e.msg}`, 5000);
    } else {
      el.style.borderColor = '';
    }
  };

  const validateButtonOnCloseModal = e => {
    const { classEl, value, msg } = e;
    const el = document.querySelector(classEl);
    if (el) {
      if (!value) {
        el.style.borderColor = '#f5000069';
        Alert.error(`${msg}`, 5000);
      } else {
        el.style.borderColor = '';
      }
    }
  };

  const validateDocs = (afterRemoved = null) => {
    const _newFiles = afterRemoved || newFiles;
    if (files.length === 0 && _newFiles.length === 0) {
      return false;
    } else if (files.length === 0 && _newFiles.length > 0) {
      let hasDoc = false;
      _newFiles.forEach(f => {
        if (f._type === 1 || f._type === 2) {
          hasDoc = true;
        }
      });
      if (hasDoc) {
        return true;
      } else {
        return false;
      }
    } else if (files.length > 0) {
      return null;
    }
  };

  const updateClassBtnDocs = (afterRemoved = null) => {
    if (typeBriefing === 1) {
      const validated = validateDocs(afterRemoved);
      if (validated) {
        setClassBtnDocs('buttonUploadGreen');
      } else if (validated === false) {
        setClassBtnDocs('buttonUploadRed');
        Alert.error(
          'É OBRIGATÓRIO ANEXAR UMA PLANILHA OU APRESENTAÇÃO PARA O CLIENTE.'
        );
      } else if (validated === null) {
        setClassBtnDocs('buttonUploadBlue');
      }
    }
  };

  // retorna true para vazio
  const verifyEmptyField = field => {
    const arrFieldValue = [];
    for (let ipt of inputList) {
      arrFieldValue.push(ipt[field]);
    }
    return (
      arrFieldValue.includes(undefined) ||
      arrFieldValue.includes(null) ||
      arrFieldValue.includes('')
    );
  };

  const compareTwoBriefingsWithoutSuppliers = (brf, newBrf) => {
    // compara se o briefing a ser enviado para alterar é exatamente igual ao briefing que foi carregado
    let equal = true;
    Object.keys(newBrf).forEach(key => {
      if (brf[key] && key !== 'release_date') {
        // ignora release_date
        if (
          typeof brf[key] !== 'object' &&
          hasField(key) && // testa se o campo é obrigatorio
          String(newBrf[key]) !== String(brf[key]) // testa de os valores sao iguais
        ) {
          equal = false;
          console.log('briefing prop dif', key, newBrf[key], brf[key]);
        } else if (typeof brf[key] === 'object' && key !== 'suppliers') {
          // ignora suppliers
          if (brf[key].length !== newBrf[key].length) {
            // se tem qtdades diferentes
            equal = false;
            console.log(
              `briefing qtdade de ${key} é dif`,
              brf[key],
              newBrf[key]
            );
          } else {
            // se tem qtdades iguais, compara cada valor nas mesmas linhas
            for (let i in brf[key]) {
              for (let ikey of Object.keys(brf[key][i])) {
                if (
                  hasField(ikey) &&
                  String(brf[key][i][ikey]) !== String(newBrf[key][i][ikey])
                ) {
                  equal = false;
                  console.log(
                    'briefing dif',
                    key,
                    i,
                    ikey,
                    brf[key][i][ikey],
                    newBrf[key][i][ikey]
                  );
                }
              }
            }
          }
        }
      }
    });
    // console.log('é igual?', equal);
    return equal;
  };

  const compareTwoBriefingsSuppliers = (brf, newBrf) => {
    console.log('compareTwoBriefingsSuppliers', brf, newBrf);
    let equal = true;
    // se a qtdade se fornecedores for diferente
    if (brf.suppliers.length !== newBrf.suppliers.length) {
      equal = false;
    } else {
      for (let i in newBrf.suppliers) {
        const suppId = newBrf.suppliers[i].supplier || newBrf.suppliers[i].id;
        console.log('suppId', suppId);
        const hasSupp = brf.suppliers.find(sup => sup.supplier === suppId);
        console.log('hasSupp', hasSupp);
        if (!hasSupp) {
          equal = false;
        }
      }
    }
    return equal;
  };

  const handleSubmitBriefing = async e => {
    e.preventDefault();
    const dateStringStart = dateToLocalJSON(dateValue).substring(0, 10);

    // YYYY-MM-DD-HH-MM-SS-
    const dateToFile = dateToLocalJSON()
      .replace(/[T:.]/g, '-')
      .substring(0, 20);

    const cdStatesArr = [];

    for (let ipt of inputList) {
      cdStatesArr.push(ipt.cd_states);
    }

    let isDestinyEmpty = false;

    cdStatesArr.forEach(x => {
      if (x.length === 0) {
        isDestinyEmpty = true;
      }
    });

    let selectedReason = '';
    // let selectedReasonChgSupp = '';

    if (typeBriefing !== 1) {
      selectedReason = reasonLists[typeBriefing].find(
        k => String(k.id) === inputReason
      );
      if (typeBriefing === 5) {
        // selectedReasonChgSupp = reasonLists[typeBriefing].find(
        //   k => String(k.id) === inputReasonChgSupp
        // );
      }
    }

    if (!selectProspection && [1, 5, 6].includes(typeBriefing)) {
      Alert.error(
        'CAMPO TIPO DE PROSPECÇÃO OBRIGATÓRIO - SELECIONE O TIPO DE PROSPECÇÃO.'
      );
    } else if (!inputDivision) {
      Alert.error(
        'CAMPO CATEGORIA OBRIGATÓRIO - SELECIONE A CATEGORIA DO PROJETO'
      );
    } else if (!inputProject) {
      Alert.error('CAMPO PROJETO OBRIGATÓRIO - ADICIONAR NOME DO PROJETO.');
    } else if (
      hasField('amicci_contract') &&
      (inputAmicciContract === null || inputAmicciContract.trim() === '')
    ) {
      Alert.error(
        "CAMPO CONTRATO AMICCI OBRIGATÓRIO - INSERIR VALOR; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
      );
    } else if (
      hasField('retailer_contract') &&
      (inputRetailerContract === null || inputRetailerContract.trim() === '')
    ) {
      Alert.error(
        "CAMPO CONTRATO VAREJISTA OBRIGATÓRIO - INSERIR VALOR; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
      );
    } else if (
      hasField('payment_term') &&
      (inputPaymentTerm === null || inputPaymentTerm.trim() === '')
    ) {
      Alert.error(
        "CAMPO PRAZO PAGAMENTO OBRIGATÓRIO - INSERIR QUANTIDADE DE DIAS; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
      );
    } else if (!managerSelect) {
      Alert.error(
        'CAMPO GESTOR CLIENTE OBRIGATÓRIO - ATRIBUIR RESPONSÁVEL AO PROJETO.'
      );
    } else if (
      !selectDistribution &&
      (typeBriefing === 1 ||
        (fromManagement && [1, 5, 6].includes(typeBriefing)))
    ) {
      Alert.error(
        'CAMPO TIPO DE DISTRIBUIÇÃO OBRIGATÓRIO - SELECIONE O TIPO DE DISTRIBUIÇÃO.'
      );
    } else if (hasField('product') && verifyEmptyField('product')) {
      Alert.error('CAMPO PRODUTO OBRIGATÓRIO - INSERIR NOME DO PROJETO.');
    } else if (hasField('variant') && verifyEmptyField('variant')) {
      Alert.error('CAMPO VARIANTE OBRIGATÓRIO - INSERIR VARIANTE DO PRODUTO.');
    } else if (hasField('package') && verifyEmptyField('package')) {
      Alert.error(
        'CAMPO EMBALAGEM OBRIGATÓRIO - SELECIONE O TIPO DE EMBALAGEM.'
      );
    } else if (hasField('weight') && verifyEmptyField('weight')) {
      Alert.error(
        'CAMPO GRAMATURA OBRIGATÓRIO - SELECIONE A UNIDADE DE MEDIDA.'
      );
    } else if (
      hasField('quality_target') &&
      verifyEmptyField('quality_target')
    ) {
      Alert.error(
        "CAMPO MARCA TARGET OBRIGATÓRIO - INSERIR MARCA TARGET; CASO NÃO TENHA A INFORMAÇÃO INSERIR '-'"
      );
    } else if (hasField('price_target') && verifyEmptyField('price_target')) {
      Alert.error(
        "CAMPO TARGET PREÇO OBRIGATÓRIO - INSERIR PV TARGET; CASO NÃO TENHA A INFORMAÇÃO INSERIR '-'"
      );
    } else if (
      hasField('sale_price_goal') &&
      verifyEmptyField('sale_price_goal')
    ) {
      Alert.error(
        "CAMPO PV OBJETIVO OBRIGATÓRIO - INSERIR PREÇO; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
      );
    } else if (hasField('margin_goal') && verifyEmptyField('margin_goal')) {
      Alert.error(
        "CAMPO MARGEM OBJETIVA OBRIGATÓRIO - INSERIR MARGEM; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
      );
    } else if (hasField('volume') && verifyEmptyField('volume')) {
      Alert.error(
        "CAMPO VOLUME UNITÁRIO OBRIGATÓRIO - INSERIR QUANTIDADE; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
      );
    } else if (hasField('cd_states') && isDestinyEmpty) {
      Alert.error('CAMPO DE CD DESTINO É OBRIGATÓRIO');
    } else if (
      hasField('package_stock_volume') &&
      verifyEmptyField('package_stock_volume')
    ) {
      Alert.error('CAMPO QUANTIDADE EM ESTOQUE É OBRIGATÓRIO');
    } else if (
      hasField('package_stock_date') &&
      verifyEmptyField('package_stock_date')
    ) {
      Alert.error('CAMPO DATA DO ESTOQUE É OBRIGATÓRIO');
    } else if (typeBriefing === 2 && !inputReason) {
      Alert.error('CAMPO MOTIVO DA NOVA EMBALAGEM É OBRIGATÓRIO');
    } else if (
      typeBriefing === 2 &&
      inputReason &&
      selectedReason?.label === 'Outro' &&
      !inputReasonOther
    ) {
      Alert.error('CAMPO MOTIVO DA NOVA EMBALAGEM "OUTRO" É OBRIGATÓRIO');
    } else if (typeBriefing === 3 && !inputReason) {
      Alert.error('CAMPO MOTIVO DA REFORMULAÇÃO É OBRIGATÓRIO');
    } else if (
      typeBriefing === 3 &&
      inputReason &&
      selectedReason?.label === 'Outro' &&
      !inputReasonOther
    ) {
      Alert.error('CAMPO MOTIVO DA REFORMULAÇÃO "OUTRO" É OBRIGATÓRIO');
    } else if (typeBriefing === 4 && !inputReason) {
      Alert.error('CAMPO MOTIVO DA EXT. DE LINHA É OBRIGATÓRIO');
    } else if (
      typeBriefing === 4 &&
      inputReason &&
      selectedReason?.label === 'Outro' &&
      !inputReasonOther
    ) {
      Alert.error('CAMPO MOTIVO DA EXT. DE LINHA "OUTRO" É OBRIGATÓRIO');
    } else {
      const formDataJson = {
        // se ja existe id (editando)
        ...(id && {
          id,
          status_code: briefing?.status_code,
          stage_code: briefing?.stage_code,
          available: briefing?.available,
        }),
        ...(typeBriefing === 1
          ? {
              // campos no novo briefing
              retailer: Number(selectPicker),
              retailer_manager_user: managerSelect
                ? Number(managerSelect)
                : null,
              // dti_user: dtiSelect ? Number(dtiSelect) : null,
              dti_user: null,
              distribution_type: Number(selectDistribution),
            }
          : {
              // campos de carta grafica/refatoracao
              retailer_id: Number(selectPicker),
              retailer_manager_user_id: managerSelect
                ? Number(managerSelect)
                : null,
              // dti_user_id: dtiSelect ? Number(dtiSelect) : null,
              dti_user_id: null,
              project_type: typeBriefing,
            }),
        division: inputDivision,
        project_classification_id: upperLevels,
        prospection_type: selectProspection || 2,
        name: inputProject,
        release_date: dateStringStart,
        notes: textAreaObs,
        current_user: emailUser,
        suppliers: suppliersArray.map(s => ({
          ...(typeBriefing === 1
            ? {
                supplier: s.id || s.value || s.supplier,
              }
            : {
                id: s.id || s.value || s.supplier,
              }),
        })),
        // faz um loop para tratar os itens
        items: inputList.map(it => {
          // remove atributo _id temporario
          // pega o campo package_stock_date que precisa de tratamento
          const { _id, package_stock_date, ...o } = it;
          // pega as chaves de inputData para nao remover
          // propriedades que o item ja tem trazidas do back
          Object.keys(inputData).forEach(key => {
            // faz um loop para verificar se o item possui os campos
            // se nao possui, anula o campo
            if (!hasField(key)) {
              o[key] = null;
            }
          });
          if (hasField('package_stock_date')) {
            if (package_stock_date === '') {
              // se a data estiver vazia, passa a data atual
              o.package_stock_date = new Date().toISOString().split('T')[0];
            } else if (typeof package_stock_date === 'object') {
              // se for um objeto Date, transforma em yyyy-mm-dd
              o.package_stock_date = package_stock_date
                .toISOString()
                .split('T')[0];
            } else {
              // senao, ja deve ser uma data tipo yyyy-mm-dd retornada do back
              o.package_stock_date = package_stock_date;
            }
          } else {
            o.package_stock_date = null;
          }
          o.sku_classification_id = null;
          if (o.product) {
            o.sku_classification_id = products.find(
              c => c.name === o.product
            )?.id || null;
          }
          o.briefing = o.briefing || id || null;
          return o;
        }),
        ...(typeBriefing !== 1 &&
          parentBriefingId && {
            parent_briefing_id: parentBriefingId,
          }),
        ...(typeBriefing !== 1 &&
          // !parentBriefingId &&
          !id && {
            external_briefing_name: externalBriefingName,
            derived_from_name: externalBriefingName,
          }),
        ...([1, 5, 6].includes(typeBriefing) && {
          amicci_contract: inputAmicciContract || null,
          retailer_contract: inputRetailerContract || null,
          payment_term: inputPaymentTerm || null,
        }),
        ...(typeBriefing === 2 && {
          new_package_print_reason_id: Number(inputReason),
          new_package_print_reason_note: inputReasonOther,
        }),
        ...(typeBriefing === 3 && {
          refact_reason_id: Number(inputReason),
          refact_reason_note: inputReasonOther,
        }),
        ...(typeBriefing === 4 && {
          extension_reason_id: Number(inputReason) || null,
          extension_reason_note: inputReasonOther || null,
        }),
        ...(typeBriefing === 5 && {
          extension_reason_id: Number(inputReason) || null,
          extension_reason_note: inputReasonOther || null,
          new_supplier_reason_id: Number(inputReasonChgSupp) || null,
          new_supplier_reason_note: inputReasonOtherChgSupp || null,
        }),
        ...(typeBriefing === 6 && {
          new_supplier_reason_id: Number(inputReason),
          new_supplier_reason_note: inputReasonOther,
        }),
      };
      if (fromManagement && !id) {
        delete formDataJson['retailer'];
        delete formDataJson['retailer_manager_user'];
        delete formDataJson['dti_user'];
        delete formDataJson['release_date'];
        delete formDataJson['parentBriefingId'];
        formDataJson['project_type'] = typeBriefing;
        formDataJson['distribution_type_id'] = selectDistribution;
        formDataJson['user'] = _user;
      }

      // se esta editando um briefing
      // if (briefing) {
      //   const isEqual = compareTwoBriefingsWithoutSuppliers(
      //     briefing,
      //     formDataJson
      //   );
      //   const isSuppEqual = compareTwoBriefingsSuppliers(
      //     briefing,
      //     formDataJson
      //   );
      //   // se apenas alterou algum fornecedor
      //   // adiciona uma prop para avisar ao back
      //   if (isEqual && !isSuppEqual) {
      //     formDataJson['supplier_only'] = true;
      //   }
      // }

      // Enviar 'supplier_only' sempre, uma vez que a tela de edição
      // só irá servir esse propósito (2023-12-04)
      formDataJson['supplier_only'] = true;

      const resp = await briefingContext.saveBriefing(
        formDataJson,
        id,
        typeBriefing,
        projectId
      );
      if (resp?.success) {
        await saveSuppliers(formDataJson.suppliers);

        const nFiles = [...newFiles].length;
        // console.log('newFiles', newFiles, nFiles);

        const removeLastFileAdded = () => {
          if (idLastFile.length > 0) {
            idLastFile.map(async idFile => {
              await briefingContext.removeFile(idFile);
            });
          }
        };

        if (nFiles > 0) {
          if (id) {
            removeLastFileAdded();
            await briefingContext.uploadFiles(newFiles, id, dateToFile);
          } else {
            removeLastFileAdded();
            await briefingContext.uploadFiles(
              newFiles,
              resp.data.id,
              dateToFile
            );
          }
        }

        if (toExcludeFiles.length > 0) {
          for (let i in toExcludeFiles) {
            await briefingContext.removeFile(toExcludeFiles[i].id);
          }
        }

        if (typeBriefing === 1) {
          Alert.success('Briefing Salvo com sucesso!');
        } else if (typeBriefing === 2) {
          Alert.success('Carta gráfica salva com sucesso!');
        } else if (typeBriefing === 3) {
          Alert.success('Reformulação salva com sucesso!');
        } else if (typeBriefing === 4) {
          Alert.success('Extensão de linha salva com sucesso!');
        } else if (typeBriefing === 6) {
          Alert.success('Troca de fornecedor salva com sucesso!');
        }

        verifyAndRemoveModalClass();
        navigateBack();
      } else {
        Alert.error('Ocorreu um erro ao salvar o produto!');
        if (resp.data?.key && resp.data?.message) {
          setTimeout(() => {
            Alert.error(
              `${fieldsList[resp.data.key] || ''} - ${resp.data.message}`,
              10000
            );
          }, 600);
        }
      }
    }
  };

  const saveSuppliers = async suppliersBriefing => {
    // faz uma consulta para trazer os fornecedores que
    // estavam associados a esse briefing
    const {
      data: { commercial_filters },
    } = await briefingContext.getSuppliersByProject(projectId);
    // console.log('comercialFilters', commercial_filters);

    const deletes = [];
    const adds = [];

    // verifica se algum fornecedor foi excluido
    commercial_filters.forEach(supp => {
      const hasSupp = suppliersBriefing.find(
        s => (s.supplier || s.id) === supp.supplier_id
      );
      if (!hasSupp) {
        deletes.push(supp.supplier_id);
      }
    });
    // verifica se algum fornecedor foi adicionado
    suppliersBriefing.forEach(supp => {
      const hasSupp = commercial_filters.find(
        s => s.supplier_id === (supp.supplier || supp.id)
      );
      if (!hasSupp) {
        adds.push(supp.supplier || supp.id);
      }
    });

    if (adds.length > 0) {
      const dataSuppliers = {
        project_id: projectId,
        suppliers: adds,
      };
      await briefingContext.setSuppliersByProject(dataSuppliers);
    }
    if (deletes.length > 0) {
      await briefingContext.deleteSuppliersByProject(projectId, deletes);
    }
  };

  const removeProvider = idSupp => {
    setSuppliersArray(sups => sups.filter(s => s.id !== idSupp));
  };

  const suppliersPills = useMemo(() => {
    if (suppliersArray && suppliersArray.length > 0) {
      return (
        <>
          {suppliersArray.map(sups => (
            <div className="supsArray btn-supplier" key={`supplier-${sups.id}`}>
              <span>{sups.name}</span>
              <button
                type="button"
                id={`btn-provider-${sups.id}`}
                disabled={briefing?.status_code === 'F'}>
                <i className="fa fa-trash"></i>
              </button>
              {briefing?.status_code !== 'F' && (
                <UncontrolledPopover
                  trigger="legacy"
                  placement="top"
                  target={`btn-provider-${sups.id}`}>
                  <PopoverHeader>Remover fornecedor</PopoverHeader>
                  <PopoverBody>
                    <p>Deseja remover o fornecedor {sups.name}?</p>
                    <br />
                    <div className="popover-footer">
                      <button
                        type="button"
                        onClick={() => removeProvider(sups.id)}>
                        Remover
                      </button>
                    </div>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </div>
          ))}
        </>
      );
    }
    return null;
  }, [suppliersArray]);

  const onSubmit = e => {
    e.preventDefault();
    return false;
  };

  /*
   * retorna se possui o campo dentro de items de acordo com o typeBriefing
   */
  const hasField = field => {
    const displays = {
      1: {
        amicci_contract: false, // contrato amicci
        retailer_contract: false, // contrato varejista (%)
        payment_term: false, // prazo de pagamento
        product: true, // produto
        variant: true, // variante
        package: true, // embalagem
        weight: true, // gramatura
        current_brand: false, // marca atual
        quality_target: false, // target marca
        price_target: false, // target preco
        package_stock_volume: false, // quantidade em estoque
        package_stock_date: false, // data do estoque
        sale_price_goal: false, // pv objetivo
        margin_goal: false, // margem objetiva (%)
        volume: false, // volume unitario (mensal)
        refact_obs: false, // orientacao reformulacao
        notes: false, // observacoes
        cd_states: false, // cd destino
      },
      2: {
        amicci_contract: false, // contrato amicci
        retailer_contract: false, // contrato varejista (%)
        payment_term: false, // prazo de pagamento
        product: true, // produto
        variant: true, // variante
        package: true, // embalagem
        weight: true, // gramatura
        current_brand: true, // marca atual
        quality_target: true, // target marca
        price_target: false, // target preco
        package_stock_volume: true, // quantidade em estoque
        package_stock_date: true, // data do estoque
        sale_price_goal: false, // pv objetivo
        margin_goal: false, // margem objetiva (%)
        volume: false, // volume unitario (mensal)
        refact_obs: false, // orientacao reformulacao
        notes: false, // observacoes
        cd_states: false, // cd destino
      },
      3: {
        amicci_contract: false, // contrato amicci
        retailer_contract: false, // contrato varejista (%)
        payment_term: false, // prazo de pagamento
        product: true, // produto
        variant: true, // variante
        package: true, // embalagem
        weight: true, // gramatura
        current_brand: true, // marca atual
        quality_target: true, // target marca
        price_target: true, // target preco
        package_stock_volume: false, // quantidade em estoque
        package_stock_date: false, // data do estoque
        sale_price_goal: false, // pv objetivo
        margin_goal: false, // margem objetiva (%)
        volume: false, // volume unitario (mensal)
        refact_obs: true, // orientacao reformulacao
        notes: false, // observacoes
        cd_states: true, // cd destino
      },
      4: {
        amicci_contract: false, // contrato amicci
        retailer_contract: false, // contrato varejista (%)
        payment_term: false, // prazo de pagamento
        product: true, // produto
        variant: true, // variante
        package: true, // embalagem
        weight: true, // gramatura
        current_brand: true, // marca atual
        quality_target: true, // target marca
        price_target: true, // target preco
        package_stock_volume: false, // quantidade em estoque
        package_stock_date: false, // data do estoque
        sale_price_goal: true, // pv objetivo
        margin_goal: true, // margem objetiva (%)
        volume: true, // volume unitario (mensal)
        refact_obs: false, // orientacao reformulacao
        notes: true, // observacoes
        cd_states: true, // cd destino
      },
      5: {
        amicci_contract: false, // contrato amicci
        retailer_contract: false, // contrato varejista (%)
        payment_term: false, // prazo de pagamento
        product: true, // produto
        variant: true, // variante
        package: true, // embalagem
        weight: true, // gramatura
        current_brand: false, // marca atual
        quality_target: false, // target marca
        price_target: false, // target preco
        package_stock_volume: false, // quantidade em estoque
        package_stock_date: false, // data do estoque
        sale_price_goal: false, // pv objetivo
        margin_goal: false, // margem objetiva (%)
        volume: false, // volume unitario (mensal)
        refact_obs: false, // orientacao reformulacao
        notes: false, // observacoes
        cd_states: false, // cd destino
      },
      6: {
        amicci_contract: false, // contrato amicci
        retailer_contract: false, // contrato varejista (%)
        payment_term: false, // prazo de pagamento
        product: true, // produto
        variant: true, // variante
        package: true, // embalagem
        weight: true, // gramatura
        current_brand: false, // marca atual
        quality_target: false, // target marca
        price_target: false, // target preco
        package_stock_volume: false, // quantidade em estoque
        package_stock_date: false, // data do estoque
        sale_price_goal: false, // pv objetivo
        margin_goal: false, // margem objetiva (%)
        volume: false, // volume unitario (mensal)
        refact_obs: false, // orientacao reformulacao
        notes: false, // observacoes
        cd_states: false, // cd destino
      },
    };
    if (typeBriefing) return displays[typeBriefing][field];
    return false;
  };

  /* Função para inputar nova carta gráfica, ext. de linha, reformulação e troca de fornecedor */
  const inputNew = (selectedReason, tpBriefing, selectedReasonOther) => {
    if (selectedReason) {
      const selected = reasonLists[tpBriefing].find(
        w => String(w.id) === selectedReason
      );

      if (selected?.label !== 'Outro') {
        return <div className="customTxtReason">{selected?.label}</div>;
      } else {
        return <div className="customTxtReason">{selectedReasonOther}</div>;
      }
    }
    return null;
  };

  /*
   * retorna a propriedade css "flex" com o tamanho de cada
   * agrupamento de campos de acordo com o typeBriefing
   */
  const sizeGroups = group => {
    const groups = {
      1: {
        descrItem: '4 4',
        target: '2 2',
        businessPrem: '3 3',
        options: '3 3',
      },
      2: {
        descrItem: '4 4',
        target: '1 1',
        businessPrem: '2 2',
        options: '2 2',
      },
      3: {
        descrItem: '3 3',
        target: '2 2',
        businessPrem: '2 2',
        options: '1 1',
      },
      4: {
        descrItem: '4 4',
        target: '2 2',
        businessPrem: '3 3',
        options: '3 3',
      },
      5: {
        descrItem: '4 4',
        target: '2 2',
        businessPrem: '3 3',
        options: '3 3',
      },
      6: {
        descrItem: '4 4',
        target: '2 2',
        businessPrem: '3 3',
        options: '3 3',
      },
    };
    if (typeBriefing) return groups[typeBriefing][group];
    return 1;
  };

  // const stageByCode = ({ stage_code }) => {
  //   const stages = {
  //     R: 'Rascunho',
  //     G: 'Em Validação do Gestor',
  //     D: 'Em Validação do DTI',
  //     E: 'Enviado para Sourcing',
  //     A: 'Analisado pelo Sourcing',
  //     V: 'Validação pelo Gestor',
  //     P: 'Em Prospecção',
  //     H: 'Pendente de Envio',
  //     B: 'Pronto para Prospecção',
  //     C: 'Em Prospecção (Cliente)',
  //     N: 'Pendente de Finalização',
  //     F: 'Finalizado',
  //     S: 'Aguardando seleção de Fornecedor',
  //   };
  //   return stages[stage_code] || 'error';
  // };

  useEffect(() => {
    if (project) {
      briefingContext.getReviewBriefing(project.briefing_id);
    }
  }, [project]);

  useEffect(() => {
    console.log('project', project);
  }, [project]);

  const briefingStatus = {
    approved: project?.briefing_status === 'Aprovado',
    inRevision: project?.briefing_status === 'Em revisão',
    pendingApproval: project?.briefing_status === 'Pendente de aprovação',
    withoutPending: project?.briefing_status === 'Sem pendência',
    notApplicable: project?.briefing_status === 'N/A',
  };

  const showCorrectStatus = () => {
    switch (project?.briefing_status) {
      case 'Aprovado':
        return 'Aprovado pelo cliente';
      case 'Em revisão':
        return 'Necessita revisão';
      case 'Pendente de aprovação':
        return 'Em validação do cliente';
      case 'Sem pendência':
        return 'Aguardando etapa';
      /* case briefingStatus.notApplicable:
        return "Aprovado pelo gestor"; */
      default:
        return 'Aprovado pelo gestor';
    }
  };

  const searchLastFileAdded = () => {
    files.map(file =>
      newFiles.map(nf => {
        if (file.type === nf._type) {
          setIdLastFile(prev => [...prev, file.id]);
        }
        return '';
      })
    );
  };

  useEffect(() => {
    searchLastFileAdded();
  }, [newFiles]);

  if (!loadState.project || !loadState.briefing) return <Loading />;

  return (
    <>
      <Header
        center
        bgColor={headerBgColor}
        txtColor="#fff"
        subTxt={headerSubText}>
        {headerText}
      </Header>
      <form
        required
        autoComplete="off"
        onSubmit={onSubmit}
        className="formBriefing"
        style={{
          background: `linear-gradient(to bottom, ${headerBgColor} 0%, ${headerBgColor} 30%, #fff 30%)`,
        }}>
        {briefing && (
          <div className="div-status-project">
            <p className="status-project">
              <span>Status de aprovação do cliente</span>
            </p>
            <div
              className={classNames({
                containerEyeStatus: true,
                contApproved: true,
                contInRevision: false,
                contInValidation: false,
              })}>
              <FakeButton
                size="small"
                color={classNames({
                  statusdefault: briefingStatus.notApplicable,
                  red: briefingStatus.inRevision,
                  green: briefingStatus.approved,
                  yellow: briefingStatus.pendingApproval,
                  grey: briefingStatus.withoutPending,
                })}>
                {showCorrectStatus()}
              </FakeButton>
              <Whisper
                placement="bottom"
                trigger="hover"
                speaker={tooltipStatusBriefing}>
                <button
                  onClick={() => {
                    modalBriefingReviewRef.current?.toggle();
                  }}
                  disabled={reviewBriefing?.length === 0}
                  className={classNames({
                    buttonStatusEye: true,
                    buttonStatusEyeNA: briefingStatus.notApplicable,
                    buttonStatusEyeApp: briefingStatus.approved,
                    buttonStatusEyeRev: briefingStatus.inRevision,
                    buttonStatusEyeCli: briefingStatus.pendingApproval,
                    buttonStatusEyeWP: briefingStatus.withoutPending,
                  })}>
                  <AiOutlineEye />
                </button>
              </Whisper>
            </div>
          </div>
        )}
        <div className="mainDiv">
          <div className="briefingLine1">
            <div className="inputDivisionLineFields">
              <label className="legendLineField">Cliente</label>
              {id || fromManagement ? (
                <div className="inputOnlyRetailer">{nameSelectPicker}</div>
              ) : (
                <SelectPicker
                  value={selectPicker}
                  onChange={setSelectPicker}
                  data={arrayRetailers}
                  className="selectPicker"
                  placeholder="Clientes"
                  disabled={fromManagement || isFieldDisabled}
                />
              )}
            </div>
            <div className="inputDivisionLineFields">
              <Label className="legendLineField">Categoria</Label>
              {fromManagement || briefing?.visualization_created ? (
                <div className="inputOnlyRetailer">{inputDivision}</div>
              ) : (
                <button
                  type="button"
                  className="btnInputCategory"
                  id="inputDivision"
                  onClick={() => modalCategoryRef.current?.toggle()}
                  title={inputDivision?.length > 42 ? inputDivision : ''}
                  disabled={isFieldDisabled}>
                  {inputDivision || 'Selecione'}
                </button>
              )}
            </div>
            <div className="inputDivisionLineFields">
              <Label className="legendLineField">Projeto</Label>
              {fromManagement || briefing?.visualization_created ? (
                <div className="inputOnlyRetailer">{inputProject}</div>
              ) : (
                <Input
                  type="text"
                  className="inputText input12"
                  value={inputProject}
                  name="inputProject"
                  id="inputProject"
                  onChange={e => setInputProject(e.target.value)}
                  onBlur={validateBlur}
                  data-name="CAMPO PROJETO OBRIGATÓRIO - ADICIONAR NOME DO PROJETO."
                  placeholder="Ex: Mercearia"
                  disabled={isFieldDisabled}
                />
              )}
            </div>
            <div className="inputDivisionLineFields">
              <Label className="legendLineField">Gestor</Label>
              {fromManagement || briefing?.visualization_created ? (
                <div className="inputOnlyRetailer">
                  {
                    managersProject.find(m => m.user === managerSelect)
                      ?.user_name
                  }
                </div>
              ) : (
                <select
                  onBlur={validateBlur}
                  data-name="CAMPO GESTOR RESPONSÁVEL OBRIGATÓRIO - ATRIBUIR RESPONSÁVEL AO PROJETO."
                  onChange={e => setManagerSelect(e.target.value)}
                  value={managerSelect}
                  name="managerSelect"
                  className="orangeSelect input12 mw150"
                  disabled={managersProject.length === 0 || isFieldDisabled}>
                  <option value="">Selecione..</option>
                  {managersProject.map(manager => (
                    <option
                      key={`resp-${manager.user}`}
                      id={manager.user}
                      value={manager.user}>
                      {manager.user_name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            {(fromManagement || briefing?.visualization_created) && (
              <div className="inputDivisionLineFields">
                <Label className="legendLineField">Marca Cliente</Label>
                <div className="inputOnlyRetailer">{clientBrand}</div>
              </div>
            )}
            {[1, 5, 6].includes(typeBriefing) && (
              <div className="inputDivisionLineFields">
                <Label className="legendLineField">Prospecção</Label>
                <select
                  name="selectProspection"
                  className="orangeSelect borderOrange"
                  onBlur={validateBlur}
                  data-name="CAMPO PROSPECÇÃO OBRIGATÓRIO - SELECIONE O TIPO DE PROSPECÇÃO."
                  value={selectProspection}
                  onChange={e => setSelectProspection(e.target.value)}
                  disabled={
                    loading.prospectionType || briefing?.status_code === 'F' || isFieldDisabled
                  }>
                  <option value="">Escolha o tipo de prospecção</option>
                  {/* Tipo de prospecção */}
                  {listProspectionType.map(pt => (
                    <option key={`lp-${pt.id}`} value={pt.id}>
                      {pt.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {externalBriefingName && (
              <div className="headerBriefingCol">
                <div
                  style={{
                    padding: '0 10px',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  Projeto Derivado: <strong>{externalBriefingName}</strong>
                </div>
              </div>
            )}
          </div>
          <div className="briefingLine2">
            {(fromManagement || briefing?.visualization_created) && (
              <>
                <div className="inputDivisionLineFields">
                  <Label className="legendLineField">
                    Responsável do Cliente
                  </Label>
                  <Input
                    type="text"
                    className="inputText input12"
                    value={selectedClientResp}
                    disabled
                  />
                </div>
                <div className="inputDivisionLineFields">
                  <Label className="legendLineField">
                    Responsável Atendimento
                  </Label>
                  <Input
                    type="text"
                    className="inputText input12"
                    value={selectedServiceResp}
                    disabled
                  />
                </div>
                <div className="inputDivisionLineFields">
                  <Label className="legendLineField">
                    Responsável Desenvolvimento
                  </Label>
                  <Input
                    type="text"
                    className="inputText input12"
                    value={selectedServiceManager}
                    disabled
                  />
                </div>
              </>
            )}
            <div className="inputDivisionLineFields">
              <Label className="legendLineField">Responsável DTI</Label>
              {/* {fromManagement || briefing?.visualization_created ? ( */}
              <Input
                type="text"
                className="inputText input12"
                value={selectedDtiResp}
                disabled
              />
              {/* ) : (
                <select
                  onBlur={validateBlur}
                  data-name="CAMPO RESPONSÁVEL DTI OBRIGATÓRIO - SELECIONAR RESPONSÁVEL DTI AO PROJETO."
                  onChange={handleDtiUsers}
                  value={dtiSelect}
                  name="dtiSelect"
                  className="orangeSelect input12 mw150">
                  <option value="">Selecione..</option>
                  {filteredDtiUsers.map(dti => (
                    <option
                      key={`resp-dti-${dti.id}`}
                      id={dti.id}
                      value={dti.id}>
                      {dti.name}
                    </option>
                  ))}
                </select>
              )} */}
            </div>
            {(typeBriefing === 1 ||
              (fromManagement && [1, 5, 6].includes(typeBriefing))) && (
              <div className="inputDivisionLineFields">
                <Label className="legendLineField">Tipo de distribuição</Label>
                <select
                  name="selectDistribution"
                  className="orangeSelect orangeSelectCustom input12 borderOrange"
                  onBlur={validateBlur}
                  data-name="CAMPO TIPO DE DISTRIBUIÇÃO OBRIGATÓRIO - SELECIONE O TIPO DE DISTRIBUIÇÃO."
                  value={selectDistribution}
                  onChange={e => setSelectDistribution(e.target.value)}
                  disabled={briefing?.status_code === 'F' || isFieldDisabled}>
                  <option value="">Tipo de distribuição</option>
                  {listDistributionType.map(dt => (
                    <option key={`dt-${dt.id}`} value={dt.id}>
                      {dt.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {hasField('amicci_contract') && (
              <div
                className="inputDivisionLineFields smallField"
                style={
                  {
                    /* width: '66px', transform: 'translateY(-8px)' */
                  }
                }>
                <Label className="legendLineField">
                  Contrato
                  <br />
                  Amicci (%)
                </Label>
                <Input
                  type="text"
                  className="inputText input12 borderOrange"
                  value={inputAmicciContract}
                  name="inputAmicciContract"
                  id="inputAmicciContract"
                  disabled={briefing?.status_code === 'F' || isFieldDisabled}
                  onChange={e =>
                    setInputAmicciContract(floatMask(e.target.value))
                  }
                  onBlur={validateBlur}
                  data-name="CAMPO CONTRATO AMICCI OBRIGATÓRIO - INSERIR VALOR; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
                  placeholder="Ex: 3.0"
                />
              </div>
            )}
            {hasField('retailer_contract') && (
              <div
                className="inputDivisionLineFields smallField"
                style={
                  {
                    /*  width: '66px', transform: 'translateY(-22px)'  */
                  }
                }>
                <Label className="legendLineField">
                  Contrato <br />
                  Varejista (%)
                </Label>
                <Input
                  type="text"
                  className="inputText input12 borderOrange"
                  value={inputRetailerContract}
                  name="inputRetailerContract"
                  id="inputRetailerContract"
                  disabled={briefing?.status_code === 'F' || isFieldDisabled}
                  onChange={e =>
                    setInputRetailerContract(floatMask(e.target.value))
                  }
                  onBlur={validateBlur}
                  data-name="CAMPO CONTRATO VAREJISTA OBRIGATÓRIO - INSERIR VALOR; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
                  placeholder="Ex: 3.0"
                />
              </div>
            )}
            {hasField('payment_term') && (
              <div
                className="inputDivisionLineFields smallField"
                style={
                  {
                    /*  width: '66px', transform: 'translateY(-8px)' */
                  }
                }>
                <Label className="legendLineField">
                  Prazo de <br />
                  pagamento
                </Label>
                <Input
                  type="text"
                  className="inputText input12 borderOrange"
                  value={inputPaymentTerm}
                  name="inputPaymentTerm"
                  id="inputPaymentTerm"
                  disabled={briefing?.status_code === 'F' || isFieldDisabled}
                  onChange={e =>
                    setInputPaymentTerm(integerMask(e.target.value))
                  }
                  onBlur={validateBlur}
                  data-name="CAMPO PRAZO PAGAMENTO OBRIGATÓRIO - INSERIR QUANTIDADE DE DIAS; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
                  placeholder="Ex: 20"
                />
              </div>
            )}

            <div className="buttonObservation">
              <Label className="legendButtonObservation">
                Observação
                <br />
                Geral
              </Label>
              <div
                onClick={() => {
                  if (!isFieldDisabled) {
                    setTxtHeaderNotes('Observação Geral');
                    setModalNotesFocus('general');
                    modalNotesRef.current?.toggle();
                  }
                }}
                id="inputMagic"
                className="inputWhiteItem"
                style={{cursor: isFieldDisabled ? 'not-allowed' : 'inherit'}}
                disabled={isFieldDisabled}>
                <FaRegEdit size={25} color="#2B408B" />
              </div>
            </div>
            {/* <div className="text-center">
                  <Label className={'legendField'}>
                    Previsão de lançamento
                  </Label>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={setDateValue}
                    value={dateValue}
                    placeholder="DD/MM/YYYY"
                    name="datePickerInput"
                    className="input12"
                    disabled={briefing?.status_code === 'F'}
                    locale={{
                      sunday: 'D',
                      monday: 'S',
                      tuesday: 'T',
                      wednesday: 'Q',
                      thursday: 'Q',
                      friday: 'S',
                      saturday: 'S',
                      ok: 'OK',
                      today: 'Hoje',
                      yesterday: 'Ontem',
                      hours: 'Horas',
                      minutes: 'Minutos',
                      seconds: 'Segundos',
                    }}
                  />
                </div> */}
          </div>
          {/* <button className="closeButton" onClick={closePage}>
            <img alt="sair" src={closeIcon} className="closeButtonIcon" />
          </button> */}

          <div className="widthBriefing">
            {/* legendas agrupadas */}
            <div className="lineLabelsTop">
              <div
                className="groupDescrItem"
                style={{ flex: sizeGroups('descrItem') }}>
                <fieldset className="groupDescrItemFieldset">
                  <legend className="groupDescrItemLegend">
                    DESCRIÇÃO DO ITEM
                  </legend>
                </fieldset>
              </div>
              <div
                className="groupTarget"
                style={{ flex: sizeGroups('target') }}>
                <fieldset className="groupTargetFieldset">
                  <legend className="groupTargetLegend">TARGET</legend>
                </fieldset>
              </div>
              <div
                className="groupBusinessPrem"
                style={{ flex: sizeGroups('businessPrem') }}>
                <fieldset className="groupBusinessPremFieldset">
                  <legend className="groupBusinessPremLegend">
                    PREMISSAS COMERCIAIS
                  </legend>
                </fieldset>
              </div>
              <div
                className="groupOptions"
                style={{ flex: sizeGroups('options') }}>
                {' '}
              </div>
            </div>

            {/* linha labels */}
            <div className="lineLabelMiddle">
              {/* grupos labels descricao do item */}
              <div
                className="groupDescrItemMiddle"
                style={{ flex: sizeGroups('descrItem') }}>
                {hasField('product') && (
                  <div className="blueBigInput">
                    <Label for="inputProduct" className="legendFieldMiddle">
                      Produtos
                    </Label>
                    {/*<Whisper
                      placement="top"
                      trigger="hover"
                      speaker={tooltipProduct}>
                      <span className="question-icon">?</span>
                     </Whisper>*/}
                  </div>
                )}
                {hasField('variant') && (
                  <div className="normalSizeInputMiddle">
                    <Label for="inputVariant" className="legendFieldMiddle">
                      Variante
                    </Label>
                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={tooltipVariant}>
                      <span className="question-icon">?</span>
                    </Whisper>
                  </div>
                )}
                {hasField('package') && (
                  <div className="normalSizeInputMiddle">
                    <Label for="inputPacking" className="legendFieldMiddle">
                      Embalagem
                    </Label>
                    {/*<Whisper
                      placement="top"
                      trigger="hover"
                      speaker={tooltipPack}>
                      <span className="question-icon">?</span>
                    </Whisper>*/}
                  </div>
                )}
                {hasField('weight') && (
                  <div className="normalSizeInputMiddle">
                    <Label for="inputGr" className="legendFieldMiddle">
                      Gramatura
                    </Label>
                    {/*<Whisper
                      placement="top"
                      trigger="hover"
                      speaker={tooltipGr}>
                      <span className="question-icon">?</span>
                    </Whisper>*/}
                  </div>
                )}
                {hasField('current_brand') && (
                  <div className="normalSizeInputMiddle">
                    <Label for="current_brand" className="legendFieldMiddle">
                      Marca Atual
                    </Label>
                  </div>
                )}
              </div>

              {/* grupo labels target */}
              <div
                className="groupTargetMiddle"
                style={{ flex: sizeGroups('target') }}>
                {hasField('quality_target') && (
                  <div className="normalSizeInputMiddle">
                    <Label
                      for="inputTargetQuality"
                      className="legendFieldMiddleTarget">
                      Target Marca
                    </Label>
                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={tooltipQuality}>
                      <span className="question-icon">?</span>
                    </Whisper>
                  </div>
                )}
                {hasField('price_target') && (
                  <div className="normalSizeInputMiddle">
                    <Label
                      for="inputTargetPrice"
                      className="legendFieldMiddleTarget">
                      Target Preço
                    </Label>
                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={tooltipPrice}>
                      <span className="question-icon">?</span>
                    </Whisper>
                  </div>
                )}
              </div>

              {/* grupo labels premissas comerciais */}
              <div
                className="groupBusinessPremMiddle"
                style={{ flex: sizeGroups('businessPrem') }}>
                {hasField('sale_price_goal') && (
                  <div className="normalSizeInputMiddle">
                    <Label
                      for="inputPvObject"
                      className="legendFieldMiddleTarget">
                      PV <br />
                      Objetivo
                    </Label>
                  </div>
                )}
                {hasField('margin_goal') && (
                  <div className="normalSizeInputMiddle">
                    <Label
                      for="inputPvObject"
                      className="legendFieldMiddleTarget">
                      Margem <br />
                      Objetiva <br />
                      (%)
                    </Label>
                  </div>
                )}
                {hasField('volume') && (
                  <div className="normalSizeInputMiddle">
                    <Label
                      for="inputVolume"
                      className="legendFieldMiddleTarget">
                      Volume Unitário (Mensal)
                    </Label>
                  </div>
                )}
                {/* hasField('amicci_contract') && (
                  <div className="normalSizeInputMiddle ">
                    <Label
                      for="inputContract"
                      className="legendFieldMiddleTarget">
                      Contrato <br />
                      Amicci <br />
                      (%)
                    </Label>
                  </div>
                ) */}
                {/* hasField('retailer_contract') && (
                  <div className="normalSizeInputMiddle ">
                    <Label
                      for="inputContractMarket"
                      className="legendFieldMiddleTarget">
                      Contrato <br />
                      Varejista <br />
                      (%)
                    </Label>
                  </div>
                ) */}
                {/* hasField('payment_term') && (
                  <div className="normalSizeInputMiddle ">
                    <Label
                      for="inputPaymentTerm"
                      className={'legendField specialFieldthree'}>
                      Prazo de <br />
                      pagamento
                    </Label>
                  </div>
                ) */}
                {hasField('package_stock_volume') && (
                  <div className="normalSizeInputMiddle">
                    <Label
                      for="stockQuantity"
                      className="legendFieldMiddleStock">
                      Quantidade em Estoque
                    </Label>
                  </div>
                )}
                {hasField('package_stock_date') && (
                  <div className="normalSizeInputMiddle">
                    <Label
                      for="package_stock_date"
                      className="legendFieldMiddleStock">
                      Data do Estoque
                    </Label>
                  </div>
                )}
              </div>

              {/* grupo labels options */}
              <div
                className="groupOptionsMiddle"
                style={{ flex: sizeGroups('options') }}>
                {hasField('notes') && (
                  <div className="whiteSmallInput">
                    <Label for="produto" className="legendFieldMiddleOption">
                      Observação
                    </Label>
                  </div>
                )}
                {hasField('cd_states') && (
                  <div className="whiteMedInput">
                    <Label for="cddestino" className="legendFieldMiddleOption">
                      CD Destino
                    </Label>
                  </div>
                )}
                {/*<div className="whiteSmallInput">
                  <Label
                    for="duplicarLinha"
                    className="legendFieldMiddleOption">
                    Duplicar <br />
                    Linha
                  </Label>
                </div>
                <div className="whiteSmallInput">
                  <Label for="produto" className="legendFieldMiddleOption">
                    Excluir
                  </Label>
                </div>*/}
              </div>
            </div>

            <div className="lineInputsArea">
              {/* loop de linha de itens */}
              {inputList.map((item, i) => {
                return (
                  <div
                    className="lineInputsLine"
                    key={`item-${item._id || item.id}`}>
                    {/* grupos inputs descricao do item */}
                    <div
                      className="groupDescrItemLineInput"
                      style={{ flex: sizeGroups('descrItem') }}>
                      {hasField('product') && (
                        <Whisper
                          placement="topStart"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              {item.product || 'Selecione o produto'}
                            </Tooltip>
                          }>
                          <div
                            className="blueBigInput"
                            style={{ overflow: 'hidden' }}>
                            <SelectPicker
                              value={item.product}
                              onChange={value =>
                                handleInputChange(
                                  {
                                    target: { name: 'product', value },
                                  },
                                  i
                                )
                              }
                              onExit={() =>
                                validateBlurSelPicker({
                                  class: `selectProduct-${i}`,
                                  value: item.product,
                                  msg: 'CAMPO OBRIGATÓRIO OBRIGATÓRIO - SELECIONE O PRODUTO.',
                                })
                              }
                              disabled={briefing?.status_code === 'F' || isFieldDisabled}
                              data={productsOptions}
                              className={`selectProduct selectProduct-${i}`}
                              placeholder="Selecione"
                            />
                          </div>
                        </Whisper>
                      )}
                      {hasField('variant') && (
                        <div className="normalSizeInput">
                          <Input
                            name="variant"
                            value={item.variant || ''}
                            onChange={e => handleInputChange(e, i)}
                            className="inputBlueItem"
                            onBlur={validateBlur}
                            disabled={briefing?.status_code === 'F' || isFieldDisabled}
                            data-name="CAMPO VARIANTE OBRIGATÓRIO - INSERIR VARIANTE DO PRODUTO."
                            placeholder="Ex: Sabor/Textura"
                          />
                        </div>
                      )}
                      {hasField('package') && (
                        <div
                          className="normalSizeInput"
                          style={{ overflow: 'hidden' }}>
                          <SelectPicker
                            value={item.package}
                            onChange={value =>
                              handleInputChange(
                                { target: { name: 'package', value } },
                                i
                              )
                            }
                            onExit={() =>
                              validateBlurSelPicker({
                                class: `package-${i}`,
                                value: item.package,
                                msg: 'CAMPO EMBALAGEM OBRIGATÓRIO - SELECIONE O TIPO DE EMBALAGEM.',
                              })
                            }
                            data={arrayPackages}
                            className={`selectPickerBlue package-${i}`}
                            placeholder="Selecione"
                            disabled={briefing?.status_code === 'F' || isFieldDisabled}
                            renderMenuItem={(label, item) => (
                              <span title={item.description}>{label}</span>
                            )}
                          />
                        </div>
                      )}
                      {hasField('weight') && (
                        <div className="normalSizeInput">
                          <button
                            className={`btnInput weight-${i}`}
                            onClick={ev => {
                              setSelectedWeight(i);
                              modalWeightRef.current?.toggleWeight();
                              modalWeightRef.current?.setPosX(ev.pageX);
                            }}
                            disabled={briefing?.status_code === 'F' || isFieldDisabled}>
                            {item.weight || 'Selecione'}
                          </button>
                        </div>
                      )}
                      {hasField('current_brand') && (
                        <div className="normalSizeInput">
                          <Input
                            className="inputBlueItem"
                            name="current_brand"
                            value={item.current_brand}
                            onChange={e => handleInputChange(e, i)}
                            onBlur={validateBlur}
                            disabled={briefing?.status_code === 'F' || isFieldDisabled}
                            data-name="CAMPO MARCA ATUAL - INSERIR A MARCA ATUAL."
                            placeholder="Ex: Marca Chocolate"
                          />
                        </div>
                      )}
                    </div>

                    {/* grupo inputs target */}
                    <div
                      className="groupDescrItemLineInput"
                      style={{ flex: sizeGroups('target') }}>
                      {hasField('quality_target') && (
                        <div className="normalSizeInput">
                          <Input
                            className="inputGrayItem"
                            name="quality_target"
                            value={item.quality_target}
                            onChange={e => handleInputChange(e, i)}
                            onBlur={validateBlur}
                            disabled={briefing?.status_code === 'F' || isFieldDisabled}
                            data-name="CAMPO MARCA TARGET OBRIGATÓRIO - INSERIR MARCA TARGET; CASO NÃO TENHA A INFORMAÇÃO INSERIR '-'"
                            placeholder="Ex: Lacta"
                          />
                        </div>
                      )}
                      {hasField('price_target') && (
                        <div className="normalSizeInput">
                          <Input
                            className="inputGrayItem"
                            name="price_target"
                            value={item.price_target}
                            onChange={e => handleInputChange(e, i)}
                            onBlur={validateBlur}
                            disabled={briefing?.status_code === 'F' || isFieldDisabled}
                            data-name="CAMPO TARGET PREÇO OBRIGATÓRIO - INSERIR PV TARGET; CASO NÃO TENHA A INFORMAÇÃO INSERIR '-'"
                            placeholder="Ex: Lacta"
                          />
                        </div>
                      )}
                    </div>

                    {/* grupo inputs premissas comerciais */}
                    <div
                      className="groupBusinessPrem"
                      style={{ flex: sizeGroups('businessPrem') }}>
                      {hasField('sale_price_goal') && (
                        <div className="normalSizeInput">
                          <Input
                            className="inputYellowItem"
                            name="sale_price_goal"
                            value={item.sale_price_goal}
                            type="number"
                            onChange={e => handleInputChange(e, i)}
                            onBlur={validateBlur}
                            disabled={briefing?.status_code === 'F' || isFieldDisabled}
                            data-name="CAMPO PV OBJETIVO OBRIGATÓRIO - INSERIR PREÇO; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
                            min="0"
                            max="99"
                            step="0.01"
                            placeholder="Ex: 1,99"
                            onKeyDown={e =>
                              (e.keyCode === 189 ||
                                e.keyCode === 190 ||
                                e.keyCode === 69) &&
                              e.preventDefault()
                            }
                          />
                        </div>
                      )}
                      {hasField('margin_goal') && (
                        <div className="normalSizeInput">
                          <Input
                            className="inputYellowItem"
                            name="margin_goal"
                            value={item.margin_goal}
                            onChange={e => handleInputChange(e, i)}
                            type="number"
                            onBlur={validateBlur}
                            disabled={briefing?.status_code === 'F' || isFieldDisabled}
                            data-name="CAMPO MARGEM OBJETIVA OBRIGATÓRIO - INSERIR MARGEM; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
                            placeholder="Ex: 40,0"
                            onKeyDown={e =>
                              (e.keyCode === 189 ||
                                e.keyCode === 190 ||
                                e.keyCode === 69) &&
                              e.preventDefault()
                            }
                          />
                        </div>
                      )}
                      {hasField('volume') && (
                        <div className="normalSizeInput">
                          <Input
                            className="inputYellowItem"
                            name="volume"
                            value={item.volume}
                            onChange={e => handleInputChange(e, i, integerMask)}
                            type="text"
                            placeholder="Ex: 2000"
                            onBlur={validateBlur}
                            disabled={briefing?.status_code === 'F' || isFieldDisabled}
                            data-name="CAMPO VOLUME UNITÁRIO OBRIGATÓRIO - INSERIR QUANTIDADE; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
                          />
                        </div>
                      )}
                      {/* hasField('amicci_contract') && (
                        <div className="normalSizeInput">
                          <Input
                            className="inputYellowItem"
                            name="amicci_contract"
                            value={item.amicci_contract}
                            onChange={e => handleInputChange(e, i)}
                            type="number"
                            placeholder="Ex: 1,99"
                            onBlur={validateBlur}
                            disabled={briefing?.status_code === 'F'}
                            data-name="CAMPO CONTRATO AMICCI OBRIGATÓRIO - INSERIR VALOR; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
                            onKeyDown={e =>
                              (e.keyCode === 189 ||
                                e.keyCode === 190 ||
                                e.keyCode === 69) &&
                              e.preventDefault()
                            }
                          />
                        </div>
                      ) */}
                      {/* hasField('retailer_contract') && (
                        <div className="normalSizeInput">
                          <Input
                            className="inputYellowItem"
                            name="retailer_contract"
                            value={item.retailer_contract}
                            onChange={e => handleInputChange(e, i)}
                            type="number"
                            placeholder="Ex: 1,99"
                            onBlur={validateBlur}
                            disabled={briefing?.status_code === 'F'}
                            data-name="CAMPO CONTRATO VAREJISTA OBRIGATÓRIO - INSERIR VALOR; CASO NÃO TENHA A INFORMAÇÃO INSERIR '0'"
                            onKeyDown={e =>
                              (e.keyCode === 189 ||
                                e.keyCode === 190 ||
                                e.keyCode === 69) &&
                              e.preventDefault()
                            }
                          />
                        </div>
                      ) */}
                      {/* hasField('payment_term') && (
                        <div className="normalSizeInput">
                          <Input
                            className="inputYellowItem"
                            name="payment_term"
                            value={item.payment_term}
                            onChange={e => handleInputChange(e, i, integerMask)}
                            type="text"
                            placeholder="Ex: 2000"
                            onBlur={validateBlur}
                            disabled={briefing?.status_code === 'F'}
                            data-name="CAMPO PRAZO DE PAGAMENTO OBRIGATÓRIO"
                          />
                        </div>
                      ) */}
                      {hasField('package_stock_volume') && (
                        <div className="normalSizeInput">
                          <Input
                            className="inputYellowItem"
                            name="package_stock_volume"
                            value={item.package_stock_volume}
                            onChange={e => handleInputChange(e, i, integerMask)}
                            type="text"
                            placeholder="Ex: 10"
                            onBlur={validateBlur}
                            disabled={briefing?.status_code === 'F' || isFieldDisabled}
                            data-name="CAMPO QUANTIDADE EM ESTOQUE OBRIGATÓRIO"
                          />
                        </div>
                      )}
                      {hasField('package_stock_date') && (
                        <div className="normalSizeInput">
                          <DatePicker
                            format="DD/MM/YYYY"
                            onChange={e =>
                              handleInputDateChange('package_stock_date', e, i)
                            }
                            value={item.package_stock_date}
                            placeholder="DD/MM/YYYY"
                            name="package_stock_date"
                            className="input12"
                            onBlur={validateBlur}
                            disabled={briefing?.status_code === 'F' || isFieldDisabled}
                            data-name="CAMPO DATA DO ESTOQUE OBRIGATÓRIO"
                            locale={{
                              sunday: 'D',
                              monday: 'S',
                              tuesday: 'T',
                              wednesday: 'Q',
                              thursday: 'Q',
                              friday: 'S',
                              saturday: 'S',
                              ok: 'OK',
                              today: 'Hoje',
                              yesterday: 'Ontem',
                              hours: 'Horas',
                              minutes: 'Minutos',
                              seconds: 'Segundos',
                            }}
                          />
                        </div>
                      )}
                      {hasField('refact_obs') && (
                        <div className="normalSizeInput">
                          <div
                            onClick={() => {
                              setModalOrientationId(i);
                              modalRefactObsRef.current?.toggle();
                            }}
                            style={{ paddingTop: '10px' }}
                            id="inputMagic"
                            className="inputYellowItem">
                            <span>Orientação da reformulação</span>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* grupo buttons options */}
                    <div
                      className="groupOptionsFinal"
                      style={{ flex: sizeGroups('options') }}>
                      {hasField('notes') && (
                        <div className="whiteSmallInput">
                          <button
                            type="button"
                            className="btnOption"
                            onClick={() => {
                              setTxtHeaderNotes('Notas');
                              setModalNotesFocus('item');
                              setModalNotesId(i);
                              modalNotesRef.current?.toggle();
                            }}>
                            <FaRegEdit size={20} />
                          </button>
                        </div>
                      )}
                      {hasField('cd_states') && (
                        <div className="whiteMedInput">
                          <button
                            type="button"
                            onClick={() => {
                              setModalCdDestinyId(i);
                              modalCdDestinyRef.current?.toggle();
                            }}
                            className="btnOption">
                            <RiRoadMapFill size={20} />
                          </button>
                          <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={tooltipDuplicateSku}>
                            <button
                              type="button"
                              className="btnOption"
                              onClick={() => {
                                setModalCdDestinyId(i);
                                modalCopyCdDestinyRef.current?.toggle();
                              }}
                              disabled={
                                inputList[i]?.cd_states?.length === 0 ||
                                briefing?.status_code === 'F'
                              }>
                              <MdContentCopy size={20} />
                            </button>
                          </Whisper>
                        </div>
                      )}
                      {!hasField('cd_states') && !hasField('notes') && (
                        <div
                          style={{
                            display: 'block',
                            width: '215px',
                            borderBottom: '2px dotted #999',
                          }}
                        />
                      )}
                      <PopoverAddAndExit
                        inputList={inputList}
                        index={i}
                        briefing={briefing}
                        handleDuplicateLine={handleDuplicateLine}
                        handleRemoveClick={handleRemoveClick}
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="btn-box">
              <button
                type="button"
                onClick={handleAddClick}
                className="moreButton"
                disabled={briefing?.status_code === 'F' || isFieldDisabled}>
                <img className="moreIconSky" src={MoreIcon} alt="Mais" />
                Adicionar Linha
              </button>
            </div>
          </div>

          <div>
            <div className="flex-space">
              <div className="getSuppliers">
              {/* Só permitir para Nova Embalagem, Reformulação ou Extensão */}
              {[2, 3, 4].includes(typeBriefing) && 
                <button
                  type="button"
                  id="inputMagicObs"
                  onClick={() => {
                    modalSuppliersRef.current?.toggle();
                  }}
                  className="inputGetSuppliers"
                  disabled={briefing?.status_code === 'F'}>
                  {/* botao selecionar fornecedor */}
                  <span>{supplierBtnTxt}</span>
                  <BiSearch color="fff" size={20} />
                </button>}
                <div id="tags" className="TagsSuppliers">
                  {suppliersPills}
                </div>
              </div>
              <div className="fileOnServerDiv">
                <div className="fileOnServer">
                  <button
                    disabled
                    className={classBtnDocs}
                    onClick={() => {
                      modalUploadDocRef.current?.toggle();
                    }}>
                    Documentos
                    <BiUpload
                      style={{
                        marginLeft: '5px',
                        width: '20px',
                        height: '19px',
                      }}
                    />
                  </button>
                  <ul className="listDocs">
                    {files.length === 0 && newFiles.length === 0 ? (
                      <li className="div-file m-t-1 flex-space with-row">
                        Sem documentos anexados
                      </li>
                    ) : (
                      <>
                        {files
                          .filter(fl => fl.available)
                          .map(fl => (
                            <li
                              key={generateUniqueKey()}
                              className="div-file m-t-1 flex-space with-row">
                              <a
                                href={fl.file}
                                target="_blank"
                                rel="noreferrer">
                                {fl.name}
                              </a>
                              <button
                                type="button"
                                className="pointer"
                                title="Remover arquivo"
                                onClick={() => handleDeleteFile(fl)}
                                disabled={briefing?.status_code === 'F' || isFieldDisabled}>
                                <BiX />
                              </button>
                            </li>
                          ))}
                        {newFiles.map(nf => (
                          <li
                            key={generateUniqueKey()}
                            className="div-file m-t-1 flex-space with-row">
                            {nf.name}
                            <button
                              type="button"
                              className="pointer"
                              title="Remover arquivo"
                              onClick={e => removeNewFile(nf)}>
                              <BiX />
                            </button>
                          </li>
                        ))}
                      </>
                    )}
                  </ul>
                  {/* <p className="typeFiles">
                    Tipos de Arquivo:{' '}
                    <strong>xls, xlsx, pdf, jpeg, doc, docx, jpg, png</strong>
                  </p>
                  <input
                    type="file"
                    accept=".xls,.xlsx,.pdf,.jpeg,.doc,.docx,.jpg,.png"
                    onChange={handleChangeFile}
                    multiple
                    name="uploader"
                    disabled={briefing?.status_code === 'F'}
                  />
                  {files.length === 0 ? (
                    <div className="div-file m-t-1 flex-space">
                      Sem Arquivos
                    </div>
                  ) : (
                    <>
                      {files
                        .filter(fl => fl.available)
                        .map(file => (
                          <div
                            className="div-file m-t-1 flex-space with-row"
                            key={`fl-${file.id}`}>
                            <a
                              rel="noreferrer"
                              href={file.file}
                              className="space-file"
                              target="_blank">
                              <span id={file.id}>{file.name}</span>
                              <span>
                                {convert(file.created_at) === ''
                                  ? 'test'
                                  : convert(file.created_at)}
                              </span>
                            </a>
                            <div className="flex-space">
                              <button
                                type="button"
                                id={file.id}
                                className="pointer"
                                onClick={e => handleDeleteFile(file.id)}
                                disabled={briefing?.status_code === 'F'}>
                                X
                              </button>
                            </div>
                          </div>
                        ))}
                    </>
                  )} */}
                </div>
              </div>
            </div>
            {typeBriefing === 5 && (
              <>
                <div
                  onClick={() => {
                    if (!isFieldDisabled)
                    modalChangeSuppRef.current?.toggleReason();
                  }}
                  className="inputGetSuppliers"
                  style={{cursor: isFieldDisabled ? 'not-allowed' : 'inherit'}}>
                  <span>Motivo da TROCA DE FORNECEDOR</span>
                </div>
                <>
                  {inputNew(inputReasonChgSupp, 6, inputReasonOtherChgSupp)}
                  <br />
                  <br />
                </>
              </>
            )}
            {typeBriefing !== 1 && (
              <>
                <div
                  onClick={() => {
                    if (!isFieldDisabled)
                    modalReasonRef.current?.toggleReason();
                  }}
                  className="inputGetSuppliers"
                  style={{cursor: isFieldDisabled ? 'not-allowed' : 'inherit'}}>
                  <span>Motivo da {headerText}</span>
                </div>
                {inputNew(inputReason, typeBriefing, inputReasonOther)}
              </>
            )}
            {/*id && (
              <div className="flex-end toCenter">
                {briefing?.stage_code !== 'C' && briefing?.stage_code !== 'F' && (
                  <div className="flex-buttons">
                    <button
                      disabled={briefing?.stage_code === 'B'}
                      id="btn-initial"
                      onClick={QuestionChangeStatusInitial}
                      className="btn-one">
                      Retomar
                    </button>
                    <button
                      id="btn-cancel"
                      onClick={QuestionChangeStatusCancel}
                      className="btn-one">
                      Cancelar
                    </button>
                    <button
                      id="btn-standBy"
                      onClick={QuestionChangeStatusStandBy}
                      className="btn-one">
                      Stand By
                    </button>
                  </div>
                )}
              </div>
            )*/}
          </div>
        </div>

        <div className="totalButtons">
          <div>
            {!isFieldDisabled && briefing?.id &&
              (briefingStatus.withoutPending ||
                briefingStatus.notApplicable) && (
                <button
                  disabled
                  onClick={() => modalBriefingDeleteRef.current?.toggle()}
                  className="actionBtnRed">
                  Excluir briefing
                </button>
              )}
          </div>
          <div className="mainButtons">
            <button onClick={closePage} className="actionBtnRed" disabled>
              Cancelar
            </button>
            <button
              disabled={briefing?.stage_code === 'C' || !changesMade}
              onClick={handleSubmitBriefing}
              type="button"
              className="buttonGreen">
              Salvar
            </button>
            {briefing?.stage_code === 'D' && (resultDti || devResponsible) && (
              <button
                onClick={changeApprovalDti}
                type="button"
                className="actionBtnBlue">
                Aprovação Técnica
              </button>
            )}
            {briefing?.stage_code === 'G' && briefingValidator && (
              <button
                onClick={changeApproval}
                type="button"
                className="actionBtnBlue">
                Aprovação do Gestor
              </button>
            )}
            {/*(briefing?.stage_code === 'E' ||
                briefing?.stage_code === 'A') && (
                <button
                  disabled
                  onClick={changeStatusFinal}
                  type="button"
                  className='actionBtnBlue'
                  >
                  Aguardando Análise do Sourcing
                </button>
                )*/}
            {briefing?.stage_code === 'H' && (
              <button
                onClick={handleSubmitEmail}
                type="button"
                className="actionBtnBlue">
                Enviar para Sourcing
              </button>
            )}
            {(briefing?.stage_code === 'B' || briefing?.stage_code === 'C') && (
              <button
                onClick={handleSubmitStartProspection}
                type="button"
                disabled={briefingStatus.approved || isFieldDisabled}
                className="actionBtnBlue">
                {typeBriefing === 1
                  ? 'Enviar para Aprovação de Cliente'
                  : 'Iniciar Prospecção'}
              </button>
            )}
            {briefing?.stage_code === 'V' && (
              <button
                onClick={handleSubmitToProspect}
                type="button"
                className="actionBtnBlue">
                {typeBriefing === 1
                  ? 'Enviar para Aprovação de Cliente'
                  : 'Aprovar e Enviar para Prospecção'}
              </button>
            )}
            {briefing?.stage_code === 'N' && (
              <button
                onClick={handleFinishWithoutProspection}
                type="button"
                className="actionBtnGreen">
                Concluir Briefing
              </button>
            )}
            {briefing?.stage_code === 'S' &&
              briefing?.prospection_type === 2 && (
                <span className="noBtnGrey">
                  Aguardando seleção de Fornecedor
                </span>
              )}
            {/*briefing &&
                !['E', 'A', 'H', 'B', 'C', 'V', 'P', 'F', 'N'].includes(
                  briefing.stage_code
                ) && (
                  <button
                    disabled
                    onClick={handleSubmitEmail}
                    type="button"
                    className='buttonBlue'
                    >
                    Iniciar Prospecção
                  </button>
                )*/}
          </div>
        </div>
      </form>
      <ModalRefactObs
        ref={modalRefactObsRef}
        inputRefactObs={inputList[modalOrientationId]?.refact_obs}
        setInputRefactObs={value =>
          handleInputChange(
            { target: { name: 'refact_obs', value } },
            modalOrientationId
          )
        }
      />
      <ModalNotes
        ref={modalNotesRef}
        header={txtHeaderNotes}
        inputNote={
          modalNotesFocus === 'general'
            ? textAreaObs
            : inputList[modalNotesId]?.notes
        }
        setInputNote={value => {
          if (modalNotesFocus === 'general') {
            setTextAreaObs(value);
          } else {
            handleInputChange(
              { target: { name: 'notes', value } },
              modalNotesId
            );
          }
        }}
        statusCode={briefing?.status_code}
      />
      <ModalCdDestiny
        ref={modalCdDestinyRef}
        cdStates={inputList[modalCdDestinyId]?.cd_states || []}
        setCdStates={value => {
          const list = [...inputList];
          list[modalCdDestinyId]['cd_states'] = [...value];
          setInputList(list);
        }}
        statusCode={briefing?.status_code}
      />
      <ModalCopyCdDestiny
        ref={modalCopyCdDestinyRef}
        duplicateSkus={duplicateSkus}
      />
      <ModalSuppliers
        ref={modalSuppliersRef}
        suppliersArray={suppliersArray}
        setSuppliersArray={setSuppliersArray}
        briefing={briefing}
        managers={managers}
        emailUser={emailUser}
      />
      <ModalCategory
        ref={modalCategoryRef}
        inputCategory={inputDivision}
        setInputCategory={setInputDivision}
        setProductsList={setProductsList}
        productsList={productsList}
        setUpperLevels={setUpperLevels}
        onClosed={() => {
          validateButtonOnCloseModal({
            classEl: '.btnInputCategory',
            value: inputDivision,
            msg: 'CAMPO CATEGORIA OBRIGATÓRIO - SELECIONE A CATEGORIA DO PROJETO',
          });
        }}
      />
      {typeBriefing !== 1 && (
        <ModalReasons
          ref={modalReasonRef}
          reasonList={reasonLists[typeBriefing]}
          headerText={headerText}
          inputReason={inputReason}
          setInputReason={setInputReason}
          inputReasonOther={inputReasonOther}
          setInputReasonOther={setInputReasonOther}
          statusCode={briefing?.status_code}
        />
      )}
      {typeBriefing === 5 && (
        <ModalReasons
          ref={modalChangeSuppRef}
          reasonList={reasonLists[6]}
          headerText={'TROCA DE FORNECEDOR'}
          inputReason={inputReasonChgSupp}
          setInputReason={setInputReasonChgSupp}
          inputReasonOther={inputReasonOtherChgSupp}
          setInputReasonOther={setInputReasonOtherChgSupp}
          statusCode={briefing?.status_code}
        />
      )}
      {inputList.length > 0 && (
        <ModalWeight
          ref={modalWeightRef}
          inputWeight={inputList[selectedWeight]?.weight}
          setInputWeight={w => {
            const list = [...inputList];
            list[selectedWeight].weight = w;
            setInputList(list);
          }}
          onClosed={() =>
            validateButtonOnCloseModal({
              classEl: `.weight-${selectedWeight}`,
              value: inputList[selectedWeight].weight,
              msg: 'CAMPO GRAMATURA OBRIGATÓRIO - SELECIONE A UNIDADE DE MEDIDA.',
            })
          }
        />
      )}
      <ModalSaving isOpen={modalSaving} />
      <ModalUploadDoc
        ref={modalUploadDocRef}
        newFiles={newFiles}
        setNewFiles={setNewFiles}
        onClosed={updateClassBtnDocs}
      />
      <ModalBriefingReview
        ref={modalBriefingReviewRef}
        status={showCorrectStatus}
        briefingStatus={briefingStatus}
        reviewBriefing={reviewBriefing}
      />
      <ModalBriefingDelete
        ref={modalBriefingDeleteRef}
        onConfirm={handleDeleteBriefing}
        loading={loading.deleteBriefing}
      />
    </>
  );
};

export default BriefingForm;
