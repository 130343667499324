import React, {
    useState,
    useImperativeHandle,
    forwardRef }
from 'react';
import { Button, Modal } from 'reactstrap';
import { timeStampToLocale, toTitleCase } from '../../../../../utils/utils';
import './ModalSkuChangeRecord.scss';

const ModalSkuChangeRecord = ({ data }, ref) => {

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  return (
    <>
      <Modal isOpen={showModal} toggle={toggleModal} centered className="change-record">
        <p className="header-change-record">Alterações nos SKUs</p>
        <div className="content-change-record">
          {data?.length === 0 && 
            <p className="no-changes">Nenhum dado encontrado.</p>
          }
          {data?.map(record => (
            <div key={record.id} className="change-record-main">
              <p
                className={`title-change-record ${
                  record.reason_type === 'Edition' ? 'edition' : 'deletion'
                }`}>
                {record.reason_type === 'Edition' ? 'Edição' : 'Cancelamento'} de
                SKU - SKU {record.project_sku}
              </p>
              <div className="div-module-stage">
                <p>
                  <strong>Módulo:</strong> {toTitleCase(record.module_edit)}
                </p>
                <p>
                  <strong>Etapa:</strong> {toTitleCase(record.stage_edit)}
                </p>
              </div>
              <p className="description-change">{record.reason}</p>
              <p className="date-change">
                Feito em: {timeStampToLocale(record.edition_date)}
              </p>
            </div>
          ))}
        </div>

        <div className="div-button-change-record">
          <Button onClick={toggleModal} className="button-sku-close">
            Fechar
          </Button>
        </div>
      </Modal>
    </>
  )
};

export default forwardRef(ModalSkuChangeRecord);
