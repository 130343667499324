import React from 'react';
import { classNames } from '../../../utils/utils';
// import ReactGA from 'react-ga';
import { useCognitoAuth } from '../../../Contexts/AuthCognitoContext';
import styles from './style.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Input, Col } from 'rsuite';

function ForgoutPasswordCognito({ setUsernameSubmit }) {
  // ReactGA.pageview('/login');
  const { forgotPassword, loading, setStepForgotPassword } = useCognitoAuth();
  const validationSchema = Yup.object({
    username: Yup.string().required('Campo Obrigatório'),
  });

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      username: '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: values => {
      setUsernameSubmit(values.username);
      forgotPassword(values.username);
    },
  });

  return (
    <>
      <div className={styles.loginInfo}>
        <p>Recuperar Senha</p>
      </div>
      <form className={styles.formLogin} autoComplete="off">
        <Input
          type="text"
          name="email"
          placeholder="EMAIL"
          onChange={handleChange('username')}
          className={styles.inputLogin}
          value={values.username}
          autoComplete="off"
        />
        <Row className={styles.space}>
          <span className={styles.error}>
            {errors.username ? errors.username : null}
          </span>
        </Row>
        <Row>
          <Col md={8} className={styles.send}>
            <Button
              block
              onClick={() => setStepForgotPassword(0)}
              className={styles.buttonSentBack}>
              Voltar
            </Button>
          </Col>
          <Col md={8} mdPush={8} className={styles.send}>
            <Button
              block
              type="submit"
              onClick={handleSubmit}
              className={classNames({
                [styles.buttonSent]: !loading, //always applies
                [styles.buttonSentLoading]: loading, //only when open === true
              })}
              loading={loading}>
              Enviar
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
}

export default ForgoutPasswordCognito;
