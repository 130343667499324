/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import '../sourcing.css';
import moment from 'moment';
import { useParams } from 'react-router';
import Table from 'reactstrap/lib/Table';
import ModalProviderDetail from './ModalProviderDetail';
import ModalProductDetail from './ModalProductDetail';
import ModalProviderSearch from './ModalProviderSearch';
import { ReactComponent as FilterIcon } from '../../../../Assets/config-slider.svg';
import back_icon from '../../../../Assets/back_button.png';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import HeaderModule from '../../../../Components/HeaderModule/HeaderModule';
import MenuPdm from '../../../../Components/Sidebar/MenuPdm';
import Pagination from '../../../../Components/Pagination/Pagination';
import { useCognitoAuth } from '../../../../Contexts/AuthCognitoContext';
import { useBriefingContext } from '../../../../Contexts/BriefingContext';
import apiConnectSuppliersDB from '../../../../services/apiConnectSuppliersDB';
import { Alert, Button, Dropdown } from 'rsuite';
import useDebounce from '../../../../utils/hooks/useDebounce';
import cnpjMask from '../../../../utils/masks/cnpjMask';
import { ReactComponent as LinkedIcon } from '../Assets/icons/color-circ-linked.svg';
import { ReactComponent as LinkedDisabledIcon } from '../Assets/icons/color-circ-linked-inactive-grey.svg';
import { ReactComponent as ReleasedWithAmicciIcon } from '../Assets/icons/color-circ-released-with-amicci.svg';
import { ReactComponent as NotReleasedWithAmicciIcon } from '../Assets/icons/color-circ-not-released-with-amicci.svg';
import { ReactComponent as MadeOwnBrandIcon } from '../Assets/icons/color-circ-made-own-brand.svg';
import { ReactComponent as MadeOwnBrandDisabledIcon } from '../Assets/icons/color-circ-made-own-brand-inactive-grey.svg';
import { ReactComponent as NotInterestedIcon } from '../Assets/icons/color-circ-not-interested.svg';
import { ReactComponent as NotInterestedDisabledIcon } from '../Assets/icons/color-circ-not-interested-inactive-grey.svg';
import { ReactComponent as AuditedSupplierIcon } from '../Assets/icons/color-circ-audited-supplier.svg';
import { ReactComponent as AuditedSupplierDisabledIcon } from '../Assets/icons/color-circ-audited-supplier-inactive-grey.svg';
import { ReactComponent as RatSupplierIcon } from '../Assets/icons/color-circ-RAT-supplier.svg';
import { ReactComponent as RatSupplierDisabledIcon } from '../Assets/icons/color-circ-RAT-supplier-inactive-grey.svg';
import { ReactComponent as BeenInCompetitionIcon } from '../Assets/icons/color-circ-been-in-competition.svg';
import { ReactComponent as BeenInCompetitionDisabledIcon } from '../Assets/icons/color-circ-been-in-competition-inactive-grey.svg';

function ProviderSearch() {
  const { id } = useParams();
  const emptyProviderFilter = {
    name: '',
    states: [],
    segment: '',
    make_mp: '',
    amicci_products: '',
    has_products: '',
    linked: '',
    has_rat: '',
  };
  const {
    user: {
      attributes: { email },
    },
  } = useCognitoAuth();
  const isInitialMount = useRef(true);
  const modalProviderSearchRef = useRef(null);
  const { user, getUser } = useBriefingContext();

  const [searchType, setSearchType] = useState('provider'); // product
  const [providerTerm, setProviderTerm] = useState('');
  const debounceProviderTerm = useDebounce(providerTerm, 600);
  const [filterProvider, setFilterProvider] = useState({
    ...emptyProviderFilter,
  });
  const [providerParams, setProviderParams] = useState({
    ...emptyProviderFilter,
  });
  const [productTerm, setProductTerm] = useState('');
  const debounceProductTerm = useDebounce(productTerm, 600);
  const [searchedTermProduct, setSearchedTermProduct] = useState('');

  const [totalPosts1, setTotalPosts1] = useState(0);
  const [currentPage1, setCurrentPage1] = useState(1);
  const postsPerPage1 = 7;
  const [sourceSupplierId1, setSourceSupplierId1] = useState([]);

  const [totalPosts2, setTotalPosts2] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(1);
  const postsPerPage2 = 7;
  const [sourceSupplierId2, setSourceSupplierId2] = useState([]);

  // const [collapse, setCollapse] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState([]);

  useEffect(() => {
    verifySelectedProviders();

    if (!user && email) {
      getUser(email);
    }
  }, []);

  const getHeaderSuppliersDb = () => ({
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem(
        'suppliers-db-token'
      )}`,
    },
  });

  /**
   * transforma estado do filtro em objeto pronto
   * para query de busca
   *
   * @param filter estado do filtro
   */
  const updateSearchedProvider = filter => {
    setProviderParams({
      ...filter,
      states: filter.states.join(','),
    });
  };

  /*
    verifica lista de provider passada pelo url 
    e seta como selecionados
   */
  const verifySelectedProviders = () => {
    const queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      if (urlParams) {
        const providers = urlParams.get('providers');
        if (providers) {
          const ids = providers.split(',').map(n => parseInt(n));
          if (ids.length > 0) {
            setSelectedProviders(ids);
          }
        }
      }
    }
  };

  const searchProviders = useCallback(() => {
    const filterData = {
      ...filterProvider,
      name: debounceProviderTerm,
    };

    setTotalPosts1(0);
    setSourceSupplierId1([]);
    setCurrentPage1(1);
    updateSearchedProvider(filterData);
  }, [filterProvider, debounceProviderTerm]);

  const searchProducts = useCallback(() => {
    setTotalPosts2(0);
    setSourceSupplierId2([]);
    setCurrentPage2(1);
    setSearchedTermProduct(debounceProductTerm);
  }, [debounceProductTerm]);

  const loadProviders = useCallback(() => {
    const url = '/provider/list';
    const config = {
      ...getHeaderSuppliersDb(),
      params: {
        ...providerParams,
        page: currentPage1 || 1,
      },
    };

    apiConnectSuppliersDB
      .get(url, config)
      .then(resp => {
        setSourceSupplierId1(resp.data.results);
        setTotalPosts1(resp.data.count);
      })
      .catch(e => {
        Alert.error('Falha ao consultar fornecedores.');
      });
  }, [currentPage1, providerParams]);

  const loadProducts = useCallback(() => {
    const url = '/product/list';
    const config = {
      ...getHeaderSuppliersDb(),
      params: {
        name: searchedTermProduct,
        page: currentPage2 || 1,
      },
    };

    apiConnectSuppliersDB
      .get(url, config)
      .then(resp => {
        setSourceSupplierId2(resp.data.results);
        setTotalPosts2(resp.data.count);
      })
      .catch(e => {
        Alert.error('Falha ao consultar produtos.');
      });
  }, [currentPage2, searchedTermProduct]);

  const commentsData1 = useMemo(() => {
    return sourceSupplierId1;
  }, [sourceSupplierId1]);

  const commentsData2 = useMemo(() => {
    return sourceSupplierId2;
  }, [sourceSupplierId2]);

  const handleUpdateSelectedProviders = (id, checked) => {
    if (checked) {
      setSelectedProviders(s => [...s, id]);
    } else {
      setSelectedProviders(s => [...s.filter(v => v !== id)]);
    }
  };

  const addProviders = () => {
    const ids = selectedProviders.join(',');
    console.log('ids', ids);
    window.location.href = `/pdm/sourcing/${id}?providers=${ids}`;
  };

  const openFilterModal = () => {
    modalProviderSearchRef?.current?.toggle();
  };

  const modalFornecedor = document.getElementsByClassName('new');
  const modalProduto = document.getElementsByClassName('new1');
  const Produ = document.getElementById('Produ');
  const Force = document.getElementById('Force');
  const btnFornecedor = document.getElementById('myBtnFornecedor');
  const btnProduto = document.getElementById('myBtnProduto');
  const iconsLegend = document.getElementById('icons-legend-wrapper');
  const adicibtn = document.getElementById('adici');
  // var span = document.getElementsByClassName('close')[0];

  function fo(event) {
    //alert(event.target.id);
    for (let i = 0; i < modalFornecedor.length; i += 1) {
      if (modalFornecedor[i].getAttribute('id') === event.target.id) {
        modalFornecedor[i].style.display = 'block';
      }
    }
  }
  // modalProduto.style.display = "none"

  function po(event) {
    // modalProduto.style.display = "block"
    //alert(event.target.id);
    for (var i = 0; i < modalProduto.length; i += 1) {
      if (modalProduto[i].getAttribute('id') === event.target.id) {
        modalProduto[i].style.display = 'block';
      }
    }
    //test1.style.display = "none"
  }

  function force() {
    // var x = Force.innerHTML;
    Force.style.display = 'inline-table';
    Produ.style.display = 'none';
    iconsLegend.style.display = 'block';
    btnFornecedor.classList.remove('btnGray');
    btnFornecedor.classList.add('btnBlue');
    btnProduto.classList.add('btnGray');
    adicibtn.classList.remove('hideAdiBtn');
    setSearchType('provider');
  }

  function pro() {
    // var y = Produ.innerHTML;
    iconsLegend.style.display = 'none';
    adicibtn.classList.add('hideAdiBtn');
    Produ.style.display = 'inline-table';
    Force.style.display = 'none';
    btnFornecedor.classList.add('btnGray');
    btnProduto.classList.remove('btnGray');
    btnProduto.classList.add('btnBlue');
    setSearchType('product');
  }

  // var closeFornecedor = document.getElementById('closeFornecedor');
  // var closeProduto = document.getElementById('closeProduto');

  window.onclick = function (event) {
    if (event.target === modalFornecedor) {
      modalFornecedor.style.display = 'none';
    }
  };

  window.onclick = function (event) {
    if (event.target === modalProduto) {
      modalProduto.style.display = 'none';
    }
  };

  const inputSearchProvider = useMemo(() => {
    if (searchType === 'provider') {
      return (
        <>
          <button type="button" className="floatLeft searchButtonLeft m-left">
            <i className="fa fa-search"></i>
          </button>
          <input
            type="text"
            placeholder="Buscar fornecedores"
            className="floatLeft searchTerm no-rad"
            value={providerTerm}
            onChange={event => {
              setProviderTerm(event.target.value);
            }}
          />
          <button className="searchButton" onClick={openFilterModal}>
            <FilterIcon />
          </button>
        </>
      );
    }
    return null;
  }, [searchType, providerTerm]);

  const inputSearchProduct = useMemo(() => {
    if (searchType === 'product') {
      return (
        <>
          <input
            type="text"
            placeholder="Buscar produtos"
            className="floatLeft searchTerm m-left"
            value={productTerm}
            onChange={event => {
              setProductTerm(event.target.value);
            }}
          />
          <button className="searchButton">
            <i className="fa fa-search"></i>
          </button>
        </>
      );
    }
    return null;
  }, [searchType, productTerm]);

  const getIndicatorIcons = provider => {
    return (
      <div className="icons-provider">
        {/* participou de competição */}
        {provider?.been_in_competition ? (
          <BeenInCompetitionIcon />
        ) : (
          <BeenInCompetitionDisabledIcon />
        )}

        {/* não tem interesse em MP */}
        {!provider?.has_interest_mp ? (
          <NotInterestedIcon />
        ) : (
          <NotInterestedDisabledIcon />
        )}

        {/* faz MP */}
        {provider?.make_mp?.id === 1 ? (
          <MadeOwnBrandIcon />
        ) : (
          <MadeOwnBrandDisabledIcon />
        )}

        {/* fornecedor vinculado */}
        {provider.is_seller_linked ? <LinkedIcon /> : <LinkedDisabledIcon />}

        {/* fornecedor com produtos analisados (RAT) */}
        {provider?.has_rat ? <RatSupplierIcon /> : <RatSupplierDisabledIcon />}

        {/* fornecedor tem produto amicci */}
        {provider?.has_amicci_products ? (
          <ReleasedWithAmicciIcon />
        ) : (
          <NotReleasedWithAmicciIcon />
        )}

        {/* fornecedor auditado */}
        {provider?.is_audited ? (
          <AuditedSupplierIcon />
        ) : (
          <AuditedSupplierDisabledIcon />
        )}
      </div>
    );
  };

  /* Inicialização */
  useEffect(() => {
    loadProviders();
  }, [loadProviders]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  /* Busca automática ao mudar campo de busca */
  useEffect(() => {
    if (!isInitialMount.current) searchProviders();
  }, [debounceProviderTerm]);

  useEffect(() => {
    if (!isInitialMount.current) searchProducts();
  }, [debounceProductTerm]);

  /* Lida com a atualização do ref de verificação de montagem */
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  }, []);

  return (
    <>
      <HeaderModule classHeader="pdmHeaderModule">Sourcing</HeaderModule>
      <Sidebar>
        <MenuPdm opened="sourcing" />
      </Sidebar>

      <div className="mainDiv">
        <a href={`/pdm/sourcing/${id}`} className="floatRight closeButton">
          Voltar <img alt="voltar" src={back_icon} className="backIcon" />
        </a>
        <br clear="all" />

        <button
          className="floatLeft btnBlue"
          id="myBtnFornecedor"
          onClick={force}>
          Fornecedor
        </button>
        <button
          className="floatLeft btnGray m-left"
          id="myBtnProduto"
          onClick={pro}>
          Tipos de Produto
        </button>
        {inputSearchProvider}
        {inputSearchProduct}
        <br clear="all" />
        <br clear="all" />
        <div id="section">
          <div id="icons-legend-wrapper">
            <p className="icons-legend">
              <LinkedIcon />
              <span>Fornecedor Vinculado</span>

              <MadeOwnBrandIcon />
              <span>Faz marca própria</span>

              <NotInterestedIcon />
              <span>Não tem interesse em MP</span>

              <ReleasedWithAmicciIcon />
              <span>Produtos com Amicci</span>

              <NotReleasedWithAmicciIcon />
              <span>Sem produtos com Amicci</span>
            </p>
            <p className="icons-legend">
              <AuditedSupplierIcon />
              <span>Fornecedor Auditado</span>

              <RatSupplierIcon />
              <span>Fornecedor com produtos analisados (RAT)</span>

              <BeenInCompetitionIcon />
              <span>Participou de concorrência</span>
            </p>
          </div>
          <Table id="Force" className="supplier_checklist">
            <tbody>
              {commentsData1 &&
                commentsData1.map(item => {
                  return (
                    <tr key={`tr1-${item.id}`}>
                      <td className="provider-search-item">
                        <div className="data-col col-8 m-0 p-0">
                          <input
                            className="provider-check"
                            type="checkbox"
                            name="key"
                            value={item.supplier}
                            defaultChecked={selectedProviders.includes(item.id)}
                            onChange={ev =>
                              handleUpdateSelectedProviders(
                                item.id,
                                ev.target.checked
                              )
                            }
                          />
                          <div className="p-0 col-6 pr-2">
                            <p>
                              Nome:{' '}
                              <span className="prov-name">{item.name}</span>
                              <ModalProviderDetail
                                id={item.id}
                                name={item.name}
                              />
                            </p>
                            <p>
                              CNPJ:{' '}
                              {item?.cnpj ? (
                                <Dropdown
                                  title={cnpjMask(item?.cnpj)}
                                  toggleComponentClass={Button}
                                  appearance="default"
                                  className="prov-cnpj-dropdown">
                                  <div className="d-flex mt-1">
                                    <div className="col">CNPJ</div>
                                    <div className="col text-right">UF</div>
                                  </div>
                                  <hr className="my-2" />
                                  <div className="d-flex my-3 matriz">
                                    <div className="col">
                                      {cnpjMask(item?.cnpj)}
                                    </div>
                                    <div className="col">
                                      {item?.uf?.acronym || '-'}
                                    </div>
                                  </div>
                                  {item?.cnpj_list?.map(a => (
                                    <div key={a?.cnpj} className="d-flex my-3">
                                      <div className="col">{a?.cnpj}</div>
                                      <div className="col">
                                        {item?.uf?.acronym || '-'}
                                      </div>
                                    </div>
                                  ))}
                                </Dropdown>
                              ) : (
                                <span>Não informado</span>
                              )}
                            </p>
                          </div>
                          <div className="pl-0 col-6">
                            <p>
                              Razão Social:{' '}
                              <span className="prov-comp-name">
                                {item.company_name || 'Não informada'}
                              </span>
                            </p>
                            <p>
                              Estado:{' '}
                              <span className="prov-state">
                                {item?.uf?.acronym || '-'}
                              </span>{' '}
                              | Segmento:{' '}
                              <span className="prov-segment">
                                {item.segment || 'Não informado'}
                              </span>
                            </p>
                          </div>
                        </div>

                        <div className="data-col col-4 decorated flex-wrap">
                          <p>
                            Categorias Produzidas:{' '}
                            <span>{item?.product_count || 0}</span>
                          </p>

                          <p>
                            Data da última atualização:{' '}
                            <span>
                              {moment(item?.modified_at).format('DD/MM/YYYY') ||
                                '-'}
                            </span>
                          </p>
                        </div>

                        <button
                          className="details-button mx-2"
                          onClick={fo}
                          id={item.id}>
                          Acessar
                        </button>

                        {getIndicatorIcons(item)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tbody className="border-0">
              <tr>
                <td className="border-0">
                  <button
                    disabled={selectedProviders.length === 0}
                    id="adici"
                    className="btnGray btnAdd"
                    onClick={addProviders}>
                    Adicionar
                  </button>
                </td>
              </tr>
            </tbody>
          </Table>

          <Table id="Produ" className="pro-none mt-3">
            <tbody>
              {commentsData2 &&
                commentsData2.map(sup => {
                  return (
                    <tr key={`tr2-${sup.id}`}>
                      <td className="provider-search-item">
                        <div className="data-col col-8 m-0 p-0">
                          <div className="p-0 col-12 pr-2">
                            <p>
                              Nome:{' '}
                              <span className="highlight font-weight-bold">
                                {sup.product_type}
                              </span>
                              <ModalProductDetail
                                id={sup.id}
                                name={sup.product_type}
                              />
                            </p>
                            <p>
                              Número de fornecedores atrelados:{' '}
                              <span className="highlight font-weight-bold">
                                {sup.provider_count || 0}
                              </span>
                            </p>
                          </div>
                        </div>

                        <button
                          className="details-button mx-2"
                          id={sup.id}
                          onClick={po}>
                          Acessar
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>

        {searchType === 'provider' ? (
          <Pagination
            pages={Math.ceil(totalPosts1 / postsPerPage1)}
            currentPage={currentPage1}
            setCurrentPage={setCurrentPage1}
          />
        ) : (
          <Pagination
            pages={Math.ceil(totalPosts2 / postsPerPage2)}
            currentPage={currentPage2}
            setCurrentPage={setCurrentPage2}
          />
        )}
      </div>
      <ModalProviderSearch
        ref={modalProviderSearchRef}
        emptyProviderFilter={emptyProviderFilter}
        searchFilterProvider={filterProvider}
        setSearchFilterProvider={setFilterProvider}
        searchProviders={searchProviders}
      />
    </>
  );
}

export default ProviderSearch;
