import React from 'react';
import { classNames } from '../../../../../../utils/utils';
// import ReactGA from 'react-ga';
import styles from './styles.module.css';
import { Icon, Message } from 'rsuite';
import moment from 'moment';
import Table from 'reactstrap/lib/Table';
import ItemDemand from './Components/ItemDemand';

function ListDemands({ summary, setStep, setType }) {
  // ReactGA.pageview('/pdm');

  return (
    <div className={styles.mainDiv}>
      <Table hover borderless className={styles.tableDemands}>
        <thead>
          <tr>
            <th>
              Projeto: <span>{summary?.project_name}</span>
            </th>
            <th>
              Data de início:{' '}
              <span>
                {moment(summary?.project_started_at).format('DD/MM/yyyy')}
              </span>
            </th>
            <th>
              Total de notas Atribuídas:{' '}
              <span>{summary?.total_notes} Notas</span>
            </th>
            <th>
              <span
                onClick={() => setStep(1)}
                className={classNames(styles.closeButton)}>
                Voltar
                <Icon
                  className={classNames(styles.icon)}
                  icon="sign-in"
                  size="lg"
                />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {summary?.notes.map((ob, i) => {
            return (
              <ItemDemand
                key={`${i}-task`}
                task={ob}
                setStep={setStep}
                setType={setType}
              />
            );
          })}
        </tbody>
      </Table>

      {summary.notes.length === 0 && (
        <div>
          <Message
            style={{ textAlign: 'center' }}
            type="warning"
            description="Sem resultados"
          />
        </div>
      )}
    </div>
  );
}

export default ListDemands;
