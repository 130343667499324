import React, { forwardRef, useImperativeHandle } from 'react';
import './ModalCopyCdDestiny.scss';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import useToggle from '../../../../../../utils/hooks/useToggle';

const ModalCopyCdDestiny = ({ duplicateSkus }, ref) => {
  const [show, toggle] = useToggle();

  useImperativeHandle(ref, () => ({ toggle }));

  const duplicate = () => {
    if (duplicateSkus) {
      duplicateSkus();
    }
    toggle();
  };

  return (
    <Modal
      className="modal-cd-destiny-sku"
      size="sm"
      toggle={toggle}
      isOpen={show}>
      <ModalHeader toggle={toggle}>CD Destino</ModalHeader>
      <ModalBody className="specialPaddingModal">
        <p className="paragrafo-modal-body">
          Deseja copiar o destino desta SKu para todas as outras?
        </p>
        <div className="hold-all-sku">
          <button onClick={toggle} className="button-cancelar">
            Cancelar
          </button>
          <button onClick={duplicate} className="button-confirmar">
            Confirmar
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default forwardRef(ModalCopyCdDestiny);
