import React, { useState, useEffect, createContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import HomeDash from './HomeDash';
import './Indicators.css';
import apiConnect from '../../services/apiConnect';
import Sidebar from '../../Components/Sidebar/Sidebar';
import MenuIndicators from '../../Components/Sidebar/MenuIndicators';
import GraphIndicatorsPage from './GraphIndicatorsPage';

const IndicatorsContext = createContext([[], () => {}]);

function Indicators() {
  const [indicators, setIndicators] = useState([]);

  useEffect(() => {
    apiConnect
      .get('/indicators', {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        },
      })
      .then(resp => {
        // console.log('resp', resp);
        if (resp?.data.length > 0) {
          setIndicators(resp.data);
        }
      });
  }, []);

  return (
    <>
      <Header classHeader="header-indicadores" />
      <IndicatorsContext.Provider value={[indicators, setIndicators]}>
        {/* <TokenRefresh /> */}
        <Sidebar link="/indicadores" title="INDICADORES">
          <MenuIndicators />
        </Sidebar>
        <main>
          <Routes>
            <Route exact path="/" element={<HomeDash />} />
            <Route path="/grafico/:name" element={<GraphIndicatorsPage />} />
          </Routes>
        </main>
      </IndicatorsContext.Provider>
    </>
  );
}

export { IndicatorsContext };
export default Indicators;
