import React, { useRef } from 'react';
// import ReactGA from 'react-ga';
import styles from './styles.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Col, Button, Modal, Icon, Row, Container, Alert, Form } from 'rsuite';
import apiConnectSourcing from '../../../../../../../../services/apiConnectSourcing';
import { useCognitoAuth } from '../../../../../../../../Contexts/AuthCognitoContext';

function ModalProspectionSimulator({
  show,
  closeModal,
  prospection,
  setProspection,
}) {
  // ReactGA.pageview('/pdm');
  const validationSchema = Yup.object({
    notes: Yup.string().required('Campo Obrigatório'),
    file: Yup.string().required('Campo Obrigatório').nullable(),
  });
  const [loadingSubmit, setLoadingSubmit] = React.useState(false);

  const filesElement = useRef(null);
  const { user } = useCognitoAuth();

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      file: '',
      notes: '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: values => {
      setLoadingSubmit(true);
      const formData = new FormData();
      formData.append('file', values?.file);
      formData.append('note', values?.notes);
      formData.append('cost_sheets_data', JSON.stringify(prospection?.cost_sheets_data));
      formData.append('user', JSON.stringify(user));
      apiConnectSourcing
        .post(`prospections/${prospection?.id}/send-simulation/`, formData)
        .then(response => {
          if (response.data) {
            setProspection({
              ...prospection,
              status: { id: 5, name: 'Finalizado' },
              costSimulatorFile: response.data,
            });
            Alert.success('Simulação cadastrado com sucesso!');
          } else {
            Alert.error(
              'Aconteceu um erro inesperado. Tente novamente, mais tarde!'
            );
          }
          handleCancel();
          setLoadingSubmit(false);
        })
        .catch(error => {
          if (error.response?.status === 403) {
            Alert.error(error.response.data.message, 50000);
          } else {
            Alert.error(
              'Aconteceu um erro inesperado. Tente novamente, mais tarde!'
            );
          }

          setLoadingSubmit(false);
        });
    },
  });

  const handleCancel = () => {
    closeModal();
    resetForm();
  };

  const saveFile = event => {
    setFieldValue('file', event.target.files[0]);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Modal show={show} onHide={closeModal}>
          <Modal.Header>
            <Container>
              <Row className={styles.iconTittle}>
                <Col className={styles.modalIcon} md={5}>
                  <Icon icon="bell-o" size="lg" />
                </Col>
                <Col className={styles.modalTittle} md={19}>
                  Simulador de Custos
                </Col>
              </Row>
            </Container>
          </Modal.Header>

          <Modal.Body className={styles.body}>
            <Container>
              <Row>
                <Col md={24}>
                  <label className={styles.modalDescription}>Informações</label>
                  <textarea
                    value={values.notes}
                    placeholder="Digite aqui"
                    className={styles.bodyCard}
                    onChange={handleChange('notes')}
                  />
                  <span className={styles.error}>
                    {errors.notes ? errors.notes : null}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <label className={styles.modalDescription}>Arquivo</label>
                  <div className={styles.cardFiles}>
                    {values.file && (
                      <>
                        <strong style={{ marginRight: '5px' }}>
                          {values.file?.name}
                        </strong>
                      </>
                    )}
                  </div>
                  <span className={styles.error}>
                    {errors.file ? errors.file : null}
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <input
                  onChange={event => {
                    saveFile(event);
                  }}
                  hidden
                  type="file"
                  ref={filesElement}
                />

                <Col md={8} mdOffset={16}>
                  <Button
                    block
                    className={styles.buttonUpload}
                    onClick={() => filesElement.current.click()}>
                    Upload
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <hr></hr>
            <Row>
              <Col md={8} mdOffset={16}>
                <Row>
                  <Col md={12}>
                    <Button
                      block
                      type="submit"
                      onClick={handleSubmit}
                      appearance="primary"
                      loading={loadingSubmit}>
                      Confirmar
                    </Button>
                  </Col>
                  <Col md={12}>
                    <Button onClick={handleCancel} appearance="subtle">
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
}

export default ModalProspectionSimulator;
