export const verifyLocalStorage = name => {
  const local = localStorage.getItem(name);
  if (!local) {
    return null;
  }
  return JSON.parse(local);
};

export const clearLocalStorage = name => {
  localStorage.removeItem(name);
};

export const addLocalStorage = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export function classNames(classesObject) {
  const classes = [];

  Object.keys(classesObject).forEach(key => {
    if (classesObject[key]) classes.push(key);
  });

  return classes.join(' ');
}

export function generateUniqueKey() {
  return (new Date().getTime() + Math.random()).toString(16);
}

// retorna a data no formato yyyy-mm-ddThh:mm:ss.Z respeitando a timezone
export function dateToLocalJSON(_d = new Date()) {
  const d = new Date(_d); // para nao alterar a data original
  d.setHours(d.getHours() + d.getTimezoneOffset() / -60);
  return d.toJSON();
}

// converte data string (yyyy-mm-dd) em Date respeitando timezone
export function dateStringToTimezoneDate(_d) {
  const t = new Date().getTimezoneOffset(); // pega o timezone local
  const d = new Date(_d);
  d.setHours(d.getHours() + t / 60);
  return d;
}

export async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function validateCNPJ(cnpj = '') {
  if (cnpj) {
    cnpj = cnpj.replace(/\D/g, '');

    // se tiver menos de 14 numeros
    if (cnpj.length !== 14) return false;

    // se todos os numeros forem iguais
    const allCharsEquals =
      cnpj.split('').filter((item, pos, self) => self.indexOf(item) === pos)
        .length === 1;

    if (allCharsEquals) return false;

    let len = cnpj.length - 2;
    let num = cnpj.substring(0, len);
    const dig = cnpj.substring(len);
    let sum = 0;
    let pos = len - 7;

    for (let i = len; i >= 1; i--) {
      sum += parseInt(num.charAt(len - i), 10) * pos--;
      if (pos < 2) pos = 9;
    }

    let res = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (res !== parseInt(dig.charAt(0), 10)) return false;

    len += 1;
    num = cnpj.substring(0, len);
    sum = 0;
    pos = len - 7;

    for (let i = len; i >= 1; i--) {
      sum += parseInt(num.charAt(len - i), 10) * pos--;
      if (pos < 2) pos = 9;
    }

    res = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (res !== parseInt(dig.charAt(1), 10)) return false;

    return true;
  }
}

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export function timeStampToLocale(date) {
  return new Date(date).toLocaleString('pt-BR').split(',')[0];
}