import axios from 'axios';
import getCognitoCredentials from '../Components/Helpers/getCognitoCredentials';
let ambiente = '';

// essa api não tem ambiente de HMG
if (process.env.REACT_APP_FRONT_ENV === 'LOCAL') {
  ambiente =
    'https://apis-hmg.amicci-digital.com.br/mercadologico/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'DEV') {
  ambiente =
    'https://apis-hmg.amicci-digital.com.br/mercadologico/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'HMG') {
  ambiente =
    'https://apis-hmg.amicci-digital.com.br/mercadologico/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'PROD') {
  ambiente =
    'https://apis.amicci.com.br/mercadologico/api';
}

const apiConnectMarketing = axios.create({
  baseURL: ambiente,
});

apiConnectMarketing.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    const { idToken, accessToken } = getCognitoCredentials();
    if (error.response?.status === 403 && accessToken && idToken) {
      error.response.data.message = 'Você não tem acesso';
    }
    return Promise.reject(error);
  }
);

export default apiConnectMarketing;
