import React, { createContext, useState } from 'react';
import apiConnect from '../services/apiConnect';
import apiConnectManagement from '../services/apiConnectManagement';
import apiConnectMarketing from '../services/apiConnectMarketing';
import apiConnectSuppliersDB from '../services/apiConnectSuppliersDB';
import apiConnectViability from '../services/apiConnectViability';

const defaultMessage = (e = null, data = null) => ({
  success: e ? false : true,
  code: e ? e?.code || e?.response?.status : 200,
  message: e ? e?.message || e?.response?.statusText : null,
  data: data || e?.response?.data || null,
});

export const DefaultParamsSearchBriefings = {
  page: 1, // number
  retailer: null, // 1,2,3 lista de numeros
  start_date: null, // yyyy-mm-dd
  end_date: null, // yyyy-mm-dd
  status_code: null, // number
  briefing_name: null, // string
};

const BriefingDefaultValue = {
  loading: {
    prospectionType: false,
    distributionType: false,
    briefing: false,
    searchBriefings: false,
    suppliers: false,
    managers: false,
    serviceManagers: false,
    managerProject: false,
    clientResponsibles: false,
    user: false,
    dtiUsers: false,
    retailers: false,
    trailTypes: false,
    operationTypes: false,
    listBrand: false,
    states: false,
    dtiApproval: false,
    managerApproval: false,
    statusBriefing: false,
    removeFile: false,
    /* putFile: false, */
    deleteBriefing: false,
    searchProviders: false,
    searchProvidersPaginated: false,
    searchProductsPaginated: false,
    submitToProspect: false,
    submitEmail: false,
    submitFinishWithoutProspection: false,
    submitStartProspection: false,
    saveBriefing: false,
    uploadFiles: false,
    updateSourcing: false,
    packages: false,
    measures: false,
    sectors: false,
    departaments: false,
    categories: false,
    products: false,
    requestAddSupplier: false,
    updateBriefing: false,
    createProject: false,
    reviewBriefing: false,
    suppliersProject: false,
  },
  error: {
    prospectionType: null,
    distributionType: null,
    briefing: null,
    searchBriefings: null,
    suppliers: null,
    managers: null,
    serviceManagers: null,
    managerProject: null,
    clientResponsibles: null,
    user: null,
    dtiUsers: null,
    retailers: null,
    trailTypes: null,
    operationTypes: null,
    listBrand: null,
    states: null,
    updateSourcing: null,
    packages: null,
    measures: null,
    sectors: null,
    departaments: null,
    categories: null,
    products: null,
    requestAddSupplier: null,
    updateBriefing: null,
    createProject: null,
    reviewBriefing: null,
    suppliersProject: null,
  },
  user: null,
  retailers: [],
  trailTypes: [],
  distributionType: [],
  operationTypes: [],
  prospectionType: [],
  states: [],
  packages: [],
  measures: [],
  sectors: [],
  departaments: [],
  categories: [],
  products: [],
  getUser: email => Promise.resolve(defaultMessage()),
  getDistributionType: () => Promise.resolve(defaultMessage()),
  getProspectionType: () => Promise.resolve(defaultMessage()),
  getStates: () => Promise.resolve(defaultMessage()),
  getBriefing: id => Promise.resolve(defaultMessage()),
  getBriefings: params => Promise.resolve(defaultMessage()),
  searchBriefings: params => Promise.resolve(defaultMessage()),
  getSuppliersNames: listSuppliers => Promise.resolve(defaultMessage()),
  getManagers: retailerId => Promise.resolve(defaultMessage()),
  getServiceManagers: retailerId => Promise.resolve(defaultMessage()),
  getManagerProject: retailerId => Promise.resolve(defaultMessage()),
  getClientResponsibles: retailerId => Promise.resolve(defaultMessage()),
  getDtiUsers: () => Promise.resolve(defaultMessage()),
  getRetailers: () => Promise.resolve(defaultMessage()),
  getTrailTypes: inter => Promise.resolve(defaultMessage()),
  getOperationTypes: () => Promise.resolve(defaultMessage()),
  getListBrands: () => Promise.resolve(defaultMessage()),
  setDtiApproval: (id, email) => Promise.resolve(defaultMessage()),
  setManagerApproval: (id, email) => Promise.resolve(defaultMessage()),
  setStatusBriefing: (id, email, status) => Promise.resolve(defaultMessage()),
  removeFile: id => Promise.resolve(defaultMessage()),
  /* putFile: (id, file) => Promise.resolve(defaultMessage()), */
  deleteBriefing: (id, email) => Promise.resolve(defaultMessage()),
  deleteBriefingFull: id => Promise.resolve(defaultMessage()),
  searchProviders: (term, ids) => Promise.resolve(defaultMessage()),
  searchProvidersPaginated: (term, page) => Promise.resolve(defaultMessage()),
  searchProvidersV2: filter => Promise.resolve(defaultMessage()),
  searchProductsPaginated: (term, page) => Promise.resolve(defaultMessage()),
  getProvider: id => Promise.resolve(defaultMessage()),
  getProduct: id => Promise.resolve(defaultMessage()),
  submitToProspect: (id, email) => Promise.resolve(defaultMessage()),
  submitEmail: (id, email) => Promise.resolve(defaultMessage()),
  submitFinishWithoutProspection: (id, email) =>
    Promise.resolve(defaultMessage()),
  submitStartProspection: (id, email) => Promise.resolve(defaultMessage()),
  saveBriefing: (id, briefing, type) => Promise.resolve(defaultMessage()),
  uploadFiles: (files, briefingId, concatName) =>
    Promise.resolve(defaultMessage()),
  updateSourcing: (briefing, id) => Promise.resolve(defaultMessage()),
  updateBriefing: (id, briefing) => Promise.resolve(defaultMessage()),
  getPackages: () => Promise.resolve(defaultMessage()),
  getMeasures: () => Promise.resolve(defaultMessage()),
  requestAddSupplier: data => Promise.resolve(defaultMessage()),
  createProject: data => Promise.resolve(defaultMessage()),
  getReviewBriefing: id => Promise.resolve(defaultMessage()),
  getSuppliersByProject: projectId => Promise.resolve(defaultMessage()),
  setSuppliersByProject: data => Promise.resolve(defaultMessage()),
  deleteSuppliersByProject: (projectId, data) =>
    Promise.resolve(defaultMessage()),
  getDepartaments: upperLevel => Promise.resolve(defaultMessage()),
  getCategories: upperLevels => Promise.resolve(defaultMessage()),
  getProducts: upperLevelsP => Promise.resolve(defaultMessage()),
};

const BriefingContext = createContext(BriefingDefaultValue);

export const BriefingContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(BriefingDefaultValue.loading);
  const [error, setError] = useState(BriefingDefaultValue.error);
  const [user, setUser] = useState(null);
  const [retailers, setRetailers] = useState([]);
  const [trailTypes, setTrailTypes] = useState([]);
  const [operationTypes, setOperationTypes] = useState([]);
  const [listBrands, setListBrands] = useState();
  const [dtiUsers, setListUsers] = useState([]);
  const [distributionType, setDistributionType] = useState([]);
  const [prospectionType, setProspectionType] = useState([]);
  const [states, setStates] = useState([]);
  const [packages, setPackages] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [departaments, setDepartaments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [managers, setManagers] = useState([]);
  const [serviceManagers, setServiceManagers] = useState([]);
  const [managerProject, setManagerProject] = useState([]);
  const [clientResponsibles, setClientResponsibles] = useState([]);
  const [reviewBriefing, setReviewBriefing] = useState([]);
  /* const [approvalStatus, setApprovalStatus] = useState([]); */

  const getHeaders = (extras = null) => ({
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      ...(extras && { ...extras }),
    },
  });

  const getHeaderSuppliersDb = () => ({
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem(
        'suppliers-db-token'
      )}`,
    },
  });

  const getProspectionType = async () => {
    if (!loading.prospectionType) {
      setLoading(l => ({
        ...l,
        prospectionType: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnect.get(
          '/briefing/prospection-type',
          headers
        );
        setProspectionType(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setProspectionType([]);
        setError(err => ({
          ...err,
          prospectionType:
            e.message || 'ocorreu um erro ao carregar prospection-type',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          prospectionType: false,
        }));
      }
    }
  };

  const getDistributionType = async () => {
    if (!loading.distributionType) {
      setLoading(l => ({
        ...l,
        distributionType: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnect.get(
          '/briefing/distribution-type',
          headers
        );
        setDistributionType(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setDistributionType([]);
        setError(err => ({
          ...err,
          distributionType:
            e.message || 'ocorreu um erro ao carregar distribution-type',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          distributionType: false,
        }));
      }
    }
  };

  const getBriefing = async id => {
    if (!loading.briefing && id) {
      setLoading(l => ({
        ...l,
        briefing: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnect.get(`briefings_full/${id}`, headers);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          briefing: e.message || 'ocorreu um erro ao carregar briefing',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          briefing: false,
        }));
      }
    }
  };

  const searchBriefings = async (params = DefaultParamsSearchBriefings) => {
    if (user && retailers.length > 0) {
      setLoading(l => ({
        ...l,
        searchBriefings: true,
      }));
      try {
        const _params = {
          page: params.page || 1,
          ...(params.retailer && { retailer: params.retailer }),
          ...(params.start_date && { start_date: params.start_date }),
          ...(params.end_date && { end_date: params.end_date }),
          ...(params.status_code && { status_code: params.status_code }),
          ...(params.briefing_name && {
            briefing_name: params.briefing_name,
          }),
        };
        if (
          user.department_name === 'VAREJISTA' ||
          user.department_name === 'NOVOS CLIENTES'
        ) {
          if (!params.retailer) {
            _params.retailer = retailers.map(r => r.value).join(',');
          }
        }
        const headers = getHeaders();
        const resp = await apiConnect.get('/briefings/', {
          ...headers,
          params: _params,
        });
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          searchBriefings: e.message || 'ocorreu um erro ao carregar briefings',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          searchBriefings: false,
        }));
      }
    }
  };

  const getSuppliersNames = async (listSuppliers = []) => {
    if (!loading.suppliers && listSuppliers && listSuppliers.length > 0) {
      setLoading(l => ({
        ...l,
        suppliers: true,
      }));
      const listIds = listSuppliers.map(s => s.supplier).join(',');
      const resp = await searchProviders(null, listIds);
      setLoading(l => ({
        ...l,
        suppliers: false,
      }));
      return resp;
    }
  };

  const getManagers = async (retailerId = null) => {
    if (!loading.managers && retailerId) {
      setLoading(l => ({
        ...l,
        managers: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnect.get(
          `/retailers/${retailerId}/users/`,
          headers
        );
        setManagers(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          managers: e.message || 'ocorreu um erro ao carregar managers',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          managers: false,
        }));
      }
    }
  };

  const getServiceManagers = async (retailerId = null) => {
    if (!loading.managers && retailerId) {
      setLoading(l => ({
        ...l,
        serviceManagers: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnect.get(
          `/retailers/${retailerId}/users/?dev_eligible=1`,
          headers
        );
        setServiceManagers(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          serviceManagers: e.message || 'ocorreu um erro ao carregar managers',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          serviceManagers: false,
        }));
      }
    }
  };

  const getManagerProject = async (
    retailerId = null,
    fromManagement = false
  ) => {
    if (!loading.managerProject && retailerId) {
      setLoading(l => ({
        ...l,
        managerProject: true,
      }));
      try {
        const headers = getHeaders();
        let route = `/retailers/${retailerId}/users/`;
        if (fromManagement) route += '?is_manager=1';
        const resp = await apiConnect.get(route, headers);
        setManagerProject(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          managerProject: e.message || 'ocorreu um erro ao carregar managers',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          managerProject: false,
        }));
      }
    }
  };

  const getClientResponsibles = async (retailerId = null) => {
    if (!loading.managers) {
      setLoading(l => ({
        ...l,
        clientResponsibles: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnectManagement.get(
          `/list-client-responsibles/${retailerId}`,
          headers
        );
        setClientResponsibles(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          clientResponsibles:
            e.message || 'ocorreu um erro ao carregar managers',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          clientResponsibles: false,
        }));
      }
    }
  };

  const getUser = async email => {
    if (!loading.user && email) {
      setLoading(l => ({
        ...l,
        user: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnect.get(`/users/?email=${email}`, headers);
        setUser(resp.data[0]);
        return defaultMessage(null, resp.data[0]);
      } catch (e) {
        console.log('error', e);
        setUser(null);
        setError(err => ({
          ...err,
          user: e.message || 'ocorreu um erro ao carregar user',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          user: false,
        }));
      }
    }
  };

  const getDtiUsers = async () => {
    if (!loading.dtiUsers) {
      setLoading(l => ({
        ...l,
        dtiUsers: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnect.get('/users', headers);
        setListUsers(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setListUsers([]);
        setError(err => ({
          ...err,
          dtiUsers: e.message || 'ocorreu um erro ao carregar users',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          dtiUsers: false,
        }));
      }
    }
  };

  const getRetailers = async () => {
    if (user && !loading.retailers) {
      setLoading(l => ({
        ...l,
        retailers: true,
      }));
      try {
        let url;
        if (
          user.department_name === 'VAREJISTA' ||
          user.department_name === 'NOVOS CLIENTES'
        ) {
          url = `/users/${user.id}/retailers/`;
        } else {
          url = '/retailers';
        }
        const headers = getHeaders();
        const resp = await apiConnect.get(url, headers);
        const rets = resp.data.map(r => ({
          ...r,
          // ajusta campos caso seja varejista ou novos clientes
          id: r.id || r.retailer,
          name: r.name || r.retailer_name,
          available: r.available || r.retailer_available || false,
        }));
        setRetailers(rets);
        return defaultMessage(null, rets);
      } catch (e) {
        console.log('error', e);
        setRetailers([]);
        setError(err => ({
          ...err,
          retailers: e.message || 'ocorreu um erro ao carregar retailers',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          retailers: false,
        }));
      }
    }
  };

  const getTrailTypes = async (inter = null) => {
    if (!loading.trailTypes) {
      setLoading(l => ({
        ...l,
        trailTypes: true,
      }));
      try {
        const filter = inter ? `?inter=1` : '';
        // const headers = getHeaders();
        const resp = await apiConnectManagement.get(
          `/list-trail-types${filter}`
        );
        setTrailTypes(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setTrailTypes([]);
        setError(err => ({
          ...err,
          trailTypes: e.message || 'ocorreu um erro ao carregar states',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          trailTypes: false,
        }));
      }
    }
  };

  const getOperationTypes = async () => {
    if (!loading.operationTypes) {
      setLoading(l => ({
        ...l,
        operationTypes: true,
      }));
      try {
        // const headers = getHeaders();
        const resp = await apiConnectManagement.get('/list-operation-types');
        setOperationTypes(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setOperationTypes([]);
        setError(err => ({
          ...err,
          operationTypes: e.message || 'ocorreu um erro ao carregar states',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          operationTypes: false,
        }));
      }
    }
  };

  const getStates = async () => {
    if (!loading.states) {
      setLoading(l => ({
        ...l,
        states: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnect.get('/states/', headers);
        setStates(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setStates([]);
        setError(err => ({
          ...err,
          states: e.message || 'ocorreu um erro ao carregar states',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          states: false,
        }));
      }
    }
  };

  const getListBrands = async clientId => {
    if (!loading.listBrand) {
      setLoading(l => ({
        ...l,
        listBrand: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnectManagement.get(
          `/list-brands/${clientId}`,
          headers
        );
        setListBrands(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setStates([]);
        setError(err => ({
          ...err,
          listBrand: e.message || 'ocorreu um erro ao carregar states',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          listBrand: false,
        }));
      }
    }
  };

  const setDtiApproval = async (id, email) => {
    if (!loading.dtiApproval && id && email) {
      setLoading(l => ({
        ...l,
        dtiApproval: true,
      }));
      try {
        const headers = getHeaders({ 'Content-Type': 'application/json' });
        const formData = new FormData();
        formData.append('briefing', id);
        formData.append('current_user', email);
        await apiConnect.post('/briefing/dti-validate/', formData, headers);
        return defaultMessage();
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          dtiApproval: false,
        }));
      }
    }
  };

  const setManagerApproval = async (id, email) => {
    if (!loading.managerApproval && id && email) {
      setLoading(l => ({
        ...l,
        managerApproval: true,
      }));
      try {
        const headers = getHeaders({ 'Content-Type': 'application/json' });
        const formData = new FormData();
        formData.append('briefing', id);
        formData.append('current_user', email);
        await apiConnect.post('/briefing/manager-validate/', formData, headers);
        return defaultMessage();
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          managerApproval: false,
        }));
      }
    }
  };

  const setStatusBriefing = async (id, email, status) => {
    if (!loading.statusBriefing && id && email && status) {
      setLoading(l => ({
        ...l,
        statusBriefing: true,
      }));
      try {
        const headers = getHeaders({ 'Content-Type': 'application/json' });
        const formData = new FormData();
        formData.append('briefing', id);
        formData.append('current_user', email);
        formData.append('status_code', status);
        await apiConnect.post('/briefing/status-update/', formData, headers);
        return defaultMessage();
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          statusBriefing: false,
        }));
      }
    }
  };

  const removeFile = async id => {
    if (!loading.removeFile && id) {
      setLoading(l => ({
        ...l,
        removeFile: true,
      }));
      try {
        const headers = getHeaders();
        await apiConnect.delete(`/briefing-files/${id}/`, headers);
        return defaultMessage();
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          removeFile: false,
        }));
      }
    }
  };

  /* const putFile = async (id, file) => {
    if (!loading.putFile && id) {
      setLoading(l => ({
        ...l,
        putFile: true,
      }));
      try {
        const headers = getHeaders();
        await apiConnect.put(`/briefing-files/${id}/`, file, headers);
        return defaultMessage();
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          putFile: false,
        }));
      }
    }
  }; */

  const deleteBriefingFull = async id => {
    if (!loading.deleteBriefing && id) {
      setLoading(l => ({
        ...l,
        deleteBriefing: true,
      }));
      try {
        const headers = getHeaders();
        await apiConnect.delete(`/briefings_full/${id}/`, headers);
        return defaultMessage();
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          deleteBriefing: false,
        }));
      }
    }
  };

  const deleteBriefing = async (id, email) => {
    if (!loading.deleteBriefing && id && email) {
      setLoading(l => ({
        ...l,
        deleteBriefing: true,
      }));
      try {
        const headers = getHeaders();
        await apiConnect.delete(`/briefings/${id}/${email}`, headers);
        return defaultMessage();
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          deleteBriefing: false,
        }));
      }
    }
  };

  const searchProviders = async (term, ids = null) => {
    if (!loading.searchProviders) {
      setLoading(l => ({
        ...l,
        searchProviders: true,
      }));
      /**
       * Gambiarra
       * a api /provider/search esta trazendo os resultados paginados
       * então é preciso fazer varias buscas pra trazer todos os fornecedores
       */
      const loadProviders = async (term, ids, page = 1, list = []) => {
        const headers = getHeaderSuppliersDb();
        const t = term ? `&name=${term}` : '';
        const i = ids ? `&ids=${ids}` : '';
        try {
          const resp = await apiConnectSuppliersDB.get(
            `/provider/search?page=${page}${t}${i}`,
            headers
          );
          list = [...list, ...resp.data.results];
          // se ainda possui mais para buscar
          if (resp.data.next) {
            return await loadProviders(term, ids, page + 1, list);
          } else {
            setLoading(l => ({
              ...l,
              searchProviders: false,
            }));
            return defaultMessage(null, list);
          }
        } catch (e) {
          setLoading(l => ({
            ...l,
            searchProviders: false,
          }));
          return defaultMessage(null, []);
        }
      };
      return await loadProviders(term, ids);
    }
  };

  const searchProvidersV2 = async filter => {
    if (!loading.searchProviders && filter) {
      setLoading(l => ({
        ...l,
        searchProviders: true,
      }));
      /**
       * Gambiarra
       * a api /provider/search esta trazendo os resultados paginados
       * então é preciso fazer varias buscas pra trazer todos os fornecedores
       */
      const loadProviders = async (filter, page = 1, list = []) => {
        const headers = getHeaderSuppliersDb();
        const f = `&filter=${filter}`;
        try {
          const resp = await apiConnectSuppliersDB.get(
            `/provider/search?page=${page}${f}`,
            headers
          );
          list = [...list, ...resp.data.results];
          // se ainda possui mais para buscar
          if (resp.data.next) {
            return await loadProviders(filter, page + 1, list);
          } else {
            setLoading(l => ({
              ...l,
              searchProviders: false,
            }));
            return defaultMessage(null, list);
          }
        } catch (e) {
          setLoading(l => ({
            ...l,
            searchProviders: false,
          }));
          return defaultMessage(e, []);
        }
      };
      return await loadProviders(filter);
    }
  };

  const searchProvidersPaginated = async (term, page = 1) => {
    if (!loading.searchProvidersPaginated) {
      setLoading(l => ({
        ...l,
        searchProvidersPaginated: true,
      }));
      const headers = getHeaderSuppliersDb();
      const url = term
        ? `/provider/search?name=${term}&page=${page}`
        : `/provider/list?page=${page}`;

      try {
        const resp = await apiConnectSuppliersDB.get(url, headers);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        return [];
      } finally {
        setLoading(l => ({
          ...l,
          searchProvidersPaginated: false,
        }));
      }
    }
  };

  const searchProductsPaginated = async (term, page = 1) => {
    if (!loading.searchProductsPaginated) {
      setLoading(l => ({
        ...l,
        searchProductsPaginated: true,
      }));
      const headers = getHeaderSuppliersDb();
      const url = term
        ? `/product/search?name=${term}&page=${page}`
        : `/product/list?page=${page}`;

      try {
        const resp = await apiConnectSuppliersDB.get(url, headers);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        return [];
      } finally {
        setLoading(l => ({
          ...l,
          searchProductsPaginated: false,
        }));
      }
    }
  };

  const getProvider = async id => {
    if (id) {
      try {
        const headers = getHeaderSuppliersDb();
        const resp = await apiConnectSuppliersDB.get(
          `/provider/${id}`,
          headers
        );
        return defaultMessage(null, resp.data[0]);
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      }
    }
  };

  const getProduct = async id => {
    if (id) {
      try {
        const headers = getHeaderSuppliersDb();
        const resp = await apiConnectSuppliersDB.get(`/product/${id}`, headers);
        return defaultMessage(null, resp.data[0]);
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      }
    }
  };

  const submitToProspect = async (id, email) => {
    if (!loading.submitToProspect && id && email) {
      setLoading(l => ({
        ...l,
        submitToProspect: true,
      }));
      try {
        const headers = getHeaders({ 'Content-Type': 'application/json' });
        const formData = new FormData();
        formData.append('briefing', id);
        formData.append('user', email);
        await apiConnect.post(
          'briefing/send-to-prospection/',
          formData,
          headers
        );
        return defaultMessage();
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          submitToProspect: false,
        }));
      }
    }
  };

  const submitEmail = async (id, email) => {
    if (!loading.submitEmail && id && email) {
      setLoading(l => ({
        ...l,
        submitEmail: true,
      }));
      try {
        const headers = getHeaders({ 'Content-Type': 'application/json' });
        const formData = new FormData();
        formData.append('briefing', id);
        formData.append('user', email);
        await apiConnect.post('/send-to-sourcing/', formData, headers);
        return defaultMessage();
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          submitEmail: false,
        }));
      }
    }
  };

  const submitFinishWithoutProspection = async (id, email) => {
    if (!loading.submitFinishWithoutProspection && id && email) {
      setLoading(l => ({
        ...l,
        submitFinishWithoutProspection: true,
      }));
      try {
        const headers = getHeaders({ 'Content-Type': 'application/json' });
        const formData = new FormData();
        formData.append('briefing', id);
        formData.append('user', email);
        await apiConnect.post(
          '/briefing/finish-without-prospection/',
          formData,
          headers
        );
        return defaultMessage();
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          submitFinishWithoutProspection: false,
        }));
      }
    }
  };

  const submitStartProspection = async (id, email) => {
    if (!loading.submitStartProspection && id && email) {
      setLoading(l => ({
        ...l,
        submitStartProspection: true,
      }));
      try {
        const headers = getHeaders({ 'Content-Type': 'application/json' });
        const formData = new FormData();
        formData.append('briefing', id);
        formData.append('user', email);
        await apiConnect.post(
          '/briefing/send-to-retailer-prospection/',
          formData,
          headers
        );
        return defaultMessage();
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          submitStartProspection: false,
        }));
      }
    }
  };

  const saveBriefing = async (
    briefing,
    id = null,
    type = 1,
    projectId = -1
  ) => {
    if (!loading.saveBriefing && briefing) {
      setLoading(l => ({
        ...l,
        saveBriefing: true,
      }));

      // Creating a briefing from new Project
      if (projectId !== -1 && !id) {
        try {
          const headers = getHeaders();
          const resp = await apiConnectManagement.post(
            `/create_briefing/${projectId}`,
            briefing,
            headers
          );
          return defaultMessage(null, resp.data);
        } catch (e) {
          console.log('error', e);
          setError(err => ({
            ...err,
            saveProjectBriefing:
              e.message || 'ocorreu um erro ao tentar criar briefing',
          }));
          return defaultMessage(e);
        } finally {
          setLoading(l => ({
            ...l,
            saveBriefing: false,
          }));
        }
      }

      try {
        const headers = getHeaders({ 'Content-Type': 'application/json' });

        if (type === 1) {
          // novo produto
          if (!id) {
            // salva novo produto
            const resp = await apiConnect.post(
              '/briefings_full/',
              briefing,
              headers
            );
            return defaultMessage(null, resp?.data?.id);
          } else {
            // altera produto
            await apiConnect.put(`/briefings_full/${id}/`, briefing, headers);
            return defaultMessage(null, id);
          }
        } else {
          // type !== 1
          // carta grafica
          // reformulacao
          // troca de fornecedor
          // ext. de linha

          if (!id) {
            // nova carta/reformulacao/troca/ext
            const resp = await apiConnect.post(
              '/briefing/type/',
              briefing,
              headers
            );
            return defaultMessage(null, resp?.data?.id);
          } else {
            // altera carta/reformulacao/troca/ext
            // await apiConnect.put(`/briefings_full/${id}/`, briefing, headers);
            await apiConnect.put('/briefing/type/', briefing, headers);
            return defaultMessage(null, id);
          }
        }
      } catch (e) {
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          saveBriefing: false,
        }));
      }
    }
  };

  const uploadFiles = async (files, briefingId, concatName = '') => {
    if (!loading.uploadFiles && files && briefingId) {
      // console.log('vai fazer upload');
      setLoading(l => ({
        ...l,
        uploadFiles: true,
      }));
      try {
        const headers = getHeaders();
        const arrayPromises = [];

        const uploadFile = async file => {
          try {
            const resp = await apiConnect.post(
              '/briefing-files/',
              file,
              headers
            );
            // console.log('uploadFile', `${resp.code} - ${resp.message}`);
            if (resp.code === 201) return resp.data;
            else return `${resp.code} - ${resp.message}`;
          } catch (e) {
            console.log('error', e);
            return `${e.code} - ${e.message}`;
          }
        };

        for (let fi of [...files]) {
          const fileName = concatName + fi.name;
          const fileData = new FormData();
          fileData.append('name', fileName);
          fileData.append('size', fi.size);
          fileData.append('file', fi, fileName);
          fileData.append('available', true);
          fileData.append('briefing', briefingId);
          fileData.append('type', fi._type);
          arrayPromises.push(uploadFile(fileData));
        }

        await Promise.all(arrayPromises);

        return defaultMessage();
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          uploadFiles: false,
        }));
      }
    }
  };

  const updateSourcing = async (briefing, id) => {
    if (!loading.updateSourcing && briefing && id) {
      setLoading(l => ({
        ...l,
        updateSourcing: true,
      }));
      try {
        const headers = getHeaders();
        await apiConnect.put(`/briefing/sourcing/${id}`, briefing, headers);
        return defaultMessage(null);
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          updateSourcing: false,
        }));
      }
    }
  };

  const updateBriefing = async (id, briefing) => {
    if (!loading.updateBriefing && id) {
      setLoading(l => ({
        ...l,
        updateBriefing: true,
      }));
      try {
        const headers = getHeaders();
        await apiConnectManagement.put(
          `/edit-project-data/${id}`,
          briefing,
          headers
        );
        return defaultMessage(null);
      } catch (e) {
        console.log('error', e);
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          updateBriefing: false,
        }));
      }
    }
  };

  const getPackages = async () => {
    if (!loading.packages) {
      setLoading(l => ({
        ...l,
        packages: true,
      }));
      try {
        // const headers = getHeaders();
        // const resp = await apiConnect.get('/api...', headers);
        const host = window.location.host;
        const http = RegExp('localhost').test(host) ? 'http' : 'https';
        const res = await fetch(`${http}://${host}/package-list.json`);
        const resp = await res.json();
        const sorted = resp.sort((a, b) => {
          if (a.name > b.name) return 1;
          else if (a.name < b.name) return -1;
          else return 0;
        });
        setPackages(sorted);
        return defaultMessage(null, sorted);
      } catch (e) {
        console.log('error', e);
        setPackages([]);
        setError(err => ({
          ...err,
          packages: e.message || 'ocorreu um erro ao carregar packages',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          packages: false,
        }));
      }
    }
  };

  const getMeasures = async () => {
    if (!loading.measures) {
      setLoading(l => ({
        ...l,
        measures: true,
      }));
      try {
        // const headers = getHeaders();
        // const resp = await apiConnect.get('/api...', headers);
        const host = window.location.host;
        const http = RegExp('localhost').test(host) ? 'http' : 'https';
        const res = await fetch(`${http}://${host}/measure-list.json`);
        const resp = await res.json();
        setMeasures(resp);
        return defaultMessage(null, resp);
      } catch (e) {
        console.log('error', e);
        setMeasures([]);
        setError(err => ({
          ...err,
          measures: e.message || 'ocorreu um erro ao carregar measures',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          measures: false,
        }));
      }
    }
  };

  const getSectors = async () => {
    if (!loading.sectors) {
      setLoading(l => ({
        ...l,
        sectors: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnectMarketing.get(
          '/v2/classification?level=1',
          headers
        );
        setSectors(resp.data);
        console.log('sectors', sectors);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setSectors({});
        setError(err => ({
          ...err,
          sectors: e.message || 'ocorreu um erro ao carregar sectors',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          sectors: false,
        }));
      }
    }
  };

  const getDepartaments = async upperLevel => {
    if (!loading.departaments) {
      setLoading(l => ({
        ...l,
        departaments: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnectMarketing.get(
          `/v2/classification?upper_level=${upperLevel}`,
          headers
        );
        setDepartaments(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setDepartaments([]);
        setError(err => ({
          ...err,
          departaments: e.message || 'ocorreu um erro ao carregar departamento',
        }));
      } finally {
        setLoading(l => ({
          ...l,
          departaments: false,
        }));
      }
    }
  };

  const getCategories = async upperLevels => {
    if (!loading.categories) {
      setLoading(l => ({
        ...l,
        categories: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnectMarketing.get(
          `/v2/classification?upper_level=${upperLevels}`,
          headers
        );
        setCategories(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setCategories([]);
        setError(err => ({
          ...err,
          categories: e.message || 'ocorreu um erro ao carregar categorias',
        }));
      } finally {
        setLoading(l => ({
          ...l,
          categories: false,
        }));
      }
    }
  };

  const getProducts = async upperLevelsP => {
    if (!loading.products) {
      setLoading(l => ({
        ...l,
        products: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnectMarketing.get(
          `/v2/classification?upper_level=${upperLevelsP}`,
          headers
        );
        setProducts(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setProducts([]);
        setError(err => ({
          ...err,
          products: e.message || 'ocorreu um erro ao carregar categorias',
        }));
      } finally {
        setLoading(l => ({
          ...l,
          products: false,
        }));
      }
    }
  };

  const getReviewBriefing = async id => {
    if (!loading.reviewBriefing) {
      setLoading(l => ({
        ...l,
        reviewBriefing: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnect.get(`/briefing/review/${id}/`, headers);
        setReviewBriefing(resp.data);
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setReviewBriefing([]);
        setError(err => ({
          ...err,
          reviewBriefing: e.message || 'ocorreu um erro ao carregar status',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          reviewBriefing: false,
        }));
      }
    }
  };

  const requestAddSupplier = async data => {
    if (!loading.requestAddSupplier) {
      setLoading(l => ({
        ...l,
        requestAddSupplier: true,
      }));
      try {
        const headers = getHeaders();
        await apiConnect.post('/briefing/request-supplier', data, headers);
        return defaultMessage();
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          requestAddSupplier:
            e.message || 'ocorreu um erro ao solicitar cadastro de usuário',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          requestAddSupplier: false,
        }));
      }
    }
  };

  const createProject = async data => {
    if (!loading.createProject) {
      setLoading(l => ({
        ...l,
        createProject: true,
      }));
      try {
        const headers = getHeaders();
        const resp = await apiConnectManagement.post(
          '/new_create_project',
          data,
          headers
        );
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          createProject:
            e.message || 'ocorreu um erro ao tentar criar um novo projeto',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          createProject: false,
        }));
      }
    }
  };

  const getSuppliersByProject = async projectId => {
    if (!loading.suppliersProject) {
      setLoading(l => ({
        ...l,
        suppliersProject: true,
      }));
      try {
        const headers = getHeaders({
          id_token: window.localStorage.getItem('id-token-cognito'),
          access_token: window.localStorage.getItem('access-token-cognito'),
        });
        const resp = await apiConnectViability.get(
          `/projects/commercial-filter/${projectId}/list`,
          headers
        );
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          suppliersProject:
            e.message || 'ocorreu um erro ao carregar projetos pais',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          suppliersProject: false,
        }));
      }
    }
  };

  const setSuppliersByProject = async data => {
    if (!loading.suppliersProject && data) {
      setLoading(l => ({
        ...l,
        suppliersProject: true,
      }));
      try {
        const headers = getHeaders({
          id_token: window.localStorage.getItem('id-token-cognito'),
          access_token: window.localStorage.getItem('access-token-cognito'),
        });
        const resp = await apiConnectViability.post(
          `/projects/commercial-filter/`,
          data,
          headers
        );
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          suppliersProject:
            e.message ||
            e.error ||
            'Aconteceu um erro inesperado, tente novamente mais tarde!',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          suppliersProject: false,
        }));
      }
    }
  };

  const deleteSuppliersByProject = async (projectId, data) => {
    if (!loading.suppliersProject && projectId && data) {
      setLoading(l => ({
        ...l,
        suppliersProject: true,
      }));
      try {
        const headers = getHeaders({
          id_token: window.localStorage.getItem('id-token-cognito'),
          access_token: window.localStorage.getItem('access-token-cognito'),
        });
        const resp = await apiConnectViability.delete(
          `/projects/commercial-filter/${projectId}/delete?suppliers=${data.join(
            ','
          )}`,
          headers
        );
        return defaultMessage(null, resp.data);
      } catch (e) {
        console.log('error', e);
        setError(err => ({
          ...err,
          suppliersProject:
            e.message ||
            e.error ||
            'Aconteceu um erro inesperado, tente novamente mais tarde!',
        }));
        return defaultMessage(e);
      } finally {
        setLoading(l => ({
          ...l,
          suppliersProject: false,
        }));
      }
    }
  };

  return (
    <BriefingContext.Provider
      value={{
        loading,
        error,
        prospectionType,
        distributionType,
        user,
        dtiUsers,
        retailers,
        trailTypes,
        operationTypes,
        listBrands,
        states,
        packages,
        measures,
        sectors,
        departaments,
        categories,
        products,
        managers,
        serviceManagers,
        managerProject,
        clientResponsibles,
        getProspectionType,
        getDistributionType,
        getBriefing,
        searchBriefings,
        getSuppliersNames,
        getManagers,
        getServiceManagers,
        getManagerProject,
        getClientResponsibles,
        getUser,
        getDtiUsers,
        getRetailers,
        getTrailTypes,
        getOperationTypes,
        getListBrands,
        getStates,
        setDtiApproval,
        setManagerApproval,
        setStatusBriefing,
        removeFile,
        /* putFile, */
        deleteBriefing,
        deleteBriefingFull,
        searchProviders,
        searchProvidersPaginated,
        searchProvidersV2,
        searchProductsPaginated,
        getProvider,
        getProduct,
        submitToProspect,
        submitEmail,
        submitFinishWithoutProspection,
        submitStartProspection,
        saveBriefing,
        uploadFiles,
        updateSourcing,
        getPackages,
        getMeasures,
        getSectors,
        getDepartaments,
        getCategories,
        getProducts,
        getReviewBriefing,
        reviewBriefing,
        requestAddSupplier,
        updateBriefing,
        createProject,
        getSuppliersByProject,
        setSuppliersByProject,
        deleteSuppliersByProject,
      }}>
      {children}
    </BriefingContext.Provider>
  );
};

export const useBriefingContext = () => React.useContext(BriefingContext);
