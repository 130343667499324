import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import styles from '../../Sourcing/Sourcing.module.css';
import DemandsList from './Components/DemandsList';
import globalStyle from '../styles.module.css';
import MenuPlm from '../../../../Components/Sidebar/MenuPdm';

function Prospection() {
  const [valuesFilter, setValuesFilter] = useState({
    responsible: '',
    client: '',
    project: '',
    supplier: '',
    stage: '',
    currDateLimit: false,
  });
  return (
    <>
      <Sidebar>
        <MenuPlm opened="sourcing" />
      </Sidebar>
      <main className={styles.homeBriefing}>
        <Routes>
          <Route
            path="/"
            element={
              <DemandsList
                globalStyle={globalStyle}
                valuesFilter={valuesFilter}
                setValuesFilter={setValuesFilter}
              />
            }
          />
        </Routes>
      </main>
    </>
  );
}

export default Prospection;
