import React, { useEffect, useState, useRef } from 'react';
import './InlineCalendar.scss';
import { Icon, Button } from 'rsuite';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import { classNames } from '../../utils/utils';

const holidays = [
  '01-01',
  '04-15',
  '04-21',
  '05-01',
  '09-07',
  '10-12',
  '11-02',
  '11-15',
  '12-25',
];

const InlineCalendar = ({
  minDate = undefined,
  maxDate = undefined,
  date = undefined,
  setDate = date => {},
  position,
  disabled,
  dateStr = false,
  classes = [],
  placeholder = '00/00/0000',
  disableWeekend,
  disableHolidays,
  ...otherProps
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    function detectEscPressed(event) {
      if (event.key === 'Escape') {
        setShowCalendar(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', detectEscPressed);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', detectEscPressed);
    };
  }, [wrapperRef]);

  return (
    <div ref={wrapperRef}>
      <div
        className={classNames({
          inputInlineCalendar: true,
          ...Object.fromEntries(classes.map((el) => [el, true]))
        })}
        onClick={() => setShowCalendar(!showCalendar && !disabled)}
        style={{ ...(disabled ? { cursor: 'not-allowed' } : {}) }}>
        <span className="inputInlineCalendarLabel">
          {date ? moment(date).format('DD/MM/YYYY') : placeholder}
        </span>
        <div style={{ position: 'absolute', right: 26 }}>
          <Icon icon="calendar" />
        </div>
      </div>
      {(() => {
        if (showCalendar && !disabled) {
          return (
            <div
              className={'calendarWrapper'}
              style={{
                ...(position ? position : {}),
                boxShadow: 'rgb(202, 202, 202) 10px 10px 20px -14px',
              }}
              {...otherProps}>
              <Calendar
                minDate={minDate ? moment(minDate).toDate() : undefined}
                maxDate={maxDate ? moment(maxDate).toDate() : undefined}
                date={date ? moment(date).toDate() : undefined}
                position={{ right: 0 }}
                onChange={(value, event) => {
                  setDate(dateStr ? moment(value).format('YYYY-MM-DD') : value);
                  setShowCalendar(false);
                }}
                locale={ptBR}
                weekdayDisplayFormat="EEEEEE"
                disabledDay={d => {
                  if (disableWeekend) {
                    const dw = d.getDay();
                    if (dw === 0 || dw === 6) return true;
                  }
                  if (disableHolidays) {
                    const y = d.getFullYear();
                    const arrayCompare = holidays.map(h =>
                      moment(`${y}-${h}`).isSame(d, 'day')
                    );
                    if (arrayCompare.some(e => e)) return true;
                  }
                  return false;
                }}
              />
              <div style={{ background: '#fff' }}>
                <Button
                  block
                  appearance="link"
                  onClick={() => {
                    setDate(dateStr ? '' : undefined);
                    setShowCalendar(false);
                  }}
                  style={{ paddingBottom: 15 }}>
                  Limpar
                </Button>
              </div>
            </div>
          );
        }
      })()}
    </div>
  );
};

export default InlineCalendar;
