import axios from 'axios';
import getCognitoCredentials from '../Components/Helpers/getCognitoCredentials';
let ambiente = '';
if (process.env.REACT_APP_FRONT_ENV === 'LOCAL') {
  ambiente =
    'http://127.0.0.1:8000/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'DEV') {
  ambiente =
    'https://twlmvcmlnd.execute-api.us-east-1.amazonaws.com/visualizador/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'HMG') {
  ambiente =
    'https://apis-hmg.amicci-digital.com.br/visualizador/api';
}

if (process.env.REACT_APP_FRONT_ENV === 'PROD') {
  ambiente =
    'https://apis.amicci.com.br/visualizador/api';
}


const apiConnectManagement = axios.create({
  baseURL: ambiente,
});

apiConnectManagement.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    const { idToken, accessToken } = getCognitoCredentials();
    if (error.response?.status === 403 && accessToken && idToken) {
      error.response.data.message = 'Você não tem acesso';
    }
    return Promise.reject(error);
  }
);

export default apiConnectManagement;
