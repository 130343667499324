/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect /*, useMemo*/ } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Collapse,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // Input,
  // Spinner,
} from 'reactstrap';
// import { SelectPicker, DatePicker } from 'rsuite';
import { FaAngleDown, FaAngleUp /*, FaSistrix*/ } from 'react-icons/fa';
import './Sidebar.scss';
import './MenuPdm.scss';
import homeIcon from '../../Assets/home-icon.svg';
// import briefingMenuPdm from '../../Assets/briefingMenuPdm.svg';
import sourcingMenuPdm from '../../Assets/sourcingMenuPdm.svg';
// import selFornecedoresMenuPdm from '../../Assets/selFornecedoresMenuPdm.svg';
import gestaoMenuPdm from '../../Assets/gestaoMenuPdm.svg';
import { useCognitoAuth } from '../../Contexts/AuthCognitoContext';
import { useBriefingContext } from '../../Contexts/BriefingContext';
// import useDebounce from '../../utils/hooks/useDebounce';
import linkVendors from '../../utils/linkVendors';

function MenuPlm({ opened }) {
  const { user } = useCognitoAuth();
  const {
    // loading,
    // retailers,
    user: userData,
    // getRetailers,
    getUser,
    // searchBriefings,
  } = useBriefingContext();

  // const [collapseBriefing, setCollapseBriefing] = useState(
  //   opened === 'briefing'
  // );
  // const [collapseNewBriefing, setCollapseNewBriefing] = useState(false);
  const [collapseSourcing, setCollapseSourcing] = useState(
    opened === 'sourcing'
  );
  const [collapseManagement, setCollapseManagement] = useState(
    opened === 'management'
  );
  // const [collapseSelectSupplier, setCollapseSelectSupplier] = useState(
  //   opened === 'select-supplier'
  // );
  // const [showNewProductModal, setShowNewProductModal] = useState(false);

  // const [selectedRetailer, setSelectedRetailer] = useState(null);
  // const [selectedProject, setSelectedProject] = useState('');
  // const [searchTerm, setSearchTerm] = useState('');
  // const [projects, setProjects] = useState([]);
  // const debounceSearchTerm = useDebounce(searchTerm, 600);

  // const [name, setName] = useState('');
  // const [dateValue, setDateValue] = useState(null);

  const [newFormatName, setNewFormatName] = useState('');
  const [avatar, setAvatar] = useState('');

  // 1    Novo Produto
  // 2    Nova Embalagem
  // 3    Reformulação
  // 4    Extensão de Linha
  // 5    Extensão de Linha Outro Fornecedor
  // 6    Troca de Fornecedor
  // const [newType, setNewType] = useState(2);

  // function toggleCollapseBriefing() {
  //   setCollapseBriefing(!collapseBriefing);
  // }

  // function toggleCollapseNewBriefing() {
  //   setCollapseNewBriefing(!collapseNewBriefing);
  // }

  function toggleCollapseSourcing2() {
    setCollapseSourcing(!collapseSourcing);
  }

  function toggleCollapseManagement() {
    setCollapseManagement(!collapseManagement);
  }

  // function toggleCollapseSelectSupplier() {
  //   setCollapseSelectSupplier(!collapseSelectSupplier);
  // }

  // function toggleShowNewProductModal() {
  //   setShowNewProductModal(!showNewProductModal);
  // }

  // useEffect(() => {
  //   console.log('selectedProject', selectedProject);
  // }, [selectedProject]);

  // useEffect(() => {
  //   setSelectedProject('');
  //   setSearchTerm('');
  //   setProjects([]);
  // }, [selectedRetailer]);

  // useEffect(() => {
  //   if (showNewProductModal && retailers.length === 0) {
  //     getRetailers();
  //   }
  //   if (!showNewProductModal) {
  //     setSelectedProject('');
  //     setSearchTerm('');
  //     setSelectedRetailer(null);
  //     setProjects([]);
  //     setName('');
  //     setDateValue(null);
  //   }
  // }, [retailers, showNewProductModal]);

  // const optionsRetailers = useMemo(() => {
  //   return retailers.map(ret => ({
  //     value: ret.id,
  //     label: ret.name,
  //   }));
  // }, [retailers]);

  useEffect(() => {
    if (!userData) {
      const { email } = user.attributes;
      getUser(email);
    }
  }, [userData]);

  // const searchProjects = term => {
  //   if (term && term.length > 1) {
  //     setSearchTerm(term);
  //   } else {
  //     setProjects([]);
  //   }
  // };

  // useEffect(() => {
  //   if (debounceSearchTerm) {
  //     searchBriefings({
  //       briefing_name: debounceSearchTerm,
  //       retailer: selectedRetailer,
  //     }).then(res => {
  //       const list = res.data.results.map(r => ({
  //         value: r.id,
  //         label: `${r.id} - ${r.name}`,
  //       }));
  //       setProjects(list);
  //     });
  //   }
  // }, [debounceSearchTerm]);

  // const canConfirm = useMemo(() => {
  //   if (selectedProject) return true;
  //   if (name.length > 2 && dateValue) return true;
  //   return false;
  // }, [selectedProject, name, dateValue]);

  // const goNewProduct = (nType = null) => {
  //   const params = {};
  //   params.newType = typeof nType === 'number' ? nType : newType;
  //   if (selectedRetailer) params.retailer = selectedRetailer;
  //   if (selectedProject) params.project = selectedProject;
  //   if (name.length > 2) params.name = name;
  //   if (dateValue) params.date = dateValue.toISOString();
  //   const urlParams = new URLSearchParams(params).toString();
  //   setShowNewProductModal(false);
  //   window.location.href = `/pdm/briefing/novo-briefing/?${urlParams}`;
  //   // document.getElementById('closeMenuButton')?.click();
  //   // navigate(`/pdm/briefing/novo-briefing/?${urlParams}`);
  // };

  const newName = () => {
    const name = user?.username;
    const formatName = name.split('.');
    const firstName = formatName[0];
    setAvatar(firstName[0].toUpperCase());
    setNewFormatName(firstName[0].toUpperCase() + firstName.substring(1));
    return newFormatName;
  };

  useEffect(() => {
    newName();
  }, []);

  return (
    <>
      <div className="userDiv">
        <div className="avatarUser">
          <p>{avatar}</p>
        </div>
        <div className="divName">
          <p>{newFormatName}</p>
          <p className="emailUser">{user?.attributes?.email}</p>
        </div>
      </div>
      <ul className="ulMenuSidebar">
        <li>
          <p className="linkMenu">
            <NavLink end to="/home">
              <img src={homeIcon} alt="home" style={{ marginTop: '-5px' }} />
              Página inicial
            </NavLink>
          </p>
        </li>
        {/* BRIEFING */}
        {/* <li>
          <p
            onClick={toggleCollapseBriefing}
            id="toggleBriefing"
            className="linkMenu">
            <img src={briefingMenuPdm} alt="Briefing" />
            Briefing{' '}
            <span>
              {collapseBriefing ? (
                <FaAngleUp className="iconMenu" size="17" />
              ) : (
                <FaAngleDown className="iconMenu" size="17" />
              )}
            </span>
          </p>
          <Collapse
            isOpen={collapseBriefing}
            className="linkAccordion"
            toggler="#toggleBriefing">
            <p
              onClick={toggleCollapseNewBriefing}
              id="toggleNewBriefing"
              className="optionMenu">
              Novo Projeto
              <span>
                {collapseNewBriefing ? (
                  <FaAngleUp className="iconMenu" size="17" />
                ) : (
                  <FaAngleDown className="iconMenu" size="17" />
                )}
              </span>
            </p>
            <Collapse
              isOpen={collapseNewBriefing}
              className="linkAccordion"
              toggler="#toggleNewBriefing">
              <NavLink
                end
                onClick={() =>
                  (window.location.href = '/pdm/briefing/novo-briefing/')
                }
                to="/pdm/briefing/novo-briefing/"
                className="linkInsideMenu">
                Novo Produto
              </NavLink>
              <button
                type="button"
                onClick={() => {
                  setNewType(2);
                  setShowNewProductModal(true);
                }}
                className="buttonInsideMenu">
                Nova Embalagem
              </button>
              <button
                type="button"
                onClick={() => {
                  setNewType(3);
                  setShowNewProductModal(true);
                }}
                className="buttonInsideMenu">
                Reformulação
              </button>
              <button
                type="button"
                onClick={() => {
                  setNewType(6);
                  setShowNewProductModal(true);
                }}
                className="buttonInsideMenu">
                Troca de Fornecedor
              </button>
              <button
                type="button"
                onClick={() => {
                  setNewType(4);
                  setShowNewProductModal(true);
                }}
                className="buttonInsideMenu">
                Ext. de Linha
              </button>
              <br />
            </Collapse>

            <NavLink end to="/pdm/briefing" className="optionMenu">
              Painel de Projetos
            </NavLink>
          </Collapse>
        </li> */}
        <li>
          <p
            onClick={toggleCollapseSourcing2}
            id="toggleSourcing"
            className="linkMenu">
            <img src={sourcingMenuPdm} alt="Sourcing" />
            Sourcing{' '}
            <span>
              {collapseSourcing ? (
                <FaAngleUp className="iconMenuSourcing" size="17" />
              ) : (
                <FaAngleDown className="iconMenuSourcing" size="17" />
              )}
            </span>
          </p>
          <Collapse
            isOpen={collapseSourcing}
            className="linkAccordion"
            toggler="#toggleSourcing">
            {/*
            <div style={{ display: 'flex' }}>
              <NavLink end to="/pdm/sourcing" className="optionMenu">
                Painel de Projetos
              </NavLink>
            </div>
            <div style={{ display: 'flex' }}>
              <NavLink end to="/pdm/prospection/" className="optionMenu">
                Controle de Projetos
              </NavLink>
            </div>
              */}
            <div style={{ display: 'flex' }}>
              <a
                target="_blank"
                href={linkVendors}
                className="optionMenu"
                rel="noreferrer">
                Base de Fornecedores
              </a>
            </div>
          </Collapse>
        </li>
        {/* <li>
          <p
            onClick={toggleCollapseSelectSupplier}
            id="toggleSelectSuplier"
            className="linkMenu">
            <img src={selFornecedoresMenuPdm} alt="SelFornecedores" />
            Sel. Fornecedores{' '}
            <span>
              {collapseSelectSupplier ? (
                <FaAngleUp className="iconMenuSourcing" size="17" />
              ) : (
                <FaAngleDown className="iconMenuSourcing" size="17" />
              )}
            </span>
          </p>
          <Collapse
            isOpen={collapseSelectSupplier}
            className="linkAccordion"
            toggler="#toggleSelectSupplier">
            <NavLink end to="/pdm/select-supplier" className="optionMenu">
              Painel de Projetos
            </NavLink>
          </Collapse>
        </li> */}
        <li>
          <p
            onClick={toggleCollapseManagement}
            id="toggleManagement"
            className="linkMenu">
            <NavLink end to="/pdm/management">
              <img src={gestaoMenuPdm} alt="Management" />
              Gestão{' '}
            </NavLink>
          </p>
        </li>
      </ul>
      {/* <Modal isOpen={showNewProductModal} toggle={toggleShowNewProductModal}>
        <ModalBody className="modalNewProducst">
          <p className="textNewProduct">
            Por favor, indique de qual projeto e cliente pretende fazer a{' '}
            <span className="orange">
              {newType === 2 && 'Nova embalagem'}
              {newType === 3 && 'Reformulação'}
              {newType === 4 && 'Extensão de Linha'}
              {newType === 6 && 'Troca de Fornecedor'}
            </span>
          </p>
          <fieldset className="blueSearch fieldsetModal">
            <button
              type="button"
              className="blueBtnPickerSearch"
              onClick={() => {
                document.getElementById('selectRetailer')?.click();
              }}>
              {loading.retailers ? (
                <Spinner size="sm" />
              ) : (
                <FaSistrix size={20} />
              )}
            </button>
            <SelectPicker
              id="selectRetailer"
              className="bluePickerSearch"
              value={selectedRetailer}
              onChange={setSelectedRetailer}
              data={optionsRetailers}
              placeholder="Especifique o Cliente"
            />
          </fieldset>
          <fieldset className="blueSearch fieldsetModal">
            <button
              type="button"
              className="blueBtnPickerSearch"
              onClick={() => {
                document.getElementById('selectProject')?.click();
              }}>
              <FaSistrix size={20} />
            </button>
            <SelectPicker
              id="selectProject"
              className="bluePickerSearch"
              value={selectedProject}
              onChange={setSelectedProject}
              data={projects}
              onSearch={searchProjects}
              renderMenu={menu => {
                if (loading.searchBriefings) {
                  return (
                    <p
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        marginBottom: '10px',
                      }}>
                      <Spinner size="sm" /> Buscando...
                    </p>
                  );
                }
                return menu;
              }}
              placeholder="Indique o Projeto"
            />
          </fieldset>
          <p className="textNewProduct">
            Caso o Projeto não conste na base, por favor insira manualmente o{' '}
            <span>Nome</span> e <span>Data de Lançamento</span>
          </p>
          <div className="lineInputs">
            <Input
              value={name}
              onChange={e => setName(e.target.value)}
              className="lightblueInput"
              placeholder="Ex: Chocolate 500"
            />
            <DatePicker
              format="DD/MM/YYYY"
              placement="rightEnd"
              onChange={setDateValue}
              value={dateValue}
              placeholder="Dia/Mês/Ano"
              name="datePickerInput"
              className="lightbluePicker"
              locale={{
                sunday: 'D',
                monday: 'S',
                tuesday: 'T',
                wednesday: 'Q',
                thursday: 'Q',
                friday: 'S',
                saturday: 'S',
                ok: 'OK',
                today: 'Hoje',
                yesterday: 'Ontem',
                hours: 'Horas',
                minutes: 'Minutos',
                seconds: 'Segundos',
              }}
            />
          </div>
          <ModalFooter className="footerModal">
            {newType !== 4 ? (
              <>
                <button
                  type="button"
                  className="btnRed"
                  onClick={toggleShowNewProductModal}>
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btnGreen"
                  onClick={goNewProduct}
                  disabled={!canConfirm}>
                  Confirmar
                </button>
              </>
            ) : (
              <div className="areaBtnsGray">
                <button
                  type="button"
                  className="btnGray"
                  disabled={!canConfirm}
                  onClick={goNewProduct}>
                  Mesmo Fornecedor
                </button>
                <button
                  type="button"
                  className="btnGray"
                  disabled={!canConfirm}
                  onClick={() => goNewProduct(5)}>
                  Fornecedor Diferente
                </button>
              </div>
            )}
          </ModalFooter>
        </ModalBody>
      </Modal> */}
    </>
  );
}

export default MenuPlm;
