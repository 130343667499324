import React from 'react';
import './ButtonDefault.scss';
import { classNames } from '../../utils/utils';
import { Spinner } from 'reactstrap';

const ButtonDefault = ({
  color = 'blue',
  type = 'button',
  size = 'large',
  icon,
  round,
  hidden,
  active,
  radius,
  opacity,
  bold,
  colorIcon,
  className = '',
  loading,
  children,
  ...otherProps
}) => {
  const classes = classNames({
    buttons: true,
    [`btn-${color}`]: true,
    [`btn-${size}`]: true,
    'btn-round': round,
    'btn-hidden': hidden,
    'btn-active': active,
    'btn-radius': radius,
    'btn-opacity': opacity,
    'btn-bold': bold,
    [`icon-${icon}`]: !!icon,
    [`color-icon-${colorIcon}`]: !!colorIcon,
  });
  return (
    <>
      <button type={type} {...otherProps} className={classes}>
        {loading ? (
          <Spinner size="sm" color="secondary">
            {' '}
          </Spinner>
        ) : (
          children
        )}
      </button>
    </>
  );
};

export default ButtonDefault;
