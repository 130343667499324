import React from "react";
import { Loader } from "rsuite";
import styles from "./style.module.css";

const Loading = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.loading}>
        <Loader speed="slow" size="lg" />
      </div>
    </div>
  );
};

export default Loading;
