/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import './SourcingForm.css';
import { useParams } from 'react-router';
import Moment from 'moment';
import { useCognitoAuth } from '../../../../Contexts/AuthCognitoContext';
import Table from 'reactstrap/lib/Table';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import upload_icon from '../../../../Assets/upload_icon.png';
import calendar_icon from '../../../../Assets/calendar_icon.png';
import back_icon from '../../../../Assets/back_button.png';
import obsGeral_icon from '../../../../Assets/obsGeral_icon.png';
import cd_destino from '../../../../Assets/cd_destino.png';
import obs_icon from '../../../../Assets/obs_icon.png';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import HeaderModule from '../../../../Components/HeaderModule/HeaderModule';
import { Alert } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import MenuPdm from '../../../../Components/Sidebar/MenuPdm';
import { Loader } from 'rsuite';
import { PROJECT_TYPE } from '../../Management/constants/constants';
import { useBriefingContext } from '../../../../Contexts/BriefingContext';
import { dateToLocalJSON } from '../../../../utils/utils';
import { useManagementContext } from '../../../../Contexts/ManagementContext';

let alertBeforeExit = false;

function SourcingForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    user: {
      attributes: { email },
    },
  } = useCognitoAuth();
  const [department, setDepartment] = useState(0);
  const [files, setFiles] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [sourceSupplierId, setSourceSupplierId] = useState([]);
  const [sourceDetail, setSourceDetail] = useState({});
  const [sourceItem, setSourceItem] = useState([]);
  const [sourceFile, setSourceFile] = useState([]);
  const [notes, setNotesData] = useState([]);
  const [selectedNote, setSelectedNote] = useState('');

  const {
    user,
    states,
    getUser,
    getBriefing,
    searchProviders,
    getStates,
    uploadFiles,
    removeFile,
    updateSourcing,
    ...briefingContext
  } = useBriefingContext();

  const { loading, project, ...managementContext } = useManagementContext();

  useEffect(() => {
    if (!user && email) {
      getUser(email);
    }

    if (states.length === 0) {
      getStates();
    }

    getData();

    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  useEffect(() => {
    if (user) {
      setDepartment(user.department);
      if (
        !['SOURCING', 'DIGITAL', 'DIRETORIA'].includes(user.department_name)
      ) {
        navigate('/pdm/');
        Alert.error('Você não tem acesso a essa área.');
      }
    }
  }, [user]);

  useEffect(() => {
    console.log('project', project);
  }, [project]);

  const alertUser = e => {
    if (alertBeforeExit) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  async function getData() {
    const { data } = await getBriefing(id);
    // console.log('briefings_full', res.data);
    setNotesData(data.notes);
    setSourceDetail(data);
    setSourceItem(data.items);
    setSourceFile(data.files);

    managementContext.getProject(data.project_id);

    let ids = [];

    // if (data.suppliers && data.suppliers.length > 0) {
    //   console.log('data.suppliers)', data.suppliers);
    //   data.suppliers.forEach(p => ids.push(p.supplier));
    // }
    const resp = await briefingContext.getSuppliersByProject(data.project_id);
    const {
      data: { commercial_filters },
    } = resp;
    if (commercial_filters.length > 0) {
      commercial_filters.forEach(cf => ids.push(cf.supplier_id));
    }
    // verifica se passou fornecedores pela url
    const queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      if (urlParams) {
        const providers = urlParams.get('providers');
        if (providers) {
          alertBeforeExit = true;
          const arr = providers.split(',').map(n => parseInt(n));
          ids = [...new Set([...ids, ...arr])];
        }
      }
    }

    const idsString = ids.join(',');

    if (idsString) {
      const resp = await searchProviders(null, idsString);
      setSourceSupplierId(resp.data);
    }
  }

  const suppliersIds = useMemo(
    () => (sourceSupplierId || []).map(s => s.id).join(','),
    [sourceSupplierId]
  );

  function handleChangeFile(e) {
    alertBeforeExit = true;
    const filesUpload = [...e.target.files];
    console.log('filesUpload', filesUpload);
    const fileTypes = [
      'image/jpg',
      'application/vnd.ms-excel',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.spreadsheet-template',
      'text/csv',
      'text/plain',
      'application/msword',
      'image/png',
      'image/jpeg',
      'image/bmp',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/pdf',
    ];
    const _files = [];
    for (const [i, file] of filesUpload.entries()) {
      console.log('file', file);
      // testa o tipo do arquivo
      if (fileTypes.some(type => type === file.type)) {
        // testa o tamanho do arquivo
        if (file.size < 30000000) {
          _files.push(file);
        } else {
          setTimeout(() => {
            Alert.error(
              `Seu arquivo "${file.name}" precisa ter menos de 30MB!`,
              5000
            );
          }, i * 200);
        }
      } else {
        setTimeout(() => {
          Alert.error(`Arquivo "${file.name}" inválido!`, 5000);
        }, i * 200);
      }
    }
    if (_files.length > 0) {
      setFiles(arqs => {
        const arr = [...arqs];
        // antes de adicionar, testa se já add antes
        for (const file of _files) {
          if (!arr.some(f => f.name === file.name)) {
            arr.push(file);
          }
        }
        return arr;
      });
    }
  }

  function deleteFile(i) {
    alertBeforeExit = true;
    setFiles(arqs => arqs.filter((el, _i) => _i !== i));
  }

  function setToRemoveFile(f) {
    alertBeforeExit = true;
    setFilesToRemove(arqs => [...arqs, f]);
    setSourceFile(arqs => arqs.filter(a => a.id !== f.id));
  }

  const onError = error => {
    console.error('error', error.response);
    if (error && error.response && error.response.status === 401) {
      Alert.error('Você não possui permissão para alterar este projeto.', 5000);
    } else if (
      error &&
      error.message === 'Request failed with status code 401'
    ) {
      Alert.error('Você não possui permissão para alterar este projeto.', 5000);
    } else {
      Alert.error(error.message, 5000);
    }
  };

  function handleClickSave(e) {
    e.preventDefault();
    if (department === 14) {
      const arrPromises = [];

      const upSourcing = async () => {
        const data = { ...sourceDetail };
        data.suppliers = [...sourceSupplierId].map(s => ({
          supplier: s.id,
          name: s.name,
        }));
        data.current_user = email;
        // atualiza fornecedores em viabilidade
        await saveSuppliers(data.suppliers, data.project_id);
        // atualiza sourcing
        const resp = await updateSourcing(data, id);
        console.log('updateSourcing', data, data.suppliers);

        // console.log('resp', resp);
        alertBeforeExit = false;
        if (resp?.success) {
          setTimeout(() => {
            window.location.href = '/pdm/sourcing/';
          }, 150);
        } else {
          onError(resp);
        }
        // console.log('put briefings/sourcing/{id}', resp);
      };

      if (files.length > 0 || filesToRemove.length > 0) {
        if (files.length > 0) {
          const arrayFiles = [...files];
          for (let i in arrayFiles) {
            arrayFiles[i]['_type'] = 3;
          }

          // YYYY-MM-DD-HH-MM-SS-
          const dateToFile = dateToLocalJSON()
            .replace(/[T:.]/g, '-')
            .substring(0, 20);

          arrPromises.push(uploadFiles(arrayFiles, id, dateToFile));
        }

        if (filesToRemove.length > 0) {
          for (const file of filesToRemove) {
            arrPromises.push(removeFile(file.id));
          }
        }

        Promise.all(arrPromises)
          .then(v => {
            console.log('subiu tudo!!!', v);
            upSourcing();
          })
          .catch(onError);
      } else {
        upSourcing();
      }
    } else {
      Alert.error('Você não possui permissão para alterar este projeto.', 5000);
    }
  }

  async function saveSuppliers(suppliersBriefing, projectId) {
    // faz uma consulta para trazer os fornecedores que
    // estavam associados a esse briefing
    const {
      data: { commercial_filters },
    } = await briefingContext.getSuppliersByProject(projectId);
    // console.log('comercialFilters', commercial_filters);

    const deleteds = [];
    const addeds = [];

    // verifica se algum fornecedor foi excluido
    commercial_filters.forEach(supp => {
      const hasSupp = suppliersBriefing.find(
        s => s.supplier === supp.supplier_id
      );
      if (!hasSupp) {
        deleteds.push(supp.supplier_id);
      }
    });
    // verifica se algum fornecedor foi adicionado
    suppliersBriefing.forEach(supp => {
      const hasSupp = commercial_filters.find(
        s => s.supplier_id === supp.supplier
      );
      if (!hasSupp) {
        addeds.push(supp.supplier);
      }
    });

    if (addeds.length > 0) {
      const dataSuppliers = {
        project_id: projectId,
        suppliers: addeds,
      };
      await briefingContext.setSuppliersByProject(dataSuppliers);
    }
    if (deleteds.length > 0) {
      await briefingContext.deleteSuppliersByProject(projectId, deleteds);
    }
  }

  var modal = document.getElementById('myModalDestino');
  var modal1 = document.getElementById('myModalObserve');
  var modal2 = document.getElementById('myModalObserva');
  // var btn = document.getElementById('myBtnDestino');
  // var span = document.getElementsByClassName('modal-close')[0];

  function desti() {
    modal.style.display = 'block';
  }

  function observe() {
    modal1.style.display = 'block';
  }

  function observa({ notes }) {
    setSelectedNote(notes);
    modal2.style.display = 'block';
  }

  function mode() {
    modal.style.display = 'none';
  }

  function mode1() {
    modal1.style.display = 'none';
  }

  function mode2() {
    modal2.style.display = 'none';
  }

  window.onclick = function (event) {
    if (event.target === modal) {
      modal.style.display = 'none';
    }
  };

  window.onclick = function (event) {
    if (event.target === modal) {
      modal1.style.display = 'none';
    }
  };

  const removeProvider = idProvider => {
    alertBeforeExit = true;
    setSourceSupplierId(list => list.filter(p => p.id !== idProvider));
  };

  return (
    <>
      <HeaderModule classHeader="pdmHeaderModule">Sourcing</HeaderModule>
      <Sidebar>
        <MenuPdm opened="sourcing" />
      </Sidebar>
      {sourceDetail?.project_type &&
        sourceDetail?.project_type !== PROJECT_TYPE.NEW_PRODUCT && (
          <div className="derived-floating-badge">
            <span>Projeto derivado: </span>
            <strong>
              {sourceDetail.derived_from_name
                ? sourceDetail.derived_from_name
                : '--'}
            </strong>
          </div>
        )}
      <div className="mainDiv modal-sourcing">
        <div id="myModalObserve" className="modal">
          <div className="modal-content" id="modalObserve">
            <button id="closeObserve" className="modal-close" onClick={mode1}>
              x
            </button>
            <h5>Observação Geral</h5>
            <br />
            <div className="orange_board">{notes}</div>
          </div>
        </div>
        <div id="myModalObserva" className="modal">
          <div className="modal-content" id="modalObserva">
            <button
              id="closeObserva"
              className="modal-close"
              onClick={mode2}
              style={{ padding: '5px 10px' }}>
              x
            </button>
            <h5 style={{ marginTop: '15px', fontSize: '13px' }}>Observação</h5>
            <br />
            <div className="modal-obs-list-itens">
              <div className="orange_board">{selectedNote}</div>
            </div>
          </div>
        </div>
        <div id="myModalDestino" className="modal">
          <div className="modal-content" id="modalDestino">
            <button id="closeDestino" className="modal-close" onClick={mode}>
              x
            </button>
            <h5>CD DESTINO</h5>
            <div className="hold-empty-cd-destinos">
              {sourceDetail.prospection_type === 1 && (
                <p>
                  <strong>Tipo de distribuição</strong>:{' '}
                  {sourceDetail.distribution_type_name}
                </p>
              )}
              {sourceItem &&
                (sourceItem.filter(si => si.cd_states.length > 0).length === 0
                  ? "Não existem Cd's Destino cadastrados para este projeto."
                  : null)}
            </div>

            {sourceItem &&
              sourceItem
                .filter(si => si.cd_states.length > 0)
                .map(curElem11 => {
                  return (
                    <div key={curElem11.id}>
                      <li className="cd_product">{curElem11.description}</li>
                      {curElem11.cd_states &&
                        curElem11.cd_states.map(element1 => {
                          return (
                            <div className="cd_list" key={element1}>
                              {states
                                .filter(state => {
                                  if (state.id === element1) {
                                    return state.code;
                                  }
                                  return false;
                                })
                                .map(function (data) {
                                  return data.code;
                                })}
                              <br />
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
          </div>
        </div>
        <a href="/pdm/sourcing" className="floatRight closeButton">
          Voltar <img alt="voltar" src={back_icon} className="backIcon" />
        </a>
        <Table className="sourcingList">
          <tbody>
            <tr className="borderTop">
              <td>Divisão</td>
              <td>Projeto</td>
              <td>Previsao Lançamento</td>
              <td>Gestor Cliente</td>
              <td>Responsável DTI</td>
            </tr>
            <tr className="borderTop">
              <td>
                <button className="btnOrange sourcingList cursor-dis">
                  {sourceDetail.division}
                </button>
              </td>
              <td>
                <button className="btnOrange sourcingList cursor-dis">
                  {sourceDetail.name}
                </button>
              </td>
              <td>
                <button className="btnOrange sourcingList cursor-dis">
                  {loading.project ? (
                    <Loader speed="slow" size="sm" />
                  ) : (
                    Moment(project?.launch_forecast_date).format('DD/MM/YYYY')
                  )}{' '}
                  <img
                    alt="calendario"
                    src={calendar_icon}
                    className="calIcon"
                  />
                </button>
              </td>
              <td>
                <button className="btnOrange sourcingList cursor-dis">
                  {sourceDetail.retailer_manager_user_name}
                </button>
              </td>
              <td>
                <button className="btnOrange sourcingList cursor-dis">
                  {sourceDetail.dti_user_name}
                </button>
              </td>
              <td>
                <button
                  className="floatLeft btnGray sourcingList pt-6"
                  onClick={observe}>
                  Observação Geral
                  <img alt="obs" src={obsGeral_icon} className="ObsIcon" />
                </button>
              </td>
              <td>
                <button
                  className="floatLeft btnGray sourcingList pt-6"
                  id="myBtnDestino"
                  onClick={desti}>
                  CD Destino{' '}
                  <img alt="cdDestino" src={cd_destino} className="CDIcon" />
                </button>
              </td>
            </tr>
          </tbody>
        </Table>
        <br />
        <br />
        <table className="sourcingList">
          <tbody>
            <tr>
              <td className="t1">
                <span className="division-blue">DESCRIÇĀO DO ITEM</span>
              </td>
              <td className="t2">
                <span className="division-grey">TARGET</span>
              </td>
              <td className="t3">
                <span className="division-yellow">PREMISSAS COMERCIAIS</span>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="sourcingList">
          <tbody>
            <tr>
              <td className="v-align-b">Produto</td>
              <td className="v-align-b">Embalagem</td>
              <td className="v-align-b">Gramatura</td>
              <td className="v-align-b">
                Target
                <br /> Qualidade
              </td>
              <td className="v-align-b">
                Target
                <br /> Preço
              </td>
              <td className="v-align-b">
                PV
                <br /> Objetivo
              </td>
              <td className="v-align-b">
                Margem
                <br /> Objetiva
                <br />
                (%)
              </td>
              <td className="v-align-b">Volume</td>
              <td className="v-align-b">
                Contrato
                <br /> Amicci
                <br />
                (%)
              </td>
              <td className="v-align-b">
                Contrato
                <br />
                Varejista
                <br />
                (%)
              </td>
              <td className="v-align-b">
                Prazo de <br />
                Pagamento
              </td>
              <td className="v-align-b">Observação</td>
            </tr>
            {sourceItem.map(curElem => {
              return (
                <tr key={curElem.id}>
                  <td>
                    <button className="btnGray btnResult sourcingList m-top cursor-dis">
                      {`${curElem.product} ${curElem.variant}`}
                    </button>
                  </td>
                  <td>
                    <button className="btnGray btnResult sourcingList m-top cursor-dis">
                      {curElem.package}
                    </button>
                  </td>
                  <td>
                    <button className="btnGray btnResult sourcingList m-top cursor-dis">
                      {curElem.weight}
                    </button>
                  </td>
                  <td>
                    <button className="btnGray btnResult sourcingList m-top cursor-dis">
                      {curElem.quality_target}
                    </button>
                  </td>
                  <td>
                    <button className="btnGray btnResult sourcingList m-top cursor-dis">
                      {curElem.price_target}
                    </button>
                  </td>
                  <td>
                    <button className="btnGray btnResult sourcingList m-top cursor-dis">
                      {curElem.sale_price_goal}
                    </button>
                  </td>
                  <td>
                    <button className="btnGray btnResult sourcingList m-top cursor-dis">
                      {curElem.margin_goal}
                    </button>
                  </td>
                  <td>
                    <button className="btnGray btnResult sourcingList m-top cursor-dis">
                      {curElem.volume}
                    </button>
                  </td>
                  <td>
                    <button className="btnGray btnResult sourcingList m-top cursor-dis">
                      {curElem.amicci_contract}
                    </button>
                  </td>
                  <td>
                    <button className="btnGray btnResult sourcingList m-top cursor-dis">
                      {curElem.retailer_contract}
                    </button>
                  </td>
                  <td>
                    <button className="btnGray btnResult sourcingList m-top cursor-dis">
                      {curElem.payment_term}
                    </button>
                  </td>
                  <td>
                    <button
                      className="btnGray btnResult sourcingList  m-top"
                      onClick={() => observa(curElem)}>
                      <img alt="obs2" src={obs_icon} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
        <br />
        <br />
        <button
          type="button"
          className="floatLeft btn btnBlue"
          disabled={!sourceDetail.id}
          onClick={() => {
            alertBeforeExit = false;
            setTimeout(() => {
              window.location.href = `/pdm/sourcing/${sourceDetail.id}/suppliers?providers=${suppliersIds}`;
            }, 150);
          }}>
          Consultar Fornecedor <i className="fa fa-search"> </i>
        </button>
        <form>
          <div
            style={{ position: 'relative', cursor: 'pointer' }}
            className="floatLeft btn btnOrange m-left">
            Upload <img alt="upload" src={upload_icon} />
            <input
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                opacity: 0,
                cursor: 'pointer',
                width: '100%',
              }}
              type="file"
              multiple
              accept=".xls,.xlsx,.pdf,.jpeg,.doc,.docx,.jpg,.png"
              onChange={handleChangeFile}
              name="uploader"
            />
          </div>
          <br clear="all" />
          <br />
          <div className="content-form">
            <ul className="floatLeft list-suppliers">
              {sourceSupplierId.length > 0 &&
                sourceSupplierId.map(curElem => {
                  return (
                    <li key={curElem.id}>
                      <div className="btnBlue margin5 btn-supplier">
                        <span>{curElem.name}</span>
                        <button type="button" id={`btn-provider-${curElem.id}`}>
                          <i className="fa fa-trash"></i>
                        </button>
                        <UncontrolledPopover
                          trigger="legacy"
                          placement="top"
                          target={`btn-provider-${curElem.id}`}>
                          <PopoverHeader>Remover fornecedor</PopoverHeader>
                          <PopoverBody>
                            <p>Deseja remover o fornecedor {curElem.name}?</p>
                            <br />
                            <div className="popover-footer">
                              <button
                                className="btnOrange"
                                type="button"
                                onClick={() => removeProvider(curElem.id)}>
                                Remover
                              </button>
                            </div>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </div>
                    </li>
                  );
                })}
            </ul>
            <ul className="floatRight listBox">
              {sourceFile.map(curElem => {
                return (
                  <li key={curElem.id} className="button-files">
                    <a href={curElem.file} target="_blank" rel="noreferrer">
                      {curElem.name}
                    </a>
                    <button
                      title="Remover arquivo?"
                      type="button"
                      onClick={() => setToRemoveFile(curElem)}>
                      <i className="fa fa-trash"></i>
                    </button>
                  </li>
                );
              })}
              {files.map((file, i) => (
                <li key={`file-${i}`} className="button-files">
                  <span>{file.name}</span>
                  <button
                    title="Remover arquivo?"
                    type="button"
                    onClick={() => deleteFile(i)}>
                    <i className="fa fa-trash"></i>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <br clear="all" />
          <button
            type="button"
            className="btn btnGray m-center"
            disabled={
              !(sourceFile.length > 0 || files.length > 0) ||
              sourceSupplierId.length === 0
            }
            onClick={handleClickSave}>
            Salvar
          </button>
        </form>
      </div>
    </>
  );
}

export default SourcingForm;
