/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import './InlineDateRange.scss';
import { classNames } from '../../utils/utils';
import { Icon, Button, Row, Col } from 'rsuite';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ptBR } from 'date-fns/locale';
// import { event } from 'react-ga';

const InlineDateRange = ({
  dateRange = {
    startDate: new Date(),
    endDate: new Date(),
  },
  setDateRange = () => {},
  position,
  disabled,
  ...otherProps
}) => {
  const classes = classNames({
    DateRangeWrapper: true,
  });
  const [tempValue, setTempValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [showDateRange, setShowDateRange] = useState(false);
  const wrapperRef = useRef(null);

  const cancelSelection = () => {
    setTempValue({
      startDate: dateRange ? dateRange.startDate : new Date(),
      endDate: dateRange ? dateRange.endDate : new Date(),
      key: 'selection',
    });
    setShowDateRange(false);
  };

  // Atualização do display interno
  useEffect(() => {
    setTempValue({
      startDate: dateRange ? dateRange.startDate : new Date(),
      endDate: dateRange ? dateRange.endDate : new Date(),
      key: 'selection',
    });
  }, [dateRange]);

  // Lida com concelamento via teclado e mouse
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        cancelSelection();
      }
    }

    function detectEscPressed(event) {
      if (event.key === 'Escape') {
        cancelSelection();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', detectEscPressed);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', detectEscPressed);
    };
  }, [wrapperRef, cancelSelection]);

  return (
    <div ref={wrapperRef}>
      <div
        className="inputInlineDateRange"
        onClick={() => setShowDateRange(!showDateRange && !disabled)}
        style={{ ...(disabled ? { cursor: 'not-allowed' } : {}) }}>
        {dateRange ? (
          <span className="inputInlineDateRangeLabel">
            {dateRange.startDate.toLocaleDateString('pt-BR')}
            {` - `}
            {dateRange.endDate.toLocaleDateString('pt-BR')}
          </span>
        ) : (
          <span className="inputInlineDateRangeLabel">Filtrar data</span>
        )}
        <div style={{ position: 'absolute', right: 16 }}>
          <Icon icon="calendar" />
        </div>
      </div>
      {(() => {
        if (showDateRange && !disabled) {
          return (
            <div
              className={classes}
              style={{
                ...(position ? position : {}),
                boxShadow: 'rgb(202, 202, 202) 10px 10px 20px -14px',
              }}
              {...otherProps}>
              <DateRange
                ranges={[tempValue]}
                position={{ right: 0 }}
                onChange={value => {
                  setTempValue(value.selection);
                  //setShowDateRange(false);
                }}
                locale={ptBR}
                weekdayDisplayFormat="EEEEEE"
              />
              <div style={{ background: '#fff' }}>
                <Row>
                  <Col xs={12}>
                    <Button
                      block
                      appearance="link"
                      onClick={() => {
                        setDateRange(null);
                        setShowDateRange(false);
                      }}
                      style={{ paddingBottom: 15 }}>
                      Limpar
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Button
                      block
                      appearance="link"
                      onClick={() => {
                        setDateRange({
                          startDate: tempValue.startDate,
                          endDate: tempValue.endDate,
                        });
                        setShowDateRange(false);
                      }}
                      style={{ paddingBottom: 15 }}>
                      OK
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          );
        }
      })()}
    </div>
  );
};

export default InlineDateRange;
