import apiConnectSourcing from '../../services/apiConnectSourcing';
import apiConnectManagement from '../../services/apiConnectManagement';

const setCognitoCredentials = userSession => {
  const idToken = userSession.idToken.jwtToken;
  const userGroupCognito = userSession.idToken.payload['cognito:groups'];

  const accessToken = userSession.accessToken.jwtToken;
  const refreshToken = userSession.refreshToken.token;
  const expiredToken = userSession.idToken.payload.exp;

  localStorage.setItem('id-token-cognito', idToken);
  localStorage.setItem('access-token-cognito', accessToken);
  localStorage.setItem('refresh-token-cognito', refreshToken);
  localStorage.setItem('expired-token-cognito', expiredToken);
  localStorage.setItem('user-group-cognito', JSON.stringify(userGroupCognito));

  apiConnectSourcing.defaults.headers.common['authorization'] = 'x';
  apiConnectSourcing.defaults.headers.common['id_token'] = idToken;
  apiConnectSourcing.defaults.headers.common['access_token'] = accessToken;

  apiConnectManagement.defaults.headers.common['authorization'] = 'x';
  apiConnectManagement.defaults.headers.common['id_token'] = idToken;
  apiConnectManagement.defaults.headers.common['access_token'] = accessToken;
};

export default setCognitoCredentials;
