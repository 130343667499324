let linkPlataform = '';
if (process.env.REACT_APP_FRONT_ENV === 'LOCAL') {
  linkPlataform = '/';
}

if (process.env.REACT_APP_FRONT_ENV === 'DEV') {
  linkPlataform = 'https://buy-dev.amicci-digital.com.br';
}

if (process.env.REACT_APP_FRONT_ENV === 'HMG') {
  linkPlataform = 'https://buy-hmg.amicci-digital.com.br';
}

if (process.env.REACT_APP_FRONT_ENV === 'PROD') {
  linkPlataform = 'https://platform.amicci.com.br';
}

export default linkPlataform;
