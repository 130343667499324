import React, { useState, useMemo, useEffect } from 'react';
import './SelectPicker.scss';
import { BiSearch } from 'react-icons/bi';
import { BsChevronDown, BsX } from 'react-icons/bs';
import { OutsideAlerter } from '../../utils/hooks/useOutsideAlerter';

const SelectPicker = ({
  data,
  value,
  onChange,
  cleanable,
  placeholder = 'Selecione..',
  searchPlaceholder = 'Buscar..',
  disabled,
  error = false,
}) => {
  const [innerValue, setInnerValue] = useState(value || '');
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);

  const filteredData = useMemo(() => {
    if (data && data.length > 0) {
      return search
        ? data.filter(item => RegExp(search, 'ig').test(item.label))
        : data;
    }
    return [];
  }, [data, search]);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  useEffect(() => {
    if (!show) {
      setSearch('');
    }
  }, [show]);

  const showList = () => {
    setShow(true);
  };

  const hideList = () => {
    setTimeout(() => {
      setShow(false);
    }, 100);
  };

  const selectValue = v => {
    setInnerValue(v);
    if (onChange) onChange(v);
    hideList();
  };

  const clearValue = () => {
    setInnerValue('');
    if (onChange) onChange('');
  };

  return (
    <OutsideAlerter onClickOutside={() => hideList()}>
      <div className="SelectPickerAmicci">
        <input
          className="spInput"
          list="test"
          placeholder={placeholder}
          value={
            innerValue
              ? data?.find(item => item.value === innerValue)?.label
              : ''
          }
          onFocus={showList}
          onChange={() => {}}
          disabled={disabled}
        />
        <button type="button" className="spBtnIcon" onClick={showList}>
          <BsChevronDown />
        </button>
        {cleanable && innerValue && (
          <button
            title="Limpar"
            type="button"
            className="spBtnClear"
            onClick={clearValue}>
            <BsX />
          </button>
        )}
        <div
          className="spWindowOptions"
          style={{ display: show ? 'flex' : 'none' }}>
          <input
            placeholder={searchPlaceholder}
            className="spInputSearch"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <BiSearch className="spIconSearch" />
          <ul className="spListOptions">
            {filteredData.map((item, index) => (
              <li
                className="spOption"
                key={`select-picker-${item.value}-${item.label}-${index}`}>
                <button
                  type="button"
                  className="spOptionButton"
                  onClick={() => selectValue(item.value)}>
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
        {!!error && <span className="spError">{error}</span>}
      </div>
    </OutsideAlerter>
  );
};

export default SelectPicker;
