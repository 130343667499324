import React from 'react';

const HeaderModule = ({
  children,
  classHeader,
  afterText = '',
  subText = '',
}) => {
  return (
    <div>
      <div className="headerModule-before"></div>
      <header className={`${classHeader} headerModule`}>
        <h1>{children}</h1>
        {subText && <h2 className="headerModule-subtext">{subText}</h2>}
      </header>
      {afterText && <h1 className="headerModule-after">{afterText}</h1>}
    </div>
  );
};

export default HeaderModule;
